import React from "react";
import useTokenStore from "modules/authentication/hooks/useTokenStore";
import { Route, Redirect, RouteProps } from "react-router-dom";
import hasRightForPath from "modules/roles/helpers/hasRightForPath";
import { useSelfcare } from "modules/selfcare/hooks/useSelfcare";
import FullLoader from "core/components/Fullloader/FullLoader";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";
import { PATHS } from "modules/shared/constants/paths";

const PrivateRoute = ({ ...routeProps }: RouteProps) => {
  const isAuthenticated = useTokenStore(({ token }) => Boolean(token));
  const { data: roles, isLoading } = useSelfcare();
  const hasRight = roles && hasRightForPath(routeProps.path, roles.routes);
  const hasRightForHome = useHasAccessRights(PATHS.HOME);

  if (isLoading) {
    return <FullLoader />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATHS.LOGIN} />;
  }

  if (hasRight) {
    return <Route {...routeProps} />;
  }

  return <Redirect to={hasRightForHome ? PATHS.HOME : PATHS.NO_RIGHT} />;
};

export default PrivateRoute;
