import { useQuery } from "react-query";
import { simpleRequest } from "core/services/api";
import { globalMessagedListSchema } from "modules/globalMessage/schemas/globalMessageFormSchema";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";
import { PATHS } from "modules/shared/constants/paths";
import globalsListTransform from "../helpers/globalsListTransform";

export const globalMessages = () =>
  simpleRequest(ENDPOINTS.GLOBALS, globalMessagedListSchema);

export function useGlobalMessages(fetch = true) {
  const hasAccess = useHasAccessRights(PATHS.GLOBAL_MESSAGES, ["GET"]);
  return useQuery(["globals"], globalMessages, {
    select: (data) => globalsListTransform(data, hasAccess),
    enabled: fetch,
    staleTime: 300000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
