import { omit } from "remeda";

import { ManageImageFormValues } from "../schemas/customImageSchema";

export const getUpdatedImageFromFormValues = (
  dataImage: ManageImageFormValues,
  regions?: string[]
) => {
  const dataImageValues =
    "original" in dataImage ? omit(dataImage, ["original"]) : dataImage;

  // if all regions are selected we wants to send an empty array
  dataImageValues.regions =
    dataImageValues.regions.length === regions?.length
      ? []
      : dataImageValues.regions;
  return dataImageValues;
};
