import { createIcon } from "@chakra-ui/react";

const LogoutIcon = createIcon({
  displayName: "LanguageIcon",
  viewBox: "0 0 18 18",

  path: (
    <path
      fill="currentColor"
      d="M 14.0001 5 L 12.5901 6.41 L 14.1701 8 H 6.00009 V 10 H 14.1701 L 12.5901 11.58 L 14.0001 13 L 18.0001 9 L 14.0001 5 Z M 2.00009 2 H 9.00009 V 0 H 0 C 0 0 0.000087738 0.9 0.000087738 2 V 16 C 0.000087738 17.1 0.000150502 18 0.000150502 18 H 9.00009 V 16 H 2.00009 V 2 Z"
    />
  ),
});

export default LogoutIcon;
