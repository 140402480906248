import i18n from "i18next";

const getSelectOptionsFromSchema = (
  schema: string[],
  key: string,
  withSelectOption = false
) => {
  const schemaValues = schema.map((option) => ({
    value: option,
    label: i18n.t(`${key}${option}`),
  }));
  return withSelectOption
    ? [...schemaValues, { value: null, label: i18n.t(`${key}select`) }]
    : schemaValues;
};

export default getSelectOptionsFromSchema;
