import React from "react";
import { createIcon } from "@chakra-ui/react";

const FilterIcon = createIcon({
  displayName: "FilterIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M10,18h4v-2h-4V18z M3,6v2h18V6H3z M6,13h12v-2H6V13z"
    />
  ),
});

export default FilterIcon;
