import React from "react";
import { Heading, Flex, Text, Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Frames from "modules/preview/components/Frames";
import ArrowBackIcon from "core/components/InterfaceIcons/ArrowBackIcon";
import TargetForm from "modules/target/components/TargetForm";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import useTemplate from "modules/preview/hooks/useTemplate";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";
import useIsArchivedTemplate from "modules/preview/hooks/useIsArchivedTemplate";
import ErrorMessage from "modules/shared/components/ErrorHandler/ErrorMessage";

const Target = () => {
  useTemplate();
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const isArchived = useIsArchivedTemplate();

  return isArchived ? (
    <ErrorMessage messageText={t("errors.archived")} />
  ) : (
    <ScreenContainer>
      <Heading as="h1" size="lg" mb={10} mt={10}>
        {t("actions.target.title")}
      </Heading>
      <Flex>
        <Box>
          <Flex justifyContent="space-between" mb="5px">
            <Text fontSize="xs"> {t("actions.target.subtitle")}</Text>
            <Button
              leftIcon={<ArrowBackIcon mr="5px" />}
              variant="link-button"
              as={RouterLink}
              to={`/template/${id}`}
            >
              {t("actions.target.back")}
            </Button>
          </Flex>
          <Frames />
        </Box>
        <TargetForm />
      </Flex>
    </ScreenContainer>
  );
};

export default Target;
