/**
 * returns the number value of string or null if string is null or undefined
 * @param string
 * @returns number or null
 * @signature
 *    sanitizeToNumberValue(string)
 * @category string
 */

const sanitizeStringToNumberValue = (value?: string | null) =>
  typeof value === "string"
    ? Number(value)
    : value === null || value === undefined
    ? null
    : null;

export default sanitizeStringToNumberValue;
