import { useQueryClient } from "react-query";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { methodSchema } from "core/schemas/http";
import { PostComment } from "../schemas/commentSchemas";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { commentApiSchema } from "modules/comments/schemas/commentsApiSchemas";

export const postComment = ({ templateId, comment }: PostComment) =>
  simpleRequest(
    generatePath(ENDPOINTS.COMMENTS, { id: templateId }),
    commentApiSchema,
    methodSchema.enum.post,
    {
      comment,
    }
  );

export function usePostComment() {
  const queryClient = useQueryClient();
  return useMutationWithErrorHandling(postComment, {
    onSuccess: (_, { templateId }) => {
      queryClient.invalidateQueries(["comments", templateId]);
    },
  });
}
