import { useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { useParams } from "react-router-dom";

import { simpleRequest } from "core/services/api";
import { Template } from "modules/preview/schemas/templateSchemas";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

export const saveTemplate = (template: Template) =>
  simpleRequest(
    generatePath(ENDPOINTS.SAVE, { id: template.template_id }),
    emptySchema,
    methodSchema.enum.put,
    template
  );

export function useSaveTemplate() {
  const queryClient = useQueryClient();
  const { id } = useParams<RouteIdParam>();

  return useMutationWithErrorHandling(saveTemplate, {
    onSuccess: (_, data) => {
      const previousTemplateData = queryClient.getQueryData<Template>([
        "template",
        Number(id),
      ]);

      if (previousTemplateData?.technical_details?.Frame0_Logo) {
        queryClient.invalidateQueries([
          "images",
          previousTemplateData?.technical_details?.Frame0_Logo,
        ]);
      }

      if (data.technical_details.Frame0_Logo) {
        queryClient.invalidateQueries([
          "images",
          data.technical_details.Frame0_Logo,
        ]);
      }

      queryClient.invalidateQueries(["template", Number(id)]);
      queryClient.invalidateQueries(["templates"]);
    },
  });
}
