import * as z from "zod";

export const locSchema = z.string();
export const ValidationErrorSchema = z
  .object({
    loc: z.array(locSchema),
    msg: z.string(),
    type: z.string(),
  })
  .nonstrict();

export const HTTPValidationErrorSchema = z
  .object({
    detail: z.array(ValidationErrorSchema).optional(),
  })
  .nonstrict();

export type HTTPValidationError = z.infer<typeof HTTPValidationErrorSchema>;

export const errorDataResponseSchema = z.object({
  code: z.string(),
  errors: z.object({}).nonstrict(),
});

export const errorResponseSchema = z
  .object({
    data: z
      .string()
      .or(errorDataResponseSchema)
      .or(z.object({ non_field_errors: z.array(z.string()) })),
    status: z.number(),
    statusText: z.string(),
  })
  .nonstrict();

export type ErrorResponse = z.infer<typeof errorResponseSchema>;
