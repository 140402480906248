import React from "react";
import { Box, AlertDescription } from "@chakra-ui/react";

interface Props {
  message: string;
}

const ResultAlert = ({ message }: Props) => (
  <Box flex="1" mx="20px" my="10px">
    <AlertDescription display="block">{message}</AlertDescription>
  </Box>
);

export default ResultAlert;
