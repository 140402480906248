import { MomaColor } from "modules/preview/schemas/colorSchemesSchemas";
import { defaultColorSchemeColor } from "modules/colorSchemes/constants/defaultColorSchemeColor";

const ALPHA_100 = "";
const ALPHA_75 = "bf";
const ALPHA_50 = "80";
const ALPHA_25 = "40";
const ALPHA_0 = "00";

export const alphaHexValueByAlphaIndex: {
  [key: string]: string | undefined;
} = {
  "00": ALPHA_0,
  "01": ALPHA_100,
  "02": ALPHA_75,
  "03": ALPHA_50,
  "04": ALPHA_25,
};

const getHexColorFromMomaColor = (momaColor?: MomaColor) => {
  if (!momaColor) {
    return defaultColorSchemeColor;
  }
  const hexValue = momaColor.slice(1);
  const colorValue = hexValue.slice(2);
  const alphaIndex = hexValue.slice(0, 2);
  const alphaHex = alphaHexValueByAlphaIndex[alphaIndex] || "";

  return `#${colorValue}${alphaHex}`;
};

export default getHexColorFromMomaColor;
