import React from "react";
import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useImageUrl } from "modules/images/hooks/useImageUrl";
import { CustomImage } from "../schemas/customImageSchema";

interface ImageInterface {
  image: CustomImage;
  onOpen: (id: number) => void;
}

const ImageBox = ({ image, onOpen }: ImageInterface) => {
  const { id, title, description } = image;
  const { t } = useTranslation();
  const { data: url } = useImageUrl(image);
  return url ? (
    <Flex
      direction="column"
      justifyContent="space-between"
      overflow="auto"
      mb="40px"
      mr="40px"
      border="solid 1px silver"
      p="30px 8px"
      borderRadius="2px"
    >
      <Image
        width="155px"
        height="60px"
        alt={title}
        src={url}
        border="solid 1px silver"
        objectFit="cover"
      />
      <Text textStyle="label" pt={3}>
        {title}
      </Text>
      <Text pb={3} textStyle="bodySmall">
        {description}
      </Text>
      <Button
        variant="tertiary-ghost"
        width="120px"
        height="22px"
        borderRadius="2px"
        fontSize="12px"
        onClick={() => onOpen(id)}
      >
        {t("assets.image.edit")}
      </Button>
    </Flex>
  ) : null;
};

export default ImageBox;
