import React from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useStopTemplate } from "modules/stopTemplate/hooks/useStopTemplate";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import AlertIcon from "core/components/InterfaceIcons/AlertIcon";
import { useHasStopTemplatesRights } from "./useHasStopTemplatesRights";

const StopTemplateAction = () => {
  const { t } = useTranslation();

  const { mutate } = useStopTemplate();
  const { id } = useParams<RouteIdParam>();
  const hasStopTemplateRights = useHasStopTemplatesRights();

  return (
    <Box mb="20px" mt="20px">
      <Text mt={5}>{t("actions.stop.description")}</Text>
      <Box mt="20px">
        <Button
          disabled={!hasStopTemplateRights}
          onClick={() => mutate(Number(id))}
          leftIcon={<AlertIcon />}
          variant="secondary-solid"
        >
          {t("actions.stop.button")}
        </Button>
      </Box>
    </Box>
  );
};

export default StopTemplateAction;
