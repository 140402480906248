import React, { useEffect, useState } from "react";
import {
  CheckboxGroup,
  Checkbox,
  VStack,
  Text,
  Box,
  useCheckboxGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, useController } from "react-hook-form";
import WarningMessage from "core/components/WarningMessage/WarningMessage";
import { GlobalMessageFormValues } from "modules/globalMessage/schemas/globalMessageFormSchema";
import useGlobalMessage from "../hooks/useGlobalMessage";

export interface Props {
  regions: string[] | undefined;
}

const RegionForm = ({ regions }: Props) => {
  const { t } = useTranslation();
  const { data } = useGlobalMessage();
  const globalMessageStatus = data?.status;
  const isDisabled = !!globalMessageStatus && globalMessageStatus !== "DRAFT";

  const { errors, control, setValue, watch } =
    useFormContext<GlobalMessageFormValues>();

  const [selectedRegion, setSelectedRegion] = useState<string[] | []>(
    watch("regions") || []
  );

  const isError = errors.regions;

  const {
    field: { ref, ...checkboxGroupProps },
  } = useController({
    name: "regions",
    control,
    defaultValue: [],
  });
  const { getCheckboxProps } = useCheckboxGroup(checkboxGroupProps);

  const handleChange = (value: string[]) => {
    setSelectedRegion(value === selectedRegion ? [] : value);
  };

  useEffect(() => {
    setValue("regions", selectedRegion ? selectedRegion : []);
  }, [selectedRegion, setValue]);

  return (
    <Box>
      <Text
        fontSize="xs"
        mb="20px"
        color={isError ? "error" : "dark"}
        textAlign="left"
      >
        {t("global_message.regions.label")}
      </Text>
      <VStack align="left" spacing={0}>
        <CheckboxGroup value={selectedRegion ? selectedRegion : []}>
          {regions?.map((region) => {
            const checkboxProps = getCheckboxProps({ value: region });
            return (
              <Checkbox
                {...checkboxProps}
                key={region}
                isChecked={checkboxProps.isChecked}
                isDisabled={isDisabled}
                alignItems="flex-start"
                value={region}
                lineHeight="15px"
                ref={ref}
                onChange={() => handleChange([region])}
              >
                <Text fontSize="sm" color="dark" mb="20px">
                  {region}
                </Text>
              </Checkbox>
            );
          })}
        </CheckboxGroup>
        {isError && (
          <WarningMessage>{t("preview.regions.error")}</WarningMessage>
        )}
      </VStack>
    </Box>
  );
};

export default RegionForm;
