import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Flex, useDisclosure } from "@chakra-ui/react";

import { ImageContent } from "modules/preview/components/forms/QrcodeForm/Contents/ImageContent";
import { Mode, TableActionMode } from "../schemas/customQrcodeSchema";
import { QrcodeModalForm } from "modules/images/components/QrcodeModalForm";
import { Qrcodes } from "./Qrcodes";
import { useImage } from "modules/images/hooks/useImage";
import { useImageUrl } from "modules/images/hooks/useImageUrl";
import { useQrcodes } from "../hooks/useQrcodes";
import PlusIcon from "core/components/InterfaceIcons/PlusIcon";

export const QrcodesPanel = () => {
  const { t } = useTranslation();
  const { data } = useQrcodes();
  const [contentType, setContentType] = useState<TableActionMode>(
    TableActionMode.SHOW_FORM
  );

  const { onOpen, isOpen, onClose } = useDisclosure();
  const [imageId, setImageId] = useState<number>();

  const handleChangeImage = useCallback(
    (id: number, type: TableActionMode) => {
      setImageId(id);
      setContentType(type);
      onOpen();
    },
    [onOpen]
  );

  const handleCloseModal = useCallback(() => {
    if (imageId) {
      setImageId(undefined);
    }
    onClose();
    setContentType(TableActionMode.SHOW_FORM);
  }, [onClose, imageId]);

  const selectedQrcode = useMemo(
    () => data?.find((qrcode) => qrcode.image_id === imageId),
    [data, imageId]
  );

  const { data: imageData } = useImage(selectedQrcode?.image_id);

  const { data: urlData } = useImageUrl(imageData, {
    staleTime: 0,
  });

  const mode = useMemo(() => {
    return selectedQrcode ? Mode.EDIT : Mode.CREATE;
  }, [selectedQrcode]);

  return (
    <Flex direction="column" data-testid="tab-content-qrcodes">
      <Button
        width="auto"
        alignSelf="flex-end"
        variant="primary-solid"
        my="10px"
        onClick={onOpen}
        leftIcon={<PlusIcon />}
      >
        {t("assets.qrcode.create")}
      </Button>
      <Qrcodes qrcodes={data} handleChangeImage={handleChangeImage} />
      {contentType === TableActionMode.SHOW_FORM ? (
        <QrcodeModalForm
          isOpen={isOpen}
          onClose={handleCloseModal}
          qrcode={selectedQrcode}
          image={imageData}
          mode={mode}
        />
      ) : (
        <ImageContent
          handleClose={handleCloseModal}
          isOpen={isOpen}
          url={urlData}
        />
      )}
    </Flex>
  );
};
