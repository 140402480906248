import React, { useMemo, useCallback } from "react";
import { Table, Tbody, Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column, Cell, useFilters } from "react-table";

import TableHeader from "modules/shared/components/Table/TableHeader";
import TableRowSentinel from "modules/shared/components/Table/TableRowSentinel";
import TableCell from "modules/shared/components/Table/TableCell";

import filterRowsByName from "modules/filters/helpers/filterRowsByName";
import {
  typeTplinspectedKeysTemplate,
  TemplatesColumnName,
  templatesColumnNameSchema,
} from "../Schemas/InspectionTemplatesSchemas";

interface Props {
  templates?: typeTplinspectedKeysTemplate[];
}
const emptyArray: never[] = [];
const TemplatesTable = ({ templates }: Props) => {
  const { t, i18n } = useTranslation();
  const getHeaderName = useCallback(
    (name: TemplatesColumnName) => {
      t(`supervision.template.${name}` as any);
    },
    [i18n.language]
  );

  const columns = useMemo(
    () =>
      templatesColumnNameSchema.options.map((name) => ({
        Header: <Box minW={24}>{name}</Box>,
        accessor: name,
        Cell: ({
          cell: { value, row },
        }: {
          cell: Cell<typeTplinspectedKeysTemplate, string | []>;
        }) => <TableCell value={value ?? "NA"} transformDatetime={true} />,
        filter: (...args) => filterRowsByName(...args, name),
      })),
    [getHeaderName, templates]
  ) as Column<typeTplinspectedKeysTemplate>[];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: templates || emptyArray,
      },
      useFilters,
      useSortBy
    );

  if (templates?.length === 0) {
    return (
      <Flex mt={20} d="column">
        <Text m="20px" align="center">
          {t("supervision.no_message")}
        </Text>
      </Flex>
    );
  }

  return (
    <Box>
      <Text textStyle="labelSmall" mt="20px">
        {templates?.length} {t("supervision.template.quantity_title")}
      </Text>
      <Box overflow="auto" marginTop="20px">
        <Table
          {...getTableProps()}
          data-testid="template-sentinel-table"
          textStyle="bodySmall"
          w="100%"
          min-width="150px"
        >
          <TableHeader headerGroups={headerGroups} />
          <Tbody {...getTableBodyProps()} bgColor="white">
            {rows.map((row) => {
              prepareRow(row);
              return <TableRowSentinel key={row.id} row={row} />;
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default TemplatesTable;
