import React from "react";
import {
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  Text,
  Box,
  Button,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  PreviewFormType,
  advancedSettingsInputSchema,
  advancedSettingsHourSchema,
} from "modules/create/schemas/formSchema";
import NumberSettingsForm from "modules/preview/components/forms/NumberSettingsForm";
import {
  defaultAdvancedSettingsValues,
  defaultAdvancedSettingsMaxValues,
  defaultAdvancedSettingsHour,
} from "modules/create/constants/defaultValues";

import TimeForm from "modules/preview/components/forms/TimeForm";
import useDisableForm from "modules/preview/hooks/useDisableForm";
import areAdvancedSettingsValuesEqualToDefaultValues from "modules/preview/helpers/areAdvancedSettingsValuesEqualToDefaultValues";
import SettingsIcon from "core/components/InterfaceIcons/SettingsIcon";

interface Props {
  isDisabled: boolean;
}

const AdvancedSettingsForm = ({ isDisabled }: Props) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<PreviewFormType>();
  const disabled = useDisableForm();

  const resetForm = () => {
    advancedSettingsInputSchema.options.forEach((input) =>
      setValue(
        `advancedSettings.${input}`,
        defaultAdvancedSettingsValues[input]
      )
    );
    advancedSettingsHourSchema.options.forEach((hour) =>
      setValue(`advancedSettings.${hour}`, defaultAdvancedSettingsHour)
    );
  };

  const isResetButtonDisabled = areAdvancedSettingsValuesEqualToDefaultValues(
    watch("advancedSettings")
  );

  return (
    <Popover
      placement="left"
      closeOnBlur={false}
      autoFocus
      preventOverflow={false}
      isLazy
    >
      <PopoverTrigger>
        <Box>
          <Button
            as="div"
            // prevent form submission
            variant="link-button"
            leftIcon={<SettingsIcon />}
            isDisabled={isDisabled}
          >
            {t("preview.settings.button")}
          </Button>
        </Box>
      </PopoverTrigger>

      <PopoverContent top="40px" mb="50px" w={96}>
        <PopoverHeader>
          <Text fontWeight={500} mr="10px" display="inline-flex">
            {t("preview.settings.advanced.title")}
          </Text>
          <Button
            onClick={resetForm}
            disabled={isResetButtonDisabled || disabled}
            variant="link-button"
            textDecoration="underline"
            leftIcon={<SettingsIcon />}
            isDisabled={isDisabled}
          >
            {t("preview.settings.reset")}
          </Button>
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <VStack direction="column">
            {advancedSettingsInputSchema.options.map((input) => (
              <NumberSettingsForm
                disabled={disabled}
                name={`advancedSettings.${input}`}
                hint={t(`preview.settings.advanced.${input}.hint` as const)}
                label={t(`preview.settings.advanced.${input}.label` as const)}
                min={0}
                max={defaultAdvancedSettingsMaxValues[input]}
                defaultValue={defaultAdvancedSettingsValues[input]}
                key={`advancedSettings.${input}`}
              />
            ))}
          </VStack>
          <TimeForm />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AdvancedSettingsForm;
