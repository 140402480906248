import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { TargetRequest } from "modules/target/schemas/targetSchemas";

export const targetTemplate = ({ templateId, request }: TargetRequest) =>
  simpleRequest(
    generatePath(ENDPOINTS.TARGET, { id: templateId }),
    emptySchema,
    methodSchema.enum.post,
    request
  );

export function useTargetTemplate() {
  return useMutationWithErrorHandling(targetTemplate);
}
