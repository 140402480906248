import React, { ReactNode } from "react";
import { Text, Flex } from "@chakra-ui/react";

import ThumbUpIcon from "core/components/InterfaceIcons/ThumbUpIcon";
import ThumbDownIcon from "core/components/InterfaceIcons/ThumbDownIcon";
import InfoIcon from "core/components/InterfaceIcons/InfoIcon";

interface Props {
  icon: "thumbUp" | "thumbDown" | "info";
  children: ReactNode;
}

const TextWithIcon = ({ icon, children }: Props) => {
  return (
    <Flex color="dark">
      {icon === "thumbUp" ? (
        <ThumbUpIcon />
      ) : icon === "thumbDown" ? (
        <ThumbDownIcon />
      ) : (
        <InfoIcon />
      )}
      <Text fontSize="xs" mb="10px" ml="10px">
        {children}
      </Text>
    </Flex>
  );
};

export default TextWithIcon;
