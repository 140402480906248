import React from "react";
import { Input } from "@chakra-ui/react";
import { FilterProps } from "modules/filters/components/SwitchFilter";

function InputFilter<T extends object>({
  filterValue,
  setFilter,
  name,
  preFilteredRows,
}: FilterProps<T, string>) {
  if (!setFilter) {
    return null;
  }

  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  return (
    <Input
      placeholder={name}
      value={filterValue || ""}
      onChange={changeFilter}
    />
  );
}

export default InputFilter;
