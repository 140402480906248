import {
  ColorSchemeColors,
  ColorSchemeColorKey,
} from "modules/preview/schemas/colorSchemesSchemas";
import getHexColorFromMomaColor from "modules/templates/helpers/getHexColorFromMomaColor";

export const getDisplayColor = (
  index: number,
  colorScheme: ColorSchemeColors
) => {
  const key = Object.keys(colorScheme).find(
    (color) => color.split("_")[1] === index.toString()
  ) as ColorSchemeColorKey;
  return getHexColorFromMomaColor(colorScheme[key]);
};
