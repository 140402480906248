import React from "react";

import { Container } from "@chakra-ui/react";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";
import RegionsStatusList from "modules/emergencyStop/RegionsStatusList";

export default function EmergencyStop() {
  return (
    <Container>
      <BreadNav />
      <RegionsStatusList />
    </Container>
  );
}
