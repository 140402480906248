import React, { useMemo } from "react";
import { Table, Tbody, Box, Text, Flex, Center } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column, Cell } from "react-table";

import {
  GlobalMessage,
  globalMessagesColumnNameSchema,
} from "modules/globalMessage/schemas/globalMessageFormSchema";

import TableHeader from "modules/shared/components/Table/TableHeader";
import TableRow from "modules/shared/components/Table/TableRow";
import TableCell from "modules/shared/components/Table/TableCell";
import CreateButton from "modules/globalMessage/components/CreateButton";
import ActionCell from "modules/shared/components/Table/ActionCell";
import DuplicateGlobalMessage from "./DuplicateGlobalMessage";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";
import { PATHS } from "modules/shared/constants/paths";

interface Props {
  globalMessages?: GlobalMessage[];
}

const MIN_WIDTH = 20;
const emptyArray: never[] = [];

const GlobalMessagesTable = ({ globalMessages }: Props) => {
  const { t } = useTranslation();
  const hasRightToCreateGlobal = useHasAccessRights(
    PATHS.CREATE_GLOBALMESSAGE,
    ["POST"]
  );
  const columns = useMemo(
    () =>
      globalMessagesColumnNameSchema.options.map((name) => ({
        Header: name,
        accessor: name,
        Cell: ({
          cell: { value, row },
        }: {
          cell: Cell<GlobalMessage, string | string[]>;
        }) =>
          name === globalMessagesColumnNameSchema.enum.actions && value ? (
            <ActionCell value={value} to={`/global/${row.values.id}`}>
              {hasRightToCreateGlobal && (
                <DuplicateGlobalMessage id={row.values.id} color={"black"} />
              )}
            </ActionCell>
          ) : (
            <TableCell value={value} />
          ),
        minWidth: name === "template_id" ? MIN_WIDTH : "null",
      })),
    []
  ) as Column<GlobalMessage>[];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<GlobalMessage>(
      {
        columns,
        data: globalMessages || emptyArray,
      },
      useSortBy
    );
  if (!globalMessages || globalMessages?.length === 0) {
    return (
      <Flex mt="10px" d="column">
        <Text m="20px" align="center">
          {t("global_message.error.no_globals")}
        </Text>
        <Center>{hasRightToCreateGlobal && <CreateButton />}</Center>
      </Flex>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="end" marginTop="40px">
        <Box>{hasRightToCreateGlobal && <CreateButton />}</Box>
      </Box>

      <Box overflow="auto" pt={5} pb={10} data-testid="global-table">
        <Table {...getTableProps()} textStyle="bodySmall" w="100%">
          <TableHeader headerGroups={headerGroups} />
          <Tbody {...getTableBodyProps()} bgColor="white">
            {rows.map((row) => {
              prepareRow(row);
              return <TableRow key={row.id} row={row} />;
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default GlobalMessagesTable;
