import { useEffect } from "react";
import {
  useMutation,
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  feedbackSchema,
  actionsSchema,
  ActionType,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { API_ERRORS } from "modules/shared/constants/errors";

export function useMutationWithErrorHandling<U, T>(
  mutationFn: MutationFunction<U, T>,
  options?: UseMutationOptions<U, unknown, T, unknown> | undefined
): UseMutationResult<U, unknown, T, unknown> {
  const { setFeedback } = useFeedbackStore();
  const mutation = useMutation(mutationFn, options);

  useEffect(() => {
    if (mutation.error) {
      const hasBadCredentialError = (
        mutation.error as Error
      )?.message?.includes(API_ERRORS.BAD_CREDENTIALS);
      const hasUsedColorScheme = (mutation.error as Error)?.message?.includes(
        API_ERRORS.USED_COLOR_SCHEME
      );
      const hasWrongTestCard = (mutation.error as Error)?.message?.includes(
        API_ERRORS.TEST_CARD_ERROR
      );

      if (Array.isArray(mutation.error)) {
        setFeedback(
          feedbackSchema.enum.error,
          actionsSchema.enum.customError,
          undefined,
          mutation.error
        );
      } else {
        let feedBackAction: ActionType | undefined;
        if (hasBadCredentialError) {
          feedBackAction = actionsSchema.enum.saveProfile;
        } else if (hasUsedColorScheme) {
          feedBackAction = actionsSchema.enum.deleteColorScheme;
        } else if (hasWrongTestCard) {
          feedBackAction = actionsSchema.enum.test;
        } else {
          feedBackAction = undefined;
        }

        setFeedback(feedbackSchema.enum.error, feedBackAction);
      }
    }
  }, [mutation.error]);

  return mutation;
}
