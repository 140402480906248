import React from "react";
import { createIcon } from "@chakra-ui/react";

const ArrowBackIcon = createIcon({
  displayName: "ArrowBackIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M16.67 3.87L14.9 2.1L5 12L14.9 21.9L16.67 20.13L8.54 12L16.67 3.87Z"
        fill="currentColor"
      />
    </>
  ),
});

export default ArrowBackIcon;
