import React, { useMemo } from "react";
import { Text } from "@chakra-ui/react";
import { useFormContext, Controller } from "react-hook-form";

import { GlobalMessageFormValues } from "modules/globalMessage/schemas/globalMessageFormSchema";
import { useTranslation } from "react-i18next";
import { useTemplates } from "modules/templates/hooks/useTemplates";
import Dropdown from "core/components/Dropdown/Dropdown";
import getSelectOptionFromTemplates from "modules/globalMessage//helpers/getSelectOptionFromTemplates";
import filterTemplatesByRegions from "modules/globalMessage//helpers/filterTemplatesByRegions";
import { TemplatesListBody } from "modules/templates/schemas/templateModelSchemas";
import WarningMessage from "core/components/WarningMessage/WarningMessage";
import useGlobalMessage from "../hooks/useGlobalMessage";

const TemplateForm = () => {
  const { t } = useTranslation();
  const { data } = useTemplates();
  const { data: globalMessageData } = useGlobalMessage();

  const globalMessageStatus = globalMessageData?.status;
  const isDisabled = !!globalMessageStatus && globalMessageStatus !== "DRAFT";

  const templates =
    data?.filter((template) => template.status === "PRODUCTION") || [];
  const { watch, errors } = useFormContext<GlobalMessageFormValues>();
  const selectedRegions = watch("regions") || [];

  const filteredTemplates = useMemo(
    () =>
      filterTemplatesByRegions(templates as TemplatesListBody, selectedRegions),
    [selectedRegions, templates]
  );
  const dropdownOptions = useMemo(
    () => getSelectOptionFromTemplates(filteredTemplates),
    [filteredTemplates]
  );

  return (
    <>
      <Text fontSize="xs" mb="20px" textAlign="left">
        {t("global_message.select_template.label")}
      </Text>
      {data && (
        <Controller
          defaultValue={""}
          name="template_id"
          render={({ onChange, value, name, ref }) => (
            <Dropdown
              disabled={isDisabled}
              options={dropdownOptions}
              name={name}
              inputRef={ref}
              value={dropdownOptions.find((option) => option.value === value)}
              onChange={(option) => onChange(option.value)}
              size="large"
            />
          )}
        />
      )}
      <Text fontSize="xs" mb="20px" textAlign="left">
        {t("global_message.template.message")}
      </Text>
      {errors.template_id && (
        <WarningMessage>
          {t("global_message.select_template.error")}
        </WarningMessage>
      )}
    </>
  );
};

export default TemplateForm;
