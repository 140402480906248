import React from "react";
import { Flex, Text, Box } from "@chakra-ui/react";

import ValidationForm from "modules/validate/components/ValidationForm";
import Stepper from "core/components/ActionFeedback/Stepper";

export type StatusType = "error" | "success" | "inprogress" | "tocome";

export interface StatusAlert {
  title: string;
  status?: string;
  type: StatusType;
  withForm: boolean;
}

interface Props {
  statutes: [StatusAlert, StatusAlert];
}

const InfoAlert = ({ statutes }: Props) => {
  const steps = statutes.map((s) => s.type);
  const getColor = (type: StatusType) =>
    type === "inprogress"
      ? "abbey"
      : type === "tocome"
      ? "jumbo"
      : type === "error"
      ? "error"
      : "validation";
  return (
    <Box>
      <Flex maxW="550px">
        <Stepper steps={steps as [StatusType, StatusType]} />
        <Box>
          <Text align="left" fontWeight={500} fontSize="md" mb="5px">
            {statutes[0].title}
          </Text>
          {statutes[0].status && (
            <Text
              align="left"
              fontSize="xs"
              color={getColor(statutes[0].type)}
              fontWeight={700}
              mb={statutes[0].withForm ? 0 : "40px"}
            >
              {statutes[0].status}
            </Text>
          )}
          {statutes[0].withForm && <ValidationForm />}
          <Text
            align="left"
            fontWeight={500}
            fontSize="md"
            color={statutes[1].status ? "dark" : "jumbo"}
          >
            {statutes[1].title}
          </Text>
        </Box>
      </Flex>
      <Box ml="35px">
        {statutes[1].status && (
          <Text
            align="left"
            fontSize="xs"
            color={getColor(statutes[1].type)}
            fontWeight={700}
          >
            {statutes[1].status}
          </Text>
        )}
        {statutes[1].withForm && <ValidationForm />}
      </Box>
    </Box>
  );
};

export default InfoAlert;
