import { Role } from "modules/roles/schemas/rolesSchemas";
import { Status } from "modules/preview/schemas/templateSchemas";

const getTemplateTitleFromStatusAndRole = (
  status?: Status,
  roles?: Array<Role | string>
) => {
  if (!roles || !status) {
    return;
  }
  switch (status) {
    case "PENDING_LEGAL":
      return roles.includes("LEGAL_VALIDATOR")
        ? "pending.validator"
        : roles.includes("COMMERCIAL_VALIDATOR")
        ? "pending.waiting_validator"
        : "pending.contributor";
    case "PENDING_COMMERCIAL":
      return roles.includes("LEGAL_VALIDATOR")
        ? "pending.waiting_validator"
        : roles.includes("COMMERCIAL_VALIDATOR")
        ? "pending.validator"
        : "pending.contributor";
    case "REFUSED":
      return roles.includes("LEGAL_VALIDATOR") ||
        roles.includes("COMMERCIAL_VALIDATOR")
        ? "refused.validator"
        : "refused.contributor";
    case "VALIDATED":
      return roles.includes("LEGAL_VALIDATOR") ||
        roles.includes("COMMERCIAL_VALIDATOR")
        ? "available.validator"
        : "available.contributor";
    case "STOPPED":
      return "stopped";
    default:
      return;
  }
};

export default getTemplateTitleFromStatusAndRole;
