import { omit } from "remeda";
import { CreateUserFormValues } from "../schemas/formSchemas";

const getNewUserFromFormValues = (userFormValues: CreateUserFormValues) => {
  if (userFormValues.password === "") {
    return omit(userFormValues, ["password"]);
  } else {
    return userFormValues;
  }
};

export default getNewUserFromFormValues;
