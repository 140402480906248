import React from "react";
import { FormControl, FormLabel, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useHistory } from "react-router-dom";

import Dropdown from "core/components/Dropdown/Dropdown";
import getSelectOptionsFromUsers from "modules/user/informations/helpers/getSelectOptionsFromUsers";
import { useUsers } from "modules/users/hooks/useUsers";
import ValidationButtons from "core/components/ActionFeedback/ValidationButtons";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { useDeleteUser } from "modules/user/informations/hooks/useDeleteUser";
import {
  DeleteUserFormValues,
  deleteUserFormSchema,
} from "modules/user/informations/schemas/formSchemas";

import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { PATHS } from "modules/shared/constants/paths";

const DeleteUserForm = () => {
  const { control, handleSubmit, errors } = useForm<DeleteUserFormValues>({
    resolver: zodResolver(deleteUserFormSchema),
  });
  const { data } = useUsers();
  const { t } = useTranslation();
  const users = data || [];
  const dropdownOptions = getSelectOptionsFromUsers(users).filter(
    (option) => option.fallback_user
  );
  const { reset, setFeedback } = useFeedbackStore();
  const deleteUser = useDeleteUser();
  const history = useHistory();

  const handleDelete = (data: DeleteUserFormValues) => {
    deleteUser.mutate(data, {
      onSuccess: () => {
        history.push(PATHS.USERS);
        setFeedback(feedbackSchema.enum.success, actionsSchema.enum.deleteUser);
      },
    });
  };

  return (
    <form>
      <FormControl mt="10px">
        <FormLabel
          fontSize="xs"
          fontWeight={700}
          color={errors.id ? "error" : "inherit"}
        >
          {t("create_user.delete_form.label")}
        </FormLabel>
        <Controller
          control={control}
          defaultValue={""}
          name="id"
          render={({ onChange, value, name, ref }) => (
            <Dropdown
              options={dropdownOptions}
              name={name}
              inputRef={ref}
              value={dropdownOptions.find((option) => option.value === value)}
              onChange={(option) => onChange(option.value)}
              size="large"
            />
          )}
        />
      </FormControl>
      <Box mt="10px">
        <ValidationButtons
          confirm={t("actions.deleteUser.warning.confirm")}
          cancel={t("actions.deleteUser.warning.cancel")}
          handleAction={handleSubmit(handleDelete)}
          reset={reset}
          type="contributor"
        />
      </Box>
    </form>
  );
};

export default DeleteUserForm;
