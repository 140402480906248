import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import useIsFinalizedTemplate from "modules/preview/hooks/useIsFinalizedTemplate";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import TargetIcon from "core/components/InterfaceIcons/TargetIcon";

const ProductionAction = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const isFinalizedTemplate = useIsFinalizedTemplate();

  return (
    <Button
      as={isFinalizedTemplate ? RouterLink : undefined}
      to={`/finalization/${id}`}
      leftIcon={<TargetIcon />}
      variant="primary-solid"
      isDisabled={!isFinalizedTemplate}
    >
      {t("actions.finalize.button")}
    </Button>
  );
};

export default ProductionAction;
