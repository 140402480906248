import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Canal';
        font-style: normal;
        font-weight: 300;
        src: url('../assets/fonts/CanalLightRomain.otf');
      }
      @font-face {
        font-family: 'Canal';
        font-style: italic;
        font-weight: 300;
        src: url('../assets/fonts/CanalLightItalique.otf') ;
      } 
      @font-face {
        font-family: 'Canal';
        font-style: italic;
        font-weight: 700;
        src: url('../assets/fonts/CanalBoldItalique.otf') ;
      }
      @font-face {
        font-family: 'Canal';
        font-style: normal;
        font-weight: 700;
        src: url('../assets/fonts/CanalBoldRomain.otf');
      }
      @font-face {
        font-family: 'Canal';
        font-style: normal;
        font-weight: 500;
        src: url('../assets/fonts/CanalDemiRomain.otf');
      }
      @font-face {
        font-family: 'Canal';
        font-style: italic;
        font-weight: 500;
        src: url('../assets/fonts/CanalDemiItalique.otf');
      }
      `}
  />
);

export default Fonts;
