import { UsersListBody } from "modules/users/schemas/usersModelSchemas";

const getSelectOptionsFromUsers = (users: UsersListBody) =>
  users.map((user) => ({
    value: user.id,
    label: user.name,
    fallback_user: user.fallback_user,
  }));

export default getSelectOptionsFromUsers;
