import React from "react";
import { createIcon } from "@chakra-ui/react";

const Success = createIcon({
  displayName: "Success",
  viewBox: "0 0 24 24",
  path: (
    <>
      <circle cx="12" cy="12" r="12" fill="#018901" />
      <path
        d="M9.56829 14.8944L5.54997 10.7302L4 12.2775L9.56829 18L20 7.54732L18.4609 6L9.56829 14.8944Z"
        fill="white"
      />
      ,
    </>
  ),
});

export default Success;
