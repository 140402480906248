import getActionFromRolesAndStatus from "modules/templates/helpers/getActionFromRolesAndStatus";
import { TemplatesListBody } from "modules/templates/schemas/templateModelSchemas";
import { Role } from "modules/roles/schemas/rolesSchemas";

const templatesListTransform = (
  data: TemplatesListBody,
  roles?: Array<Role | string>
) =>
  data.map((template) => ({
    ...template,
    label: template.label || "",
    actions: getActionFromRolesAndStatus(template.status, roles),
  }));

export default templatesListTransform;
