import { generatePath } from "react-router";
import { useParams } from "react-router-dom";

import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";

import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { resetUserPasswordApiSchema } from "modules/user/informations/schemas/userApiSchema";

export const resetUserPassword = (userId?: string) =>
  userId
    ? simpleRequest(
        generatePath(ENDPOINTS.USER, { id: userId }),
        resetUserPasswordApiSchema,
        methodSchema.enum.patch,
        { reset_password: true }
      )
    : Promise.reject();

export const useResetUserPassword = () => {
  const { id: userId } = useParams<RouteIdParam>();

  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(() => resetUserPassword(userId), {
    onSuccess: () => {
      setFeedback(
        feedbackSchema.enum.success,
        actionsSchema.enum.resetPassword
      );
    },
  });
};
