import { useQueryClient } from "react-query";
import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { createGlobalMessageSchema } from "../schemas/createGlobalMessageSchema";
import { GlobalMessageFormValues } from "../schemas/globalMessageFormSchema";

export const createGlobalMessage = (globalMessage: GlobalMessageFormValues) =>
  simpleRequest(
    ENDPOINTS.GLOBALS,
    createGlobalMessageSchema,
    methodSchema.enum.post,
    globalMessage
  );

export const useCreateGlobalMessage = () => {
  const queryClient = useQueryClient();
  return useMutationWithErrorHandling(createGlobalMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["globals"]);
    },
  });
};
