import { BuiltInLogos } from "modules/create/schemas/formSchema";
import { WindowLogo } from "../schemas/templateSchemas";

const getBuiltInLogosFromWindowLogo = (windowLogo?: WindowLogo): BuiltInLogos =>
  windowLogo === "both"
    ? { canalplus: true, canalsat: true }
    : windowLogo === "canalsat"
    ? { canalplus: false, canalsat: true }
    : windowLogo === "canal+"
    ? { canalplus: true, canalsat: false }
    : { canalplus: false, canalsat: false };

export default getBuiltInLogosFromWindowLogo;
