import CenterIcon from "core/components/PositionIcons/CenterIcon";
import LeftIcon from "core/components/PositionIcons/LeftIcon";
import RightIcon from "core/components/PositionIcons/RightIcon";
import {
  Alignment,
  alignmentSchema,
} from "modules/preview/schemas/templateSchemas";

const getTextAlignmentIcon = (position: Alignment) =>
  position === alignmentSchema.enum.left
    ? LeftIcon
    : position === alignmentSchema.enum.center
    ? CenterIcon
    : RightIcon;

export default getTextAlignmentIcon;
