import { create } from "zustand";
import {
  FeedBackType,
  ActionType,
} from "core/components/ActionFeedback/schemas/actionSchemas";

interface FeedBackState {
  action?: ActionType | null;
  type: FeedBackType | null;
  handleAction?: (param?: any) => void;
  customMessage?: string[] | null;
  setFeedback: (
    type: FeedBackType,
    action?: ActionType,
    handleAction?: (param?: any) => void,
    customMessage?: string[]
  ) => void;
  setCustomMessage: (customMessage: string[]) => void;
  reset: () => void;
}

const useFeedbackStore = create<FeedBackState>((set) => ({
  action: null,
  type: null,
  customMessage: null,
  setFeedback: (type, action, handleAction, customMessage) =>
    set({ type, action, handleAction, customMessage }),
  setCustomMessage: (customMessage) => set({ customMessage }),
  reset: () =>
    set({
      type: null,
      action: null,
      handleAction: undefined,
    }),
}));

export default useFeedbackStore;
