import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import * as z from "zod";

export const postContactForm = (message: string) =>
  simpleRequest(
    ENDPOINTS.CONTACT,
    z.string().nonempty(),
    methodSchema.enum.post,
    {
      message,
    }
  );

export function usePostContactForm() {
  return useMutationWithErrorHandling(postContactForm);
}
