import React from "react";
import styled from "@emotion/styled";
import { useState } from "react";

import { ProbeDetails } from "modules/monitoring/ProbeDetails/components/ProbeDetails";
import { ProbeList } from "modules/monitoring/ProbeList/components/ProbeList/ProbeList";
import { Container } from "@chakra-ui/react";
import { ProbeType } from "modules/monitoring/ProbeList/schemas/formSchemas";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";

const MonitoringStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProbeDetailsStyled = styled.div<{ displayDetails: boolean }>`
  width: 0%;
  transform: translateX(100%);
  animation: ${(props) =>
    props.displayDetails ? "slide-in 0.5s forwards" : ""};
  @keyframes slide-in {
    to {
      width: 33%;
      transform: translateX(0%);
    }
  }
`;

const ProbeListStyled = styled.div<{ displayDetails: boolean }>`
  width: 100%;
  height: 100vh;
  overflow: auto;
`;

export default function Monitoring() {
  const [activeItem, setActiveItem] = useState<ProbeType | null>(null);
  const displayDetail = activeItem !== null;

  return (
    <Container>
      <BreadNav />
      <MonitoringStyled>
        <ProbeListStyled displayDetails={displayDetail}>
          <ProbeList setActiveItem={setActiveItem} activeItem={activeItem} />
        </ProbeListStyled>
        <ProbeDetailsStyled displayDetails={displayDetail}>
          {activeItem && (
            <ProbeDetails
              id={activeItem?.id}
              name={activeItem?.name}
              title={activeItem?.title}
              error={activeItem?.error}
              description={activeItem?.description}
              last_run={activeItem?.last_run}
              status={activeItem?.status}
              value={activeItem?.value}
              isLeaf={activeItem.hasOwnProperty("probes")}
            />
          )}
        </ProbeDetailsStyled>
      </MonitoringStyled>
    </Container>
  );
}
