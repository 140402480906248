/**
 * Creates a new function which returns the given argument untouched,
 * and call the given side effect function with this argument.
 * @param sideEffectFunction
 */
function tap<A extends T, T extends any>(
  sideEffectFunction: (arg: T) => void
): (arg: A) => A {
  return (arg: A) => {
    sideEffectFunction(arg);
    return arg;
  };
}

export default tap;
