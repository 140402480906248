import { useHistory } from "react-router-dom";
import i18next from "i18next";

import { baseToast } from "modules/shared/helpers/baseToast";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { PATHS } from "modules/shared/constants/paths";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import getApiUrl from "core/helpers/env/getApiUrl";
import { getTokenFromURL } from "../helpers/getCredentialsToken";

const resetCredentialsPassword = async (credentials: {
  new_password: string;
}) => {
  try {
    const token = getTokenFromURL();

    const url = getApiUrl(ENDPOINTS.RESET_PASSWORD);

    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token || "",
      },
      body: JSON.stringify({ password: credentials.new_password }),
    });

    const data = { status: response.status };

    if (data.status === 400) {
      baseToast({
        id: "RESET_PASSWORD_ERROR",
        status: "error",
        title: `${i18next.t("login.reset.error")} ${i18next.t(
          "login.reset.internal_error"
        )}`,
        description: data.status,
      });
    }

    return data;
  } catch (error) {
    console.error("An  error occured: ", error);
    return Promise.reject();
  }
};
export function useResetCredentialsPassword() {
  const history = useHistory();

  return useMutationWithErrorHandling(resetCredentialsPassword, {
    onSuccess: (data: { status: number }) => {
      if (data) {
        if (data.status === 200) {
          history.push(PATHS.RESET_CREDENTIAL_SUCCEED);
        }
      }
    },
  });
}
