import { useQuery } from "react-query";
import { simpleRequest } from "core/services/api";
import { selfcareBodySchema } from "../../selfcare/schemas/selfcareApiSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";

export const selfcare = () =>
  simpleRequest(ENDPOINTS.SELFCARE, selfcareBodySchema);

export function useSelfcare(fetch = true) {
  return useQuery(["selfcare"], selfcare, {
    enabled: fetch,
    staleTime: 300000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
