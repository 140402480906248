import React from "react";
import { Text, Box, Flex, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";

import VariableStorableInput from "modules/preview/components/ui/VariableStorableInput";

import useDisableForm from "modules/preview/hooks/useDisableForm";
import PlusIcon from "core/components/InterfaceIcons/PlusIcon";

const VariablesForm = () => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    name: "variables",
  });
  const disabled = useDisableForm();

  return (
    <Box data-testid="variables-form">
      <Text fontSize="xs" color="dark" mb="20px">
        {t("preview.variables.globalHint")}
      </Text>
      {fields.map((field, index) => (
        <Box key={field.id} mt="10px">
          <Flex>
            <VariableStorableInput
              index={index}
              disabled={disabled}
              type="variables"
            />
            <Button
              marginTop="25px"
              onClick={() => remove(index)}
              width="fill-available"
              variant="link-button"
              disabled={index !== fields.length - 1 || disabled}
            >
              {t("preview.variables.delete")}
            </Button>
          </Flex>
        </Box>
      ))}
      <Box mt="20px">
        <Button
          onClick={() => append({ value: "" })}
          variant="link-button"
          disabled={fields.length >= 8 || disabled}
          leftIcon={<PlusIcon />}
        >
          {t("preview.variables.add")}
        </Button>
      </Box>
    </Box>
  );
};

export default VariablesForm;
