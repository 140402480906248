import { generatePath } from "react-router";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { PATHS } from "modules/shared/constants/paths";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

export const deleteColorScheme = (id: string) =>
  simpleRequest(
    generatePath(ENDPOINTS.COLORSCHEME, { id }),
    emptySchema,
    methodSchema.enum.delete
  );

export const useDeleteColorScheme = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(deleteColorScheme, {
    onSuccess: () => {
      queryClient.invalidateQueries(["colorSchemes"]);
      history.push(PATHS.ASSETS);
      setFeedback(
        feedbackSchema.enum.success,
        actionsSchema.enum.deleteColorScheme
      );
    },
  });
};
