import React, { ReactNode } from "react";
import { Text, Flex } from "@chakra-ui/react";

import AlertIcon from "core/components/InterfaceIcons/AlertIcon";

interface Props {
  children: ReactNode;
}

const WarningMessage = ({ children }: Props) => (
  <Flex color="error" mt="2px">
    <AlertIcon />
    <Text fontSize="xs" mb="10px" ml="10px" textAlign="left">
      {children}
    </Text>
  </Flex>
);

export default WarningMessage;
