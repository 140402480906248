import React from "react";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import { useTranslation, Trans } from "react-i18next";

import { AuthentificationBox } from "modules/authentication/components/AuthentificationBox";
import { PATHS } from "modules/shared/constants/paths";

export const ResetCredentialsSucceed = () => {
  const { t } = useTranslation();

  return (
    <AuthentificationBox>
      <Text mt={5} textStyle="subtitle">
        {t("login.reset.title")}
      </Text>
      <Text mt="50px">
        <Trans
          t={t}
          i18nKey="login.reset.success"
          components={[<Link color="monza" as={RouterLink} to={PATHS.LOGIN} />]}
        />
      </Text>
    </AuthentificationBox>
  );
};
