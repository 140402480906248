import React from "react";
import { FormControl, FormLabel, Flex, Box, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";

import {
  CreateUserFormValues,
  UpdateUserFormValues,
} from "modules/user/informations/schemas/formSchemas";
import Dropdown from "core/components/Dropdown/Dropdown";
import { userStatusSchema } from "modules/user/informations/schemas/formSchemas";
import getSelectOptionsFromSchema from "modules/preview/helpers/getSelectOptionsFromSchema";
import RolesForm from "modules/user/informations/components/RolesForm";
import RegionsForm from "modules/regions/components/RegionsForm";
import { useAvailableRegions } from "modules/regions/hooks/useAvailableRegions";

const TechnicalForm = () => {
  const context = useFormContext<CreateUserFormValues | UpdateUserFormValues>();
  const { data } = useAvailableRegions();

  const { t } = useTranslation();
  const dropdownOptions = getSelectOptionsFromSchema(
    userStatusSchema.options,
    `create_user.technical_form.`
  );

  return (
    <Box>
      <Heading as="h2" size="md" textAlign="left">
        {t("create_user.technical_form.title")}
      </Heading>
      <Flex flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <FormControl mt="10px">
          <FormLabel>{t("create_user.technical_form.status")}</FormLabel>
          <Controller
            control={context.control}
            defaultValue={""}
            name="status"
            render={({ onChange, value, name, ref }) => (
              <Dropdown
                options={dropdownOptions}
                name={name}
                inputRef={ref}
                value={dropdownOptions.find((option) => option.value === value)}
                onChange={(option) => onChange(option.value)}
                size="large"
              />
            )}
          />
        </FormControl>
        <Flex
          mt="30px"
          w="400px"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <FormControl id="regions" isInvalid={!!context.errors.regions}>
            <FormLabel>{t("create_user.technical_form.regions")}</FormLabel>
            <RegionsForm regions={data} />
          </FormControl>
          <FormControl id="roles" isInvalid={!!context.errors.roles}>
            <FormLabel>{t("create_user.technical_form.roles.label")}</FormLabel>
            <RolesForm />
          </FormControl>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TechnicalForm;
