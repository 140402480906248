import React from "react";
import { Flex } from "@chakra-ui/react";
import ColorsForm from "./ColorsForm";

import FixedColors from "./FixedColors";

const ColorSchemeForm = () => {
  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      overflow="auto"
      pb="20px"
    >
      <ColorsForm />
      <FixedColors />
    </Flex>
  );
};

export default ColorSchemeForm;
