import * as z from "zod";

export const regionStatus = z.object({
  id: z.number(),
  name: z.string(),
  is_active: z.boolean(),
});

export type RegionStatus = z.infer<typeof regionStatus>;

export const regionListStatus = z.array(regionStatus);

export type RegionListStatus = z.infer<typeof regionListStatus>;

export const regionColumnName = z.enum(["name", "is_active", "delete"]);

export type RegionColumnName = z.infer<typeof regionColumnName>;

export const genericSchema = z.object({
  region: z.string(),
  is_active: z.boolean(),
});

export type GenericSchema = z.infer<typeof genericSchema>;
