const radiocardSizes = {
  large: {
    w: 265,
    h: 200,
  },
  medium: {
    w: 140,
    h: 89,
  },
  small: {
    w: 100,
    h: 60,
  },
  largeRounded: {
    w: 100,
    h: 100,
  },
  mediumRounded: {
    w: 68,
    h: 68,
  },
  smallRounded: {
    w: 40,
    h: 40,
  },
};

export default radiocardSizes;
