import * as z from "zod";

import isPasswordCompliant from "core/helpers/utils/isPasswordCompliant";

export const profileFormValuesSchema = z.object({
  name: z.string().optional(),
  email: z.string().email().optional(),
  password: z
    .object({
      password: z.string().refine(isPasswordCompliant),
      confirm: z.string(),
    })
    .refine((data) => data.password === data.confirm, {
      message: "Passwords don't match",
      path: ["confirm"],
    }),

  currentPassword: z.string(),
});

export type ProfileFormValues = z.infer<typeof profileFormValuesSchema>;
