import React from "react";

import { useUsers } from "modules/users/hooks/useUsers";
import UsersTable from "modules/users/components/UsersTable";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";
import { ContentFilterProvider } from "modules/filters/context/ContentFilterContext";

const Users = () => {
  const { data } = useUsers();
  return (
    <ScreenContainer>
      <ContentFilterProvider>
        <UsersTable users={data} />
      </ContentFilterProvider>
    </ScreenContainer>
  );
};

export default Users;
