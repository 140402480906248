import * as z from "zod";
import { regionsListBodySchema } from "modules/regions/schemas/regionsSchemas";
import { rolesListSchema } from "modules/roles/schemas/rolesSchemas";
import isPasswordCompliant from "core/helpers/utils/isPasswordCompliant";

export const userStatusSchema = z.enum(["ON", "OFF"]);

export const createUserFormSchema = z.object({
  name: z.string().min(2),
  email: z.string().email(),
  password: z.union([z.string(), z.string().max(0)]),
  status: userStatusSchema,
  regions: regionsListBodySchema.nonempty(),
  roles: rolesListSchema.nonempty(),
});

export type CreateUserFormValues = z.infer<typeof createUserFormSchema>;

export const updateUserFormSchema = createUserFormSchema.extend({
  password: z
    .union([z.string().refine(isPasswordCompliant), z.string().length(0)])
    .optional(),
});

export type UpdateUserFormValues = z.infer<typeof updateUserFormSchema>;

export const deleteUserFormSchema = z.object({
  id: z.number().min(1),
});

export type DeleteUserFormValues = z.infer<typeof deleteUserFormSchema>;
