const textStyles = {
  h1: {
    fontWeight: "300",
    fontSize: "sm",
    fontFamily: "Canal",
    letterSpacing: "0.01em",
  },
  h2: {
    fontWeight: "700",
    fontSize: "2xl",
    fontFamily: "Canal",
    letterSpacing: "-0.01rem",
  },
  h3: {
    fontWeight: "500",
    fontSize: "xl",
    fontFamily: "Canal",
  },
  subtitle: {
    fontWeight: "500",
    fontSize: "lg",
    fontFamily: "Canal",
  },
  text: {
    fontWeight: "300",
    fontSize: "sm",
    fontFamily: "Canal",
  },
  navigation: {
    fontSize: "sm",
    fontWeight: "bold",
    fontFamily: "Canal",
    letterSpacing: "0.02rem",
  },
  label: {
    fontSize: "sm",
    fontWeight: "500",
    fontFamily: "Canal",
    letterSpacing: "0.02rem",
  },

  labelSmall: {
    fontSize: "xs",
    fontWeight: "bold",
    fontFamily: "Canal",
    letterSpacing: "0.02rem",
  },

  link: {
    fontSize: "sm",
    fontWeight: "500",
    fontFamily: "Canal",
    letterSpacing: "0.02rem",
  },

  linkSmall: {
    fontSize: "xs",
    fontWeight: "bold",
    fontFamily: "Canal",
    letterSpacing: "0.02rem",
  },

  breadCrumb: {
    fontSize: "xs",
    fontWeight: "500",
    fontFamily: "Canal",
    letterSpacing: "0.02rem",
  },

  bodySmall: {
    fontSize: "xs",
    fontWeight: "300",
    fontFamily: "Canal",
    letterSpacing: "0.03rem",
  },
};

export default textStyles;
