import React from "react";
import { createIcon } from "@chakra-ui/react";

const CommentIcon = createIcon({
  displayName: "CommentIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M15 4V11H5.17L4.58 11.59L4 12.17V4H15ZM16 2H3C2.45 2 2 2 2 2V17L6 13H16C16.55 13 17 13 17 13V2C17 2 16.55 2 16 2ZM21 6H19V15H6V17C6 17.55 6 18 6 18H18L22 22V6C22 6 21.55 6 21 6Z"
        fill="currentColor"
      />
    </>
  ),
});

export default CommentIcon;
