import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  FormHelperText,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { PreviewFormType } from "modules/create/schemas/formSchema";
import {
  genreSchema,
  themeSchema,
} from "modules/preview/schemas/templateSchemas";
import {
  defaultTheme,
  defaultGenre,
} from "modules/create/constants/defaultValues";
import InformationsDropdown from "../ui/InformationsDropdown";
import WarningMessage from "core/components/WarningMessage/WarningMessage";
import useDisableForm from "modules/preview/hooks/useDisableForm";
import useIsEmptyLayout from "modules/preview/hooks/useIsEmptyLayout";

const InformationsForm = () => {
  const context = useFormContext<PreviewFormType>();
  const { t } = useTranslation();
  const isErrorName = context?.errors.name;
  const isErrorLabel = context?.errors.label;
  const disabled = useDisableForm();
  const isEmptyLayout = useIsEmptyLayout();
  const labelRules = t("preview.informations.label.rules");
  return (
    <Box data-testid="informations-form">
      <FormControl id="name" isDisabled={disabled}>
        <FormLabel color={isErrorName ? "error" : "inherit"}>
          {t("preview.informations.name.title")}
        </FormLabel>
        <Input
          variant="outline"
          name="name"
          ref={context?.register({
            setValueAs: (value) => (value === "" ? null : value),
          })}
          bgColor={isErrorName ? "cosmos" : "inherit"}
        />
        {isErrorName && (
          <WarningMessage>
            {t("preview.informations.name.error")}
          </WarningMessage>
        )}
        <FormHelperText fontSize="xs" color="abbey">
          {t("preview.informations.name.hint")}
        </FormHelperText>
      </FormControl>
      <FormControl mt="10px" id="label" isDisabled={disabled}>
        <FormLabel color={isErrorLabel ? "error" : "inherit"}>
          {t("preview.informations.label.title")}
        </FormLabel>
        <Input
          variant="outline"
          name="label"
          ref={context?.register({
            setValueAs: (value) => (value === "" ? "" : value),
          })}
          bgColor={isErrorLabel ? "cosmos" : "inherit"}
        />
        <FormHelperText fontSize="xs" color="abbey">
          {t("preview.informations.label.hint")}
        </FormHelperText>
        {isErrorLabel ? (
          <WarningMessage>{labelRules}</WarningMessage>
        ) : (
          <FormHelperText fontSize="xs" fontStyle="italic" color="abey">
            {labelRules}
          </FormHelperText>
        )}
      </FormControl>
      <InformationsDropdown
        formName="theme"
        options={themeSchema.options}
        defaultValue={defaultTheme}
        disabled={disabled}
      />
      <InformationsDropdown
        formName="genre"
        options={genreSchema.options}
        defaultValue={isEmptyLayout ? null : defaultGenre}
        disabled={disabled}
      />
    </Box>
  );
};

export default InformationsForm;
