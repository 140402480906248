import React from "react";
import { Text, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Details = ({
  image,
}: {
  image?: { title: string; description: string };
}) => {
  const { t } = useTranslation();

  if (!image) return null;

  return (
    <>
      <Box display="flex" gridGap={2}>
        <Text mb={0} fontSize="m" fontWeight="medium">
          {t("preview.logos.qrcodes.title")} :
        </Text>
        <Text mb={0} fontSize="m">
          {image.title}
        </Text>
      </Box>

      <Box display="flex" gridGap={2}>
        <Text mb={0} fontSize="m" fontWeight="medium">
          {t("preview.logos.qrcodes.description")} :
        </Text>
        <Text mb={0} fontSize="m">
          {image.description}
        </Text>
      </Box>
    </>
  );
};
