import React from "react";
import { createIcon } from "@chakra-ui/react";

const DraftIcon = createIcon({
  displayName: "DraftIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 20H18V7.82843L14.1716 4H6V20ZM15 2L20 7V22H4V2H15Z"
        fill="currentColor"
      />
      <rect x="8" y="12" width="8" height="2" fill="currentColor" />
      <rect x="8" y="16" width="8" height="2" fill="currentColor" />
      <path d="M14 8V2L20 8H14Z" fill="currentColor" />
    </>
  ),
});

export default DraftIcon;
