import React, { useMemo, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Button, VStack } from "@chakra-ui/react";
import { FilterProps } from "modules/filters/components/SwitchFilter";

type DateValue = [Date, Date | null];

function CalendarFilter<T extends object>({
  filterValue,
  setFilter,
}: FilterProps<T, DateValue | undefined>) {
  const { t } = useTranslation();
  const refDate = useRef(new Date());
  const startDate = useMemo(
    () => filterValue?.[0] || refDate.current,
    [filterValue]
  );
  const endDate = useMemo(() => filterValue?.[1] || null, [filterValue]);
  if (!setFilter) {
    return null;
  }

  const onChange = (dates: DateValue) => {
    setFilter(dates);
  };

  const clearDate = () => {
    setFilter(undefined);
  };

  return (
    <VStack spacing={0}>
      <DatePicker
        selected={endDate === null ? startDate : endDate}
        onChange={onChange as any}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
      />
      <Button variant="secondary-solid" size="xs" onClick={clearDate}>
        {t(`templates.clear_date`)}
      </Button>
    </VStack>
  );
}

export default CalendarFilter;
