import React, { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Text, Flex, Link } from "@chakra-ui/react";

interface Props {
  icon: ReactNode;
  to: string;
  text: string;
}

const ActionIconBlock = ({ icon, to, text }: Props) => (
  <Link as={RouterLink} to={to} variant="link-with-icon">
    <Flex alignItems="center">
      {icon}
      <Text fontWeight={700} ml="10px">
        {text}
      </Text>
    </Flex>
  </Link>
);

export default ActionIconBlock;
