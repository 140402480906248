import getMomaColorFromHexColor from "modules/colorSchemes/helpers/getMomaColorfromHexColor";
import { CustomizedColorSchemeForm } from "modules/colorSchemes/schemas/formSchemas";

const getColorSchemeFromFormValues = ({
  name,
  description,
  color_12,
  color_13,
  color_14,
  color_15,
}: CustomizedColorSchemeForm) => {
  return {
    name,
    description,
    colors: {
      color_12: getMomaColorFromHexColor(color_12),
      color_13: getMomaColorFromHexColor(color_13),
      color_14: getMomaColorFromHexColor(color_14),
      color_15: getMomaColorFromHexColor(color_15),
    },
  };
};
export default getColorSchemeFromFormValues;
