import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

export interface Props {
  top: number;
  left: number;
  zIndex: number;
  width: number;
  height: number;
  bgColor?: string;
  children?: ReactNode;
  borderColor?: string;
  display: string;
  justifyContent: string;
}

const FixedBox = ({
  top,
  left,
  zIndex,
  width,
  height,
  bgColor,
  children,
  borderColor,
  display,
  justifyContent,
}: Props) =>
  children ? (
    <Box
      position="absolute"
      border={borderColor ? `1px solid ${borderColor}` : "none"}
      top={`${top}px`}
      left={`${left}px`}
      bgColor={bgColor}
      zIndex={zIndex}
      w={`${width}px`}
      h={`${height}px`}
      display={display}
      justifyContent={justifyContent}
    >
      {children}
    </Box>
  ) : (
    <Box
      position="absolute"
      border={borderColor ? `1px solid ${borderColor}` : "none"}
      top={`${top}px`}
      left={`${left}px`}
      bgColor={bgColor}
      zIndex={zIndex}
      w={`${width}px`}
      h={`${height}px`}
    />
  );

export default FixedBox;
