import { useQuery } from "react-query";
import { inspectedMessagesSchema } from "../Schemas/InspectionMessagesSchemas";
import { inspectedTemplatesSchema } from "../Schemas/InspectionTemplatesSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { simpleRequest } from "core/services/api";
import { generatePath } from "react-router-dom";

export const getMessage = (id?: number) =>
  id
    ? simpleRequest(
        generatePath(ENDPOINTS.MSG_INSPECTION, { id }),
        inspectedMessagesSchema
      )
    : undefined;

export function useInspectedMessage(id?: number, fetch = true) {
  return useQuery(["message", id], () => getMessage(id), {
    enabled: !!id,
  });
}

export const getTemplateMonitor = (region?: string) => {
  if (region && region !== "ALL") {
    return simpleRequest(
      generatePath(ENDPOINTS.TPL_INSPECTION, { region }),
      inspectedTemplatesSchema
    );
  }
  return simpleRequest(
    generatePath(ENDPOINTS.TPL_INSPECTION_ALL_REGIONS),
    inspectedTemplatesSchema
  );
};

export function useInspectedTemplate(region?: string, fetch = true) {
  return useQuery(["templates", region], () => getTemplateMonitor(region), {
    enabled: !!region,
  });
}
