import React, { useState } from "react";
import {
  Button,
  Text,
  HStack,
  VStack,
  Divider,
  CloseButton,
  Icon,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import FilterIcon from "core/components/InterfaceIcons/FilterIcon";
import useFilterContext from "modules/filters/hooks/useFilterContext";
import hasGlobalFilterValues from "modules/filters/helpers/hasGlobalFilterValues";
import useFilterConfig from "modules/filters/hooks/useFilterConfig";
import SwitchFilter from "./SwitchFilter";
import isGlobalFilter from "modules/filters/helpers/isGlobalFilter";
import ContentFilter from "./filters/ContentFilter";
import { useContentFilter } from "../context/ContentFilterContext";

const CircleIcon = (props: any) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

export default function FiltersBlock<T extends object>() {
  const { t } = useTranslation();
  const [isToggle, setIsToggle] = useState(false);
  const globalFilterValue = useFilterContext<T>();
  const { schema, defaultGlobalFilters, optionalSchema } = useFilterConfig();
  const { filterText, setFilterText } = useContentFilter();

  if (!globalFilterValue) {
    return null;
  }
  const {
    setAllFilters,
    setGlobalFilter,
    globalFilter,
    headerGroups,
    filters,
  } = globalFilterValue;

  const toggleFilters = () => {
    setIsToggle(!isToggle);
  };

  const hasGlobalFilterChanged =
    globalFilter?.keyword !== defaultGlobalFilters?.keyword ||
    globalFilter?.archived !== defaultGlobalFilters?.archived;
  const hasFiltered = !!(
    filters?.length > 0 || hasGlobalFilterValues(globalFilter)
  );

  const handleFiltersReset = () => {
    setAllFilters([]);
    if (
      globalFilter &&
      hasGlobalFilterChanged &&
      defaultGlobalFilters &&
      setGlobalFilter
    ) {
      setGlobalFilter(defaultGlobalFilters);
    }
    if (filterText && filterText.length > 0) {
      setFilterText("");
    }
  };

  return (
    <VStack align="flex-start">
      <HStack>
        <Text textStyle="subtitle">{t("filters.filter")}</Text>

        {(hasFiltered || hasGlobalFilterChanged) && (
          <Button
            alignSelf="end"
            onClick={handleFiltersReset}
            color="monza"
            fontSize="10px"
            fontWeight="700"
            textDecoration="underline"
            size="xs"
            colorScheme="white"
          >
            {t("filters.reset_filters")}
          </Button>
        )}
      </HStack>

      <HStack spacing={3}>
        {(schema.options as string[]).map((id) => {
          const column = headerGroups[0].headers.find(
            (header) => header.id === id
          );
          return (
            <Box key={id}>
              {column ? (
                column.render("Filter")
              ) : isGlobalFilter(id, defaultGlobalFilters) ? (
                <SwitchFilter column={{ id }} />
              ) : null}
            </Box>
          );
        })}

        <HStack spacing={1}>
          {optionalSchema && (
            <Button
              data-testid="toggle-filters"
              onClick={toggleFilters}
              variant="unstyled"
              color={isToggle ? "jumbo" : "monza"}
            >
              <HStack>
                <VStack spacing={-1} align="flex-end">
                  {hasFiltered && (
                    <CircleIcon boxSize={1.5} color={!isToggle && "green"} />
                  )}
                  <FilterIcon boxSize={4} />
                </VStack>
                <Text textStyle="label">{t("filters.more_filters")}</Text>
              </HStack>
            </Button>
          )}

          {hasFiltered && (
            <Text fontSize="10px" fontWeight="300">
              {t("filters.edited")}
            </Text>
          )}
        </HStack>
      </HStack>

      {isToggle && optionalSchema && (
        <VStack alignItems="flex-start">
          <VStack>
            <Divider
              orientation="horizontal"
              colorScheme="silver"
              width="40rem"
            />
          </VStack>

          <HStack spacing={3}>
            {(optionalSchema.options as string[]).map((id) => {
              const column = headerGroups[0].headers.find(
                (header) => header.id === id
              );
              return (
                <Box key={id}>
                  {column ? (
                    column.render("Filter")
                  ) : isGlobalFilter(id, defaultGlobalFilters) ? (
                    <SwitchFilter column={{ id }} />
                  ) : null}
                </Box>
              );
            })}
            <ContentFilter />

            <CloseButton size="lg" onClick={toggleFilters} />
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}
