import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Text, Flex, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import DuplicateIcon from "core/components/InterfaceIcons/DuplicateIcon";

interface Props {
  id: string;
  color: string;
}

const DuplicateGlobalMessage = ({ id, color }: Props) => {
  const { t } = useTranslation();

  return (
    <Link
      variant="link-with-icon"
      as={RouterLink}
      to={`/globals/create?id=${id}`}
      color={color}
    >
      <Flex alignItems="center">
        <DuplicateIcon />
        <Text fontWeight={700} ml="10px">
          {t("actions.duplicate.button")}
        </Text>
      </Flex>
    </Link>
  );
};

export default DuplicateGlobalMessage;
