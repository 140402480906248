import { useQuery } from "react-query";
import { simpleRequest } from "core/services/api";
import { availableRegionsApiSchema } from "modules/user/informations/schemas/userApiSchema";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";

export const availableRegions = () =>
  simpleRequest(ENDPOINTS.REGIONS, availableRegionsApiSchema);

export function useAvailableRegions(fetch = true) {
  return useQuery(["regions"], availableRegions, {
    enabled: fetch,
    suspense: true,
  });
}
