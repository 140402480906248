import {
  Template,
  DraftTemplate,
} from "modules/preview/schemas/templateSchemas";

const templateBackToDraftTransform = (template?: Template) => {
  if (!template) {
    return;
  }
  const draftTemplate: DraftTemplate = { ...template };
  delete draftTemplate.template_id;
  delete draftTemplate.functional_details;
  return draftTemplate;
};

export default templateBackToDraftTransform;
