import React from "react";
import { Text, useTheme, HStack, Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import DISABLED_OPACITY from "modules/preview/constants/disabledOpacity";

import { COLORS } from "theme";

interface StyledButtonProps {
  disabled: boolean;
  colors: COLORS;
}

interface ColorProps {
  background: string;
  colors: COLORS;
}

interface Props {
  text: string;
  color: string;
  disabled?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  text-decoration: "none";
  color: ${(props) =>
    props.disabled ? props.colors.silver : props.colors.dark};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  justify-content: flex-start;
  &:hover:enabled {
    color: ${(props) => props.colors.amaranth};
    text-decoration: underline;
    text-underline-position: under;
    & > div > div {
      border-color: ${(props) => props.colors.malibu};
    }
  }
  &:focus:enabled,
  &:active:enabled {
    outline: 0;
  }
`;

const Color = styled(Box)<ColorProps>`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${(props) => props.background};
  border: 2px solid;
  border-color: ${(props) => props.colors.silver};
`;

const ColorButton = ({ text, color, disabled = false }: Props) => {
  const { colors } = useTheme();
  return (
    <StyledButton colors={colors} disabled={disabled} type="button">
      <HStack spacing={2}>
        <Color
          colors={colors}
          background={color}
          sx={{ opacity: disabled ? DISABLED_OPACITY : 1 }}
        />
        <Text fontSize="12px">{text}</Text>
      </HStack>
    </StyledButton>
  );
};

export default ColorButton;
