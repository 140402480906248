import React, { useMemo } from "react";
import { Flex, HStack, useRadioGroup } from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  Alignment,
  alignmentSchema,
  AlignmentParam,
} from "modules/preview/schemas/templateSchemas";
import { useController, useFormContext } from "react-hook-form";
import IconAlignmentButton from "modules/preview/components/ui/IconAlignmentButton";
import { PreviewFormType } from "modules/create/schemas/formSchema";

interface Props {
  name: AlignmentParam;
  defaultValue: Alignment;
}

const Controls = styled(Flex)`
  position: absolute;
  top: -35px;
  width: 100%;
  justify-content: center;
`;

const Position = styled(HStack)`
  background-color: rgba(34, 38, 42, 1);
  padding: 10px;
  box-shadow: 0px 4px 4px 0px rgba(34, 38, 42, 0.5);
`;

const TextAlignmentForm = ({ name, defaultValue }: Props) => {
  const alignmentInputName = useMemo(() => `alignment[${name}]`, [name]);
  const { control } = useFormContext<PreviewFormType>();

  const {
    field: { ref, ...radioGroupProps },
  } = useController({
    name: alignmentInputName,
    defaultValue,
    control,
  });

  const { getRootProps, getRadioProps } = useRadioGroup(radioGroupProps);

  const group = getRootProps();

  return (
    <Controls>
      <Position spacing={3} {...group}>
        {alignmentSchema.options.map((value) => {
          const radio = getRadioProps({ value });
          return <IconAlignmentButton {...radio} key={value} />;
        })}
      </Position>
    </Controls>
  );
};

export default TextAlignmentForm;
