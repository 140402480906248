import React from "react";
import { Tab, TabList, Tabs, TabPanels, TabPanel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import ProfileInfoForm from "modules/user/profile/components/ProfileInfoForm";
import ContactAdminForm from "modules/user/profile/components/ContactAdminForm";
import { useCanSeeTestCards } from "modules/user/profile/hooks/useCanSeeTestCards";

import Cards from "modules/user/cards/components/Cards";

interface Props {
  tab?: number;
}

const ProfileForms = ({ tab }: Props) => {
  const { t } = useTranslation();
  const canSeeTestCards = useCanSeeTestCards();

  return (
    <Tabs
      align="end"
      w="880px"
      data-testid="user-forms"
      defaultIndex={tab ?? 0}
    >
      <TabList>
        <Tab>{t("create_user.infos")}</Tab>
        {canSeeTestCards && <Tab>{t("create_user.cards")}</Tab>}
        <Tab>{t("contact_admin.title")}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form>
            <ProfileInfoForm />
          </form>
        </TabPanel>
        {canSeeTestCards && (
          <TabPanel>
            <Cards />
          </TabPanel>
        )}
        <TabPanel>
          <ContactAdminForm />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ProfileForms;
