import React, { useMemo } from "react";
import {
  Flex,
  VStack,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  Text,
  useRadioGroup,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, useController } from "react-hook-form";

import { ColorParam } from "modules/preview/schemas/templateSchemas";
import {
  radioCardSizeSchema,
  PreviewFormType,
} from "modules/create/schemas/formSchema";
import useCustomizableColors from "modules/preview/hooks/useCustomizableColors";
import useColorScheme from "modules/preview/hooks/useColorScheme";
import RadioCard from "modules/create/components/ui/RadioCard";
import getHexColorFromMomaColor from "modules/templates/helpers/getHexColorFromMomaColor";

interface Props {
  param?: ColorParam;
}

const ColorForm = ({ param }: Props) => {
  const { control } = useFormContext<PreviewFormType>();
  const name = useMemo(() => `colors.${param}`, [param]);
  const { t, i18n } = useTranslation();
  const colorScheme = useColorScheme();
  const colors = useCustomizableColors();

  const {
    field: { ref, ...radioGroupProps },
  } = useController({
    name,
    control,
  });

  const { getRootProps, getRadioProps } = useRadioGroup(radioGroupProps);

  const group = getRootProps();
  const subtitle = useMemo(
    () =>
      i18n.language === "en"
        ? `${colorScheme?.name} ${t("preview.colorScheme.colors")}`
        : `${t("preview.colorScheme.colors")} ${colorScheme?.name} `,
    [i18n.language, colorScheme?.name]
  );

  return (
    <PopoverContent top={12} w={96}>
      <PopoverHeader border="none">
        <Box>
          {param && (
            <Text fontWeight={500} display="flex">
              {t(`preview.colorScheme.${param}` as const)}
            </Text>
          )}
          <Text fontWeight={500} mt="20px" display="flex" fontSize="small">
            {subtitle}
          </Text>
        </Box>
      </PopoverHeader>
      <PopoverCloseButton />
      <PopoverBody>
        <Flex
          {...group}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-around"
          data-testid="color-form"
        >
          {colors &&
            Object.entries(colors).map(([colorParam, momaColor]) => {
              // the value to set is the number of the color : "color_0" => "0"
              const value = colorParam.split("_")[1];
              const radio = getRadioProps({
                value,
              });

              const hexColor = getHexColorFromMomaColor(momaColor);
              return (
                <RadioCard
                  key={colorParam}
                  {...radio}
                  size={radioCardSizeSchema.enum.smallRounded}
                  roundedColors={[hexColor, hexColor]}
                >
                  <VStack maxWidth={20}>
                    <Text fontSize="x-small">{hexColor.toUpperCase()}</Text>
                  </VStack>
                </RadioCard>
              );
            })}
        </Flex>
      </PopoverBody>
    </PopoverContent>
  );
};

export default ColorForm;
