import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";
import layerStyles from "./layerStyles";
import textStyles from "./textStyles";
import fonts from "./fonts";
import styles from "./styles";
import Container from "./components/container";
import sizes from "./sizes";
import Heading from "./components/heading";
import Link from "./components/link";
import Breadcrumb from "./components/breadcrumb";
import Select from "./components/select";
import Tooltip from "./components/toolTip";
import Checkbox from "./components/checkbox";
import Switch from "./components/switch";
import Button from "./components/button";
import Radio from "./components/radio";
import Tabs from "./components/tabs";
import Popover from "./components/popover";
import Input from "./components/input";

const theme = {
  styles,
  colors,
  layerStyles,
  sizes,
  fonts,
  textStyles,
  components: {
    Heading,
    Container,
    Checkbox,
    Button,
    Switch,
    Radio,
    Tooltip,
    Link,
    Breadcrumb,
    Select,
    Tabs,
    Popover,
    Input,
  },
};
const extendedTheme = extendTheme(theme);
export default extendedTheme;
export type COLORS = typeof extendedTheme.colors;
