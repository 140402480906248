import React, { ReactNode, SetStateAction, useCallback, useMemo } from "react";
import { Accordion, Spinner } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { DashboardType, ProbeType } from "../../schemas/formSchemas";
import { ProbeItem } from "../ProbeItem";
import { Title } from "modules/monitoring/shared/component/Title";
import { useDashboards } from "../../hooks/useDashboards";

type callbackType = (arg: any, { getChildren, children }: any) => any;

type childrenType = {
  getChildren: (child: ProbeType) => ReactNode;
  children: ProbeType;
};

export const ProbeList = ({
  setActiveItem,
  activeItem,
}: {
  setActiveItem: React.Dispatch<SetStateAction<ProbeType | null>>;
  activeItem: ProbeType | null;
}) => {
  const { data, isLoading } = useDashboards();

  const getChildren = useCallback(
    (probes: ProbeType[]): ReactNode => {
      const probeElements = (
        probe: ProbeType,
        { getChildren, children }: childrenType
      ) => {
        const isActiveItem = probe?.id === activeItem?.id;

        return (
          <ProbeItem
            key={probe.id}
            child={probe}
            setActiveItem={setActiveItem}
            isActiveItem={isActiveItem}
            getChildren={getChildren ?? ((child: ProbeType) => null)}
            children={children ?? {}}
          />
        );
      };

      return mapProbes(probes, probeElements);
    },
    [activeItem?.id, setActiveItem]
  );

  const mapProbes: callbackType = useCallback(
    (probes: ProbeType[], callback: callbackType, args: any = {}) => {
      return probes?.map((probe: ProbeType) => {
        if (!probe.probes) {
          return callback(probe, args);
        }

        const probeChildren = Object.values(probe.probes);

        return callback(probe, {
          getChildren: getChildren,
          children: probeChildren,
        });
      });
    },
    [getChildren]
  );

  const getParents = useCallback(
    (dashboard?: DashboardType[]) => {
      return mapProbes(dashboard, (dashboard: DashboardType) => {
        return (
          dashboard?.probes && getChildren(Object.values(dashboard.probes))
        );
      });
    },
    [mapProbes, getChildren]
  );
  const probesList = useMemo(() => getParents(data), [getParents, data]);

  const Wrapper = styled.div`
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;
  `;
  return (
    <div data-testid="probe-list">
      <Title title={data?.[0]?.dashboard_title || ""} />
      {isLoading ? (
        <Wrapper>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="sunglo"
            color="white"
            size="xl"
          />
        </Wrapper>
      ) : (
        <Accordion allowMultiple={true} allowToggle={true}>
          {probesList}
        </Accordion>
      )}
    </div>
  );
};
