import React from "react";
import {
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormHelperText,
} from "@chakra-ui/react";
import { useFormContext, Controller } from "react-hook-form";

import { PreviewFormType } from "modules/create/schemas/formSchema";

interface Props {
  name: string;
  label: string;
  hint: string;
  defaultValue?: string;
  min: number;
  max: number;
  disabled: boolean;
}

const NumberSettingsForm = ({
  name,
  label,
  hint,
  min,
  max,
  defaultValue = "0",
  disabled,
}: Props) => {
  const context = useFormContext<PreviewFormType>();
  return (
    <Controller
      name={name}
      control={context?.control}
      defaultValue={defaultValue}
      render={({ ref, ...rest }) => (
        <FormControl id={name}>
          <FormLabel>{label}</FormLabel>
          <NumberInput
            defaultValue={defaultValue}
            isDisabled={disabled}
            min={min}
            max={max}
            ref={ref}
            {...rest}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormHelperText fontSize="xs" color="abbey">
            {hint}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default NumberSettingsForm;
