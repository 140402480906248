import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { userTestCardsBodySchema } from "modules/user/cards/schemas/userTestCardsApiSchemas";
import userTestCardsTransform from "modules/user/cards/helpers/userTestCardsTransform";

export const userTestCards = (userId?: string) =>
  userId
    ? simpleRequest(
        generatePath(ENDPOINTS.USER_TEST_CARDS, { id: userId }),
        userTestCardsBodySchema
      )
    : Promise.reject();

export function useUserTestCards(fetch = true) {
  const { id: userId } = useParams<RouteIdParam>();
  return useQuery(
    ["userTestCards", Number(userId)],
    () => userTestCards(userId),
    {
      enabled: fetch,
      suspense: true,
      select: userTestCardsTransform,
    }
  );
}
