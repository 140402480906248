import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Flex, Button, useDisclosure } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import CardsTable from "modules/user/cards/components/CardsTable";
import ErrorHandler from "modules/shared/components/ErrorHandler/ErrorHandler";
import CardModal from "modules/user/cards/components/CardModal";
import { UserTestCardFromList } from "modules/user/cards/schemas/userTestCardsApiSchemas";
import { useUserTestCards } from "../hooks/useUserTestCards";
import { useIsParamRoute } from "modules/shared/hooks/useIsParamRoute";
import { useProfileTestCards } from "../hooks/useProfileTestCard";

const Cards = () => {
  const isParamRoute = useIsParamRoute();
  const { data } = (isParamRoute ? useUserTestCards : useProfileTestCards)();
  const { t } = useTranslation();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [card, setCard] = useState<UserTestCardFromList>();
  const handleCardEdit = (card: UserTestCardFromList) => {
    setCard(card);
    onOpen();
  };

  const handleCloseModal = () => {
    if (card) {
      setCard(undefined);
    }
    onClose();
  };
  return (
    <>
      <Flex mt="10px" justifyContent="space-between" data-testid="user-cards">
        <Heading as="h1" size="md">
          {t("user-cards.title")}
        </Heading>
        {isParamRoute && (
          <Button
            variant="primary-solid"
            leftIcon={<AddIcon />}
            data-testid="create-card-button"
            onClick={onOpen}
          >
            {t("user-cards.add")}
          </Button>
        )}

        <CardModal
          isOpen={isOpen}
          onClose={handleCloseModal}
          card={card}
          key={card?.id}
        />
      </Flex>
      <ErrorHandler>
        <CardsTable cards={data} handleCardEdit={handleCardEdit} />
      </ErrorHandler>
    </>
  );
};

export default Cards;
