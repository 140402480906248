import React from "react";
import { HStack, Box, Container } from "@chakra-ui/react";

import SaveUserAction from "./SaveUserAction";
import ResetPasswordAction from "./ResetPasswordAction";
import DeleteUserAction from "./DeleteUserAction";
import { useRouteMatch } from "react-router-dom";

const UserActionBar = () => {
  const isNewUser = useRouteMatch({
    path: "/administration/create-user",
    exact: true,
  });
  const isNewUserFromAnotherUser = useRouteMatch({
    path: "/administration/duplicate-user/:id",
    exact: true,
  });

  const allowButton = isNewUser || isNewUserFromAnotherUser;
  return (
    <Box bgColor="black">
      <Container>
        <HStack spacing={5} p="10px" justifyContent="flex-end" pr="30px">
          <SaveUserAction />
          <DeleteUserAction />
          {!allowButton && <ResetPasswordAction />}
        </HStack>
      </Container>
    </Box>
  );
};

export default UserActionBar;
