import React from "react";
import { createIcon } from "@chakra-ui/react";

const CloseIcon = createIcon({
  displayName: "CheckIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.2364L6.41004 4.64645L4.64648 6.41L10.2365 12L4.64648 17.59L6.41004 19.3536L12 13.7636L17.59 19.3536L19.3536 17.59L13.7636 12L19.3536 6.41L17.59 4.64645L12 10.2364Z"
        fill="currentColor"
      />
    </>
  ),
});

export default CloseIcon;
