import { useQuery } from "react-query";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { templateSchema } from "modules/preview/schemas/templateSchemas";

import useLocationQueryParams from "core/hooks/useLocationQueryParams";
import templateBackToDraftTransform from "modules/duplicate/helpers/templateBackToDraftTransform";

export const getTemplate = (id?: string | null) =>
  id
    ? simpleRequest(generatePath(ENDPOINTS.TEMPLATE, { id }), templateSchema)
    : undefined;

export default function useDuplicatedTemplate() {
  const query = useLocationQueryParams();
  const id = query.has("id") ? query.get("id") : undefined;
  return useQuery(["template", Number(id)], () => getTemplate(id), {
    enabled: !!id,
    suspense: true,
    select: templateBackToDraftTransform,
  });
}
