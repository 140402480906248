import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import {
  commentsBodySchema,
  CommentsBody,
} from "modules/comments/schemas/commentsApiSchemas";
import { generatePath } from "react-router";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";

export const getComments = (templateId: number) =>
  simpleRequest(
    generatePath(ENDPOINTS.COMMENTS, { id: templateId }),
    commentsBodySchema
  );

export default function useComments(fetch = true) {
  const { id } = useParams<RouteIdParam>();
  return useQuery<CommentsBody>(
    ["comments", Number(id)],
    () => getComments(Number(id)),
    {
      enabled: fetch,
      suspense: true,
    }
  );
}
