import i18n from "i18next";
import { Role } from "modules/roles/schemas/rolesSchemas";
import { Status } from "modules/preview/schemas/templateSchemas";
import { StatusAlert } from "modules/feedback/components/InfoAlert";

const getFirstStep = (
  templateStatus: Status,
  roles: Array<Role | string>
): StatusAlert => {
  const isLegalValidator = roles.includes("LEGAL_VALIDATOR");
  const isCommercialValidator = roles.includes("COMMERCIAL_VALIDATOR");
  const title = i18n.t("info.step1.title");
  let status: string | undefined = "progress";
  if (templateStatus === "PENDING_COMMERCIAL") {
    if (isLegalValidator) {
      status = "validated.legal";
    } else if (isCommercialValidator) {
      status = "validated.commercial";
    } else status = "validated.contributor";
  }
  if (templateStatus === "VALIDATED") {
    if (isLegalValidator) {
      status = "validated.legal";
    } else if (isCommercialValidator) {
      status = "validated.commercial";
    } else status = "validated.contributor";
  }
  if (templateStatus === "REFUSED") {
    if (isLegalValidator) {
      status = "refused.legal";
    } else if (isCommercialValidator) {
      status = "validated.contributor";
    } else status = "refused.contributor";
  }
  status = status && i18n.t(`info.step1.status.${status}` as const);
  const type =
    templateStatus === "PENDING_LEGAL"
      ? "inprogress"
      : templateStatus === "PENDING_COMMERCIAL" ||
        templateStatus === "VALIDATED" ||
        isCommercialValidator
      ? "success"
      : "error";
  const withForm =
    roles.includes("LEGAL_VALIDATOR") && templateStatus === "PENDING_LEGAL";
  return { title, status, type, withForm };
};

const getSecondStep = (
  templateStatus: Status,
  roles: Array<Role | string>
): StatusAlert => {
  const isLegalValidator = roles.includes("LEGAL_VALIDATOR");
  const isCommercialValidator = roles.includes("COMMERCIAL_VALIDATOR");
  const title = i18n.t(`info.step2.title` as const);

  let status;
  if (templateStatus === "PENDING_COMMERCIAL") {
    status = "progress";
  }
  if (templateStatus === "VALIDATED") {
    if (isLegalValidator) {
      status = "validated.legal";
    } else if (isCommercialValidator) {
      status = "validated.commercial";
    } else status = "validated.contributor";
  }
  if (templateStatus === "REFUSED") {
    if (isCommercialValidator) {
      status = "refused.commercial";
    } else if (!isLegalValidator) {
      status = "refused.contributor";
    }
  }

  status = status && i18n.t(`info.step2.status.${status}` as const);
  const type =
    templateStatus !== "STOPPED"
      ? templateStatus === "PENDING_LEGAL"
        ? "tocome"
        : templateStatus === "PENDING_COMMERCIAL"
        ? "inprogress"
        : templateStatus === "VALIDATED"
        ? "success"
        : templateStatus === "REFUSED" && isLegalValidator
        ? "tocome"
        : "error"
      : "error";
  const withForm =
    roles.includes("COMMERCIAL_VALIDATOR") &&
    templateStatus === "PENDING_COMMERCIAL";
  return { title, status, type, withForm };
};

const getTemplateStatusInfo = (
  status?: Status,
  roles?: Array<Role | string>
): [StatusAlert, StatusAlert] | undefined => {
  if (!roles || !status) {
    return;
  }
  return [getFirstStep(status, roles), getSecondStep(status, roles)];
};

export default getTemplateStatusInfo;
