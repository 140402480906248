import React from "react";
import DraftIcon from "core/components/InterfaceIcons/DraftIcon";
import { Button } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { merge } from "remeda";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { CustomizedColorSchemeForm } from "modules/colorSchemes/schemas/formSchemas";
import getColorSchemeFromFormValues from "modules/colorSchemes/helpers/getColorSchemeFromFormValues";
import getFullColorSchemeFromFormValues from "modules/colorSchemes/helpers/getFullColorSchemeFromFormValues";
import useCustomColorScheme from "modules/colorSchemes/hooks/useCustomColorScheme";
import { useCreateColorScheme } from "../hooks/useCreateColorScheme";
import { useParams } from "react-router-dom";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { useSaveColorScheme } from "../hooks/useSaveColorScheme";
import { useTranslation } from "react-i18next";

const SaveColorSchemeAction = () => {
  const context = useFormContext<CustomizedColorSchemeForm>();
  const { id } = useParams<RouteIdParam>();
  const { setFeedback } = useFeedbackStore();
  const create = useCreateColorScheme();
  const save = useSaveColorScheme();
  const { data } = useCustomColorScheme();
  const { t } = useTranslation();
  const handleError = () => {
    setFeedback(feedbackSchema.enum.error, actionsSchema.enum.save);
  };

  const handleSave = (formValues: CustomizedColorSchemeForm) => {
    const colorScheme = merge(data, getColorSchemeFromFormValues(formValues));
    if (id) {
      save.mutate(colorScheme);
    } else {
      create.mutate(getFullColorSchemeFromFormValues(formValues));
    }
  };

  return (
    <Button
      onClick={context?.handleSubmit(handleSave, handleError)}
      leftIcon={<DraftIcon />}
      variant="primary-solid"
    >
      {t("assets.color_scheme.save")}
    </Button>
  );
};

export default SaveColorSchemeAction;
