import React from "react";
import { Box, VStack, Heading, Text } from "@chakra-ui/react";
import { ProbeType } from "modules/monitoring/ProbeList/schemas/formSchemas";

const GraphCard = ({ graph }: { graph: ProbeType }) => {
  return (
    <>
      {graph?.value?.ddog ? (
        <VStack
          borderWidth="1px"
          borderRadius="md"
          alignItems={"flex-start"}
          p={4}
        >
          <Heading size="sm">{graph?.title} </Heading>
          <Text mt={2}>{graph?.description} </Text>
          <Box mt={4} overflow="scroll">
            <iframe
              src={graph?.value?.ddog}
              title="Graph"
              height="200"
              width="400"
              data-testid="graph-iframe"
            />
          </Box>
        </VStack>
      ) : null}
    </>
  );
};

export default GraphCard;
