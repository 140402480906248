import * as z from "zod";

export const imageTypes = z.enum(["logo", "qrcode"]);
export type ImageTypes = z.infer<typeof imageTypes>;

export const customImageSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  regions: z.array(z.string()),
  url: z.string(),
  type: imageTypes.optional(),
  mutable: z.boolean().optional(),
  templates: z.array(z.number()).optional(),
  deletable: z.boolean().optional(),
});

export type CustomImage = z.infer<typeof customImageSchema>;

export const customImageBodySchema = z.array(customImageSchema);

export const imageFormSchema = z.object({
  id: z.number().optional(),
  title: z.string().min(1),
  description: z.string().min(1),
  regions: z.string().array(),
  original: z.instanceof(FileList),
  type: imageTypes.optional(),
});

export const qrcodeFormSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1),
  description: z.string().min(1),
  target_url: z.string().min(1),
  size: z.number().optional(),
  regions: z.string().array().min(1),
  short_url: z.string().optional(),
});

export type ManageImageFormValues = z.infer<typeof imageFormSchema>;
export type ManageQrcodeFormValues = z.infer<typeof qrcodeFormSchema>;
