import React from "react";
import {
  CheckboxGroup,
  Checkbox,
  VStack,
  Text,
  Box,
  useCheckboxGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, useController } from "react-hook-form";

import { CreateUserFormValues } from "modules/user/informations/schemas/formSchemas";
import WarningMessage from "core/components/WarningMessage/WarningMessage";
import useDisableForm from "modules/preview/hooks/useDisableForm";
import { PreviewFormType } from "modules/create/schemas/formSchema";

export interface Props {
  regions: string[] | undefined;
}

const RegionsForm = ({ regions }: Props) => {
  const { t } = useTranslation();

  const disabled = useDisableForm();

  const { errors, control } = useFormContext<
    CreateUserFormValues | PreviewFormType
  >();

  const isError = errors.regions;

  const {
    field: { ref, ...checkboxGroupProps },
  } = useController({
    name: "regions",
    control,
    defaultValue: [],
  });
  const { getCheckboxProps, value } = useCheckboxGroup(checkboxGroupProps);

  return (
    <Box>
      <Text
        fontSize="xs"
        mb="20px"
        color={isError ? "error" : "dark"}
        textAlign="left"
      >
        {t("preview.regions.label")}
      </Text>
      <CheckboxGroup value={value} isDisabled={disabled}>
        <VStack align="left" spacing={0}>
          {regions?.map((region) => {
            const checkboxProps = getCheckboxProps();
            return (
              <Checkbox
                {...checkboxProps}
                data-testid="form-regions"
                name="regions"
                key={region}
                isChecked={checkboxProps.isChecked}
                isDisabled={disabled}
                alignItems="flex-start"
                value={region}
                lineHeight="15px"
                ref={ref}
              >
                <Text fontSize="sm" color="dark" mb="20px">
                  {region}
                </Text>
              </Checkbox>
            );
          })}
        </VStack>
        {isError && (
          <WarningMessage>{t("preview.regions.error")}</WarningMessage>
        )}
      </CheckboxGroup>
    </Box>
  );
};

export default RegionsForm;
