import * as z from "zod";

export const commentSchema = z.object({
  id: z.number(),
  author: z.string(),
  comment: z.string(),
  date: z.string(),
});

export type Comment = z.infer<typeof commentSchema>;

export const commentsBodySchema = z.array(commentSchema);

export type CommentsBody = z.infer<typeof commentsBodySchema>;

export const commentApiSchema = z
  .object({
    comment_id: z.number(),
  })
  .nonstrict();
