import * as z from "zod";
export const rolesSchema = z.enum([
  "ADMINISTRATOR",
  "LEGAL_VALIDATOR",
  "COMMERCIAL_VALIDATOR",
  "TESTER",
  "CONTRIBUTOR",
  "OBSERVER",
  "BROADCASTER",
  "TARGETER",
  "SENDER",
  "CONFIGURATOR",
]);

export type Role = z.infer<typeof rolesSchema>;

export const knownRolesListSchema = z.array(rolesSchema);

// roles may be known or unknown
export const rolesListSchema = z.array(z.union([rolesSchema, z.string()]));

export const verbSchema = z.enum([
  "GET",
  "POST",
  "PUT",
  "PATCH",
  "DELETE",
  "*",
]);
export type Verb = z.infer<typeof verbSchema>;
export const routesSchema = z.object({
  route: z.string(),
  verbs: z.array(verbSchema),
});
export type Routes = z.infer<typeof routesSchema>;

export const rolesBodySchema = z.object({
  roles: rolesListSchema,
  routes: z.array(routesSchema),
});

export type RolesBody = z.infer<typeof rolesBodySchema>;
