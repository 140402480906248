import { FrameObject } from "modules/preview/schemas/specSchemas";
import { FrameProps } from "modules/preview/components/Frames";
import { textObjectClassSchema } from "modules/preview/schemas/specSchemas";
export const getBoxProps = (params: FrameProps<FrameObject>) => {
  const {
    frame: { x, y, w, h, name },
    index,
  } = params;
  const isOT5 = name === textObjectClassSchema.enum.OT5;
  const display = isOT5 ? "flex" : "initial";
  const justifyContent = isOT5 ? "space-between" : "";
  return {
    display,
    justifyContent,
    top: y,
    left: x,
    zIndex: index,
    width: w,
    height: h,
  };
};
