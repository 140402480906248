const Checkbox = {
  baseStyle: {
    control: {
      borderColor: "abbey",
      borderRadius: "none",
      _checked: {
        bg: "amaranth",
        border: "none",
        _hover: {
          bg: "monza",
        },
      },

      _disabled: {
        bg: "silver",
        border: "silver",
      },
      _indeterminate: {
        bg: "amaranth",
        border: "none",
      },
    },
  },
};

export default Checkbox;
