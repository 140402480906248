import React from "react";
import { Button, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import DraftIcon from "core/components/InterfaceIcons/DraftIcon";
import EditIcon from "core/components/InterfaceIcons/EditIcon";
import ViewIcon from "core/components/InterfaceIcons/ViewIcon";

interface ActionButtonsProps {
  handleEdit?: () => void;
  handleView?: () => void;
  handleInsert?: (imageId: number) => void;
  qrcodeId: number;
  imageId: number;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  handleInsert,
  handleEdit,
  handleView,
  imageId,
}) => {
  const { t } = useTranslation();

  const insertButton = handleInsert && (
    <Button
      colorScheme="light"
      leftIcon={<DraftIcon mr="5px" />}
      variant="link-button"
      onClick={() => handleInsert(imageId)}
    >
      {t("actions.table.insert")}
    </Button>
  );

  const editButton = handleEdit && (
    <Button
      colorScheme="light"
      leftIcon={<EditIcon mr="5px" />}
      variant="link-with-icon"
      onClick={handleEdit}
    >
      {t("actions.table.write")}
    </Button>
  );

  const viewButton = handleView && (
    <Button
      colorScheme="light"
      leftIcon={<ViewIcon mr="5px" />}
      variant="link-with-icon"
      onClick={handleView}
    >
      {t("actions.table.read")}
    </Button>
  );

  return (
    <VStack spacing={2} alignItems="flex-start">
      {handleInsert ? (
        insertButton
      ) : (
        <>
          {editButton} {viewButton}
        </>
      )}
    </VStack>
  );
};
