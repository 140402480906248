import React from "react";
import {
  Tab,
  TabList,
  Tabs,
  TabPanels,
  TabPanel,
  Divider,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import LoginInfoForm from "modules/user/informations/components/LoginInfoForm";
import TechnicalForm from "modules/user/informations/components/TechnicalForm";
import Cards from "modules/user/cards/components/Cards";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { UserIdContext } from "modules/shared/context/UserIdContext";
import { useRouteMatch } from "react-router-dom";

const UserForms = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const isNewUserFromAnotherUser = useRouteMatch({
    path: "/administration/duplicate-user/:id",
    exact: true,
  });

  return (
    <Tabs align="end" w="880px" data-testid="user-forms">
      <TabList>
        <Tab>{t("create_user.infos")}</Tab>
        <Tab isDisabled={isNewUserFromAnotherUser?.isExact || !id}>
          {t("create_user.cards")}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form>
            <LoginInfoForm />
            <Divider orientation="horizontal" borderColor="silver" my="20px" />
            <TechnicalForm />
          </form>
        </TabPanel>

        <TabPanel>
          {id && (
            <UserIdContext.Provider value={id}>
              <Cards />
            </UserIdContext.Provider>
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default UserForms;
