import React from "react";
import DraftIcon from "core/components/InterfaceIcons/DraftIcon";
import { Button } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { merge } from "remeda";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { useParams, useHistory } from "react-router-dom";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { useTranslation } from "react-i18next";
import {
  DraftGlobalMessage,
  GlobalMessageFormValues,
} from "modules/globalMessage/schemas/globalMessageFormSchema";
import { useCreateGlobalMessage } from "modules/globalMessage/hooks/useCreateGlobalMessage";
import getGlobalMessageFromFormValues from "modules/globalMessage/helpers/getGlobalMessageFromFormValues";
import { useSaveGlobalMessage } from "modules/globalMessage/hooks/useSaveGlobalMessage";
import useGlobalMessage from "modules/globalMessage/hooks/useGlobalMessage";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";
import { PATHS } from "modules/shared/constants/paths";

const SaveGlobalMessage = () => {
  const context = useFormContext<GlobalMessageFormValues>();
  const { id } = useParams<RouteIdParam>();
  const { setFeedback } = useFeedbackStore();
  const history = useHistory();
  const { data } = useGlobalMessage();
  const create = useCreateGlobalMessage();
  const save = useSaveGlobalMessage();
  const { t } = useTranslation();
  const handleError = () => {
    setFeedback(feedbackSchema.enum.error, actionsSchema.enum.save);
  };
  const hasRightForGlobalMessages = useHasAccessRights(PATHS.GLOBAL_MESSAGES, [
    "POST",
  ]);
  const globalMessageStatus = data?.status;
  const isDisabled = React.useMemo(
    () =>
      (!!globalMessageStatus && globalMessageStatus !== "DRAFT") ||
      !hasRightForGlobalMessages,
    [globalMessageStatus, hasRightForGlobalMessages]
  );
  const handleSave = (formValues: DraftGlobalMessage) => {
    const global = merge(data, getGlobalMessageFromFormValues(formValues));
    if (id) {
      save.mutate(global, {
        onSuccess: () => {
          setFeedback(feedbackSchema.enum.success, actionsSchema.enum.save);
        },
      });
    } else {
      create.mutate(global, {
        onSuccess: ({ id }) => {
          history.push(`/global/${id}`);
          setFeedback(feedbackSchema.enum.success, actionsSchema.enum.save);
        },
      });
    }
  };

  return (
    <Button
      onClick={context?.handleSubmit(handleSave, handleError)}
      leftIcon={<DraftIcon />}
      variant="primary-solid"
      disabled={isDisabled}
      data-testid="save-global-message"
    >
      {t("global_message.save")}
    </Button>
  );
};

export default SaveGlobalMessage;
