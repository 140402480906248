import { useQuery } from "react-query";
import { simpleRequest } from "core/services/api";
import { usersListSchema } from "modules/users/schemas/usersModelSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import usersListTransform from "modules/users/helpers/usersListTransform";

export const users = () => simpleRequest(ENDPOINTS.USERS, usersListSchema);

export function useUsers(fetch = true) {
  return useQuery(["users"], users, {
    select: usersListTransform,
    enabled: fetch,
    suspense: true,
  });
}
