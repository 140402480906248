import {
  colorSchemeColorKeySchema,
  ColorSchemeColorKey,
} from "modules/preview/schemas/colorSchemesSchemas";
const customizableColors: ColorSchemeColorKey[] = [
  // colorSchemeColorKeySchema.enum.color_0, this color is transparent and is not supported on every box
  colorSchemeColorKeySchema.enum.color_1,
  colorSchemeColorKeySchema.enum.color_2,
  colorSchemeColorKeySchema.enum.color_3,
  colorSchemeColorKeySchema.enum.color_4,
  colorSchemeColorKeySchema.enum.color_5,
  colorSchemeColorKeySchema.enum.color_12,
  colorSchemeColorKeySchema.enum.color_13,
  colorSchemeColorKeySchema.enum.color_14,
  colorSchemeColorKeySchema.enum.color_15,
];

export default customizableColors;
