import React from "react";
import { Button, MenuButton, MenuList, Menu, MenuItem } from "@chakra-ui/react";
import { NavLink, useRouteMatch } from "react-router-dom";
interface MenuLink {
  path: string;
  label: string;
  hasRight?: boolean;
}

interface DropdownProps {
  menuLinks: MenuLink[];
  title: string;
}

export const Dropdown: React.FC<DropdownProps> = ({ menuLinks, title }) => {
  const MENU_THEME = {
    DARK: "dark",
    WEWAK: "wewak",
    WHITE: "white",
  };

  const match = useRouteMatch();

  const isActive = menuLinks.some((link) => {
    return match.path === link.path;
  });

  return (
    <Menu>
      <MenuButton
        data-testid="dropdown-button"
        fontSize="xs"
        borderRadius="0"
        color={isActive ? "sunglo" : MENU_THEME.WHITE}
        borderBottom="2px"
        borderColor={isActive ? "initial" : "transparent"}
        height="100%"
        variant=""
        fontWeight="bold"
        textDecoration="none"
        py="25px"
        px="17px"
        _hover={{
          color: MENU_THEME.WEWAK,
          py: "1.6rem",
          borderBottom: "2px",
        }}
        _focus={{
          color: MENU_THEME.WEWAK,
          py: "1.6rem",
          borderBottom: "2px",
        }}
        as={Button}
      >
        {title}
      </MenuButton>
      <MenuList bg={MENU_THEME.DARK} borderColor="none">
        {menuLinks.map(
          (link) =>
            (link?.hasRight === undefined || link?.hasRight) && (
              <MenuItem
                key={link.path}
                color="white"
                bg={MENU_THEME.DARK}
                _focus={{
                  bg: MENU_THEME.DARK,
                }}
                _hover={{ bg: MENU_THEME.DARK, color: MENU_THEME.WEWAK }}
              >
                <NavLink to={link.path}>{link.label}</NavLink>
              </MenuItem>
            )
        )}
      </MenuList>
    </Menu>
  );
};
