const sizes = {
  lg: "1.125rem",
  xl: "1.375rem",
  "2xl": "1.75rem",
  md: "1rem",
  sm: "0.875rem",
  xs: "0.75rem",
  "2xs": "0.625rem",
};
export default sizes;
