import useTemplateStatus from "./useTemplateStatus";
import { useIsContributor } from "modules/roles/hooks/useIsContributor";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { useIsUserRoute } from "modules/user/informations/hooks/useIsUserRoute";
import { useHasRightForUser } from "modules/user/informations/hooks/useHasRightForUser";
import useIsEmptyLayout from "./useIsEmptyLayout";

export default function useDisableForm() {
  const status = useTemplateStatus();
  const isContributor = useIsContributor();
  const isAdministrator = useIsAdministrator();
  const isUserRoute = useIsUserRoute();
  const hasRightForUser = useHasRightForUser();
  const isEmptyLayout = useIsEmptyLayout();
  const disabledStatutes =
    status === "PENDING_LEGAL" ||
    status === "PENDING_COMMERCIAL" ||
    status === "VALIDATED" ||
    status === "PRODUCTION" ||
    status === "ARCHIVED" ||
    status === "REFUSED";
  const disabledRoles = !isAdministrator && !isContributor;
  const emptyLayoutEditRules = !isAdministrator && isEmptyLayout;
  return isUserRoute
    ? !hasRightForUser
    : disabledStatutes || disabledRoles || emptyLayoutEditRules;
}
