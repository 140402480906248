import { useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { feedbackSchema } from "core/components/ActionFeedback/schemas/actionSchemas";

export const stopTemplate = (id: number) => {
  return simpleRequest(
    generatePath(ENDPOINTS.STOP, { id }),
    emptySchema,
    methodSchema.enum.post
  );
};

export function useStopTemplate() {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(stopTemplate, {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries(["template", id]);
      queryClient.invalidateQueries(["templates"]);
      setFeedback(feedbackSchema.enum.info);
    },
  });
}
