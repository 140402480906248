import { create } from "zustand";

interface InitializedState {
  isInitialized: boolean;
  setInitialized: () => void;
}

const useInitializationStore = create<InitializedState>((set) => ({
  isInitialized: false,
  setInitialized: () => set({ isInitialized: true }),
}));

export default useInitializationStore;
