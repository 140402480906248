import React from "react";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { AuthentificationBox } from "modules/authentication/components/AuthentificationBox";
import { ResetCredentialsPasswordStepForm } from "modules/authentication/components/ResetCredentialsPasswordStepForm/ResetCredentialsPasswordStepForm";

export const ResetCredentialsPasswordStep = () => {
  const { t } = useTranslation();

  return (
    <AuthentificationBox>
      <Text mt={5} textStyle="subtitle">
        {t("login.reset.title")}
      </Text>
      <ResetCredentialsPasswordStepForm />
    </AuthentificationBox>
  );
};
