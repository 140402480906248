const parts = ["content"];

const baseStyle = () => ({
  content: {
    borderRadius: "sm",
    borderColor: "var(--popover-bg)",

    _after: {
      content: "''",
      boxShadow: " 0px 4px 8px 2px rgba(34, 38, 42, 0.25)",
      borderRadius: "inherit",
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: -2,
    },
  },
});

const Popover = { parts, baseStyle };

export default Popover;
