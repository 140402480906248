/**
 * check if a string is a MomaDate
 * @param input
 * @returns boolean
 * @signature
 *    isDateString(string)
 * @category string
 */
const momaDateRegex = /([0-9]{4})-(?:[0-9]{2})-([0-9]{2})/;
const isMomaDate = (input: string) => momaDateRegex.test(input);

export default isMomaDate;
