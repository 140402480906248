import { FormHourType } from "core/components/HourInput/HourInput";
import modulo from "core/helpers/utils/modulo";

const formatTimeInput = (value: string, type: FormHourType) =>
  type === "hours"
    ? formatStringToLimit(value, 24)
    : formatStringToLimit(value, 60);

const formatStringToLimit = (value: string, limit: number) =>
  modulo(Number(value), limit).toString().padStart(2, "0");

export default formatTimeInput;
