import React from "react";
import { Checkbox, CheckboxGroup, HStack } from "@chakra-ui/react";
import capitalizeFirstLetter from "core/helpers/utils/capitalizeFirstLetter";
import { FilterProps } from "modules/filters/components/SwitchFilter";

function MultiCheckboxFilter<T extends object>({
  filterValue,
  setFilter,
  preFilteredRows,
  name,
}: FilterProps<T, string[]>) {
  if (!setFilter) {
    return null;
  }
  const filter = filterValue ?? [];
  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter = filter.includes(e.target.value)
      ? filter.filter((area: string) => area !== e.target.value)
      : [...filter, e.target.value];

    setFilter(newFilter);
  };

  const values = new Set<string>();

  preFilteredRows?.forEach((row) => {
    row.values[name!].forEach((value: string) => {
      values.add(value);
    });
  });

  return (
    <CheckboxGroup defaultValue={[]} value={filter}>
      <HStack spacing={0} flexWrap="wrap" maxWidth={96}>
        {Array.from(values)
          .sort()
          .map((value) => (
            <Checkbox
              key={value}
              value={value}
              onChange={changeFilter}
              flex="50%"
            >
              {capitalizeFirstLetter(value)}
            </Checkbox>
          ))}
      </HStack>
    </CheckboxGroup>
  );
}

export default MultiCheckboxFilter;
