import * as z from "zod";

import isPasswordCompliant from "core/helpers/utils/isPasswordCompliant";

export const emailValidation = z.object({
  message: z.string(),
  validation_error: z.string().optional(),
});

export const credentialsEmailStepSchema = z.object({
  email: z.string(),
});

export const credentialsPasswordStepSchema = z
  .object({
    new_password: z.string().refine(isPasswordCompliant),
    confirm_password: z.string(),
  })
  .refine(
    (schema: { new_password: string; confirm_password: string }) =>
      schema.new_password === schema.confirm_password,
    {
      path: ["confirm_password"],
    }
  );

export type CredentialsEmailStep = z.infer<typeof credentialsEmailStepSchema>;
export type CredentialsPasswordStep = z.infer<
  typeof credentialsPasswordStepSchema
>;
