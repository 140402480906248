import { useQuery } from "react-query";

import { simpleRequest } from "core/services/api";
import { colorSchemesBodySchema } from "modules/preview/schemas/colorSchemesSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";

const getColorSchemes = () =>
  simpleRequest(ENDPOINTS.COLORSCHEMES, colorSchemesBodySchema);

export default function useColorSchemes() {
  return useQuery(["colorSchemes"], getColorSchemes, {
    staleTime: 300000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    suspense: true,
  });
}
