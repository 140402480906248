import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Text,
  Tab,
  TabList,
  Tabs,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import useComments from "modules/comments/hooks/useComments";
import CommentsForm from "modules/comments/components/CommentsForm";
import Comment from "modules/comments/components/Comment";
import useTemplateStatus from "modules/preview/hooks/useTemplateStatus";
import { useHasRightForComment } from "modules/comments/hooks/useHasRightForComment";
import StopTemplateAction from "modules/stopTemplate/StopTemplateAction";

const BottomActions = () => {
  const { t } = useTranslation();
  const { data } = useComments();

  const status = useTemplateStatus();
  const hasRightToPostComment = useHasRightForComment("POST");
  const hasRightToSeeComment = useHasRightForComment("GET");
  return (
    <Tabs position="relative">
      <TabList>
        <Tab isDisabled={!hasRightToSeeComment}>{t("comments.title")}</Tab>
        <Tab isDisabled={status !== "PRODUCTION"}>{t("actions.stop.tab")}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Box w="455px" mb="25px">
            {status !== "ARCHIVED" && hasRightToPostComment && <CommentsForm />}
            <Box mt="60px">
              {data?.length === 0 ? (
                <Text>{t("comments.empty")}</Text>
              ) : (
                data?.map((comment) => (
                  <Comment {...comment} key={comment.id} />
                ))
              )}
            </Box>
          </Box>
        </TabPanel>
        <TabPanel>
          <Box mb="25px">
            <StopTemplateAction />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default BottomActions;
