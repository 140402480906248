import { createStandaloneToast, UseToastOptions } from "@chakra-ui/react";
import Toast from "core/components/ToastMessage/Toast";
import theme from "theme";

const BASE_TOAST_DURATION = 5000;
const BASE_TOAST_POSITION = "bottom-right";

const toast = createStandaloneToast({ theme });

type ToastId =
  | "PARSE_WARNING"
  | "REDIRECT_INFO"
  | "LOGOUT_ERROR"
  | "LOGOUT_SUCCESS"
  | "RESET_PASSWORD_ERROR"
  | "RESET_PASSWORD_SUCCESS";

export const baseToast = (
  options: Partial<UseToastOptions> & { id: ToastId } & { title: string }
) => {
  const extendedOptions: UseToastOptions = {
    render: ({ onClose }) => <Toast {...options} onClose={onClose} />,
    duration: BASE_TOAST_DURATION,
    position: BASE_TOAST_POSITION,
    ...options,
  };

  if (!options.id || !toast.isActive(options.id)) {
    toast(extendedOptions);
  }
};
