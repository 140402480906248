import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import useTokenStore from "modules/authentication/hooks/useTokenStore";
import { SSOAuthenticationBox } from "modules/authentication/components/SSOAuthenticationBox";
import useLocationQueryParams from "core/hooks/useLocationQueryParams";

const LoginOidcCallback = () => {
  const query = useLocationQueryParams();
  const setToken = useTokenStore(({ setToken }) => setToken);
  const queryClient = useQueryClient();
  const token = query.get("token")!;

  const { t } = useTranslation();
  useEffect(() => {
    setToken(token);
    queryClient.invalidateQueries(["selfcare"]);
  }, []);
  return (
    <SSOAuthenticationBox>
      <p>{t("okta.oidc.callback")}</p>
    </SSOAuthenticationBox>
  );
};

export default LoginOidcCallback;
