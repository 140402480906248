import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { Title } from "modules/monitoring/shared/component/Title";

const ListStyled = styled.ul`
  list-style: none;
  li {
    padding: 1em;
  }
`;

const Category = styled.p`
  font-weight: bold;
`;

const Info = styled.p`
  margin-left: 0.5em;
`;

const ResultStyled = styled.ul`
  list-style: none;
  li {
    padding: 0.5em;
  }
`;

type Props = {
  description: string;
  error: string | null;
  last_run: string | null;
  status: string | null;
  name: string;
  title: string | null;
  value: any;
  isLeaf: boolean;
  id: number | null;
};

export const ProbeDetails = ({
  name,
  title,
  error,
  description,
  last_run,
  status,
  value,
  isLeaf,
  id,
}: Props) => {
  const { t } = useTranslation();

  const renderResults = useCallback((results) => {
    const entries = Object.entries(results);

    return (
      <ResultStyled>
        {entries.map(([key, value]) => (
          <li key={key}>
            {key}: {value}
          </li>
        ))}
      </ResultStyled>
    );
  }, []);

  const displayResults = value && !isLeaf;
  return (
    <div>
      <Title title={t("monitoring.title_details")} />
      <ListStyled>
        <li>
          <Category>{t("monitoring.probe_name")} : </Category>
          <Info>
            {name} ({id})
          </Info>
        </li>
        <li>
          <Category>{t("monitoring.probe_title")} : </Category>
          <Info> {title} </Info>
        </li>
        <li>
          <Category> {t("monitoring.probe_description")}:</Category>
          <Info>{description}</Info>
        </li>
        {displayResults && (
          <li>
            <Category>{t("monitoring.probe_value")} :</Category>
            <Info>{renderResults(value)}</Info>
          </li>
        )}
        <li>
          <Category>{t("monitoring.probe_last_run")} :</Category>
          <Info>{last_run}</Info>
        </li>
        <li>
          <Category>{t("monitoring.title_status")} :</Category>
          <Info>{status}</Info>
        </li>
        <li>
          <Category>{t("monitoring.probe_error")} :</Category>
          <Info>{error || "NC"}</Info>
        </li>
      </ListStyled>
    </div>
  );
};
