import React, { MouseEventHandler, useMemo, ReactNode } from "react";
import { Alert, CloseButton, Center } from "@chakra-ui/react";
import AlertIcon from "core/components/FeedbackIcons/AlertIcon";
import SuccessIcon from "core/components/FeedbackIcons/SuccessIcon";
import { FeedBackType, feedbackSchema } from "./schemas/actionSchemas";

interface Props {
  type: FeedBackType;
  onClick: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
}

const ActionFeedback = ({ type, onClick, children }: Props) => {
  const isWarning = useMemo(() => type === feedbackSchema.enum.warning, [type]);
  const isSuccess = useMemo(() => type === feedbackSchema.enum.success, [type]);
  const isInfo = useMemo(() => type === feedbackSchema.enum.info, [type]);
  return (
    <Center width="100%">
      <Alert
        status={type}
        m={10}
        w={isInfo || isWarning ? "100%" : "70%"}
        flexDirection="row"
        justifyContent="center"
        bgColor={
          isSuccess ? "snowyMint" : isWarning || isInfo ? "white" : "cosmos"
        }
        borderColor={
          isSuccess ? "validation" : isWarning || isInfo ? "white" : "error"
        }
        borderWidth="2px"
        borderRadius="md"
      >
        {isSuccess ? (
          <SuccessIcon w="20px" h="20px" ml={2} />
        ) : isWarning || isInfo ? null : (
          <AlertIcon w="20px" h="20px" ml={2} />
        )}
        {children}
        {!isInfo && (
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={onClick}
          />
        )}
      </Alert>
    </Center>
  );
};

export default ActionFeedback;
