import React from "react";
import {
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  HStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { GlobalMessageFormValues } from "modules/globalMessage/schemas/globalMessageFormSchema";
import useGlobalMessage from "../hooks/useGlobalMessage";

interface ControllerRenderProps {
  value?: boolean;
  onChange(e: boolean): void;
}

const ValidCardSwitch = () => {
  const { t } = useTranslation();

  const { data } = useGlobalMessage();

  const globalMessageStatus = data?.status;
  const isDisabled = !!globalMessageStatus && globalMessageStatus !== "DRAFT";

  const { control, getValues, setValue } =
    useFormContext<GlobalMessageFormValues>();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const defaultRadioValue =
    data?.filters?.card_valid !== undefined
      ? data?.filters?.card_valid === true
        ? "1"
        : "2"
      : undefined;

  const defaultValue = data?.filters?.card_valid ?? undefined;
  React.useEffect(() => {
    const cardValid = getValues("filters.card_valid");
    if (cardValid !== undefined) {
      setIsChecked(true);
    }
    if (!isChecked) {
      setValue("filters.card_valid", undefined);
    }
  }, [isChecked, setValue, getValues("filters.card_valid")]);

  return (
    <>
      <HStack>
        <Switch
          onChange={() => {
            setIsChecked(!isChecked);
          }}
          isChecked={isChecked}
          data-testid="filter-card"
          isDisabled={isDisabled}
        />
        <Text mb="0" ml={2} fontSize="sm" color="dark">
          {t("global_message.filter_card")}
        </Text>
      </HStack>
      <Controller
        name="filters.card_valid"
        defaultValue={defaultValue}
        disabled={!isChecked}
        control={control}
        render={({ value }: ControllerRenderProps) => {
          const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const selectedValue = e.target.value;
            const newValue = selectedValue === "1" ? true : false;
            setValue("filters.card_valid", newValue);
          };

          return (
            <>
              <FormControl
                isDisabled={!isChecked || isDisabled}
                display="flex"
                pt={4}
                pl={4}
                alignItems="center"
              >
                <RadioGroup defaultValue={`${defaultRadioValue}`}>
                  <Stack spacing={5} direction="row">
                    <Radio
                      colorScheme="red"
                      value="1"
                      onChange={handleChange}
                      isChecked={value === true}
                    >
                      {t("global_message.filters.valid_card")}
                    </Radio>
                    <Radio
                      colorScheme="green"
                      value="2"
                      onChange={handleChange}
                      isChecked={value === false}
                    >
                      {t("global_message.filters.invalid_card")}
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </>
          );
        }}
      />
    </>
  );
};

export default ValidCardSwitch;
