import React from "react";
import { Popover, PopoverTrigger, VStack, Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import useTemplateColors from "modules/preview/hooks/useTemplateColors";
import ColorButton from "modules/preview/components/ui/ColorButton";
import ColorForm from "modules/preview/components/forms/ColorForm";

import { useFormContext } from "react-hook-form";
import { defaultColorsValue } from "modules/create/constants/defaultValues";
import { ColorParam } from "modules/preview/schemas/templateSchemas";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import useDisableForm from "modules/preview/hooks/useDisableForm";

const Container = styled(VStack)`
  display: inline-block;
`;

const Colors = () => {
  const { t } = useTranslation();
  const colors = useTemplateColors();
  const context = useFormContext<PreviewFormType>();
  const disabled = useDisableForm();

  const resetForm = () => {
    const colors = context.getValues("colors");
    colors &&
      Object.keys(colors).forEach((key) =>
        context.setValue(`colors.${key}`, defaultColorsValue[key as ColorParam])
      );
  };

  return (
    <Container align="left" mt={5}>
      <Box mb="10px">
        {colors?.map(([colorParam, hexColor]) => (
          <Popover
            key={`${colorParam}-${hexColor}`}
            placement="left"
            closeOnBlur={false}
            isLazy
            preventOverflow={false}
          >
            {disabled ? (
              <Box>
                <ColorButton
                  text={t(`preview.colorScheme.${colorParam}` as const)}
                  color={hexColor}
                  disabled={disabled}
                />
              </Box>
            ) : (
              <PopoverTrigger>
                <Box>
                  <ColorButton
                    text={t(`preview.colorScheme.${colorParam}` as const)}
                    color={hexColor}
                    disabled={disabled}
                  />
                </Box>
              </PopoverTrigger>
            )}
            <ColorForm param={colorParam} />
          </Popover>
        ))}
      </Box>
      <Button
        as="div"
        //prevent reload
        onClick={resetForm}
        isDisabled={!context.formState.dirtyFields.colors || disabled}
        variant="tertiary-ghost"
      >
        {" "}
        {t("preview.colorScheme.reset")}
      </Button>
    </Container>
  );
};

export default Colors;
