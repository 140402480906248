import { useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { ValidateTemplate } from "modules/validate/schemas/validateSchemas";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

export const validateTemplate = ({
  id,
  endpointToUse,
  comment,
}: ValidateTemplate) => {
  const endpoint =
    endpointToUse === "legal"
      ? ENDPOINTS.LEGAL_VALIDATE
      : ENDPOINTS.COMMERCIAL_VALIDATE;
  return comment
    ? simpleRequest(
        generatePath(endpoint, { id }),
        emptySchema,
        methodSchema.enum.post,
        {
          comment,
        }
      )
    : simpleRequest(
        generatePath(endpoint, { id }),
        emptySchema,
        methodSchema.enum.post
      );
};

export function useValidateTemplate() {
  const queryClient = useQueryClient();
  const { reset } = useFeedbackStore();
  return useMutationWithErrorHandling(validateTemplate, {
    onSuccess: (_, { id, comment }) => {
      queryClient.invalidateQueries(["template", id]);
      queryClient.invalidateQueries(["templates"]);
      if (comment) {
        queryClient.invalidateQueries(["comments", id]);
      }
      reset();
    },
  });
}
