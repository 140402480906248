import React, { ChangeEventHandler } from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";

import { PreviewFormType } from "modules/create/schemas/formSchema";
import DISABLED_OPACITY from "modules/preview/constants/disabledOpacity";

interface ControllerRenderProps {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

interface Props {
  index: number;
  disabled: boolean;
  type: string;
}

const VariableStorableInput = ({ index, disabled, type }: Props) => {
  const context = useFormContext<PreviewFormType>();
  const { t } = useTranslation();
  const name = `${type}[${index}].value`;

  const storableLabel = `preview.storables.label`;
  const variableLabel = `preview.variables.label`;

  const storableHint = `preview.storables.hint`;
  const variableHint = `preview.variables.hint`;

  return (
    <Controller
      name={name}
      defaultValue={context?.getValues(name) || ""}
      control={context?.control}
      render={(renderProps: ControllerRenderProps) => {
        return (
          <FormControl id={name} isDisabled={disabled}>
            <FormLabel fontSize="sm">
              {t(type === "storables" ? storableLabel : variableLabel, {
                index,
              })}
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                children="#"
                color="dark"
                opacity={disabled ? DISABLED_OPACITY : 1}
              />
              <Input
                variant="outline"
                name={name}
                ref={context?.register}
                w="100%"
                {...renderProps}
              />
            </InputGroup>
            <FormHelperText>
              {t(type === "storables" ? storableHint : variableHint, { index })}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default VariableStorableInput;
