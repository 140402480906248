import React, { useRef } from "react";
import { Input } from "@chakra-ui/react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CalendarInput = ({
  selected,
  onChange,
  locale,
  ...rest
}: ReactDatePickerProps) => {
  const minDate = useRef(new Date());
  return (
    <DatePicker
      {...rest}
      locale={locale}
      onChange={onChange}
      selected={selected}
      dateFormat="dd/MM/yyyy"
      customInput={<Input borderRadius="4px" borderColor="abbey" />}
      minDate={minDate.current}
    />
  );
};

export default CalendarInput;
