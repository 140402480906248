import React from "react";
import { Image } from "@chakra-ui/react";
import { ImageFrame } from "modules/preview/schemas/specSchemas";
import FixedBox from "core/components/FixedBox/FixedBox";
import { FrameProps } from "modules/preview/components/Frames";
import { getBoxProps } from "../../helpers/getBoxProps";
import { useFormContext } from "react-hook-form";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import getBuiltInLogosFromWindowLogo from "modules/preview/helpers/getBuiltInLogosFromWindowLogo";
import useTemplate from "modules/preview/hooks/useTemplate";
import { useImageUrl } from "modules/images/hooks/useImageUrl";
import { useImage } from "modules/images/hooks/useImage";

type ObjectFitValue = "fill" | "cover" | "contain";

const LogoBlock = (props: FrameProps<ImageFrame>) => {
  const context = useFormContext<PreviewFormType>();
  const { data } = useTemplate(false);

  const {
    frame: { name, w, h },
  } = props;
  const logo = context
    ? context.watch("builtInLogos")
    : getBuiltInLogosFromWindowLogo(
        data?.technical_details?.Window_Logo || "none"
      );

  const customLogoId = context
    ? context.watch("customLogo")
    : data?.technical_details?.Frame0_Logo;
  const { data: image } = useImage(customLogoId ?? undefined);

  const { data: url } = useImageUrl(image);

  const urlByElement = {
    OIMA1: url,
    OIMA2: require("assets/canal.png").default,
    OIMA3: require("assets/canalsat.png").default,
  };

  const isElementVisible =
    (name === "OIMA1" && url) ||
    (name === "OIMA2" && logo?.canalplus) ||
    (name === "OIMA3" && logo?.canalsat);

  const logoUrl = urlByElement[name];
  const isCustomLogo = logoUrl === url && image?.type === "logo";

  const isImageByQrcode = image?.type === "qrcode";

  const imageStyle = {
    cover: isCustomLogo ? (isImageByQrcode ? "fill" : "cover") : "contain",
    w: isCustomLogo ? "150px" : isImageByQrcode ? "initial" : `${w}px`,
    h: isCustomLogo || isImageByQrcode ? "60px" : `${h}px`,
  };

  return (
    <FixedBox {...getBoxProps(props)}>
      <Image
        src={logoUrl}
        opacity={isElementVisible ? "1" : "0"}
        transition={logoUrl && "opacity 0.2s"}
        objectFit={imageStyle.cover as ObjectFitValue}
        w={imageStyle.w}
        h={imageStyle.h}
      />
    </FixedBox>
  );
};

export default LogoBlock;
