import {
  TemplateLayoutType,
  layoutFormTypeSchema,
  layoutBannerTypeSchema,
  layoutHybridTypeSchema,
  HybridType,
  BannerType,
  layoutEmptySchema,
} from "modules/preview/schemas/templateSchemas";
import {
  BannerPosition,
  bannerPositionSchema,
} from "modules/preview/schemas/specSchemas";

const bannerPositionByLayout: Record<BannerType | HybridType, BannerPosition> =
  {
    [layoutBannerTypeSchema.enum.bannermid]: bannerPositionSchema.enum.center,
    [layoutBannerTypeSchema.enum.bannertop]: bannerPositionSchema.enum.top,
    [layoutHybridTypeSchema.enum.hybridmid]: bannerPositionSchema.enum.center,
    [layoutHybridTypeSchema.enum.hybridtop]: bannerPositionSchema.enum.top,
    [layoutHybridTypeSchema.enum.hybrid]: bannerPositionSchema.enum.bottom,
  };

export const getFormValuesFromTemplateName = (layout: TemplateLayoutType) => {
  let layoutToUse: TemplateLayoutType = layoutFormTypeSchema.enum.full1;
  let position: BannerPosition = bannerPositionSchema.enum.bottom;
  let extraBanner = false;
  if (layoutFormTypeSchema.check(layout)) {
    layoutToUse = layout;
  } else if (layoutBannerTypeSchema.check(layout)) {
    layoutToUse = layoutFormTypeSchema.enum.banner;
    position = bannerPositionByLayout[layout];
  } else if (layoutHybridTypeSchema.check(layout)) {
    layoutToUse = layoutFormTypeSchema.enum.full4;
    extraBanner = true;
    position = bannerPositionByLayout[layout];
  } else {
    layoutToUse = layoutEmptySchema.enum.empty;
  }
  return { layout: layoutToUse, position, extraBanner };
};

export default getFormValuesFromTemplateName;
