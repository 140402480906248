import React, { useState } from "react"; // Import React
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  VStack,
} from "@chakra-ui/react";
import MetricModalDetails from "./MetricModalDetails";
import theme from "theme";
import { ProbeType } from "modules/monitoring/ProbeList/schemas/formSchemas";
import { getDisplayValue } from "../helpers/getDisplayValue";

const COLORS = {
  PASSED: theme.colors.validation,
  WARNING: theme.colors.warning,
  FAILED: theme.colors.amaranth,
  ERROR: theme.colors.dark,
  DONE: theme.colors.malibu,
  DEACTIVATED: theme.colors.silver,
};

const MetricsCard = ({ metric }: { metric: ProbeType }) => {
  const [showPreview, setShowPreview] = useState(false);

  const displayValue = getDisplayValue(metric);

  const color = metric.status
    ? COLORS[metric.status as keyof typeof COLORS] || COLORS["DEACTIVATED"]
    : null;

  return (
    <VStack
      w={56}
      p={2}
      h={36}
      mt={6}
      ml={6}
      borderWidth="1px"
      borderRadius="md"
      boxShadow="base"
      overflow="hidden"
      borderBottom={"2px"}
      alignItems="left"
      borderBottomColor={color ?? "transparent"}
      onMouseEnter={() => setShowPreview(true)}
      onMouseLeave={() => setShowPreview(false)}
      data-testid="metrics-card"
    >
      <Stat>
        <StatLabel noOfLines={2} color="dark">
          {metric.title?.toUpperCase()}
        </StatLabel>
        <StatNumber color="dark">{displayValue}</StatNumber>
        {showPreview ? null : (
          <StatHelpText noOfLines={2} as="i">
            {metric.description}
          </StatHelpText>
        )}
      </Stat>
      {showPreview ? (
        <MetricModalDetails
          metric={metric}
          onCloseModal={() => setShowPreview(false)}
        />
      ) : null}
    </VStack>
  );
};

export default MetricsCard;
