import { omit } from "remeda";
import { UserFromList } from "modules/users/schemas/usersModelSchemas";

export const getFormValuesFromUser = (user?: UserFromList) =>
  user ? omit(user, ["id", "last_login", "failed_auth_counter"]) : {};

export const getFormValuesForDuplicateUser = (user?: UserFromList) =>
  user
    ? omit(user, ["id", "last_login", "failed_auth_counter", "email", "name"])
    : {};
