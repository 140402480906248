import React from "react";
import { chakra } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  momaDateSchema,
  themeSchema,
} from "modules/preview/schemas/templateSchemas";
import { regionsListBodySchema } from "modules/regions/schemas/regionsSchemas";
import { statusSchema } from "modules/preview/schemas/templateSchemas";
import { rolesListSchema } from "modules/roles/schemas/rolesSchemas";
import getRolesDisplayNames from "modules/users/helpers/getRolesDisplayNames";
import { statusSentinelTemplate } from "modules/monitoring/Inspect/Schemas/InspectionTemplatesSchemas";

interface Props {
  value: string | string[];
  transformDatetime?: boolean;
}

const TableCell = ({ value, transformDatetime }: Props) => {
  const { t, i18n } = useTranslation();
  if (rolesListSchema.check(value)) {
    return <chakra.span>{getRolesDisplayNames(value)}</chakra.span>;
  }
  if (statusSchema.check(value)) {
    return (
      <chakra.span>
        {t(`templates.status_values.${value}` as const)}
      </chakra.span>
    );
  }
  if (regionsListBodySchema.check(value)) {
    return <chakra.span>{value.join(", ")}</chakra.span>;
  }
  if (momaDateSchema.check(value)) {
    if (transformDatetime) {
      const formatedDatetimeInParis = `${new Date(value).toLocaleDateString(
        "fr"
      )} ${new Date(value).toLocaleTimeString("fr")}`;
      return <chakra.span>{formatedDatetimeInParis}</chakra.span>;
    } else {
      return (
        <chakra.span>
          {new Date(value).toLocaleDateString(i18n.language)}
        </chakra.span>
      );
    }
  }
  if (themeSchema.check(value)) {
    return (
      <chakra.span>
        {t(`preview.informations.theme.${value}` as const)}
      </chakra.span>
    );
  }
  if (statusSentinelTemplate.check(value)) {
    return (
      <chakra.span>
        {t(`supervision.template.sentinel_status.${value}` as const)}
      </chakra.span>
    );
  }
  if (typeof value == "boolean") {
    return (
      <chakra.span>
        {value ? t("emergency.active") : t("emergency.inactive")}
      </chakra.span>
    );
  } else return <chakra.span>{value}</chakra.span>;
};

export default TableCell;
