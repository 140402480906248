import * as z from "zod";

export const bulkMessageSchema = z.object({
  emails_sent: z.number(),
  invalid_users: z.number().array().optional(),
});

export const bulkMessageFormSchema = z.object({
  subject: z.string().min(1),
  body: z.string().min(1),
});

export type SendBulkMessageFormValues = z.infer<typeof bulkMessageFormSchema>;

export const bulkMessagePayloadSchema = z.object({
  subject: z.string().min(1),
  body: z.string().min(1),
  users: z.number().array(),
});

export type SendBulkMessagePayload = z.infer<typeof bulkMessagePayloadSchema>;
