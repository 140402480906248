import React from "react";
import { createIcon } from "@chakra-ui/react";

const AlertIcon = createIcon({
  displayName: "AlertIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <circle cx="12" cy="12" r="12" fill="#BB0002" />
      <path
        d="M4 17H20L12 4L4 17ZM12.7273 14.9474H11.2727V13.5789H12.7273V14.9474ZM12.7273 12.2105H11.2727V9.47368H12.7273V12.2105Z"
        fill="white"
      />
      ,
    </>
  ),
});

export default AlertIcon;
