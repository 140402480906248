import * as z from "zod";

export const actionsSchema = z.enum([
  "save",
  "test",
  "validate",
  "target",
  "refuse",
  "cancel",
  "legal",
  "commercial",
  "finalize",
  "backToDraft",
  "archive",
  "duplicate",
  "createUser",
  "saveUser",
  "deleteUser",
  "createCard",
  "deleteCard",
  "editCard",
  "saveColorScheme",
  "deleteColorScheme",
  "resetPassword",
  "saveProfile",
  "saveImage",
  "deleteImage",
  "changeImage",
  "saveQrcode",
  "deleteQrcode",
  "changeQrcode",
  "changeLayout",
  "redirect",
  "editEmptyLayout",
  "contactMessage",
  "customError",
  "sendBulkMessage",
  "deleteGlobal",
]);
export type ActionType = z.infer<typeof actionsSchema>;

export const feedbackSchema = z.enum(["success", "error", "info", "warning"]);
export type FeedBackType = z.infer<typeof feedbackSchema>;
