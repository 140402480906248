import React, { useContext, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
} from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { useUserRegions } from "modules/regions/hooks/useUserRegions";
import { getImageFromFormValues } from "../helpers/getImageFromFormValues";
import { useCreateImage } from "../hooks/logo/useCreateImage";
import { useDeleteImage } from "../hooks/logo/useDeleteImage";
import { useChangeImage } from "../hooks/logo/useChangeImage";
import { useChangeImageUrl } from "../hooks/logo/useChangeImageUrl";
import ManageImageForm from "./ManageImageForm";
import { defaultFormValues } from "../constants/defaultFormValues";
import { ImageIdContext } from "modules/shared/context/ImageIdContext";
import WarningAlert from "core/components/ActionFeedback/WarningAlert";
import {
  imageFormSchema,
  ManageImageFormValues,
} from "../schemas/customImageSchema";
import { useImage } from "../hooks/useImage";
import { getUpdatedImageFromFormValues } from "../helpers/getUpdatedImageFromFormValues";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ImageModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const id = useContext(ImageIdContext);

  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const { data: image } = useImage(id);

  const methods = useForm<ManageImageFormValues>({
    mode: "onChange",
    resolver: zodResolver(imageFormSchema),
    shouldFocusError: true,
    defaultValues: defaultFormValues,
  });
  const {
    formState: { isDirty, dirtyFields },
    clearErrors,
    reset,
    watch,
  } = methods;
  const regions = useUserRegions();
  const createImage = useCreateImage();
  const deleteImage = useDeleteImage();
  const changeImage = useChangeImage();
  const changeImageUrl = useChangeImageUrl();
  const disableSaveButton = id && (!isDirty || watch("original")?.length < 1);
  const disableDeleteButton = React.useMemo(
    () => !image?.deletable,
    [id, image]
  );

  const handleSave = useCallback(
    (dataImage: ManageImageFormValues) => {
      if (id) {
        handleChangeImage(dataImage);
      } else {
        const imageFormData = getImageFromFormValues(dataImage, regions ?? []);
        createImage.mutate(imageFormData, {
          onSettled: handleCloseModal,
        });
      }
    },
    [
      getImageFromFormValues,
      createImage,
      changeImage,
      id,
      image,
      dirtyFields,
      changeImageUrl,
    ]
  );
  const handleChangeImage = useCallback(
    async (dataImage: ManageImageFormValues) => {
      const hasUrlChanged = dirtyFields.original;
      const hasInfosChanged =
        dirtyFields.description ||
        dirtyFields.title ||
        dirtyFields.regions ||
        dataImage.regions !== image?.regions;
      try {
        if (hasInfosChanged) {
          const image = getUpdatedImageFromFormValues(dataImage, regions);
          await changeImage.mutateAsync({ image, imageId: id!.toString() });
        }
        if (hasUrlChanged) {
          await changeImageUrl.mutateAsync({
            image: dataImage.original,
            imageId: id!.toString(),
          });
        }
      } finally {
        handleCloseModal();
      }
    },
    [id, changeImage, changeImageUrl, dirtyFields]
  );

  const handleDelete = useCallback(() => {
    deleteImage.mutate(id!, {
      onSettled: handleCloseModal,
    });
  }, [id, deleteImage]);

  const handleCloseModal = useCallback(() => {
    clearErrors();
    reset(defaultFormValues);
    if (showWarningAlert) {
      setShowWarningAlert(false);
    }
    onClose();
  }, [clearErrors, reset, onClose, showWarningAlert]);

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered={true}>
      <ModalOverlay />
      <ModalContent minWidth="700px" borderRadius="sm">
        <ModalHeader>
          <Box textStyle="h2">
            {t(`assets.image.modal_title_${id ? "change" : "create"}` as const)}
          </Box>
          <Box textStyle="text">{t("assets.image.hint")}</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <ManageImageForm />
          </FormProvider>
        </ModalBody>
        <ModalFooter display="flex" h="160px">
          {showWarningAlert ? (
            <WarningAlert
              title={t("assets.image.warning.title")}
              message={t("assets.image.warning.message")}
              confirm={t("assets.image.warning.confirm")}
              cancel={t("assets.image.warning.cancel")}
              handleAction={handleDelete}
              reset={handleCloseModal}
            />
          ) : (
            <>
              <Button
                variant="tertiary-ghost"
                mr={3}
                onClick={handleCloseModal}
              >
                {t("assets.image.cancel")}
              </Button>
              {id && (
                <Button
                  mr={3}
                  variant="secondary-solid"
                  onClick={methods.handleSubmit(handleSave)}
                  disabled={!image?.mutable}
                  isLoading={changeImage.isLoading}
                >
                  {t("assets.image.change")}
                </Button>
              )}
              <Button
                variant="secondary-solid"
                isLoading={createImage.isLoading}
                onClick={
                  id
                    ? () => setShowWarningAlert(true)
                    : methods.handleSubmit(handleSave)
                }
                disabled={!!(id ? disableDeleteButton : disableSaveButton)}
              >
                {t(`assets.image.${id ? "delete" : "save"}` as const)}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
