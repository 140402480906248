import { useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SSOAuthenticationBox } from "modules/authentication/components/SSOAuthenticationBox";
import {
  forgeOKTAUrl,
  generateStateAndNonce,
  generatePKCEPair,
} from "modules/authentication/helpers/oktaHelpers";

interface OktaDataSchema {
  challengeCode: string;
  state: string;
  nonce: string;
  codeVerifier: string;
}
function createOktaData() {
  const oktaData = {
    ...generateStateAndNonce(),
    ...generatePKCEPair(),
  };
  return oktaData;
}

const RedirectOkta = () => {
  const { t } = useTranslation();
  const oktaData: OktaDataSchema = createOktaData();
  const oktaUrl = forgeOKTAUrl(oktaData);
  useEffect(() => {
    const prevOktaData = window.localStorage.getItem("oktaData");
    if (prevOktaData !== null) {
      localStorage.removeItem("oktaData");
    }
    localStorage.setItem("oktaData", JSON.stringify(oktaData));
  });

  window.location.href = oktaUrl;
  return (
    <SSOAuthenticationBox>
      <p>{t("okta.redirect")}</p>
      <Spinner size="sm" mt={3} />
    </SSOAuthenticationBox>
  );
};

export default RedirectOkta;
