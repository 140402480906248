import React from "react";
import { components, OptionProps } from "react-select";
import { Box } from "@chakra-ui/react";

const Option = (props: OptionProps<any, false>) => {
  return (
    <components.Option {...props}>
      <Box>{props.data.label}</Box>
      {props.data.subtitle && (
        <Box fontSize="xs" lineHeight="14px">
          {props.data.subtitle}
        </Box>
      )}
    </components.Option>
  );
};

export default Option;
