import format from "date-fns/format";
import { DraftGlobalMessage } from "../schemas/globalMessageFormSchema";

const getGlobalMessageFromFormValues = ({
  title,
  template_id,
  regions,
  start_date,
  end_date,
  filters,
}: DraftGlobalMessage) => {
  if (typeof start_date === "string" || typeof end_date === "string") {
    start_date = new Date(start_date);
    end_date = new Date(end_date);
  }

  return {
    title,
    template_id,
    regions,
    start_date: format(start_date, "yyyy-MM-dd"),
    end_date: format(end_date, "yyyy-MM-dd"),
    filters,
  };
};

export default getGlobalMessageFromFormValues;
