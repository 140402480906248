import * as z from "zod";

export const loginBodySchema = z
  .object({
    token: z.string(),
  })
  .nonstrict();

export type LoginBody = z.infer<typeof loginBodySchema>;

export const logoutBodySchema = z.string();

export type LogoutBody = z.infer<typeof logoutBodySchema>;
