import React from "react";
import {
  ModalBody,
  Modal,
  ModalCloseButton,
  ModalContent,
  Image,
  ModalOverlay,
  ModalHeader,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FullLoader from "core/components/Fullloader/FullLoader";

export const ImageContent = ({
  url,
  isOpen,
  handleClose,
}: {
  handleClose: () => void;
  isOpen: boolean;
  url?: string;
}) => {
  const { t } = useTranslation();

  return url ? (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        minWidth="34em"
        position="fixed"
        top="30%"
        h="40%"
        borderRadius="sm"
      >
        <ModalHeader>
          <Box textStyle="h3">{t("preview.logos.qrcodes.test")}</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          alignContent="center"
          justifyContent="center"
          overflow="auto"
        >
          <Image objectFit="contain" w="60" src={url} />
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    <FullLoader />
  );
};
