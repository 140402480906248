/**
 * returns the number value of string or null if string is null or undefined
 * @param string
 * @returns string or null
 * @signature
 *    sanitizeStringValue(string)
 * @category string
 */

const sanitizeStringValue = (value?: string | null) =>
  typeof value === "string" && value !== "" ? value : null;

export default sanitizeStringValue;
