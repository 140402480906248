import React, { useEffect, useLayoutEffect, useState } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import PrivateRoute from "modules/authentication/components/PrivateRoute/PrivateRoute";
import NavBar from "modules/shared/components/NavBar/NavBar";
import { Flex, Box } from "@chakra-ui/react";

import { PATHS } from "modules/shared/constants/paths";
import { ResetCredentialsEmailStep } from "./ResetCredentialsEmailStep/ResetCredentialsEmailStep";
import { ResetCredentialsPasswordStep } from "./ResetCredentialsPasswordStep/ResetCredentialsPasswordStep";
import { ResetCredentialsSucceed } from "./ResetCredentialsSucceed/ResetCredentialsSucceed";
import ErrorHandler from "modules/shared/components/ErrorHandler/ErrorHandler";
import Footer from "modules/shared/components/Footer/Footer";
import FullLoader from "core/components/Fullloader/FullLoader";
import Login from "./Login/Login";
import NoRight from "./NoRight/NoRight";
import privateRoutes from "./privateRoutes";
import RedirectToHome from "./Home/RedirectToHome";
import RedirectOkta from "./LoginOkta/RedirectOkta";
import LoginOktaCallback from "./LoginOkta/LoginOktaCallback";
import LoginOidc from "./LoginOktaOidc/LoginOidc";
import LoginOidcCallback from "./LoginOktaOidc/LoginOidcCallback";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import useInitializationStore from "modules/shared/hooks/useInitializationStore";
import useTokenStore from "modules/authentication/hooks/useTokenStore";

const Screens = () => {
  const isInitialized = useInitializationStore(
    ({ isInitialized }) => isInitialized
  );
  const isAuthenticated = useTokenStore(({ token }) => Boolean(token));
  const { reset: resetFeedback } = useFeedbackStore();
  const location = useLocation();
  const [templateRoute, setTemplateRoute] = useState("/");

  // rely on useLayoutEffect to reset the current feedback before any child component set a new feedback
  // prevent for resetFeedback after redirection on the homepage and the creation page
  useLayoutEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/create") {
      resetFeedback();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuthenticated) {
      if (location.pathname.includes("template")) {
        setTemplateRoute(location.pathname);
      }
    }
  }, [location.pathname]);

  return isInitialized ? (
    <Flex direction="column" minHeight="100vh">
      <Box>
        <NavBar isAuthenticated={isAuthenticated} />
        <ErrorHandler key={location.pathname} path={location.pathname}>
          <Switch>
            <Route exact path={PATHS.LOGIN}>
              {isAuthenticated ? (
                templateRoute.includes("template") ? (
                  <Redirect to={templateRoute} />
                ) : (
                  <RedirectToHome />
                )
              ) : (
                <Login />
              )}
            </Route>
            <Route exact path={PATHS.OKTA_OIDC}>
              {isAuthenticated ? <RedirectToHome /> : <LoginOidc />}
            </Route>
            <Route exact path={PATHS.OKTA_OIDC_CALLBACK}>
              {isAuthenticated ? <RedirectToHome /> : <LoginOidcCallback />}
            </Route>
            <Route exact path={PATHS.OKTA_REDIRECT}>
              {isAuthenticated ? <RedirectToHome /> : <RedirectOkta />}
            </Route>
            <Route exact path={PATHS.OKTA_CB}>
              {isAuthenticated ? <RedirectToHome /> : <LoginOktaCallback />}
            </Route>
            <Route exact path={PATHS.RESET_CREDENTIAL_EMAIL_STEP}>
              <ResetCredentialsEmailStep />
            </Route>
            <Route exact path={PATHS.RESET_CREDENTIAL_SUCCEED}>
              <ResetCredentialsSucceed />
            </Route>
            <Route exact path={PATHS.RESET_CREDENTIAL_PASSWORD_STEP}>
              <ResetCredentialsPasswordStep />
            </Route>
            <Route exact path={PATHS.NO_RIGHT}>
              {isAuthenticated ? <NoRight /> : <RedirectToHome />}
            </Route>
            {privateRoutes.map(({ exact, path, Component }) => (
              <PrivateRoute
                exact={exact}
                path={path}
                key={path}
                component={Component}
              />
            ))}
            <Route path="*">
              <RedirectToHome />
            </Route>
          </Switch>
        </ErrorHandler>
      </Box>
      <Footer />
    </Flex>
  ) : (
    <FullLoader />
  );
};

export default Screens;
