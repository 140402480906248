import { useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SSOAuthenticationBox } from "modules/authentication/components/SSOAuthenticationBox";

const LoginOidc = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL;
    window.location.href = `${url}/login_oidc`;
  }, []);

  return (
    <SSOAuthenticationBox>
      <p>{t("okta.oidc.login")}</p>
      <Spinner size="sm" mt={3} />
    </SSOAuthenticationBox>
  );
};

export default LoginOidc;
