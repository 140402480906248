import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { EmailIcon } from "@chakra-ui/icons";
import { Filters, Row } from "react-table";

import { defaultFormValues } from "../constants/defaultFormValues";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { useIsConfigurator } from "modules/roles/hooks/useIsConfigurator";

import {
  bulkMessageFormSchema,
  SendBulkMessageFormValues,
} from "../schemas/bulkMessageSchema";
import SendBulkMessageForm from "./SendBulkMessageForm";

interface Props<T extends object> {
  users: Row<T>[];

  activeFilters: Filters<T>;
}

const SendBulkMessageButton = <T extends object>({
  users,
  activeFilters,
}: Props<T>) => {
  const { t } = useTranslation();
  const isAdministrator = useIsAdministrator();
  const isConfigurator = useIsConfigurator();

  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const disabled = !(isAdministrator || isConfigurator);

  const methods = useForm<SendBulkMessageFormValues>({
    mode: "onChange",
    resolver: zodResolver(bulkMessageFormSchema),
    shouldFocusError: true,
    defaultValues: defaultFormValues,
  });

  return (
    <>
      <Button
        variant="primary-solid"
        leftIcon={<EmailIcon />}
        data-testid="send-message-button"
        onClick={() => setIsModalOpened(true)}
        mr="20px"
        isDisabled={disabled}
      >
        {t("send_message.button")}
      </Button>
      <Modal
        isOpen={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent
          minWidth="700px"
          borderRadius="sm"
          data-testid="send-message-modal"
        >
          <ModalHeader>
            <Box textStyle="h2">{t("send_message.title")}</Box>
            <Box textStyle="text">{t("send_message.hint")}</Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="20px">
            <FormProvider {...methods}>
              <SendBulkMessageForm
                closeModal={() => setIsModalOpened(false)}
                users={users}
                activeFilters={activeFilters}
              />
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SendBulkMessageButton;
