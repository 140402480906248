import React, { MutableRefObject } from "react";
import Select, { OptionTypeBase, StylesConfig } from "react-select";

import { useTheme } from "@chakra-ui/react";
import { COLORS } from "theme";
import Option from "./Option";
import NoOptionsMessage from "./NoOptions";

export interface SelectProps {
  colors: COLORS;
  isDisabled: boolean;
  noOptions?: string;
  size: "small" | "large";
  isError: boolean;
}

interface Props {
  [prop: string]: any;
  options?: Array<OptionTypeBase>;
  name: string;
  inputRef?: MutableRefObject<HTMLSelectElement>;
  value?: OptionTypeBase;
  onChange?(e: OptionTypeBase): void;
  disabled?: boolean;
  searchable?: boolean;
  placeholder?: string;
  noOptions?: string;
  size?: "small" | "large";
  error?: boolean;
}

const customStyles: StylesConfig<SelectProps, false> = {
  option: (provided, { selectProps: { colors } }) => ({
    ...provided,
    backgroundColor: colors.white,
    color: colors.abbey,
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    cursor: "pointer",
    padding: "0px 12px",
    marginTop: "10px",
    ":hover": { backgroundColor: colors.zircon },
  }),
  menu: (provided, { selectProps: { colors } }) => ({
    ...provided,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderColor: colors.silver,
    borderRadius: "2px",
    borderWidth: "1px",
    top: 0,
    paddingBottom: "10px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  container: (
    _,
    { isFocused, selectProps: { colors, isDisabled, size, isError } }: any
  ) => ({
    // types definitions are incomplete
    ":hover:enabled": {
      borderColor: colors.silver,
      backgroundColor: colors.zircon,
    },
    borderRadius: "4px",
    cursor: isDisabled ? "not-allowed" : "pointer",
    minHeight: "40px",
    width: size === "small" ? "270px" : "400px",
    borderColor: isFocused
      ? colors.malibu
      : isError
      ? colors.error
      : colors.silver,
    borderWidth: isFocused ? "2px" : "1px",
    ":focus": {
      border: "solid 3px",
      borderColor: colors.malibu,
    },
  }),
  control: (_, { selectProps: { colors } }) => ({
    display: "flex",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const Dropdown = (props: Props) => {
  const theme = useTheme();
  return (
    <Select
      {...props}
      colors={theme.colors}
      styles={customStyles}
      // hack for issue https://github.com/JedWatson/react-select/issues/4088
      menuPortalTarget={document.body}
      isDisabled={props.disabled ?? false}
      isSearchable={props.searchable}
      isError={props.error}
      backspaceRemovesValue={props.searchable}
      components={{ Option, NoOptionsMessage }}
      placeholder={props.placeholder ?? null}
      size={props.size ?? "small"}
      noOptions={props.noOptions}
    />
  );
};

export default Dropdown;
