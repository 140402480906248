import React, { ReactNode, useMemo } from "react";
import { Box, UseRadioProps, VStack, useRadio } from "@chakra-ui/react";

import { RadioCardSize } from "../../schemas/formSchema";
import getBackgroundProps from "modules/create/helpers/getBackgroundProps";
import {
  layoutFormTypeSchema,
  layoutEmptySchema,
} from "modules/preview/schemas/templateSchemas";
import { bannerPositionSchema } from "modules/preview/schemas/specSchemas";
import radiocardSizes from "modules/create/constants/radiocardSizes";
import { radioCardSizeSchema } from "modules/create/schemas/formSchema";
import DISABLED_OPACITY from "modules/preview/constants/disabledOpacity";

interface InheritedProps {
  size: RadioCardSize;
  children: ReactNode;
  roundedColors?: [string, string];
}

type Props = UseRadioProps & InheritedProps;

const RadioCard = ({ size, children, roundedColors, ...rest }: Props) => {
  const { getInputProps, getCheckboxProps } = useRadio(rest);
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const { isChecked, isDisabled, value } = rest;
  const w = `${radiocardSizes[size].w}px`;
  const h = `${radiocardSizes[size].h}px`;
  const borderRadius = roundedColors ? radiocardSizes[size].w / 2 : "2px";
  const background =
    typeof value === "undefined"
      ? "none"
      : getBackgroundProps(!!isChecked, value, roundedColors);
  const hasLowOpacity = useMemo(
    () =>
      (layoutFormTypeSchema.check(value) || layoutEmptySchema.check(value)) &&
      !isChecked &&
      !isDisabled,
    [value, isChecked, isDisabled]
  );
  const sx = {
    opacity: hasLowOpacity ? 0.7 : isDisabled ? DISABLED_OPACITY : 1,
  };
  const border =
    layoutFormTypeSchema.check(value) ||
    bannerPositionSchema.check(value) ||
    layoutEmptySchema.check(value) ||
    size === radioCardSizeSchema.enum.smallRounded
      ? "1px solid #838383"
      : "4px solid white";

  const checkboxProps = {
    ...checkbox,
    borderRadius,
    background,
    backgroundSize:
      size === radioCardSizeSchema.enum.small ||
      size === radioCardSizeSchema.enum.medium
        ? "contain"
        : "initial",
    sx,
    w,
    h,
    border,
  };

  return (
    <VStack as="label" mr={8} mt={5}>
      <input {...input} type="radio" />
      <Box
        {...checkboxProps}
        cursor="pointer"
        _disabled={{ cursor: "not-allowed" }}
        _checked={{
          boxShadow: "outline",
        }}
      />
      {children}
    </VStack>
  );
};

export default RadioCard;
