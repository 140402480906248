import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { localeSchema } from "./schemas";
import { LOCAL_STORAGE_KEY, DEFAULT_LOCALE } from "./constants/locale";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
      lookupLocalStorage: LOCAL_STORAGE_KEY,
    },
    fallbackLng: DEFAULT_LOCALE,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    supportedLngs: localeSchema.options,
    whitelist: localeSchema.options,
    nonExplicitSupportedLngs: false,
    load: "languageOnly",
    saveMissing: true,
    debug: process.env.NODE_ENV === "development",
  });

export default i18n;
