import * as z from "zod";

export const inspectedMessageSchema = z.object({
  sentinel_status: z.string(),
  id: z.number(),
  rsmid: z.number(),
  campaign_id: z.number(),
  template_id: z.number(),
  start_date: z.string(),
  end_date: z.string(),
  network: z.string(),
  count: z.number(),
  cycle: z.number(),
  first_appearance: z.string(),
  least_appearance: z.string(),
  next_estimated_tour: z.string(),
});

export const inspectedMessagesSchema = z.array(inspectedMessageSchema);
export type MessageType = z.infer<typeof inspectedMessageSchema>;

export const messagesColumnNameSchema = z.enum([
  "sentinel_status",
  "id",
  "campaign_id",
  "template_id",
  "network",
  "cycle",
  "last_appearance",
  "action",
]);

export type MessagesColumnName = z.infer<typeof messagesColumnNameSchema>;
