import React from "react";
import { HStack, Box, Container } from "@chakra-ui/react";

import { useIsContributor } from "modules/roles/hooks/useIsContributor";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import SaveAction from "./SaveAction";
import TestAction from "./TestAction";
import ValidateAction from "./ValidateAction";
import TargetAction from "./TargetAction";
import ProductionAction from "./ProductionAction";
import DuplicateAction from "./DuplicateAction";
import ArchiveAction from "./ArchiveAction";
import { PATHS } from "modules/shared/constants/paths";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";

const ActionBar = () => {
  const isContributor = useIsContributor();
  const isAdministrator = useIsAdministrator();
  const hasRightForProduction = useHasAccessRights(PATHS.FINALIZATION);
  const hasRightToArchived = useHasAccessRights(PATHS.ARCHIVE);
  const hasRightToTarget = useHasAccessRights(PATHS.TARGET);
  const hasRightToTest = useHasAccessRights(PATHS.TEST);

  return (
    <Box bgColor="black">
      <Container>
        <HStack spacing={5} p="10px" justifyContent="space-around" pr="30px">
          {isContributor || isAdministrator ? (
            <>
              <SaveAction />
              {hasRightToTest && <TestAction />}
              <ValidateAction />
              {hasRightToTarget && <TargetAction />}
            </>
          ) : (
            <>
              {hasRightToTest && <TestAction />}
              {hasRightToTarget && <TargetAction />}
            </>
          )}
          {hasRightForProduction && <ProductionAction />}
          {(isContributor || isAdministrator) && <DuplicateAction />}
          {hasRightToArchived && <ArchiveAction />}
        </HStack>
      </Container>
    </Box>
  );
};

export default ActionBar;
