import React, { useState } from "react";
import { HStack, useDisclosure, Box } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import { Buttons } from "./Buttons";
import { Details } from "./Details";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import { useImage } from "modules/images/hooks/useImage";
import { useImageUrl } from "modules/images/hooks/useImageUrl";
import { useQrcodes } from "modules/qrcodes/hooks/useQrcodes";
import { ImageContent } from "./Contents/ImageContent";
import { ListContent } from "./Contents/ListContent";

interface Props {
  isDisabled: boolean;
}

export const Form = ({ isDisabled }: Props) => {
  const { getValues, setValue } = useFormContext<PreviewFormType>();
  const [contentType, setContentType] = useState<"image" | "list">("list");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const logoIdInitialValue = getValues("customLogo") || undefined;
  const { data: qrcodeData } = useQrcodes();
  const { data: imageData } = useImage(logoIdInitialValue);
  const qrcodeImageData = imageData?.type === "qrcode" ? imageData : undefined;
  const { data: urlData } = useImageUrl(qrcodeImageData);

  const handleConfirmValue = (selectedQrcodeId: number) => {
    setValue("customLogo", selectedQrcodeId);
    onClose();
  };

  const handleDisplayOnclick = () => {
    setContentType("image");
    if (!isDisabled) onOpen();
  };

  const handleClose = () => {
    onClose();
    setContentType("list");
  };

  const Modal = () => {
    return urlData && contentType === "image" ? (
      <ImageContent handleClose={handleClose} isOpen={isOpen} url={urlData} />
    ) : (
      <ListContent
        data={qrcodeData}
        methods={{
          onConfirmValue: handleConfirmValue,
          onClose,
        }}
        isOpen={isOpen}
      />
    );
  };

  return (
    <HStack>
      <Box mt={5} display="flex" flexDirection="column" gridGap={3}>
        <Details image={qrcodeImageData} />
        <Buttons
          isQrcodeSelected={!!qrcodeImageData}
          isDisabled={isDisabled}
          methods={{
            onOpen,
            onDisplayClick: handleDisplayOnclick,
          }}
        />
      </Box>
      <Modal />
    </HStack>
  );
};
