import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
} from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import CardForm from "modules/user/cards/components/CardForm";
import { useCreateTestCard } from "modules/user/cards/hooks/useCreateTestCard";
import { useEditTestCard } from "modules/user/cards/hooks/useEditTestCard";
import {
  CreateCardFormValues,
  createCardFormSchema,
} from "modules/user/cards/schemas/formSchemas";
import { UserTestCardFromList } from "../schemas/userTestCardsApiSchemas";
import { defaultFormValues } from "modules/user/cards/constants/defaultFormValues";
import getFormValuesFromCard from "modules/user/cards/helpers/getFormValuesFromCard";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  card?: UserTestCardFromList;
}

const CardModal = ({ isOpen, onClose, card }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<CreateCardFormValues>({
    resolver: zodResolver(createCardFormSchema),
    defaultValues: card ? getFormValuesFromCard(card) : defaultFormValues,
    shouldUnregister: false,
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const create = useCreateTestCard();
  const edit = useEditTestCard();

  const handleSave = (data: CreateCardFormValues) => {
    if (card) {
      const newcard = { ...data, id: card.id };
      edit.mutate(newcard, {
        onSettled: () => handleCloseModal(),
      });
    } else {
      create.mutate(data, {
        onSettled: () => handleCloseModal(),
      });
    }
  };

  const handleCloseModal = () => {
    methods.clearErrors();
    methods.reset(defaultFormValues);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered={true}>
      <ModalOverlay />
      <ModalContent minWidth="450px" borderRadius="sm">
        <ModalHeader>
          <Box textStyle="h2">
            {card
              ? t("user-cards.form.title.edit")
              : t("user-cards.form.title.create")}
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <CardForm />
          </FormProvider>
        </ModalBody>
        <ModalFooter display="flex">
          <Button variant="tertiary-ghost" mr={3} onClick={handleCloseModal}>
            {t("user-cards.form.cancel")}
          </Button>
          <Button
            variant="secondary-solid"
            onClick={methods.handleSubmit(handleSave)}
          >
            {card ? t("user-cards.form.save") : t("user-cards.form.add")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CardModal;
