import React, { useMemo, useEffect, useCallback } from "react";
import { Heading, Container } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Redirect, useHistory } from "react-router-dom";

import CreateForm from "modules/create/components/CreateForm";
import Preview from "modules/preview/components/Preview";
import useLocationQueryParams from "core/hooks/useLocationQueryParams";
import {
  PreviewFormType,
  previewFormSchema,
} from "modules/create/schemas/formSchema";
import { defaultFormValues } from "modules/create/constants/defaultValues";
import ActionBar from "modules/actions/components/ActionBar";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";
import getFormValuesFromTemplate from "modules/preview/helpers/getFormValuesFromTemplate";
import useDuplicatedTemplate from "modules/duplicate/hooks/useDuplicatedTemplate";
import Feedback from "modules/feedback/components/Feedback";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";

const Create = () => {
  const { data } = useDuplicatedTemplate();
  const { t } = useTranslation();
  const query = useLocationQueryParams();
  const step = query.get("step");
  const { setFeedback } = useFeedbackStore();
  const history = useHistory();

  const methods = useForm<PreviewFormType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: zodResolver(previewFormSchema),
    shouldUnregister: false,

    defaultValues: useMemo(
      () => (data ? getFormValuesFromTemplate(data, true) : defaultFormValues),
      [data]
    ),
    shouldFocusError: true,
  });

  const setDuplicatedFeedbackSuccess = useCallback(() => {
    if (data) {
      setFeedback(feedbackSchema.enum.success, actionsSchema.enum.duplicate);
    }
  }, [data]);

  useEffect(() => {
    setDuplicatedFeedbackSuccess();
  }, []);

  // redirect to step 1 if template has not been saved and window is reloaded
  if (step === "2" && history.action === "POP") {
    return <Redirect to="/create?step=1" />;
  }

  return (
    <FormProvider {...methods}>
      {step === "2" && <ActionBar />}
      <Container variant="body">
        <BreadNav />
        <Feedback />
        <Heading as="h2" variant="h2" mt={10}>
          {data ? t("actions.duplicate.title") : t("create.title")}
        </Heading>
        <form>{step === "1" ? <CreateForm /> : <Preview />}</form>
      </Container>
    </FormProvider>
  );
};

export default Create;
