import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Text,
  useCheckboxGroup,
  CheckboxGroup,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, useController } from "react-hook-form";

import {
  CustomImage,
  ManageQrcodeFormValues,
} from "../schemas/customImageSchema";
import { getFormValuesFromImage } from "modules/images/helpers/getFormValuesFromImage";
import { QrcodeBody, Mode } from "modules/qrcodes/schemas/customQrcodeSchema";
import { useUserRegions } from "modules/regions/hooks/useUserRegions";
import InputError from "core/components/InputError/InputError";

export const ManageQrcodeForm = ({
  qrcode,
  image,
  mode,
}: {
  qrcode?: QrcodeBody;
  image?: CustomImage;
  mode: Mode;
}) => {
  const userRegions = useUserRegions();

  const isImageMutable = !!image?.mutable;
  const disableForm = mode === Mode.EDIT && !isImageMutable;

  const { setValue, watch, control, errors, register, setError, clearErrors } =
    useFormContext<ManageQrcodeFormValues>();
  const { t } = useTranslation();
  const {
    field: { ref, ...checkboxGroupProps },
  } = useController({
    name: "regions",
    control,
    defaultValue: [],
  });
  const getRegionFromForm = watch("regions");
  const { getCheckboxProps, value } = useCheckboxGroup(checkboxGroupProps);
  const checkboxProps = getCheckboxProps();
  const [isAllItemsChecked, setIsAllItemsChecked] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const isBulkSelectChecked = React.useMemo(() => {
    return value.length === userRegions?.length;
  }, [value, userRegions]);

  useEffect(() => {
    if (mode === Mode.EDIT && image) {
      const { title, description, regions } = getFormValuesFromImage(
        image,
        userRegions
      );
      setValue("regions", regions);
      setValue("name", title);
      setValue("description", description);
      setValue("target_url", qrcode?.target_url);
      setValue("short_url", qrcode?.short_url);
    } else if (mode === Mode.CREATE) {
      setValue("regions", userRegions);
    }
  }, [qrcode, image, userRegions, mode, setValue]);

  useEffect(() => {
    if (isAllItemsChecked) {
      setValue("regions", userRegions);
    } else if (!isAllItemsChecked) {
      if (hasFocus) {
        setValue("regions", []);
      }
    }
  }, [isAllItemsChecked, userRegions, hasFocus, setValue]);

  useEffect(() => {
    if (getRegionFromForm.length === userRegions?.length) {
      setIsAllItemsChecked(true);
    } else {
      setIsAllItemsChecked(false);
    }
  }, [getRegionFromForm, userRegions]);

  useEffect(() => {
    if (getRegionFromForm.length < 1) {
      setError("regions", {
        type: "manual",
      });
    } else {
      clearErrors("regions");
    }
  }, [setError, getRegionFromForm, clearErrors]);

  return (
    <form id="image-form" name="image-form">
      <FormControl
        id="name"
        w="400px"
        isInvalid={!!errors?.name}
        isDisabled={disableForm}
      >
        <FormLabel>{t("assets.qrcode.name")}</FormLabel>
        <Input
          variant="outline"
          name="name"
          ref={register}
          errorBorderColor="error"
        />
        <InputError text={errors?.name ? t("assets.qrcode.error") : ""} />
      </FormControl>
      <FormControl
        id="description"
        w="100%"
        isInvalid={!!errors?.description}
        isDisabled={disableForm}
      >
        <FormLabel>{t("assets.qrcode.description")}</FormLabel>
        <Input
          variant="outline"
          name="description"
          ref={register}
          errorBorderColor="error"
        />
        <InputError
          text={errors?.description ? t("assets.qrcode.error") : ""}
        />
      </FormControl>
      <FormControl
        id="target_url"
        w="100%"
        isInvalid={!!errors?.target_url}
        isDisabled={disableForm}
      >
        <FormLabel>{t("assets.qrcode.target_url")}</FormLabel>
        <Input
          variant="outline"
          name="target_url"
          ref={register}
          errorBorderColor="error"
        />
        <InputError text={errors?.target_url ? t("assets.qrcode.error") : ""} />
      </FormControl>
      <FormControl mb="30px" id="short_url" w="100%" isDisabled={true}>
        <FormLabel>{t("assets.qrcode.short_url")}</FormLabel>
        <Input variant="outline" name="short_url" ref={register} />
      </FormControl>
      <CheckboxGroup value={value}>
        <FormControl
          ref={register}
          isInvalid={!!errors?.regions}
          isDisabled={disableForm}
        >
          <FormLabel>{t("assets.qrcode.regions")}</FormLabel>
          <Checkbox
            isChecked={isBulkSelectChecked}
            isIndeterminate={value.length > 0 && !isBulkSelectChecked}
            onChange={() => setIsAllItemsChecked(!isAllItemsChecked)}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            isDisabled={disableForm}
            name="regions"
          >
            <Text fontSize="sm" color="dark" mr="20px">
              {t("assets.qrcode.all_regions")}
            </Text>
          </Checkbox>
          <Flex
            flexDirection="column"
            height="150px"
            flexWrap="wrap"
            mt="5px"
            ml="5px"
          >
            {userRegions?.map((region) => (
              <Checkbox
                {...checkboxProps}
                name="regions"
                ref={ref}
                key={region}
                alignItems="flex-start"
                value={region}
                lineHeight="15px"
                isDisabled={disableForm}
              >
                <Text fontSize="sm" color="dark" mb="10px" mr="20px">
                  {region}
                </Text>
              </Checkbox>
            ))}
          </Flex>
          <InputError
            text={errors?.regions ? t("qrcode.error.no_region") : ""}
          />
        </FormControl>
      </CheckboxGroup>
    </form>
  );
};
