import { pipe, mapValues } from "remeda";
import { FormColors } from "modules/create/schemas/formSchema";

const sanitizeColors = (colors: FormColors) =>
  pipe(
    colors,
    mapValues((value) =>
      value === null || value === undefined ? null : Number(value)
    )
  );

export default sanitizeColors;
