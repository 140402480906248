import React from "react";

import { Box } from "@chakra-ui/react";

interface Props {
  number: string;
}

const HeadingNumber = ({ number }: Props) => (
  <Box
    width=" 26px"
    height="26px"
    bg="black"
    color="white"
    borderRadius="sm"
    textAlign="center"
    textStyle="label"
    paddingTop="5px"
    mr="5px"
  >
    {number}
  </Box>
);

export default HeadingNumber;
