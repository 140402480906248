import { PreviewFormType } from "modules/create/schemas/formSchema";
import useColorSchemes from "modules/colorSchemes/hooks/useColorSchemes";
import { useFormContext } from "react-hook-form";
import useTemplate from "modules/preview/hooks/useTemplate";

export default function useColorScheme() {
  const context = useFormContext<PreviewFormType>();
  const template = useTemplate(false);
  const colorSchemeKey = context
    ? Number(context.watch("colorScheme"))
    : template.data?.configuration_details.palette;

  const colorSchemes = useColorSchemes();
  const colorScheme = colorSchemes.data?.find((p) => p.id === colorSchemeKey);
  if (!colorScheme) {
    return null;
  }
  return colorScheme;
}
