import React from "react";
import { merge, omit } from "remeda";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { generatePath } from "react-router";

import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { useCreateUser } from "modules/user/informations/hooks/useCreateUser";
import { useSaveUser } from "modules/user/informations/hooks/useSaveUser";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import useUser from "modules/users/hooks/useUser";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import DraftIcon from "core/components/InterfaceIcons/DraftIcon";
import { CreateUserFormValues } from "modules/user/informations/schemas/formSchemas";
import { PATHS } from "modules/shared/constants/paths";
import getUserFromFormValues from "../helpers/getUserFromFormValues";
import getNewUserFromFormValues from "../helpers/getNewUserFromFormValues";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { useIsConfigurator } from "modules/roles/hooks/useIsConfigurator";
import { useRouteMatch } from "react-router-dom";

const SaveUserAction = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const history = useHistory();
  const { data } = useUser(false);
  const create = useCreateUser();
  const save = useSaveUser();
  const context = useFormContext<CreateUserFormValues>();
  const { setFeedback } = useFeedbackStore();
  const isAdministrator = useIsAdministrator();
  const isConfigurator = useIsConfigurator();
  const handleError = () => {
    setFeedback(feedbackSchema.enum.error, actionsSchema.enum.saveUser);
  };

  const isDuplicateUser = useRouteMatch({
    path: "/administration/duplicate-user/:id",
    exact: true,
  });

  const disabled = !(isAdministrator || isConfigurator);

  const handleSave = (formValues: CreateUserFormValues) => {
    const user = merge(data, getUserFromFormValues(formValues));
    const formattedUser = omit(user, ["last_login", "failed_auth_counter"]);

    if (id && !isDuplicateUser) {
      save.mutate(formattedUser as CreateUserFormValues, {
        onSuccess: () => {
          setFeedback(feedbackSchema.enum.success, actionsSchema.enum.saveUser);
        },
      });
    } else {
      const userValues = getNewUserFromFormValues(formValues);
      create.mutate(userValues as CreateUserFormValues, {
        onSuccess: ({ user_id }) => {
          const id = user_id.toString();
          history.push(generatePath(PATHS.USER, { id }));
          setFeedback(
            feedbackSchema.enum.success,
            actionsSchema.enum.createUser
          );
        },
      });
    }
  };

  return (
    <Button
      onClick={context?.handleSubmit(handleSave, handleError)}
      leftIcon={<DraftIcon />}
      variant="primary-solid"
      data-testid="save-user-action"
      isDisabled={disabled}
      isLoading={save.isLoading || create.isLoading}
    >
      {t("create_user.actions.save")}
    </Button>
  );
};

export default SaveUserAction;
