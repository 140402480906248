import React from "react";

import {
  FrameObject,
  textFrameSchema,
  imageFrameSchema,
  backgroundFrameSchema,
} from "modules/preview/schemas/specSchemas";
import LogoBlock from "./blocks/LogoBlock";
import TextBlock from "./blocks/TextBlock";
import BackgroundBlock from "./blocks/BackgroundBlock";
import { FrameProps } from "modules/preview/components/Frames";

const Frame = ({ frame, index }: FrameProps<FrameObject>) => {
  if (textFrameSchema.check(frame)) {
    return <TextBlock index={index} frame={frame} />;
  } else if (imageFrameSchema.check(frame)) {
    return <LogoBlock index={index} frame={frame} />;
  } else if (backgroundFrameSchema.check(frame)) {
    return <BackgroundBlock index={index} frame={frame} />;
  } else return null;
};

export default Frame;
