function MomaTabVariation() {
  return {
    tab: {
      color: "abbey",
      fontSize: "sm",
      margin: "-2px",
      textStyle: "label",
      borderBottom: "2px solid",
      borderColor: "transparent",

      _selected: {
        borderRadius: "0px",
        color: "monza",
        borderColor: "monza",
        bg: "zircon",
      },

      _hover: {
        color: "amaranth",
      },
      _focus: {
        color: "amaranth",
      },
      _disabled: {
        color: "jumbo",
      },
    },
    tablist: {
      borderBottom: "2px solid",
      borderColor: "silver",
    },
  };
}

const Tabs = {
  variants: {
    "tabs-moma": MomaTabVariation,
  },
  defaultProps: {
    variant: "tabs-moma",
  },
};

export default Tabs;
