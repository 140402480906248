import * as z from "zod";
import { regionsListBodySchema } from "modules/regions/schemas/regionsSchemas";
import {
  routesSchema,
  rolesListSchema,
} from "modules/roles/schemas/rolesSchemas";
import { testCardsBodySchema } from "modules/test/schemas/testCardsApiSchemas";

const selfcareSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  roles: rolesListSchema,
  routes: z.array(routesSchema),
  regions: regionsListBodySchema,
  test_cards: testCardsBodySchema,
});

export const selfcareBodySchema = selfcareSchema.nonstrict();

export type SelfCare = z.infer<typeof selfcareSchema>;
