import React from "react";
import { Flex, Heading, Divider } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import ColorSchemeForm from "modules/colorSchemes/components/ColorSchemeForm";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";
import {
  CustomizedColorSchemeForm,
  customizedColorSchemeFormSchema,
} from "modules/colorSchemes/schemas/formSchemas";
import ColorSchemeActionBar from "modules/colorSchemes/components/ColorSchemeActionBar";
import { defaultColorScheme } from "modules/create/constants/defaultValues";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

const CreateColorScheme = () => {
  const methods = useForm<CustomizedColorSchemeForm>({
    shouldUnregister: false,
    defaultValues: defaultColorScheme,
    resolver: zodResolver(customizedColorSchemeFormSchema),
    shouldFocusError: true,
  });
  const { t } = useTranslation();

  return (
    <FormProvider {...methods}>
      <ColorSchemeActionBar />
      <ScreenContainer>
        <Flex direction="column">
          <Heading as="h2" variant="h2" mt="20px">
            {t("assets.color_scheme.create")}
          </Heading>
          <Divider
            orientation="horizontal"
            colorScheme="silver"
            maxWidth="600px"
            width="60%"
            my={4}
          />
          <ColorSchemeForm />
        </Flex>
      </ScreenContainer>
    </FormProvider>
  );
};

export default CreateColorScheme;
