import {
  layoutHybridTypeSchema,
  TemplateLayoutType,
  layoutFormTypeSchema,
} from "modules/preview/schemas/templateSchemas";
import { FormColors } from "modules/create/schemas/formSchema";

const getColorsFromLayout = (
  layout: TemplateLayoutType,
  colors: FormColors,
  extraBanner: boolean
) => {
  const isOptionnalBannerTemplate =
    layoutHybridTypeSchema.check(layout) ||
    (layout === layoutFormTypeSchema.enum.full4 && extraBanner);
  const commonExcludedKeys = [
    "Frame3_BackColor",
    "Frame3_TextColor",
    "Frame3_BorderColor",
  ];

  const full3ExcludedKeys = [
    ...commonExcludedKeys,
    "Frame1_BackColor",
    "Frame1_TextColor",
    "Frame1_BorderColor",
  ];

  const full4ExcludedKeys = [
    ...commonExcludedKeys,
    "Frame1_BorderColor",
    "Frame1_BackColor",
  ];

  const hybridExcludedKeys = ["Window_TextColor"];

  const excludedKeys =
    layout === layoutFormTypeSchema.enum.full3
      ? full3ExcludedKeys
      : layout === layoutFormTypeSchema.enum.full4 && !extraBanner
      ? full4ExcludedKeys
      : isOptionnalBannerTemplate
      ? hybridExcludedKeys
      : commonExcludedKeys;

  const excludedColors: Record<string, string | null> = Object.fromEntries(
    excludedKeys.map((key) => [key, null])
  );

  if (
    isOptionnalBannerTemplate &&
    (excludedColors["Frame3_BackColor"] === null ||
      excludedColors["Frame3_TextColor"] === null ||
      excludedColors["Frame3_BorderColor"] === null)
  ) {
    excludedColors["Frame3_BackColor"] = "12";
    excludedColors["Frame3_TextColor"] = "1";
    excludedColors["Frame3_BorderColor"] = "12";
  }

  return { ...colors, ...excludedColors };
};

export default getColorsFromLayout;
