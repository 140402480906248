import React, { useCallback } from "react";
import { Checkbox } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import useFilterContext from "modules/filters/hooks/useFilterContext";
import { TemplateFromList } from "modules/templates/schemas/templateModelSchemas";

function ArchiveFilter() {
  const { t } = useTranslation();
  const globalFilterValue = useFilterContext<TemplateFromList>();

  //Archived templates are displayed when the filter is set to false
  const includeArchivedTemplates = !globalFilterValue?.globalFilter?.archived;

  const handleChange = useCallback(() => {
    if (globalFilterValue?.setGlobalFilter) {
      globalFilterValue?.setGlobalFilter({
        ...globalFilterValue.globalFilter,
        archived: includeArchivedTemplates,
      });
    }
  }, [includeArchivedTemplates, globalFilterValue?.filters]);

  if (!globalFilterValue) {
    return null;
  }

  return (
    <Checkbox
      isChecked={includeArchivedTemplates as boolean}
      onChange={handleChange}
    >
      {t("templates.archived_label")}
    </Checkbox>
  );
}

export default ArchiveFilter;
