import { Configuration } from "modules/preview/schemas/templateSchemas";

const getAdvancedSettingsFromConfiguration = (configuration: Configuration) => {
  const {
    Popup_Timeout,
    Message_Timeout,
    start_hour,
    end_hour,
    msg_ack_count,
    msg_ack_delay,
  } = configuration;
  return {
    popupTimeOut: Popup_Timeout?.toString(),
    messageTimeOut: Message_Timeout?.toString(),
    startHour: start_hour,
    endHour: end_hour,
    messageCount: msg_ack_count?.toString(),
    messageDelay: msg_ack_delay?.toString(),
  };
};

export default getAdvancedSettingsFromConfiguration;
