import { pick, pipe, mapValues } from "remeda";
import {
  TechnicalDetails,
  backgroundColorParamSchema,
  borderColorParamSchema,
  textColorParamSchema,
  textParamSchema,
  alignmentParamSchema,
  layoutHybridTypeSchema,
} from "modules/preview/schemas/templateSchemas";
import getColorsFromLayout from "./getColorsFromLayout";
import { defaultColorsValue } from "modules/create/constants/defaultValues";

const colorSchema = [
  ...backgroundColorParamSchema.options,
  ...borderColorParamSchema.options,
  ...textColorParamSchema.options,
];

const getFormValuesFromTechnicalDetails = (
  technicalDetails: TechnicalDetails
) => {
  const colors = pipe(
    pick(technicalDetails, colorSchema),
    mapValues((value) => String(value))
  );

  if (
    layoutHybridTypeSchema.check(technicalDetails.Window_Layout) &&
    (colors["Frame3_BackColor"] === "null" ||
      colors["Frame3_TextColor"] === "null" ||
      colors["Frame3_BorderColor"] === "null")
  ) {
    colors["Frame3_BackColor"] = getColorsFromLayout(
      "full4",
      defaultColorsValue,
      true
    ).Frame3_BackColor!;
    colors["Frame3_TextColor"] = getColorsFromLayout(
      "full4",
      defaultColorsValue,
      true
    ).Frame3_TextColor!;
    colors["Frame3_BorderColor"] = getColorsFromLayout(
      "full4",
      defaultColorsValue,
      true
    ).Frame3_BorderColor!;
  }

  const text = pick(technicalDetails, textParamSchema.options);
  const alignment = pick(technicalDetails, alignmentParamSchema.options);
  return { colors, alignment, text };
};

export default getFormValuesFromTechnicalDetails;
