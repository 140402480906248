import React from "react";
import { createIcon } from "@chakra-ui/react";

const EditIcon = createIcon({
  displayName: "EditIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M3 19V15.3349L13.8095 4.52541L17.4746 8.19048L6.66507 19H3Z"
        fill="currentColor"
      />
      <path
        d="M20.1883 3.85337L21 4.66507L18.686 6.97909L15.0209 3.31402L17.3349 1L18.8061 2.47119L20.1883 3.85337Z"
        fill="currentColor"
      />
      <line
        x1="3"
        y1="22"
        x2="21"
        y2="22"
        stroke="currentColor"
        strokeWidth="2"
      />
    </>
  ),
});

export default EditIcon;
