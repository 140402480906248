import React from "react";
import { TextFrame } from "modules/preview/schemas/specSchemas";
import FixedBox from "core/components/FixedBox/FixedBox";
import { FrameProps } from "modules/preview/components/Frames";
import { getBoxProps } from "../../helpers/getBoxProps";
import { fontSizesSpec } from "modules/preview/constants/specs";
import EditableTextForm from "../forms/EditableTextForm";
import { Alignment } from "modules/preview/schemas/templateSchemas";
import useColor from "modules/preview/hooks/useColor";
import useAlignment from "modules/preview/hooks/useAlignment";
import ExitTextBlock from "modules/preview/components/blocks/ExitTextBlock";

const TextBlock = (props: FrameProps<TextFrame>) => {
  const {
    frame: {
      style,
      size,
      foregroundColor: foregroundColorFrame,
      backgroundColor: backgroundColorFrame,
      name,
      h,
      maxLength,
      text,
      textAlign,
    },
  } = props;

  const foregroundColor = useColor(foregroundColorFrame);
  const backgroundColor = useColor(backgroundColorFrame);
  const align = useAlignment(textAlign);
  const isOT5 = name === "OT5";

  const styles = {
    ...style,
    color: foregroundColor,
    fontSize: fontSizesSpec[size],
    lineHeight: fontSizesSpec[size],
    textAlign: align as Alignment,
  };

  return (
    <FixedBox
      {...getBoxProps(props)}
      bgColor={backgroundColor}
      borderColor={backgroundColor}
    >
      {!isOT5 ? (
        <EditableTextForm
          style={styles}
          name={name}
          h={h}
          maxLength={maxLength}
          text={text!}
          textAlign={textAlign}
        />
      ) : (
        <ExitTextBlock style={styles} />
      )}
    </FixedBox>
  );
};

export default TextBlock;
