import React from "react";
import {
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { PATHS } from "modules/shared/constants/paths";
import { QrcodesPanel } from "modules/qrcodes/components/QrcodesPanel";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";
import ColorSchemePanel from "modules/colorSchemes/components/ColorSchemePanel";
import ImagePanel from "modules/images/components/ImagePanel";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";

const renderTab = (
  condition: boolean | undefined,
  label: string
): React.ReactNode | null => {
  return condition ? <Tab>{label}</Tab> : null;
};

const renderTabPanel = (
  condition: boolean | undefined,
  component: React.ReactNode
): React.ReactNode | null => {
  return condition ? <TabPanel p="0px">{component}</TabPanel> : null;
};
const Assets = () => {
  const { t } = useTranslation();
  const hasRightForColorScheme = useHasAccessRights(PATHS.CREATE_COLORSCHEME, [
    "POST",
  ]);
  const hasRightForManageImages = useHasAccessRights(PATHS.IMAGES, ["POST"]);
  const hasRightForManageQrcodes = useHasAccessRights(PATHS.QRCODES, ["GET"]);

  return (
    <ScreenContainer>
      <Flex direction="column">
        <Heading as="h2" variant="h2" mt="80px">
          {t("assets.manage.assets")}
        </Heading>
        <Tabs>
          <TabList justifyContent="flex-end">
            {renderTab(
              hasRightForColorScheme,
              t("assets.manage.color_schemes")
            )}
            {renderTab(hasRightForManageImages, t("assets.manage.images"))}
            {renderTab(hasRightForManageQrcodes, t("assets.manage.qrcodes"))}
          </TabList>
          <TabPanels>
            {renderTabPanel(hasRightForColorScheme, <ColorSchemePanel />)}
            {renderTabPanel(hasRightForManageImages, <ImagePanel />)}
            {renderTabPanel(hasRightForManageQrcodes, <QrcodesPanel />)}
          </TabPanels>
        </Tabs>
      </Flex>
    </ScreenContainer>
  );
};

export default Assets;
