import React from "react";
import { createIcon } from "@chakra-ui/react";

const ReviewIcon = createIcon({
  displayName: "ReviewIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M15 10.8H11.8V14H10.2V10.8H7V9.2H10.2V6H11.8V9.2H15V10.8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10C16 12.7614 13.7614 15 11 15C8.23858 15 6 12.7614 6 10C6 7.23858 8.23858 5 11 5C13.7614 5 16 7.23858 16 10ZM14.2547 16.1989C13.2824 16.7105 12.175 17 11 17C7.13401 17 4 13.866 4 10C4 6.13401 7.13401 3 11 3C14.866 3 18 6.13401 18 10C18 11.9707 17.1856 13.7512 15.8751 15.0233L20.1929 20.4502L18.6278 21.6954L14.2547 16.1989Z"
        fill="currentColor"
      />
    </>
  ),
});

export default ReviewIcon;
