import React from "react";
import { Flex, Heading, Divider } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import ColorSchemeForm from "modules/colorSchemes/components/ColorSchemeForm";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";
import {
  CustomizedColorSchemeForm,
  customizedColorSchemeFormSchema,
} from "modules/colorSchemes/schemas/formSchemas";
import { zodResolver } from "@hookform/resolvers/zod";
import ColorSchemeActionBar from "modules/colorSchemes/components/ColorSchemeActionBar";
import useCustomColorScheme from "modules/colorSchemes/hooks/useCustomColorScheme";
import getFormValuesFromColorScheme from "modules/colorSchemes/helpers/getFormValuesFromColorScheme";

const ColorScheme = () => {
  const { data } = useCustomColorScheme();
  const defaultValues = getFormValuesFromColorScheme(data);
  const methods = useForm<CustomizedColorSchemeForm>({
    shouldUnregister: false,
    defaultValues,
    resolver: zodResolver(customizedColorSchemeFormSchema),
    shouldFocusError: true,
  });

  return (
    <FormProvider {...methods}>
      <ColorSchemeActionBar />
      <ScreenContainer>
        <Flex direction="column">
          {data?.name && (
            <Heading as="h2" variant="h2" mt="20px">
              {data.name}
            </Heading>
          )}
          <Divider
            orientation="horizontal"
            colorScheme="silver"
            maxWidth="600px"
            my={4}
          />
          <ColorSchemeForm />
        </Flex>
      </ScreenContainer>
    </FormProvider>
  );
};

export default ColorScheme;
