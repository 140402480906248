import React from "react";

import Frame from "./Frame";
import { Frames } from "../schemas/specSchemas";
import { layoutsSpec } from "modules/preview/constants/specs";
import {
  layoutBannerTypeSchema,
  layoutFormTypeSchema,
  layoutEmptySchema,
} from "modules/preview/schemas/templateSchemas";
import useLayout from "modules/preview/hooks/useLayout";
import EmptyTemplate from "./EmptyTemplate";
import { NonEmptyLayoutSpec } from "modules/preview/schemas/specSchemas";

interface Props {
  view?: "banner" | "full";
}

const Panel = ({ view = "full" }: Props) => {
  const layout = useLayout();
  if (!layout) {
    return null;
  }

  if (layoutEmptySchema.check(layout)) {
    return <EmptyTemplate />;
  }
  const frames =
    layoutBannerTypeSchema.check(layout) ||
    layout === layoutFormTypeSchema.enum.banner
      ? ((layoutsSpec as NonEmptyLayoutSpec)[layout].frames["banner"] as Frames)
      : ((layoutsSpec as NonEmptyLayoutSpec)[layout].frames[view] as Frames);
  return (
    <>
      {frames.map((frame, index) => (
        <Frame frame={frame} index={index} key={`txt-${index}`} />
      ))}
    </>
  );
};

export default Panel;
