import React from "react";
import { Tr, Td, chakra } from "@chakra-ui/react";
import { Row } from "react-table";
import {
  validSentinelStatus,
  invalidSentinelStatus,
} from "modules/monitoring/Inspect/Schemas/InspectionTemplatesSchemas";
import { Link as RouterLink } from "react-router-dom";
import { generatePath } from "react-router";
import { PATHS } from "modules/shared/constants/paths";

interface Props<T extends object> {
  row: Row<T>;
}

const MAX_WIDTH = 50;

const TableRowSentinel = <T extends object>({ row }: Props<T>) => (
  <Tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      const column = cell.column.id;
      const sentinelStatus = row.values.sentinel_status;
      const color = validSentinelStatus.options.includes(sentinelStatus)
        ? "green"
        : invalidSentinelStatus.options.includes(sentinelStatus)
        ? "red"
        : "inherit";
      const styleProps = {
        ...(cell.column.minWidth !== null && {
          minWidth: cell.column.minWidth,
        }),
        maxWidth: MAX_WIDTH,
        color: color,
      };
      if (
        ["id", "name"].includes(column) &&
        sentinelStatus !== invalidSentinelStatus.Enum.unknown_and_broadcast
      ) {
        return (
          <Td {...cell.getCellProps()} {...styleProps}>
            <chakra.span
              as={RouterLink}
              to={generatePath(PATHS.TEMPLATE, { id: row.values.id })}
              textDecoration="underline"
            >
              {cell.render("Cell")}
            </chakra.span>
          </Td>
        );
      }
      return (
        <Td {...cell.getCellProps()} {...styleProps}>
          {cell.render("Cell")}
        </Td>
      );
    })}
  </Tr>
);

export default TableRowSentinel;
