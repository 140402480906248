import { useMemo } from "react";

import useDisableForm from "../hooks/useDisableForm";
import useIsEmptyLayout from "../hooks/useIsEmptyLayout";

export const useIsDisableEmptyLayout = () => {
  const disabled = useDisableForm();
  const isEmptyLayout = useIsEmptyLayout();

  return useMemo(() => isEmptyLayout || disabled, [disabled, isEmptyLayout]);
};
