import { Role } from "modules/roles/schemas/rolesSchemas";
import {
  actionsNameSchema,
  Actions,
} from "modules/shared/schemas/actionsSchemas";
import { Status } from "modules/preview/schemas/templateSchemas";

const getActionFromRolesAndStatus = (
  status: Status,
  roles?: Array<Role | string>
): Actions | undefined => {
  if (!roles) {
    return;
  }
  if (
    (status === "DRAFT" || status === "REFUSED") &&
    (roles?.includes("ADMINISTRATOR") || roles?.includes("CONTRIBUTOR"))
  ) {
    return [actionsNameSchema.enum.write, actionsNameSchema.enum.delete];
  }
  return [actionsNameSchema.enum.read];
};

export default getActionFromRolesAndStatus;
