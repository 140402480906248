import { useRole } from "modules/roles/hooks/useRole";

export function useCanSeeTestCards() {
  const role = useRole();
  return (
    role?.includes("TESTER") ||
    role?.includes("CONFIGURATOR") ||
    role?.includes("ADMINISTRATOR")
  );
}
