type Dict = Record<string, any>;

function variantTertiaryGhost(props: Dict) {
  const { colorScheme: c } = props;

  if (c === "dark") {
    return {
      field: {
        borderRadius: "0px",
        margin: "1rem",
        paddingInlineStart: "2.5rem",
        cursor: "pointer",
        paddingRight: "0.8rem",
        width: "auto",
        layerStyle: "tertiaryDarkBg",
      },
      icon: {
        left: "1.5rem",
        color: "white",
        mixBlendMode: "difference",
      },
    };
  }
  return {
    field: {
      borderRadius: "0px",
      margin: "1rem",
      paddingInlineStart: "2.5rem",
      cursor: "pointer",
      paddingRight: "0.8rem",
      width: "auto",
      layerStyle: "tertiaryLightBg",
    },
    icon: {
      color: "dark",
      left: "1.5rem",
    },
  };
}
const Select = {
  variants: {
    "tertiary-ghost": variantTertiaryGhost,
  },
};

export default Select;
