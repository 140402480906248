import * as z from "zod";
import {
  layoutTypeSchema,
  textsSchema,
  alignmentsSchema,
  themeSchema,
  genreSchema,
  behaviorSchema,
  layoutEmptySchema,
} from "modules/preview/schemas/templateSchemas";
import { bannerPositionSchema } from "modules/preview/schemas/specSchemas";

export const advancedSettings = z.object({
  popupTimeOut: z.string().optional(),
  messageTimeOut: z.string().optional(),
  startHour: z.union([z.string(), z.null()]),
  endHour: z.union([z.string(), z.null()]),
  messageCount: z.string().optional(),
  messageDelay: z.string().optional(),
});

export type AdvancedSettings = z.infer<typeof advancedSettings>;

const formColorsSchema = z.object({
  Window_BackColor: z.string().optional(),
  Window_TextColor: z.string().optional(),
  Window_BorderColor: z.string().optional(),
  Frame0_BackColor: z.string().optional(),
  Frame0_BorderColor: z.string().optional(),
  Frame1_BackColor: z.string().optional(),
  Frame1_TextColor: z.string().optional(),
  Frame1_BorderColor: z.string().optional(),
  Frame2_BackColor: z.string().optional(),
  Frame2_TextColor: z.string().optional(),
  Frame2_BorderColor: z.string().optional(),
  Frame3_BackColor: z.union([z.string(), z.null()]).optional(),
  Frame3_TextColor: z.union([z.string(), z.null()]).optional(),
  Frame3_BorderColor: z.union([z.string(), z.null()]).optional(),
});

export type FormColors = z.infer<typeof formColorsSchema>;

const builtInLogosSchema = z.object({
  canalplus: z.boolean(),
  canalsat: z.boolean(),
});

export type BuiltInLogos = z.infer<typeof builtInLogosSchema>;

const customLogoSchema = z.union([z.number(), z.null()]);

export type CustomLogo = z.infer<typeof customLogoSchema>;

// allow specific characters
const labelRegex = /^(?![0-9]+$)[0-9A-Za-zÀ-ÿ\s_\-+.']{0,256}$/;

export const previewFormSchema = z.object({
  name: z.string(),
  label: z
    .string()
    .refine((value) => labelRegex.test(value), {
      message: "The Label field is not valid.",
    })
    .optional()
    .nullable(),
  layout: layoutTypeSchema.or(layoutEmptySchema),
  colorScheme: z.string().optional().nullable(),
  position: bannerPositionSchema.optional(),
  extraBanner: z.boolean().optional(),
  colors: formColorsSchema.optional(),
  text: textsSchema.optional(),
  logo: z.number().optional(),
  alignment: alignmentsSchema.optional(),
  builtInLogos: builtInLogosSchema.optional(),
  customLogo: customLogoSchema.optional(),
  theme: themeSchema.nullable(),
  genre: genreSchema.nullable(),
  variables: z.array(z.object({ value: z.string() })).optional(),
  storables: z.array(z.object({ value: z.string() })).optional(),
  regions: z.array(z.string()).nonempty(),
  behavior: behaviorSchema.nullable(),
  advancedSettings: advancedSettings.optional(),
});

const emptyPreviewFormSchema = previewFormSchema.extend({
  regions: z.array(z.string()),
});

export type PreviewFormType = z.infer<typeof previewFormSchema>;
export type EmptyPreviewFormType = z.infer<typeof emptyPreviewFormSchema>;

export const regionsSchema = z.array(z.string()).nonempty();
export type Regions = z.infer<typeof regionsSchema>;

export const radioCardSizeSchema = z.enum([
  "large",
  "medium",
  "small",
  "largeRounded",
  "mediumRounded",
  "smallRounded",
]);

export type RadioCardSize = z.infer<typeof radioCardSizeSchema>;

export const advancedSettingsInputSchema = z.enum([
  "messageCount",
  "messageDelay",
  "popupTimeOut",
  "messageTimeOut",
]);

export const advancedSettingsHourSchema = z.enum(["startHour", "endHour"]);

export type AdvancedSettingsHour = z.infer<typeof advancedSettingsHourSchema>;
