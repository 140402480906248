import { ENDPOINTS_FOR_BACKEND as ENDPOINTS } from "modules/shared/webservices/constants/endpointsForBackend";
import { Routes } from "./schemas/rolesSchemas";
import { PATHS } from "modules/shared/constants/paths";

export const REQUIRED_ENDPOINTS_BY_PATH: Record<string, Routes[]> = {
  [PATHS.HOME]: [
    {
      route: ENDPOINTS.TEMPLATES,
      verbs: ["GET"],
    },
  ],
  [PATHS.CREATE]: [
    {
      route: ENDPOINTS.TEMPLATES,
      verbs: ["POST"],
    },
  ],
  [PATHS.TEMPLATE]: [
    {
      route: ENDPOINTS.TEMPLATE,
      verbs: ["GET"],
    },
  ],
  [PATHS.FINALIZATION]: [
    {
      route: ENDPOINTS.FINALIZE,
      verbs: ["POST"],
    },
  ],
  [PATHS.TEST]: [
    {
      route: ENDPOINTS.TEST,
      verbs: ["POST"],
    },
  ],
  [PATHS.USERS]: [
    {
      route: ENDPOINTS.USERS,
      verbs: ["GET"],
    },
  ],
  [PATHS.CREATE_USER]: [
    {
      route: ENDPOINTS.USERS,
      verbs: ["POST"],
    },
  ],
  [PATHS.USER]: [
    {
      route: ENDPOINTS.USER,
      verbs: ["GET", "PATCH", "DELETE"],
    },
  ],
  [PATHS.ADMINISTRATION]: [
    {
      route: ENDPOINTS.USERS,
      verbs: ["GET"],
    },
    {
      route: ENDPOINTS.COLORSCHEMES,
      verbs: ["GET"],
    },
  ],

  [PATHS.COLORSCHEME]: [
    {
      route: ENDPOINTS.COLORSCHEME,
      verbs: ["DELETE", "PATCH"],
    },
  ],

  [PATHS.ARCHIVE]: [
    {
      route: ENDPOINTS.ARCHIVE,
      verbs: ["POST"],
    },
  ],

  [PATHS.TARGET]: [
    {
      route: ENDPOINTS.TARGET,
      verbs: ["POST"],
    },
  ],

  [PATHS.CREATE_COLORSCHEME]: [
    {
      route: ENDPOINTS.COLORSCHEMES,
      verbs: ["GET"],
    },
    {
      route: ENDPOINTS.COLORSCHEMES,
      verbs: ["POST"],
    },
  ],

  [PATHS.IMAGES]: [
    {
      route: ENDPOINTS.IMAGES,
      verbs: ["GET", "POST"],
    },
  ],
  [PATHS.QRCODES]: [
    {
      route: ENDPOINTS.QR_CODES,
      verbs: ["GET", "POST"],
    },
  ],
  [PATHS.GLOBAL_MESSAGES]: [
    {
      route: ENDPOINTS.GLOBALS,
      verbs: ["GET"],
    },
  ],
  [PATHS.GLOBAL_MESSAGE]: [
    {
      route: ENDPOINTS.GLOBAL,
      verbs: ["GET"],
    },
  ],
  [PATHS.CREATE_GLOBALMESSAGE]: [
    {
      route: ENDPOINTS.GLOBALS,
      verbs: ["GET"],
    },
    {
      route: ENDPOINTS.GLOBALS,
      verbs: ["POST"],
    },
  ],
};
