import React from "react";
import { Row } from "react-table";

import ArchiveFilter from "./filters/ArchiveFilter";
import CalendarFilter from "./filters/CalendarFilter";
import CheckboxFilter from "./filters/CheckboxFilter";
import CreatorFilter from "./filters/CreatorFilter";
import InputFilter from "./filters/InputFilter";
import KeywordFilter from "./filters/KeywordFilter";
import MultiCheckboxFilter from "./filters/MultiCheckboxFilter";

export type FilterProps = {
  name: string | undefined;
  preFilteredRows: Row<any>[] | undefined;
  setFilter: ((updater: any) => void) | undefined;
  filterValue: any;
};

export const FiltersTypes = {
  roles: (filterProps: FilterProps) => <MultiCheckboxFilter {...filterProps} />,
  regions: (filterProps: FilterProps) => (
    <MultiCheckboxFilter {...filterProps} />
  ),
  status: (filterProps: FilterProps) => <CheckboxFilter {...filterProps} />,
  theme: (filterProps: FilterProps) => <CheckboxFilter {...filterProps} />,
  template_id: (filterProps: FilterProps) => <InputFilter {...filterProps} />,
  template_name: (filterProps: FilterProps) => <InputFilter {...filterProps} />,
  label: (filterProps: FilterProps) => <InputFilter {...filterProps} />,
  creation_date: (filterProps: FilterProps) => (
    <CalendarFilter {...filterProps} />
  ),
  author: (filterProps: FilterProps) => <CreatorFilter {...filterProps} />,
  archived: () => <ArchiveFilter />,
  keyword: () => <KeywordFilter />,
  name: (filterProps: FilterProps) => <InputFilter {...filterProps} />,
};
