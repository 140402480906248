import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import { PreviewFormType } from "modules/create/schemas/formSchema";

export const Buttons = ({
  isQrcodeSelected,
  isDisabled,
  methods,
}: {
  isDisabled: boolean;
  isQrcodeSelected: boolean;
  methods: {
    onOpen: () => void;
    onDisplayClick: () => void;
  };
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<PreviewFormType>();

  const { onOpen, onDisplayClick } = methods;

  const OpenQrcodesListButton = () => (
    <Button
      cursor="pointer"
      gridColumn="1"
      gridRow="2"
      as="div"
      variant="secondary-solid"
      onClick={isDisabled ? undefined : onOpen}
      isDisabled={isDisabled}
    >
      {isQrcodeSelected
        ? `${t("preview.logos.qrcodes.change")}`
        : `${t("preview.logos.qrcodes.display")}`}
    </Button>
  );

  if (!isQrcodeSelected) {
    return (
      <Box display="flex">
        <OpenQrcodesListButton />
      </Box>
    );
  }
  return (
    <Box
      display="grid"
      flexDirection="column"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="auto auto"
      gridGap={2}
    >
      <Button
        width="min-content"
        cursor="pointer"
        as="div"
        gridColumn="1"
        gridRow="1"
        variant="secondary-solid"
        onClick={onDisplayClick}
        isDisabled={isDisabled}
      >
        {t("preview.logos.qrcodes.visualize")}
      </Button>
      <OpenQrcodesListButton />
      <Button
        cursor="pointer"
        as="div"
        gridColumn="2"
        gridRow="2"
        variant="link-button"
        colorScheme="light"
        onClick={isDisabled ? undefined : () => setValue("customLogo", null)}
        isDisabled={isDisabled}
      >
        {t("preview.logos.qrcodes.delete")}
      </Button>
    </Box>
  );
};
