import React from "react";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import LoginForm from "modules/authentication/components/LoginForm/LoginForm";
import { AuthentificationBox } from "modules/authentication/components/AuthentificationBox";

const Login = () => {
  const { t } = useTranslation();

  return (
    <AuthentificationBox>
      <Text mt={5} textStyle="subtitle">
        {t("login.login")}
      </Text>
      <LoginForm />
    </AuthentificationBox>
  );
};

export default Login;
