import React from "react";
import Dropdown from "core/components/Dropdown/Dropdown";
import { FilterProps } from "modules/filters/components/SwitchFilter";

function CreatorFilter<T extends object>({
  filterValue = [],
  setFilter,
  name,
  preFilteredRows,
}: FilterProps<T, string[]>) {
  const creatorsList = new Set<string>([]);
  preFilteredRows?.forEach((row) => {
    creatorsList.add(row.values.author);
  });
  if (!setFilter) {
    return null;
  }

  const creatorsListArray = Array.from(creatorsList);

  const creatorsOptions = creatorsListArray.map((value: string) => ({
    value,
    label: value,
  }));

  const onChange = (
    selectedOptions: Array<{ value: string; label: string }>
  ) => {
    setFilter(selectedOptions.map((option) => option.value));
  };

  const filterOptions = filterValue.map((value: string) => ({
    value,
    label: value,
  }));

  return (
    <Dropdown
      disabled={false}
      options={creatorsOptions}
      name={name!}
      value={filterOptions}
      onChange={onChange}
      isMulti
    />
  );
}

export default CreatorFilter;
