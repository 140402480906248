import React from "react";
import { Link } from "@chakra-ui/react";
import { NavLink, useRouteMatch } from "react-router-dom";

interface MenuProps {
  target: string;
  name: string;
}

export const LinkItem = ({ target, name }: MenuProps) => {
  const isActive = useRouteMatch({ path: target, exact: true });

  return (
    <Link
      variant={isActive ? "nav-links-active" : "nav-links"}
      as={NavLink}
      to={target}
      height="100%"
      alignContent="center"
    >
      {name}
    </Link>
  );
};
