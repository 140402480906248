import React, { useCallback, useState } from "react";
import {
  Button,
  Modal,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useRadioGroup,
  RadioGroup,
  Box,
  Image,
  Grid,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { PreviewFormType } from "modules/create/schemas/formSchema";
import { useImage } from "modules/images/hooks/useImage";
import { useImages } from "modules/images/hooks/useImages";
import { useImageUrl } from "modules/images/hooks/useImageUrl";
import RadioLogoCard from "modules/preview/components/ui/RadioLogoCard";
import TooltipArrow from "core/components/TooltipArrow/TooltipArrow";

interface Props {
  isDisabled: boolean;
}

const CustomLogoForm = ({ isDisabled }: Props) => {
  const { getValues, setValue } = useFormContext<PreviewFormType>();
  const [logoId, setLogoId] = useState<number | null | undefined>(
    getValues("customLogo") || null
  );

  const { t } = useTranslation();
  const { data } = useImages();
  const { isOpen, onOpen, onClose: handleClose } = useDisclosure();
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "customLogo",
    onChange: (value) => setLogoId(parseInt(value, 10)),
    value: logoId || undefined,
  });
  const { data: image } = useImage(getValues("customLogo") ?? undefined);
  const { data: url } = useImageUrl(image?.type === "logo" ? image : undefined);

  const deleteLogo = useCallback(() => {
    setValue("customLogo", null);
    setLogoId(null);
  }, [setValue]);

  const confirmValue = useCallback(() => {
    setValue("customLogo", logoId);
    setLogoId(logoId);
    handleClose();
  }, [setValue, logoId]);

  return (
    <>
      <HStack>
        <HStack mt={5} spacing={5}>
          {url && <Image objectFit="contain" w={20} src={url} />}
          <Button
            cursor="pointer"
            as="div"
            variant="secondary-solid"
            onClick={isDisabled ? undefined : onOpen}
            isDisabled={isDisabled}
          >
            {url
              ? `${t("preview.logos.customLogo.change")}`
              : `${t("preview.logos.builtInLogos.display")}`}
          </Button>
          {url && (
            <Button
              cursor="pointer"
              as="div"
              variant="link-button"
              colorScheme="light"
              onClick={isDisabled ? undefined : deleteLogo}
              margin={5}
              isDisabled={isDisabled}
            >
              {t("preview.logos.customLogo.delete")}
            </Button>
          )}
        </HStack>

        <Modal isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent
            minWidth="35em"
            position="fixed"
            top="30%"
            h="40%"
            borderRadius="sm"
          >
            <ModalHeader>
              <Box textStyle="h3">{t("preview.logos.customLogo.choose")}</Box>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody {...getRootProps()} overflow="auto">
              <RadioGroup>
                <Grid templateColumns="repeat(3, 1fr)" gap={5}>
                  {data?.map((image) => (
                    <RadioLogoCard
                      key={image.id}
                      image={image}
                      radioProps={getRadioProps({ value: image.id })}
                    />
                  ))}
                </Grid>
              </RadioGroup>
            </ModalBody>

            <ModalFooter display="flex" justifyContent="space-between">
              <Box textStyle="link" textDecoration="underline">
                <TooltipArrow
                  label={t("preview.logos.customLogo.request_message")}
                >
                  <Button
                    variant="link-button"
                    as={RouterLink}
                    to={{ pathname: "/profile", state: { tab: 2 } }}
                  >
                    {t("preview.logos.customLogo.missing")}
                  </Button>
                </TooltipArrow>
              </Box>
              <HStack>
                <Button variant="tertiary-ghost" mr={3} onClick={handleClose}>
                  {t("create.cancel")}
                </Button>
                <Button variant="secondary-solid" onClick={confirmValue}>
                  {t("preview.logos.customLogo.insert")}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </HStack>
    </>
  );
};

export default CustomLogoForm;
