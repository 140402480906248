import React from "react";
import { Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import ProfileIcon from "core/components/InterfaceIcons/ProfileIcon";
import { useUserName } from "modules/selfcare/hooks/useUserName";

export const ProfileButton = () => {
  const userName = useUserName();
  return (
    <Button
      pt="10px"
      variant="unstyled"
      color="white"
      fontSize="xs"
      as={RouterLink}
      to="/profile"
      data-testid="profile-button"
      rightIcon={<ProfileIcon color="white" />}
    >
      {userName}
    </Button>
  );
};
