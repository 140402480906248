import React from "react";
import {
  CheckboxGroup,
  Checkbox,
  VStack,
  Text,
  useCheckboxGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, useController } from "react-hook-form";

import { CreateUserFormValues } from "modules/user/informations/schemas/formSchemas";
import WarningMessage from "core/components/WarningMessage/WarningMessage";
import { rolesSchema } from "modules/roles/schemas/rolesSchemas";
import { useAvailableRoles } from "modules/roles/hooks/useAvailableRoles";
import { useRole } from "modules/roles/hooks/useRole";
import { useHasRightForUser } from "../hooks/useHasRightForUser";

const RolesForm = () => {
  const { t } = useTranslation();
  const { errors, control } = useFormContext<CreateUserFormValues>();
  const isError = errors.roles;
  const { data } = useAvailableRoles();
  const roles = data ?? rolesSchema.options;
  const userRoles = useRole();
  const disabledRole =
    !useHasRightForUser() && userRoles?.includes("TESTCARD_MANAGER");
  const {
    field: { ref, ...checkboxGroupProps },
  } = useController({
    name: "roles",
    control,
    defaultValue: [],
  });
  const { getCheckboxProps, value } = useCheckboxGroup(checkboxGroupProps);

  return (
    <>
      <Text
        fontSize="xs"
        mb="20px"
        color={isError ? "error" : "dark"}
        textAlign="left"
      >
        {t("create_user.technical_form.roles.hint")}
      </Text>
      <CheckboxGroup value={value}>
        <VStack align="left" spacing={0}>
          {roles.map((role) => {
            const checkboxProps = getCheckboxProps();
            return (
              <Checkbox
                {...checkboxProps}
                name="roles"
                isDisabled={disabledRole}
                key={role}
                isChecked={checkboxProps.isChecked}
                alignItems="flex-start"
                value={role}
                lineHeight="15px"
                ref={ref}
              >
                <Text fontSize="sm" color="dark" mb="20px">
                  {role}
                </Text>
              </Checkbox>
            );
          })}
        </VStack>
        {isError && (
          <WarningMessage>
            {t("create_user.technical_form.roles.error")}
          </WarningMessage>
        )}
      </CheckboxGroup>
    </>
  );
};

export default RolesForm;
