import React from "react";
import {
  useTheme,
  Box,
  UseRadioProps,
  useRadio,
  Center,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { COLORS } from "theme";
import { Behavior } from "modules/preview/schemas/templateSchemas";
import useDisableForm from "modules/preview/hooks/useDisableForm";
import DISABLED_OPACITY from "modules/preview/constants/disabledOpacity";

interface StyledButtonProps {
  checked: boolean;
  colors: COLORS;
  disabled: boolean;
}

const StyledButton = styled(Center)<StyledButtonProps>`
  width: 106px;
  height: 52px;
  font-weight: 700;
  border-width: 1px;
  border-color: ${(props) =>
    props.checked ? props.colors.amaranth : props.colors.silver};
  background-color: ${(props) =>
    props.checked ? props.colors.amaranth : props.colors.white};
  color: ${(props) =>
    props.checked ? props.colors.white : props.colors.abbey};
  padding: 10px;
  text-align: center;
  opacity: ${(props) => (props.disabled ? DISABLED_OPACITY : 1)};
  &:hover:enabled {
    background-color: ${(props) => props.colors.zircon};
    color: ${(props) => props.colors.amaranth};
    border-color: ${(props) => props.colors.silver};
  }
  &:focus:enabled,
  &:active:enabled {
    background-color: ${(props) => props.colors.amaranth};
    color: ${(props) => props.colors.white};
    border-color: ${(props) => props.colors.amaranth};
  }
`;

const SwitchButton = (props: UseRadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const disabled = useDisableForm();

  return (
    <Box as="label">
      <input {...input} disabled={disabled} />
      <StyledButton
        cursor="pointer"
        _disabled={{ cursor: "not-allowed" }}
        colors={colors}
        fontSize="xs"
        checked={!!props.isChecked}
        {...checkbox}
        disabled={disabled}
      >
        {t(`preview.settings.${props.value as Behavior}.label` as const)}
      </StyledButton>
    </Box>
  );
};

export default SwitchButton;
