import React, { MouseEventHandler } from "react";
import { Button, HStack } from "@chakra-ui/react";
import CheckIcon from "core/components/InterfaceIcons/CheckIcon";
import CloseIcon from "core/components/InterfaceIcons/CloseIcon";

import ArrowBackIcon from "core/components/InterfaceIcons/ArrowBackIcon";

interface Props {
  confirm: string;
  cancel: string;
  handleAction: MouseEventHandler<HTMLButtonElement>;
  reset: MouseEventHandler<HTMLButtonElement>;
  type: "contributor" | "validator";
}

const ValidationButtons = ({
  confirm,
  cancel,
  handleAction,
  reset,
  type = "contributor",
}: Props) => {
  return type === "contributor" ? (
    <HStack>
      <Button onClick={handleAction} variant="secondary-solid">
        {confirm}
      </Button>
      <Button
        onClick={reset}
        leftIcon={<ArrowBackIcon />}
        variant="tertiary-ghost"
      >
        {cancel}
      </Button>
    </HStack>
  ) : (
    <HStack mt="15px">
      <Button
        variant="tertiary-ghost"
        onClick={handleAction}
        bg="validation"
        color="white"
        leftIcon={<CheckIcon />}
      >
        {confirm}
      </Button>
      <Button
        variant="tertiary-ghost"
        onClick={reset}
        bg="error"
        color="white"
        leftIcon={<CloseIcon />}
      >
        {cancel}
      </Button>
    </HStack>
  );
};

export default ValidationButtons;
