import { actionsSchema } from "modules/shared/schemas/actionsSchemas";
import * as z from "zod";

export const testCardSchema = z.object({
  id: z.number().optional(),
  target: z.number(),
  description: z.string(),
  region: z.string(),
  actions: actionsSchema.optional(),
});

export const testCardsBodySchema = z.array(testCardSchema);

export type TestCard = z.infer<typeof testCardSchema>;
export type TestCardBody = z.infer<typeof testCardsBodySchema>;
