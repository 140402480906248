import React, { useEffect } from "react";
import { createPipe } from "remeda";
import { useRadioGroup, Text, Flex, VStack } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";

import RadioCard from "../ui/RadioCard";
import useColorSchemes from "modules/colorSchemes/hooks/useColorSchemes";
import {
  PreviewFormType,
  radioCardSizeSchema,
} from "modules/create/schemas/formSchema";
import { defaultColorsValue } from "modules/create/constants/defaultValues";
import useIsSide from "modules/create/hooks/useIsSide";
import { ColorParam } from "modules/preview/schemas/templateSchemas";
import getHexColorFromMomaColor from "modules/templates/helpers/getHexColorFromMomaColor";
import useDisableForm from "modules/preview/hooks/useDisableForm";
import tap from "core/helpers/utils/tap";
import useIsEmptyLayout from "modules/preview/hooks/useIsEmptyLayout";

const ColorSchemeForm = () => {
  const { data } = useColorSchemes();
  const { control, getValues, setValue } = useFormContext<PreviewFormType>();

  const {
    field: { ref, ...radioGroupProps },
  } = useController({
    name: "colorScheme",
    control,
  });

  const radioGroupPropsWithSideEffect = {
    ...radioGroupProps,
    onChange: createPipe(
      radioGroupProps.onChange,
      tap(() => {
        const colors = getValues("colors");
        if (colors) {
          Object.keys(colors).forEach((key) =>
            setValue(`colors.${key}`, defaultColorsValue[key as ColorParam])
          );
        }
      })
    ),
  };

  const { getRootProps, getRadioProps } = useRadioGroup(
    radioGroupPropsWithSideEffect
  );

  const group = getRootProps();
  const side = useIsSide();
  const isEmptyLayout = useIsEmptyLayout();
  const disabled = useDisableForm() || isEmptyLayout;

  useEffect(() => {
    if (isEmptyLayout) {
      setValue("colorScheme", undefined);
    }
  }, [isEmptyLayout, setValue]);

  return (
    <Flex
      {...group}
      flexDirection="row"
      flexWrap="wrap"
      align="flex-start"
      data-testid="colorScheme-form"
    >
      {data?.map((colorScheme) => {
        const radio = getRadioProps({
          value: colorScheme.id.toString(),
        });
        return (
          <VStack key={colorScheme.id}>
            <RadioCard
              isDisabled={disabled}
              key={colorScheme.id}
              {...radio}
              size={
                side
                  ? radioCardSizeSchema.enum.mediumRounded
                  : radioCardSizeSchema.enum.largeRounded
              }
              roundedColors={[
                getHexColorFromMomaColor(colorScheme.colors.color_6),
                getHexColorFromMomaColor(colorScheme.colors.color_12),
              ]}
            >
              <VStack maxWidth={side ? "90px" : "120px"}>
                <Text fontSize="sm" textAlign="center">
                  {colorScheme.name}
                </Text>
                <Text fontSize="x-small">{colorScheme.description}</Text>
              </VStack>
            </RadioCard>
          </VStack>
        );
      })}
    </Flex>
  );
};

export default ColorSchemeForm;
