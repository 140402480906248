import React from "react";
import { createIcon } from "@chakra-ui/react";

const ArrowUpIcon = createIcon({
  displayName: "ArrowUpIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M4 12L5.41 13.41L11 7.83V20H13V7.83L18.58 13.42L20 12L12 4L4 12Z"
        fill="currentColor"
      />
    </>
  ),
});

export default ArrowUpIcon;
