import getHexColorFromMomaColor from "modules/templates/helpers/getHexColorFromMomaColor";
import { formColorKeySchema } from "modules/colorSchemes/schemas/formSchemas";
import { ColorScheme } from "modules/preview/schemas/colorSchemesSchemas";

const getFormValuesFromColorScheme = (colorScheme?: ColorScheme) => {
  const formColors = formColorKeySchema.options.reduce<Record<string, string>>(
    (acc, current) => {
      acc[current] = getHexColorFromMomaColor(colorScheme?.colors[current]);
      return acc;
    },
    {}
  );
  return {
    name: colorScheme?.name,
    description: colorScheme?.description,
    ...formColors,
  };
};

export default getFormValuesFromColorScheme;
