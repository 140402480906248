import React from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import { CreateCardFormValues } from "modules/user/cards/schemas/formSchemas";
import Dropdown from "core/components/Dropdown/Dropdown";
import getSelectOptionsFromRegions from "modules/user/cards/helpers/getSelectOptionsFromRegions";
import { useUserRegions } from "modules/regions/hooks/useUserRegions";
import { CARD_MAX_TARGET_VALUE } from "modules/user/cards/constants/defaultFormValues";
import InputError from "core/components/InputError/InputError";

const CardForm = () => {
  const data = useUserRegions();
  const context = useFormContext<CreateCardFormValues>();
  const { t } = useTranslation();
  const regions = data || [];
  const dropdownOptions = getSelectOptionsFromRegions(regions);

  return (
    <form>
      <FormControl
        id="description"
        w="400px"
        isInvalid={!!context.errors.description}
      >
        <FormLabel>{t("user-cards.form.description")}</FormLabel>
        <Input
          variant="outline"
          name="description"
          ref={context.register}
          errorBorderColor="error"
        />
        <InputError
          text={
            context.errors.description
              ? t("user-cards.form.error.required")
              : ""
          }
        />
      </FormControl>
      <FormControl id="target" w="400px" isInvalid={!!context.errors.target}>
        <FormLabel>{t("user-cards.form.target")}</FormLabel>
        <Input
          variant="outline"
          name="target"
          ref={context.register({
            valueAsNumber: true,
          })}
          errorBorderColor="error"
          type="number"
        />
        <InputError
          text={
            !context.errors?.target
              ? ""
              : context.errors?.target?.type === "too_big"
              ? t("user-cards.form.error.too_big", {
                  max: CARD_MAX_TARGET_VALUE,
                })
              : t("user-cards.form.error.required")
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t("user-cards.form.region")}</FormLabel>
        <Controller
          control={context.control}
          defaultValue={""}
          name="region"
          render={({ onChange, value, name, ref }) => (
            <>
              <Dropdown
                options={dropdownOptions}
                name={name}
                inputRef={ref}
                value={dropdownOptions.find((option) => option.value === value)}
                onChange={(option) => onChange(option.value)}
                size="large"
                error={!!context.errors.region}
              />
              <InputError
                text={
                  context.errors.region
                    ? t("user-cards.form.error.required")
                    : ""
                }
              />
            </>
          )}
        />
      </FormControl>
    </form>
  );
};

export default CardForm;
