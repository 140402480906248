import { useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  feedbackSchema,
  actionsSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

export const backToDraft = (templateId: number) =>
  simpleRequest(
    generatePath(ENDPOINTS.BACK_TO_DRAFT, { id: templateId }),
    emptySchema,
    methodSchema.enum.post
  );

export function useSetTemplateBackToDraft() {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(backToDraft, {
    onSuccess: (_, templateId) => {
      queryClient.invalidateQueries(["template", templateId]);
      queryClient.invalidateQueries(["templates"]);
      setFeedback(feedbackSchema.enum.success, actionsSchema.enum.backToDraft);
    },
  });
}
