import { useRouteMatch } from "react-router-dom";
import { PATHS } from "modules/shared/constants/paths";
import { useIsParamRoute } from "modules/shared/hooks/useIsParamRoute";

export const useIsUserRoute = () => {
  const isUserParamRoute = useIsParamRoute();
  const isCreateUserRoute = useRouteMatch({
    path: PATHS.CREATE_USER,
    exact: true,
  });
  return isUserParamRoute || isCreateUserRoute;
};
