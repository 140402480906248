import { useQueryClient } from "react-query";
import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { createImageBodySchema } from "../../schemas/createImageApiSchema";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { ManageQrcodeFormValues } from "modules/images/schemas/customImageSchema";

export const createQrcode = (formDataImage: ManageQrcodeFormValues) =>
  simpleRequest(
    ENDPOINTS.QR_CODES,
    createImageBodySchema,
    methodSchema.enum.post,
    formDataImage
  );

export const useCreateQrcode = () => {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();

  return useMutationWithErrorHandling(createQrcode, {
    onMutate: async (newQrcodeData) => {
      await queryClient.cancelQueries(["qrcodes"]);

      const previousQrcodes = queryClient.getQueryData(["qrcodes"]);

      if (previousQrcodes) {
        queryClient.setQueryData(["qrcodes"], (oldQrcodes: any) => [
          ...oldQrcodes,
          newQrcodeData,
        ]);
      }

      return { previousQrcodes };
    },
    onError: (err, newQrcodeData, context: any) => {
      if (context.previousQrcodes) {
        queryClient.setQueryData(["qrcodes"], context.previousQrcodes);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["qrcodes"]);
      setFeedback(feedbackSchema.enum.success, actionsSchema.enum.saveQrcode);
    },
  });
};
