import {
  Button,
  Modal,
  HStack,
  ModalBody,
  UnorderedList,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
  ListItem,
} from "@chakra-ui/react";
import { MessageType } from "../Schemas/InspectionMessagesSchemas";
import { ViewIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

interface Props {
  message?: MessageType;
}
const PreviewMessageModal = ({ message }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  return (
    <>
      <HStack>
        <ViewIcon />
        <Button variant="link-button" color="dark" onClick={onOpen}>
          {t("supervision.preview")}
        </Button>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={"30%"}>
          <ModalHeader>{t("supervision.modal")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {message ? (
              <UnorderedList>
                {Object.entries(message).map(([key, value]) => (
                  <ListItem key={key}>
                    <strong>{t(`supervision.message.${key}` as any)}: </strong>
                    {value?.toString() ?? "N/A"}
                  </ListItem>
                ))}
              </UnorderedList>
            ) : null}
          </ModalBody>

          <ModalFooter>
            <Button variant="secondary-solid" mr={3} onClick={onClose} mt={0}>
              {t("supervision.close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PreviewMessageModal;
