import { GlobalMessage } from "../schemas/globalMessageFormSchema";
import getGlobalsListActions from "./getGlobalListActions";

const globalsListTransform = (data: GlobalMessage[], roles?: boolean) =>
  data.map((global) => ({
    ...global,
    label: global.label || "",
    actions: getGlobalsListActions(global.status, roles),
  }));

export default globalsListTransform;
