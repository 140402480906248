import React from "react";
import { createIcon } from "@chakra-ui/react";

const UploadIcon = createIcon({
  displayName: "UploadIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M9.41 8.40995L8 6.99995L12 2.99995L16 6.99995L14.58 8.40995L13 6.82995L13 15H11L11 6.82995L9.41 8.40995ZM3 21V21L3 19L3 15L5 15L5 19H19V15L21 15V19V20.9999V21H19.1212L19 21L5 21L3 21Z"
        fill="currentColor"
      />
      ,
    </>
  ),
});

export default UploadIcon;
