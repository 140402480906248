import { useQuery } from "react-query";
import { simpleRequest } from "core/services/api";
import { regionListStatus } from "modules/emergencyStop/schemas/schemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";

export const getRegionsStatus = () =>
  simpleRequest(ENDPOINTS.REGIONS_STATUS, regionListStatus);

export function useRegionStatus(fetch = true) {
  return useQuery(["regionListStatus"], getRegionsStatus, {
    enabled: fetch,
    suspense: true,
  });
}
