import React from "react";
import { Flex } from "@chakra-ui/react";
import { useImages } from "modules/images/hooks/useImages";
import ImageBox from "./ImageBox";

const Images = ({ onChangeImage }: { onChangeImage: (id: number) => void }) => {
  const { data } = useImages();
  return (
    <Flex
      direction="row"
      justifyContent="flex-start"
      overflow="auto"
      pt="30px"
      flexFlow="wrap"
    >
      {data?.map((image) => (
        <ImageBox image={image} key={image.id} onOpen={onChangeImage} />
      ))}
    </Flex>
  );
};

export default Images;
