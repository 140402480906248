import * as z from "zod";

export const targetFormValuesSchema = z.object({
  id: z.string(),
  message: z.string().nonempty(),
  startDate: z.date(),
  endDate: z.date(),
});

export type TargetFormValues = z.infer<typeof targetFormValuesSchema>;
