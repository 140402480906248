import React from "react";
import { createIcon } from "@chakra-ui/react";

const RoundedIcon = createIcon({
  displayName: "RoundedIcon",
  viewBox: "0 0 200 200",

  path: (
    <>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </>
  ),
});

export default RoundedIcon;
