import React, { useMemo, useEffect } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  PreviewFormType,
  previewFormSchema,
} from "modules/create/schemas/formSchema";
import useTemplate from "modules/preview/hooks/useTemplate";
import getFormValuesFromTemplate from "modules/preview/helpers/getFormValuesFromTemplate";
import { defaultFormValues } from "modules/create/constants/defaultValues";
import { useTranslation } from "react-i18next";

import { GlobalMessageFormValues } from "modules/globalMessage/schemas/globalMessageFormSchema";
import Frames from "modules/preview/components/Frames";
import { HStack } from "@chakra-ui/react";

const TemplatePreview = () => {
  const { watch } = useFormContext<GlobalMessageFormValues>();
  const { t } = useTranslation();

  const templateId = watch("template_id")?.toString();
  const { data } = useTemplate(true, templateId); //

  const methods = useForm<PreviewFormType>({
    resolver: zodResolver(previewFormSchema),
    shouldUnregister: false,
    defaultValues: useMemo(
      () => (data ? getFormValuesFromTemplate(data) : defaultFormValues), // because of suspense, we should never fallback to defaultFormValues
      [data]
    ),
  });

  useEffect(() => {
    // Use reset function to update defaultValues
    if (data) {
      methods.reset(getFormValuesFromTemplate(data));
    }
  }, [data]);

  return data ? (
    <FormProvider {...methods}>
      <form>
        <Frames />
      </form>
    </FormProvider>
  ) : (
    <HStack width="100%" justifyContent="center" pt={8}>
      <p> {t("global_message.select")}</p>
    </HStack>
  );
};

export default TemplatePreview;
