import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Text, Flex, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import DuplicateIcon from "core/components/InterfaceIcons/DuplicateIcon";

interface Props {
  to: string;
}

const DuplicateIconBlock = ({ to }: Props) => {
  const { t } = useTranslation();

  return (
    <Link variant="link-with-icon" as={RouterLink} to={to}>
      <Flex alignItems="center">
        <DuplicateIcon />
        <Text fontWeight={700} ml="10px">
          {t("actions.duplicate.button")}
        </Text>
      </Flex>
    </Link>
  );
};

export default DuplicateIconBlock;
