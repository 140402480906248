import { ProfileFormValues } from "modules/user/profile/schemas/profileFormSchema";

const getUserProfileFromFormValues = ({
  name,
  email,
  password,
  currentPassword,
}: ProfileFormValues) => ({
  name,
  email,
  password: password.password,
  current_password: currentPassword,
});

export default getUserProfileFromFormValues;
