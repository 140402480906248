import React from "react";

import { Container } from "@chakra-ui/react";
import MetricsList from "modules/monitoring/Metrics/components/MetricsList";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";

export default function Metrics() {
  return (
    <Container>
      <BreadNav />
      <MetricsList />
    </Container>
  );
}
