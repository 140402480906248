import { ManageImageFormValues } from "../schemas/customImageSchema";
import { omit } from "remeda";

export const getImageFromFormValues = (
  dataImage: ManageImageFormValues,
  regions: string[]
) => {
  const formData = new FormData();

  const data = omit(dataImage, ["original"]);

  // if all regions are selected we wants to post an empty array
  const formRegions =
    dataImage.regions.length === regions.length ? [] : dataImage.regions;

  data["regions"] = formRegions;

  formData.append("data", JSON.stringify(data));
  formData.append("original", dataImage.original![0]);

  return formData;
};
