import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import useTestedTemplate from "modules/preview/hooks/useTestedTemplate";
import useIsProductionTemplate from "modules/preview/hooks/useIsProductionTemplate";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import TargetIcon from "core/components/InterfaceIcons/TargetIcon";

const TargetAction = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const isTested = useTestedTemplate();
  const isProduction = useIsProductionTemplate();

  if (!isTested || !isProduction) {
    return null;
  }

  return (
    <Button
      as={RouterLink}
      to={`/target/${id}`}
      leftIcon={<TargetIcon />}
      variant="primary-solid"
    >
      {t("actions.target.button")}
    </Button>
  );
};

export default TargetAction;
