import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/react";

import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { useArchiveTemplate } from "modules/actions/hooks/useArchiveTemplate";

import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import useTemplateStatus from "modules/preview/hooks/useTemplateStatus";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";

import { useParams, useHistory } from "react-router-dom";

const ArchiveAction = () => {
  const { t } = useTranslation();
  const archive = useArchiveTemplate();
  const { id } = useParams<RouteIdParam>();
  const { setFeedback } = useFeedbackStore();
  const status = useTemplateStatus();
  const history = useHistory();

  return status === "ARCHIVED" ? null : (
    <Button
      onClick={() => {
        setFeedback(
          feedbackSchema.enum.warning,
          actionsSchema.enum.archive,
          () =>
            archive.mutate(Number(id), {
              onSuccess: () => {
                history.push("/");
                setFeedback(
                  feedbackSchema.enum.success,
                  actionsSchema.enum.archive
                );
              },
            })
        );
      }}
      variant="link-button"
      colorScheme="dark"
      isDisabled={!status}
    >
      {t("actions.archive.button")}
    </Button>
  );
};

export default ArchiveAction;
