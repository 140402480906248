import React from "react";
import { Box, AlertDescription } from "@chakra-ui/react";
import capitalizeFirstLetter from "core/helpers/utils/capitalizeFirstLetter";

interface Props {
  message?: string | null;
}

const CustomMessage = ({ message }: Props) => {
  const errorMessage = message?.substring(
    message.indexOf("Error message") + "Error message:".length
  );

  const capitalizedErrorMessage = React.useMemo(
    () => errorMessage && capitalizeFirstLetter(errorMessage),
    [errorMessage]
  );

  return (
    <Box flex="1" mx="20px" my="10px">
      <AlertDescription m="5px" display="block">
        {message || <p>{capitalizedErrorMessage}</p>}
      </AlertDescription>
    </Box>
  );
};

export default CustomMessage;
