import React from "react";
import { Button, VStack } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

import EditIcon from "core/components/InterfaceIcons/EditIcon";
import { UserTestCardFromList } from "modules/user/cards/schemas/userTestCardsApiSchemas";

interface Props {
  handleEdit: (card: UserTestCardFromList) => void;
  handleDelete: (id: number) => void;
  card: UserTestCardFromList;
}

const CardsActionButtons = ({ handleEdit, handleDelete, card }: Props) => {
  const { t } = useTranslation();
  return (
    <VStack spacing={2} alignItems="flex-start">
      <Button
        colorScheme="light"
        leftIcon={<EditIcon mr="5px" />}
        variant="link-with-icon"
        onClick={() => handleEdit(card)}
      >
        {t("actions.table.write")}
      </Button>
      <Button
        colorScheme="light"
        leftIcon={<DeleteIcon mr="5px" />}
        variant="link-with-icon"
        onClick={() => handleDelete(card.id!)}
      >
        {t("actions.table.delete")}
      </Button>
    </VStack>
  );
};

export default CardsActionButtons;
