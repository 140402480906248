import React from "react";
import { createIcon } from "@chakra-ui/react";

const CheckIcon = createIcon({
  displayName: "CheckIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3536 7L19.5902 5.23653L9.00017 15.8165L4.83071 11.6471L3.05591 13.4094L9.00009 19.3536L21.3536 7Z"
        fill="currentColor"
      />
    </>
  ),
});

export default CheckIcon;
