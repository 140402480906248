import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";

import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { useIsConfigurator } from "modules/roles/hooks/useIsConfigurator";
import { useRouteMatch } from "react-router-dom";

const DeleteUserAction = () => {
  const { t } = useTranslation();
  const { setFeedback } = useFeedbackStore();
  const { id } = useParams<RouteIdParam>();

  const isAdministrator = useIsAdministrator();
  const isConfigurator = useIsConfigurator();

  const isNewUserFromAnotherUser = useRouteMatch({
    path: "/administration/duplicate-user/:id",
    exact: true,
  });
  const disabled =
    isNewUserFromAnotherUser?.isExact ||
    !id ||
    !(isAdministrator || isConfigurator);

  return (
    <Button
      onClick={() =>
        setFeedback(feedbackSchema.enum.warning, actionsSchema.enum.deleteUser)
      }
      leftIcon={<DeleteIcon />}
      data-testid="delete-user-action"
      variant="primary-solid"
      isDisabled={disabled}
    >
      {t("create_user.actions.delete")}
    </Button>
  );
};

export default DeleteUserAction;
