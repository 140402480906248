import { fixedColors } from "modules/preview/constants/specs";
import { defaultColorKeySchema } from "modules/colorSchemes/schemas/formSchemas";
import { defaultColorSchemeColor } from "../constants/defaultColorSchemeColor";
import { ColorSchemeColors } from "modules/preview/schemas/colorSchemesSchemas";
import getMomaColorFromHexColor from "modules/colorSchemes/helpers/getMomaColorfromHexColor";

const getFixedColors = () => {
  const defaultColor = getMomaColorFromHexColor(defaultColorSchemeColor);
  const fixedColorsValues = fixedColors.reduce<Record<string, string>>(
    (acc, current, index) => {
      acc[`color_${index}`] = getMomaColorFromHexColor(current.color);
      return acc;
    },
    {}
  );
  const defaultColorsValues = defaultColorKeySchema.options.reduce<
    Record<string, string>
  >((acc, current) => {
    acc[current] = defaultColor;
    return acc;
  }, {});
  return {
    ...fixedColorsValues,
    ...defaultColorsValues,
  } as Partial<ColorSchemeColors>;
};

export default getFixedColors;
