import { useQueryClient } from "react-query";
import { simpleRequest } from "core/services/api";
import { DraftTemplate } from "modules/preview/schemas/templateSchemas";
import { createTemplateBodySchema } from "modules/actions/schemas/createTemplateApiSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

export const createTemplate = (template: DraftTemplate) =>
  simpleRequest(
    ENDPOINTS.TEMPLATES,
    createTemplateBodySchema,
    methodSchema.enum.post,
    template
  );

export const useCreateTemplate = () => {
  const queryClient = useQueryClient();
  return useMutationWithErrorHandling(createTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(["templates"]);
    },
  });
};
