import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Container, Button } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { useSetTemplateBackToDraft } from "modules/backtodraft/hooks/useSetTemplateBackToDraft";
import useTemplateStatus from "modules/preview/hooks/useTemplateStatus";
import { useHasRightForBackToDraft } from "modules/backtodraft/hooks/useHasRightForBackToDraft";

const BackToDraft = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const backToDraft = useSetTemplateBackToDraft();
  const status = useTemplateStatus();
  const hasRightForBackToDraft = useHasRightForBackToDraft();
  const displayBackToDraft = useMemo(
    () =>
      hasRightForBackToDraft &&
      (status === "PENDING_LEGAL" ||
        status === "PENDING_COMMERCIAL" ||
        status === "REFUSED"),
    [hasRightForBackToDraft, status]
  );

  if (!displayBackToDraft) {
    return null;
  }

  return (
    <Container mt="20px">
      <Flex justifyContent="flex-end">
        <Button
          onClick={() => backToDraft.mutate(Number(id))}
          variant="link-button"
          textDecoration="underline"
        >
          {t("actions.backToDraft.text")}
        </Button>
      </Flex>
    </Container>
  );
};

export default BackToDraft;
