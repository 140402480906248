import React from "react";
import { Spinner, Center } from "@chakra-ui/react";

const FullLoader = () => {
  return (
    <Center
      left={0}
      top={0}
      bottom={0}
      right={0}
      margin="auto"
      position="fixed"
    >
      <Spinner size="lg" />
    </Center>
  );
};

export default FullLoader;
