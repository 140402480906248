import { defaultStorables } from "modules/create/constants/defaultValues";
import { Storables } from "modules/preview/schemas/templateSchemas";

const getStorablesFromDetails = (details?: Storables | null) =>
  details
    ? Object.values(details)
        .filter((value) => value !== null)
        .map((value) => ({ value }))
    : defaultStorables;

export default getStorablesFromDetails;
