import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import Screens from "screens/Screens";
import theme from "theme";
import Fonts from "theme/global/Fonts";
import reportWebVitals from "./reportWebVitals";
import FullLoader from "core/components/Fullloader/FullLoader";

// providers
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import "focus-visible/dist/focus-visible";

// intl
import "modules/i18n/config";

if (process.env.REACT_APP_GIT_SHA) {
  // eslint-disable-next-line no-console
  console.log(
    `The latest version published on this environnement is : ${process.env.REACT_APP_GIT_SHA}`
  );
}

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_ENABLE_MOCK === "full"
) {
  const browserWorker =
    require("./modules/shared/webservices/mocks/config/browserWorker").default;
  browserWorker.start();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 30000, // TODO : refine this
    },
    mutations: {},
  },
});

registerLocale("fr", fr);

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Fonts />
      <QueryClientProvider client={queryClient}>
        <Router>
          <Suspense fallback={<FullLoader />}>
            <Screens />
          </Suspense>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
