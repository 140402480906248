import { useQueryClient } from "react-query";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  feedbackSchema,
  actionsSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { testTemplateApiSchema } from "../schemas/testTemplateApiSchema";

export interface StorableForm {
  storable_1: string;
}
interface TestPayload {
  templateId: number;
  test_card_id: number;
  storables: StorableForm | null | {};
}

export const testTemplate = ({
  templateId,
  test_card_id,
  storables,
}: TestPayload) =>
  simpleRequest(
    generatePath(ENDPOINTS.TEST, { id: templateId }),
    testTemplateApiSchema,
    methodSchema.enum.post,
    {
      test_card_id,
      storables,
    }
  );

export function useTestTemplate() {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(testTemplate, {
    onSuccess: (_, { templateId }) => {
      queryClient.invalidateQueries(["template", templateId]);
      setFeedback(feedbackSchema.enum.success, actionsSchema.enum.test);
    },
  });
}
