import React, { useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { HStack, Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import HourInput, { FormHourType } from "core/components/HourInput/HourInput";
import {
  PreviewFormType,
  AdvancedSettingsHour,
} from "modules/create/schemas/formSchema";
import formatTimeInput from "modules/preview/helpers/formatTimeInput";
import { defaultAdvancedSettingsHour } from "modules/create/constants/defaultValues";

interface Props {
  name: AdvancedSettingsHour;
}

const HourForm = ({ name }: Props) => {
  const { t } = useTranslation();
  const context = useFormContext<PreviewFormType>();
  const fieldValue: string =
    context.watch(`advancedSettings.${name}`) || "00:00";
  const hours = useMemo(() => fieldValue.split(":")[0], [fieldValue]);
  const minutes = useMemo(() => fieldValue.split(":")[1], [fieldValue]);

  const handleChange = (input: string, type: FormHourType) => {
    const newFieldValue =
      type === "hours"
        ? `${formatTimeInput(input, type)}:${minutes}`
        : `${hours}:${formatTimeInput(input, type)}`;
    context.setValue(`advancedSettings.${name}`, newFieldValue);
  };

  return (
    <Flex direction="column" mr="15px">
      <Text as="label" fontSize="sm">
        {t(`preview.settings.advanced.timeRange.${name}` as const)}
      </Text>
      <Controller
        name={`advancedSettings.${name}`}
        control={context?.control}
        defaultValue={defaultAdvancedSettingsHour}
        render={({ ref }) => (
          <HStack>
            <HourInput
              type="hours"
              onChange={handleChange}
              ref={ref}
              value={hours}
            />
            <Text fontSize="lg" fontWeight={700}>
              :
            </Text>
            <HourInput
              type="minutes"
              onChange={handleChange}
              ref={ref}
              value={minutes}
            />
          </HStack>
        )}
      />
    </Flex>
  );
};

export default HourForm;
