import React, { useMemo } from "react";
import { Checkbox, CheckboxGroup, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { statusSchema } from "modules/preview/schemas/templateSchemas";
import useFilterContext from "modules/filters/hooks/useFilterContext";
import { themeSchema } from "modules/preview/schemas/templateSchemas";
import { FilterProps } from "modules/filters/components/SwitchFilter";

function CheckboxFilter<T extends object>({
  filterValue,
  setFilter,
  name,
}: FilterProps<T, string[]>) {
  const globalFilterValue = useFilterContext<T>();
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      name === "theme"
        ? themeSchema.options
        : globalFilterValue?.globalFilter?.archived
        ? statusSchema.options.filter((option) => option !== "ARCHIVED")
        : statusSchema.options,
    [name, globalFilterValue?.globalFilter]
  ) as string[];

  const translationKey = useMemo(
    () =>
      name === "theme"
        ? "preview.informations.theme"
        : "templates.status_values",
    [name]
  );

  if (!setFilter) {
    return null;
  }

  const filter = filterValue ?? [];

  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter = filter.includes(e.target.value)
      ? filter.filter((status: string) => status !== e.target.value)
      : [...filter, e.target.value];
    setFilter(newFilter);
  };

  return (
    <CheckboxGroup defaultValue={[]} value={filter}>
      <VStack spacing={0} alignItems="left" flexWrap="wrap" maxHeight="120px">
        {options.map((option) => (
          <Checkbox key={option} value={option} onChange={changeFilter}>
            {t(`${translationKey}.${option}` as any)}
          </Checkbox>
        ))}
      </VStack>
    </CheckboxGroup>
  );
}

export default CheckboxFilter;
