import React from "react";
import { createIcon } from "@chakra-ui/react";

const ArrowDownIcon = createIcon({
  displayName: "ArrowDownIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M20 12L18.59 10.59L13 16.17V4H11V16.17L5.42 10.58L4 12L12 20L20 12Z"
        fill="currentColor"
      />
    </>
  ),
});

export default ArrowDownIcon;
