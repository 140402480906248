import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/react";

import TargetIcon from "core/components/InterfaceIcons/TargetIcon";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { useIsConfigurator } from "modules/roles/hooks/useIsConfigurator";
import { useResetUserPassword } from "modules/user/informations/hooks/useResetUserPassword";

const ResetPasswordAction = () => {
  const { t } = useTranslation();
  const { setFeedback } = useFeedbackStore();
  const reset = useResetUserPassword();
  const isAdministrator = useIsAdministrator();
  const isConfigurator = useIsConfigurator();

  const disabled = !(isAdministrator || isConfigurator);

  const handleClick = useCallback(() => {
    setFeedback(
      feedbackSchema.enum.warning,
      actionsSchema.enum.resetPassword,
      () => reset.mutate({ reset_password: true })
    );
  }, [reset]);

  return (
    <Button
      onClick={handleClick}
      leftIcon={<TargetIcon />}
      variant="primary-solid"
      isDisabled={disabled}
    >
      {t("create_user.actions.reset")}
    </Button>
  );
};

export default ResetPasswordAction;
