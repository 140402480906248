import React, { ReactNode, SetStateAction } from "react";
import styled from "@emotion/styled";
import theme from "theme";

import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { ProbeStatus } from "./ProbeStatus";
import { Status } from "../types";
import { ProbeType } from "../schemas/formSchemas";

const ProbeName = styled.div`
  margin-inline: 0.5em;
  overflow: auto;
  text-align: left;
`;
const AccordionButtonStyled = styled(AccordionButton)<{
  isActiveItem: boolean;
}>`
  background-color: ${(props) =>
    props.isActiveItem ? theme.colors.silver_gray : ""};
`;

type Props = {
  child: ProbeType;
  setActiveItem: React.Dispatch<SetStateAction<ProbeType | null>>;
  getChildren: (child: ProbeType) => ReactNode;
  children: ProbeType;
  isActiveItem: boolean;
};

const DISPLAYABLE_PROBES_TYPE = [
  "PASSED",
  "FAILED",
  "ERROR",
  "WARNING",
  "DEACTIVATED",
];

export const ProbeItem = ({
  child,
  setActiveItem,
  isActiveItem,
  getChildren,
  children,
}: Props): JSX.Element | null => {
  if (DISPLAYABLE_PROBES_TYPE.includes(child?.status as Status)) {
    return (
      <AccordionItem>
        <AccordionButtonStyled
          isActiveItem={isActiveItem}
          onClick={() => setActiveItem(child)}
        >
          <ProbeStatus status={child?.status} />{" "}
          <ProbeName>{child?.title}</ProbeName>
          {child?.probes && <AccordionIcon />}
        </AccordionButtonStyled>
        {child?.probes && (
          <AccordionPanel>{getChildren(children)}</AccordionPanel>
        )}
      </AccordionItem>
    );
  }
  return null;
};
