function variantOutline(props: Record<string, any>) {
  return {
    field: {
      border: "1px solid",
      borderColor: "silver",
      bg: "inherit",
      _hover: {
        borderColor: "silver",
      },
      _focus: { borderColor: "malibu", borderWidth: "2px", boxShadow: "0px" },
    },
  };
}

const Input = {
  variants: {
    outline: variantOutline,
  },
};

export default Input;
