/**
 * @description
 * This function is used to forge the Okta URL for the user to login.
 *
 */
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";

const AUTHORIZE_URI: string =
  "https://groupecanalplus.okta.com/oauth2/auscwgsehyxO5zUux417/v1/authorize";
const CLIENT_ID: string = "0oaclg5f2i2rA5LlP417";
const SCOPES: string[] = ["openid", "profile", "email", "momagroups"];
const RANDOM_STRING_LENGTH = 8;
const RANDOM_CODE_VERIFIER_LENGTH = 44; // standard states that: 43 chars <= verifier <= 128 chars

const createRandomString = (length: number) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

export const generateStateAndNonce = () => {
  return {
    state: createRandomString(RANDOM_STRING_LENGTH),
    nonce: createRandomString(RANDOM_STRING_LENGTH),
  };
};

export const generatePKCEPair = () => {
  const codeVerifier = createRandomString(RANDOM_CODE_VERIFIER_LENGTH);
  const hash = Base64.stringify(sha256(codeVerifier));
  const challengeCode = hash
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, ""); // Clean base64 to make it URL safe
  return { codeVerifier, challengeCode };
};

interface OktaForgeUrlSchema {
  challengeCode: string;
  state: string;
  nonce: string;
}

export const forgeOKTAUrl = ({
  challengeCode,
  state,
  nonce,
}: OktaForgeUrlSchema) => {
  /*The code_challenge is a Base64URL-encoded SHA256 hash of the code_verifier.
   Your app saves the code_verifier for later, and sends the code_challenge along
    with the authorization request to your authorization server's /authorize URL.
*/

  const params = {
    client_id: CLIENT_ID,
    redirect_uri: window.location.origin + "/auth/cb",
    scope: SCOPES.join(" "),
    response_type: "code",
    response_mode: "query",
    code_challenge_method: "S256",
    code_challenge: challengeCode,
    state: state,
    nonce: nonce,
  };
  return `${AUTHORIZE_URI}?${new URLSearchParams(params).toString()}`;
};
