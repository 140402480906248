import * as z from "zod";
import { Status } from "../types";

export type ProbeType = {
  id: number | null;
  name: string;
  title: string;
  description: string;
  parent: number | null;
  last_run: string | null;
  value?: any;
  status: Status | null;
  enable?: number | null;
  error: string | null;
  categories: string[] | null;
  regions: string[] | null;
  roles: string[] | null;
  probes?: { [key: string]: ProbeType };
};

const Probe: z.ZodSchema<ProbeType> = z.object({
  id: z.number().nullable(),
  name: z.string(),
  title: z.string(),
  last_run: z.string().nullable(),
  value: z.any(),
  description: z.string(),
  parent: z.number().nullable(),
  status: z
    .enum([
      "PASSED",
      "FAILED",
      "DONE",
      "ERROR",
      "WARNING",
      "CANCELED",
      "DEACTIVATED",
    ])
    .nullable(),
  enable: z.number().optional(),
  error: z.string().nullable(),
  categories: z.array(z.string()),
  regions: z.array(z.string()),
  roles: z.array(z.string()),
  probes: z.lazy(() => z.record(Probe)).optional(),
});

export const Dashboard = z.object({
  category_id: z.number(),
  category_name: z.string(),
  dashboard_title: z.string(),
  is_folded: z.boolean(),
  probes: z.record(Probe),
});
export const dashboardSchema = z.array(Dashboard);

export type ProbesType = z.infer<typeof Probe>;
export type DashboardType = z.infer<typeof Dashboard>;
