import {
  actionsNameSchema,
  Actions,
} from "modules/shared/schemas/actionsSchemas";

const getGlobalsListActions = (
  status?: string,
  access?: Boolean
): Actions | undefined => {
  if (!access) {
    return;
  }
  if ((status === "DRAFT" || status === "REFUSED") && access) {
    return [actionsNameSchema.enum.write, actionsNameSchema.enum.delete];
  }
  return [actionsNameSchema.enum.read];
};

export default getGlobalsListActions;
