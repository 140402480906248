import { PreviewFormType } from "modules/create/schemas/formSchema";
import useColorScheme from "modules/preview/hooks/useColorScheme";
import { useFormContext } from "react-hook-form";
import { FALLBACK_COLOR } from "../constants/colors";
import { getDisplayColor } from "../helpers/getDisplayColor";
import { ColorParam } from "modules/preview/schemas/templateSchemas";
import useLayout from "modules/preview/hooks/useLayout";
import getColorsFromLayout from "modules/preview/helpers/getColorsFromLayout";
import { FormColors } from "modules/create/schemas/formSchema";

export default function useTemplateColors() {
  const context = useFormContext<PreviewFormType>();
  const formColors = context.watch("colors") as FormColors;
  const layout = useLayout();
  const colorScheme = useColorScheme();
  const extraBanner = context.watch("extraBanner") as boolean;

  if (layout === "empty") {
    return undefined;
  }
  // we filter the colors to display according to the selected layout
  const colors = getColorsFromLayout(layout!, formColors, extraBanner);

  const getColor = (colorIndex?: number) =>
    colorScheme && typeof colorIndex !== "undefined"
      ? getDisplayColor(colorIndex, colorScheme.colors)
      : FALLBACK_COLOR;

  return Object.entries(colors).map(([key, value]) => [
    key,
    getColor(Number(value)),
  ]) as [ColorParam, string][];
}
