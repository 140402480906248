import React, { useLayoutEffect } from "react";
import { PATHS } from "modules/shared/constants/paths";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { baseToast } from "modules/shared/helpers/baseToast";

const RedirectToHome = () => {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    baseToast({
      id: "REDIRECT_INFO",
      title: t("infos.redirect.title"),
      status: "info",
    });
  }, []);

  return <Redirect to={PATHS.HOME} />;
};

export default RedirectToHome;
