import useColorScheme from "modules/preview/hooks/useColorScheme";
import { useFormContext } from "react-hook-form";
import { FALLBACK_COLOR } from "../constants/colors";
import { getDisplayColor } from "../helpers/getDisplayColor";
import { ColorParam } from "modules/preview/schemas/templateSchemas";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import useTemplate from "modules/preview/hooks/useTemplate";
import getHexColorFromMomaColor from "modules/templates/helpers/getHexColorFromMomaColor";

export default function useColor(colorParam: ColorParam | string) {
  const colorScheme = useColorScheme();
  const context = useFormContext<PreviewFormType>();
  const { data } = useTemplate(false);
  if (!colorScheme) {
    return FALLBACK_COLOR;
  }

  const colorIndex = context
    ? context.watch("colors")?.[colorParam as ColorParam]
    : data?.technical_details[colorParam as ColorParam];

  if (!colorIndex) {
    return getHexColorFromMomaColor(colorParam);
  }
  return getDisplayColor(Number(colorIndex), colorScheme.colors);
}
