import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { templateSchema } from "modules/preview/schemas/templateSchemas";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";

export const getTemplate = (id?: string) =>
  id
    ? simpleRequest(generatePath(ENDPOINTS.TEMPLATE, { id }), templateSchema)
    : undefined;

export default function useTemplate(fetch = true, templateId?: string) {
  const { id } = useParams<RouteIdParam>();
  const history = useHistory();
  // redirect to create template if id is a string
  if (id && isNaN(Number(id))) {
    history.push("/create?step=1");
  }

  const templateToFetch = templateId || id;

  if (templateToFetch && isNaN(Number(templateToFetch))) {
    history.push("/create?step=1");
  }

  return useQuery(
    ["template", Number(templateToFetch)],
    () => getTemplate(templateToFetch),
    {
      enabled: fetch && !isNaN(Number(templateToFetch)),
      suspense: true,
    }
  );
}
