import { useParams } from "react-router-dom";

import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import useColorSchemes from "./useColorSchemes";

export default function useCustomColorScheme() {
  const { id } = useParams<RouteIdParam>();
  const { data, ...rest } = useColorSchemes();
  const currentColorScheme = data?.find(
    (colorScheme) => colorScheme.id === Number(id)
  );

  return { ...rest, data: currentColorScheme };
}
