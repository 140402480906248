import React, { ReactNode } from "react";
import { AccordionButton, Box, AccordionIcon } from "@chakra-ui/react";

const AccordionTitle = ({
  children,
  error = false,
}: {
  children: ReactNode;
  error?: boolean;
}) => {
  return (
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left" color={error ? "error" : "inherit"}>
          {children}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
  );
};

export default AccordionTitle;
