import React, { useCallback, ChangeEvent } from "react";
import { Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import sanitizeLocale from "../helpers/sanitizeLocale";
import { Locale, localeSchema } from "../schemas";
import LanguageIcon from "core/components/InterfaceIcons/LanguageIcon";

const LocaleSelect = () => {
  const { t, i18n } = useTranslation();
  const locale = sanitizeLocale(i18n.language);

  const handleLocaleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const newLocale = e.target.value as Locale;
      i18n.changeLanguage(newLocale);
    },
    [i18n]
  );
  return (
    <Select
      value={locale}
      onChange={handleLocaleChange}
      data-testid="locale-select"
      variant="tertiary-ghost"
      colorScheme="dark"
      bg="dark"
      icon={<LanguageIcon />}
    >
      {localeSchema.options.map((locale) => (
        <option value={locale} key={locale}>
          {locale === "fr" ? ` ${t("menu.french")}` : `${t("menu.english")} `}
        </option>
      ))}
    </Select>
  );
};

export default LocaleSelect;
