import React from "react";
import {
  HStack,
  Button,
  Heading,
  Flex,
  Divider,
  Center,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import LayoutForm from "./forms/LayoutForm";
import ColorSchemeForm from "./forms/ColorSchemeForm";
import BannerForm from "./forms/BannerForm";
import HeadingNumber from "core/components/HeadingNumber/HeadingNumber";

const CreateForm = () => {
  const { t } = useTranslation();

  return (
    <Box data-testid="create-form" py={10}>
      <Heading as="h3" variant="h3" textAlign="left" display="flex">
        <HeadingNumber number="1" /> {t("create.title_layout")}
      </Heading>

      <Flex alignItems="flex-start">
        <LayoutForm />
        <Center height="560px" mr={10}>
          <Divider orientation="vertical" />
        </Center>
        <BannerForm />
      </Flex>

      <Divider orientation="horizontal" borderColor="silver" my={10} />

      <Heading as="h3" variant="h3" textAlign="left" display="flex">
        <HeadingNumber number="2" />
        {t("create.title_scheme")}
      </Heading>

      <ColorSchemeForm />
      <HStack justifyContent="flex-end" spacing={4} mt={10} mb={10}>
        <Button
          as={RouterLink}
          to="/"
          leftIcon={<ChevronLeftIcon />}
          variant="tertiary-ghost"
        >
          {t("create.cancel")}
        </Button>
        <Button
          variant="primary-solid"
          as={RouterLink}
          to="/create?step=2"
          rightIcon={<ChevronRightIcon />}
        >
          {t("create.configure")}
        </Button>
      </HStack>
    </Box>
  );
};

export default CreateForm;
