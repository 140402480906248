import * as z from "zod";
import { regionsListBodySchema } from "modules/regions/schemas/regionsSchemas";

export type GlobalMessageFormValues = z.infer<typeof globalMessageFormSchema>;

export const country = z.object({
  id: z.number(),
  regions: z.array(z.string()),
  code: z.string(),
  name: z.string(),
});

export const globalMessageFormSchema = z
  .object({
    id: z.number().optional(),
    title: z.string().nonempty(),
    template_id: z.number(),
    regions: regionsListBodySchema.nonempty().max(1),
    created_at: z.string().optional(),
    start_date: z.string().or(z.date()),
    end_date: z.string().or(z.date()),
    status: z.string().optional(),
    filters: z.object({
      card_valid: z.boolean().optional(),
      countries_on: z.array(z.string()).optional(),
      countries_off: z.array(z.string()).optional(),
      products_on: z.array(z.number()).optional(),
      products_off: z.array(z.number()).optional(),
    }),
  })
  .nonstrict();

export const baseGlobalMessage = z
  .object({
    title: z.string(),
    template_id: z.number(),
    regions: regionsListBodySchema.nonempty(),
    start_date: z.date(),
    end_date: z.date(),
    filters: z.object({
      card_valid: z.boolean(),
      countries_on: z.array(z.string()).optional(),
      countries_off: z.array(z.string()).optional(),
    }),
  })
  .nonstrict();

export type DraftGlobalMessage = z.infer<typeof baseGlobalMessage>;

export const globalMessageSchema = z
  .object({
    id: z.number(),
    title: z.string(),
    template_id: z.number(),
    regions: regionsListBodySchema.nonempty(),
    created_at: z.string(),
    start_date: z.string().or(z.date()),
    end_date: z.string().or(z.date()),
    status: z.string(),
    filters: z.object({
      card_valid: z.boolean().optional(),
      countries_on: z.array(z.string()).optional(),
      countries_off: z.array(z.string()).optional(),
      products_on: z.array(z.number()).optional(),
      products_off: z.array(z.number()).optional(),
    }),
  })
  .nonstrict();

export type GlobalMessage = z.infer<typeof globalMessageSchema>;

export const globalMessagesColumnNameSchema = z.enum([
  "id",
  "template_id",
  "title",
  "start_date",
  "end_date",
  "created_at",
  "regions",
  "status",
  "actions",
]);

export type GlobalMessageColumnName = z.infer<
  typeof globalMessagesColumnNameSchema
>;

export const globalMessagedListSchema = z.array(globalMessageSchema);
