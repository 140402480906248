import * as z from "zod";

export const methodSchema = z.enum([
  "get",
  "put",
  "post",
  "delete",
  "options",
  "head",
  "patch",
  "trace",
]);

export type Method = z.infer<typeof methodSchema>;
