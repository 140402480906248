import * as z from "zod";

import {
  templatesFilterNameSchema,
  templatesOptionalFilterNameSchema,
} from "modules/templates/schemas/templateViewSchema";
import { usersFilterNameSchema } from "modules/users/schemas/usersViewSchemas";
import { FilteredTable } from "modules/filters/schemas/filterSchemas";
import { GlobalFilter } from "modules/filters/context/filterContext";

interface FilterConfigOptions {
  defaultGlobalFilters: GlobalFilter | undefined;
  translationKey: FilteredTable;
  schema: z.ZodEnum<[string, ...string[]]>;
  optionalSchema: z.ZodEnum<[string, ...string[]]> | undefined;
}
type FilterConfig = {
  [key in FilteredTable]: FilterConfigOptions;
};

const filterConfig: FilterConfig = {
  templates: {
    defaultGlobalFilters: {
      keyword: "",
      archived: true,
    },
    schema: templatesFilterNameSchema,
    optionalSchema: templatesOptionalFilterNameSchema,
    translationKey: "templates",
  },
  users: {
    defaultGlobalFilters: undefined,
    schema: usersFilterNameSchema,
    optionalSchema: undefined,
    translationKey: "users",
  },
};

export default filterConfig;
