import { useMutation } from "react-query";
import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import {
  bulkMessageSchema,
  SendBulkMessagePayload,
} from "../schemas/bulkMessageSchema";

export const sendBulkMessage = (message: SendBulkMessagePayload) =>
  simpleRequest(
    ENDPOINTS.MAIL,
    bulkMessageSchema,
    methodSchema.enum.post,
    message,
    true
  );

export function useSendBulkMessage() {
  return useMutation(sendBulkMessage);
}
