import { useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { RefuseTemplate } from "modules/validate/schemas/validateSchemas";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

const refuseTemplate = ({ id, comment }: RefuseTemplate) =>
  simpleRequest(
    generatePath(ENDPOINTS.REFUSE, { id }),
    emptySchema,
    methodSchema.enum.post,
    {
      comment,
    }
  );

export function useRefuseTemplate() {
  const queryClient = useQueryClient();
  const { reset } = useFeedbackStore();
  return useMutationWithErrorHandling(refuseTemplate, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(["template", id]);
      queryClient.invalidateQueries(["templates"]);
      queryClient.invalidateQueries(["comments", id]);
      reset();
    },
  });
}
