import React, { useMemo, useCallback } from "react";
import { Table, Tbody, Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column, Cell, useFilters } from "react-table";

import TableHeader from "modules/shared/components/Table/TableHeader";
import TableRow from "modules/shared/components/Table/TableRow";
import TableCell from "modules/shared/components/Table/TableCell";

import filterRowsByName from "modules/filters/helpers/filterRowsByName";
import {
  MessageType,
  MessagesColumnName,
  messagesColumnNameSchema,
} from "../Schemas/InspectionMessagesSchemas";
import PreviewMessageModal from "./PreviewMessageModal";

interface Props {
  messages?: MessageType[];
}
const emptyArray: never[] = [];
const MessagesTable = ({ messages }: Props) => {
  const { t, i18n } = useTranslation();

  const getHeaderName = useCallback(
    (name: MessagesColumnName) => <Box minW={24}>{name}</Box>,
    [i18n.language]
  );

  const columns = useMemo(
    () =>
      messagesColumnNameSchema.options.map((name) => ({
        Header: <Box minW={24}>{name}</Box>,
        accessor: name,
        Cell: ({
          cell: { value, row },
        }: {
          cell: Cell<MessageType, string | string[]>;
        }) =>
          name === messagesColumnNameSchema.enum.action ? (
            <PreviewMessageModal
              message={messages?.find(
                (message) => message.campaign_id === row.values.campaign_id
              )}
            />
          ) : (
            <TableCell value={value ?? "N/A"} />
          ),
        filter: (...args) => filterRowsByName(...args, name),
      })),
    [getHeaderName, messages]
  ) as Column<MessageType>[];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: messages || emptyArray,
      },
      useFilters,
      useSortBy
    );

  if (messages?.length === 0) {
    return (
      <Flex mt={20} d="column">
        <Text m="20px" align="center">
          {t("supervision.no_message")}
        </Text>
      </Flex>
    );
  }

  return (
    <>
      <Box position="relative" marginTop="40px"></Box>
      <Box overflow="auto" pt={5} pb={10}>
        <Table
          {...getTableProps()}
          data-testid="users-table"
          textStyle="bodySmall"
          w="100%"
          min-width="150px"
        >
          <TableHeader headerGroups={headerGroups} />
          <Tbody {...getTableBodyProps()} bgColor="white">
            {rows.map((row) => {
              prepareRow(row);
              return <TableRow key={row.id} row={row} />;
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default MessagesTable;
