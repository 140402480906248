import { useQueryClient } from "react-query";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

export const archiveTemplate = (templateId: number) =>
  simpleRequest(
    generatePath(ENDPOINTS.ARCHIVE, { id: templateId }),
    emptySchema,
    methodSchema.enum.post
  );

export function useArchiveTemplate() {
  const queryClient = useQueryClient();
  return useMutationWithErrorHandling(archiveTemplate, {
    onSuccess: (_, templateId) => {
      queryClient.invalidateQueries(["template", templateId]);
      queryClient.invalidateQueries(["templates"]);
    },
  });
}
