import React from "react";
import { Box, Flex, Text, chakra } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import formatDate from "modules/i18n/helpers/formatDate";
import { Locale } from "modules/i18n/schemas";

import { Comment as CommentInterface } from "modules/comments/schemas/commentsApiSchemas";

const Comment = ({ author, comment, date }: CommentInterface) => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const commentDate = formatDate(date, language as Locale);

  return (
    <Box mt="20px" pb="10px" _notLast={{ borderBottom: "1px solid silver" }}>
      <Flex alignItems="baseline">
        <Text fontWeight={700}>{author}</Text>
        <chakra.span ml="5px" mr="5px" fontWeight={700}>
          .
        </chakra.span>
        <chakra.span fontSize="xs" ml="5px" fontWeight={300} color="abbey">
          {commentDate}
        </chakra.span>
      </Flex>
      <Text lineHeight="22px" mt="10px" mb="10px">
        {comment}
      </Text>
    </Box>
  );
};

export default Comment;
