import * as z from "zod";

export const qrcodeFormSchema = z.object({
  id: z.number().optional(),
  size: z.number().optional(),
  name: z.string().optional(),
  target_url: z.string().optional(),
  short_url: z.string().optional(),
  shortener: z.string().nullable().optional(),
  description: z.string(),
  regions: z.string().array().optional(),
  image_id: z.number().optional(),
});

export const qrcodeBodySchema = z.array(qrcodeFormSchema);

export const qrcodesTableSchema = z.enum([
  "id",
  "name",
  "description",
  "regions",
  "actions",
]);

export type QrcodeColumnName = z.infer<typeof qrcodesTableSchema>;
export type QrcodesListBody = z.infer<typeof qrcodeBodySchema>;
export type QrcodeBody = z.infer<typeof qrcodeFormSchema>;
export enum Mode {
  EDIT = "EDIT",
  CREATE = "CREATE",
}

export enum TableActionMode {
  SHOW_QR_CODE = "SHOW_QR_CODE",
  SHOW_FORM = "SHOW_FORM",
}
