import { useQuery } from "react-query";

import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { qrcodeBodySchema } from "../schemas/customQrcodeSchema";
import { transformQrcodeList } from "../helpers/transformQrcodeList";
import { simpleRequest } from "core/services/api";

export const fetchQrcodes = () =>
  simpleRequest(ENDPOINTS.QR_CODES, qrcodeBodySchema);

export function useQrcodes(fetch = true) {
  return useQuery(["qrcodes"], fetchQrcodes, {
    select: (data) => transformQrcodeList(data),
    enabled: fetch,
    suspense: true,
  });
}
