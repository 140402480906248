import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";

interface Props {
  messageText: string;
  retryText?: string;
  reset?: () => void;
}

const ErrorMessage = ({ reset, messageText, retryText }: Props) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    mt="40px"
    data-testid="error"
  >
    <Text textAlign="center">{messageText}</Text>
    {retryText && reset && (
      <Button type="submit" variant="primary-solid" mt="30px" onClick={reset}>
        {retryText}
      </Button>
    )}
  </Flex>
);

export default ErrorMessage;
