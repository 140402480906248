import * as z from "zod";

import {
  backgroundColorParamSchema,
  borderColorParamSchema,
  textColorParamSchema,
  imageParamSchema,
  textParamSchema,
  TemplateLayoutType,
  alignmentParamSchema,
  alignmentSchema,
  LayoutEmpty,
} from "./templateSchemas";

// Font sizes
const fontTypeSchema = z.enum(["Large", "Medium"]);

export type FontType = z.infer<typeof fontTypeSchema>;

const fontSpecSchema = z.object({ Large: z.string(), Medium: z.string() });

export type FontSpec = z.infer<typeof fontSpecSchema>;

export const textObjectClassSchema = z.enum(["OT1", "OT2", "OT3", "OT5"]);

export type TextObject = z.infer<typeof textObjectClassSchema>;

const backgroundObjectClassSchema = z.enum([
  "OB",
  "OF0",
  "OF1",
  "OF2",
  "OF3",
  "OF5",
]);
const imageObjectClassSchema = z.enum(["OIMA1", "OIMA2", "OIMA3"]);
export const bannerPositionSchema = z.enum(["top", "bottom", "center"]);
export type BannerPosition = z.infer<typeof bannerPositionSchema>;

const layoutObjectSchema = z.object({
  fullScreen: z.boolean(),
  textAlign: alignmentSchema,
  position: bannerPositionSchema.optional(),
  singleTitleLine: z.boolean().optional(),
  separatedTitle: z.boolean().optional(),
});

export const foregroundColorParamSchema = z.union([
  textColorParamSchema,
  borderColorParamSchema,
]);

const abstractObjectSchema = z.object({
  x: z.number(),
  y: z.number(),
  w: z.number(),
  h: z.number(),
});

export const imageFrameSchema = abstractObjectSchema.extend({
  name: imageObjectClassSchema,
  content: z.union([imageParamSchema, z.string()]),
});

export const textFrameSchema = abstractObjectSchema.extend({
  name: textObjectClassSchema,
  backgroundColor: backgroundColorParamSchema,
  foregroundColor: z.union([foregroundColorParamSchema, z.string()]),
  size: fontTypeSchema,
  text: textParamSchema.optional(),
  textAlign: alignmentParamSchema,
  maxLength: z.number(),
  style: z.record(z.string()),
});

export const backgroundFrameSchema = abstractObjectSchema.extend({
  name: backgroundObjectClassSchema,
  backgroundColor: backgroundColorParamSchema,
  foregroundColor: foregroundColorParamSchema,
});

const frameSchema = z.union([
  backgroundFrameSchema,
  imageFrameSchema,
  textFrameSchema,
]);

const framesSchema = z.array(frameSchema);

export type Frames = z.infer<typeof framesSchema>;

const framesObjectSchema = z.object({
  full: framesSchema.optional(),
  banner: framesSchema.optional(),
});

const layoutSchema = z.object({
  layout: layoutObjectSchema,
  frames: framesObjectSchema,
});

export type BackgroundFrame = z.infer<typeof backgroundFrameSchema>;
export type TextFrame = z.infer<typeof textFrameSchema>;
export type ImageFrame = z.infer<typeof imageFrameSchema>;
export type FrameObject = z.infer<typeof frameSchema>;

export type Layout = z.infer<typeof layoutSchema>;

export type EmptyLayoutSpec = Record<LayoutEmpty, {}>;
export type NonEmptyLayoutSpec = Record<TemplateLayoutType, Layout>;

export type LayoutSpec = EmptyLayoutSpec | NonEmptyLayoutSpec;
