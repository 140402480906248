import React, { useState, useMemo, useEffect } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useFormContext } from "react-hook-form";

import { PreviewFormType } from "modules/create/schemas/formSchema";
import Panel from "./Panel";
import {
  layoutFormTypeSchema,
  layoutHybridTypeSchema,
} from "../schemas/templateSchemas";
import useLayout from "../hooks/useLayout";
import { frameHeight, frameWidth } from "../constants/frameDimensions";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import useIsEmptyLayout from "../hooks/useIsEmptyLayout";
interface FrameLayoutProps {
  index: number;
}

const StyledPanel = styled(TabPanel)`
  width: ${frameWidth}px;
  height: ${frameHeight}px;
  position: relative;
  margin-top: 20px;
`;

const StyledBox = styled(Box)`
  width: ${frameWidth}px;
  height: ${frameHeight + 10}px;
  position: relative;
`;

export type FrameProps<T> = FrameLayoutProps & { frame: T };

const Frames = () => {
  const { t } = useTranslation();
  const layout = useLayout();
  const [tabIndex, setTabIndex] = useState(1);
  const context = useFormContext<PreviewFormType>();
  const { setFeedback } = useFeedbackStore();
  const isEmptyLayout = useIsEmptyLayout();
  const isAdmin = useIsAdministrator();

  useEffect(() => {
    if (isEmptyLayout && !isAdmin) {
      setFeedback(
        feedbackSchema.enum.error,
        actionsSchema.enum.editEmptyLayout
      );
    }
  }, [isAdmin, isEmptyLayout]);
  useEffect(() => {
    if (layout !== layoutFormTypeSchema.enum.full4 && context) {
      setTabIndex(1);
    }
  }, [layout]);

  const isHybrid = useMemo(
    () => layoutHybridTypeSchema.check(layout),
    [layout]
  );
  if (!layout) {
    return null;
  }

  return context || isHybrid ? (
    <Tabs
      isLazy
      w={720}
      align="end"
      onChange={(index) => setTabIndex(index)}
      data-testid="frames"
      index={tabIndex}
    >
      <TabList>
        <Tab isDisabled={!isHybrid}>{t("preview.extra_banner")} </Tab>
        <Tab tabIndex={-1}>{t("preview.layout")}</Tab>
      </TabList>
      <TabPanels>
        <StyledPanel>{isHybrid && <Panel view="banner" />}</StyledPanel>
        <StyledPanel>
          <Panel />
        </StyledPanel>
      </TabPanels>
    </Tabs>
  ) : (
    <StyledBox>
      <Panel />
    </StyledBox>
  );
};

export default Frames;
