import { Storables } from "modules/preview/schemas/templateSchemas";

const getStorablesDetailsFromForm = (
  storables?: { value: string }[]
): Storables | null => {
  if (!storables || storables.length === 0) {
    return null;
  }
  const keyPairs = storables.map((storable, index) => [
    `S${index}`,
    storable.value,
  ]);
  return Object.fromEntries(keyPairs);
};

export default getStorablesDetailsFromForm;
