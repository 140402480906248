import { useQueryClient } from "react-query";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { UserFromList } from "modules/users/schemas/usersModelSchemas";
import { updateUserApiSchema } from "../schemas/userApiSchema";

export const saveUser = (user: UserFromList) =>
  simpleRequest(
    generatePath(ENDPOINTS.USER, { id: user.id! }),
    updateUserApiSchema,
    methodSchema.enum.patch,
    user
  );

export function useSaveUser() {
  const queryClient = useQueryClient();
  return useMutationWithErrorHandling(saveUser, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(["user", id]);
      queryClient.invalidateQueries(["users"]);
    },
  });
}
