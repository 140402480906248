import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import useTemplateStatus from "modules/preview/hooks/useTemplateStatus";
import LiveTvIcon from "core/components/InterfaceIcons/LiveTvIcon";

const TestAction = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const status = useTemplateStatus();
  const isDisabled = !status || status === "ARCHIVED";

  return (
    <Button
      as={isDisabled ? undefined : RouterLink}
      to={`/test/${id}`}
      leftIcon={<LiveTvIcon />}
      variant="primary-solid"
      disabled={isDisabled}
    >
      {t("actions.test.button")}
    </Button>
  );
};

export default TestAction;
