import React, { forwardRef, MutableRefObject, useEffect } from "react";
import { Textarea, useEditableState, StyleProps } from "@chakra-ui/react";

import resetCSS from "modules/preview/constants/resetCSS";

interface Props {
  onChange: () => void;
  onBlur: () => void;
  value: string;
  name: string;
  style: StyleProps;
  placeholder: string;
  maxLength?: number;
}

const EditableTextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ style, maxLength, ...rest }, ref) => {
    const { isEditing } = useEditableState();
    const sx = {
      ...style,
      display: isEditing ? "block" : "none",
    };
    useEffect(() => {
      if ((ref as MutableRefObject<HTMLTextAreaElement>).current) {
        (ref as MutableRefObject<HTMLTextAreaElement>).current.focus();
      }
    }, [isEditing]);
    return (
      <Textarea
        {...rest}
        maxLength={maxLength}
        sx={sx}
        ref={ref}
        _focus={resetCSS}
      />
    );
  }
);

export default EditableTextArea;
