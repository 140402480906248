import React from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/react";

import { RouteIdParam } from "modules/shared/schemas/paramsSchema";

const DuplicateAction = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();

  return (
    <Button
      variant="link-button"
      colorScheme="dark"
      as={Link}
      to={`/create?step=2&id=${id}`}
    >
      {t("actions.duplicate.button")}
    </Button>
  );
};

export default DuplicateAction;
