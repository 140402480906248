import React from "react";
import { UseRadioProps, useRadio, VStack } from "@chakra-ui/react";
import getTextAlignmentIcon from "modules/preview/helpers/getTextAlignmentIcon";
import { Alignment } from "modules/preview/schemas/templateSchemas";

const IconAlignmentButton = (props: UseRadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const Icon = getTextAlignmentIcon(props.value as Alignment);

  return (
    <VStack as="label" spacing={0}>
      <input {...input} />
      <Icon
        {...checkbox}
        cursor="pointer"
        _hover={{ color: "white" }}
        color="silver"
        _checked={{
          color: "white",
        }}
      />
    </VStack>
  );
};

export default IconAlignmentButton;
