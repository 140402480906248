const Radio = {
  baseStyle: {
    control: {
      borderColor: "abbey",

      _checked: {
        bg: "amaranth",
        border: "none",
        _hover: {
          bg: "monza",
        },
      },

      _disabled: {
        bg: "silver",
        border: "silver",
      },
    },
  },
};
export default Radio;
