import { generatePath } from "react-router";
import { methodSchema } from "core/schemas/http";
import { simpleRequest } from "core/services/api";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  feedbackSchema,
  actionsSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";

export const validationGlobal = (globalId: number) =>
  simpleRequest(
    generatePath(ENDPOINTS.VALIDATION, { id: globalId }),
    emptySchema,
    methodSchema.enum.post
  );

export function useValidationGlobal() {
  const { setFeedback } = useFeedbackStore();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutationWithErrorHandling(validationGlobal, {
    onSuccess: (data: any, globalId) => {
      queryClient.invalidateQueries(["global", globalId]);
      queryClient.invalidateQueries(["globals"]);
      history.push(`/global/${globalId}`);

      setFeedback(feedbackSchema.enum.success, actionsSchema.enum.validate);
    },
  });
}
