import React from "react";
import { components } from "react-select";
import { NoticeProps } from "react-select/src/components/Menu";

const NoOptions = (props: NoticeProps<any, false>) => (
  <components.NoOptionsMessage {...props}>
    {props.selectProps.noOptions}
  </components.NoOptionsMessage>
);

export default NoOptions;
