import {
  Actions,
  actionsNameSchema,
} from "modules/shared/schemas/actionsSchemas";
import {
  UserTestCard,
  UserTestCardsBody,
} from "modules/user/cards/schemas/userTestCardsApiSchemas";

const userTestCardsTransform = (data: UserTestCard[]): UserTestCardsBody =>
  data.map((card) => ({
    ...card,
    actions: [
      actionsNameSchema.enum.write,
      actionsNameSchema.enum.delete,
    ] as Actions,
  }));

export default userTestCardsTransform;
