import React from "react";
import { Flex, useRadioGroup, VStack, Text, Button } from "@chakra-ui/react";

import RadioCard from "modules/create/components/ui/RadioCard";
import { Link as RouterLink } from "react-router-dom";
import useColorSchemes from "modules/colorSchemes/hooks/useColorSchemes";
import { PATHS } from "modules/shared/constants/paths";
import getHexColorFromMomaColor from "modules/templates/helpers/getHexColorFromMomaColor";
import { radioCardSizeSchema } from "modules/create/schemas/formSchema";
import { generatePath } from "react-router";
import PlusIcon from "core/components/InterfaceIcons/PlusIcon";
import { useTranslation } from "react-i18next";

const ColorSchemePanel = () => {
  const { data } = useColorSchemes();
  const { getRadioProps } = useRadioGroup();
  const { t } = useTranslation();
  return (
    <Flex direction="column">
      <Button
        width="auto"
        alignSelf="flex-end"
        variant="primary-solid"
        my="10px"
        as={RouterLink}
        to="/administration/color_schemes"
        leftIcon={<PlusIcon />}
      >
        {t("assets.color_scheme.create")}
      </Button>
      <Flex flexWrap="wrap">
        {data?.map(({ id, colors, name, description }) => {
          const radio = getRadioProps({
            value: id.toString(),
          });
          return (
            <Flex
              key={id}
              direction="column"
              justifyContent="space-between"
              my="10px"
              mx="20px"
              width="150px"
            >
              <RadioCard
                key={id}
                {...radio}
                size={radioCardSizeSchema.enum.largeRounded}
                roundedColors={[
                  getHexColorFromMomaColor(colors.color_6),
                  getHexColorFromMomaColor(colors.color_12),
                ]}
              >
                <VStack maxWidth="150px">
                  <Text fontSize="sm" marginRight="auto">
                    {name}
                  </Text>
                  <Text fontSize="x-small">{description}</Text>
                </VStack>
              </RadioCard>
              <Button
                width="150px"
                height="28px"
                variant="tertiary-ghost"
                mt="5px"
                borderRadius="sm"
                as={RouterLink}
                to={generatePath(PATHS.COLORSCHEME, {
                  id,
                })}
              >
                {t("assets.color_scheme.edit")}
              </Button>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default ColorSchemePanel;
