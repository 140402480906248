import { useQuery } from "react-query";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { globalMessageSchema } from "../schemas/globalMessageFormSchema";

import useLocationQueryParams from "core/hooks/useLocationQueryParams";

export const getGlobal = (id?: number) =>
  id
    ? simpleRequest(generatePath(ENDPOINTS.GLOBAL, { id }), globalMessageSchema)
    : undefined;

export default function useDuplicateGlobal() {
  const query = useLocationQueryParams();
  const id = query?.has("id") ? Number(query.get("id")) : undefined;
  return useQuery(["global", id], () => getGlobal(id), {
    enabled: !!id,
    suspense: true,
  });
}
