import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

import Colors from "modules/preview/components/Colors";
import ColorSchemeForm from "modules/create/components/forms/ColorSchemeForm";
import { useTranslation } from "react-i18next";

const ColorSchemes = () => {
  const { t } = useTranslation();
  return (
    <Tabs isLazy h="100%" p={0}>
      <TabList>
        <Tab>{t("preview.colorScheme.colorSchemes")}</Tab>
        <Tab>{t("preview.colorScheme.customize")}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel p={0}>
          <ColorSchemeForm />
        </TabPanel>
        <TabPanel p={0}>
          <Colors />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ColorSchemes;
