import React from "react";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Link as RouterLink } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";
import { PATHS } from "modules/shared/constants/paths";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";

const CreateTemplateButton = () => {
  const { t } = useTranslation();
  const hasRightToCreateGlobal = useHasAccessRights(
    PATHS.CREATE_GLOBALMESSAGE,
    ["POST"]
  );

  return hasRightToCreateGlobal ? (
    <Button
      variant="primary-solid"
      as={RouterLink}
      leftIcon={<AddIcon />}
      data-testid="create-button"
      to="/globals/create"
    >
      {t("global_message.create")}
    </Button>
  ) : null;
};

export default CreateTemplateButton;
