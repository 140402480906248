import React from "react";
import { FormHelperText } from "@chakra-ui/react";

interface Props {
  text: string;
}

const InputError = ({ text }: Props) => (
  <FormHelperText fontSize="xs" color="error" h="25px" mt="5px">
    {text}
  </FormHelperText>
);

export default InputError;
