import * as z from "zod";

export const templateColumnNameSchema = z.enum([
  "template_id",
  "label",
  "template_name",
  "creation_date",
  "theme",
  "author",
  "regions",
  "status",
  "actions",
]);

export type TemplateColumnName = z.infer<typeof templateColumnNameSchema>;

export const templatesFilterNameSchema = z.enum([
  "template_name",
  "label",
  "author",
  "regions",
  "status",
  "archived",
]);

export const templatesOptionalFilterNameSchema = z.enum([
  "template_id",
  "creation_date",
  "theme",
  "keyword",
]);
