import { ManageQrcodeFormValues } from "../schemas/customImageSchema";

export const getUpdatedQrcodeFromFormValues = (
  dataImage: ManageQrcodeFormValues,
  regions?: string[]
) => {
  // if all regions are selected we wants to send an empty array
  dataImage.regions =
    dataImage.regions.length === regions?.length ? [] : dataImage.regions;
  return dataImage;
};
