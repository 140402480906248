import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useTheme,
} from "@chakra-ui/react";
import { ColumnInstance, IdType, Row } from "react-table";
import { useTranslation } from "react-i18next";

import useFilterContext from "modules/filters/hooks/useFilterContext";
import useFilterConfig from "modules/filters/hooks/useFilterConfig";
import { FiltersTypes } from "./FiltersTypes";

interface Props<T extends object> {
  column: Partial<ColumnInstance<T>>;
}

export interface FilterProps<T extends object, U extends any> {
  filterValue?: U;
  setFilter?: (filterValue: U) => void;
  preFilteredRows?: Row<T>[];
  name?: IdType<T>;
}

function SwitchFilter<T extends object>({
  column: { id: name, filterValue, setFilter, preFilteredRows },
}: Props<T>) {
  const { t } = useTranslation();
  const [highlighted, setHighlighted] = useState(false);
  const globalFilterValue = useFilterContext<T>();
  const { translationKey } = useFilterConfig();
  const { colors } = useTheme();
  const activeFilters = globalFilterValue?.filters;

  const hasFilterValue = useMemo(
    () => (Array.isArray(filterValue) ? filterValue.length > 0 : filterValue),
    [filterValue]
  );

  const isGlobalFilterActive = useMemo(
    () =>
      !globalFilterValue?.globalFilter || !name
        ? false
        : !!globalFilterValue?.globalFilter[name] && name !== "archived",
    [name, globalFilterValue?.globalFilter]
  );

  //archived templates are displayed when the filter is set to false, therefore when need to check this condition to highlight the button
  const isFilteringArchivedTemplate = useMemo(
    () =>
      globalFilterValue?.globalFilter &&
      name &&
      !globalFilterValue?.globalFilter[name] &&
      name === "archived",
    [globalFilterValue?.globalFilter, name]
  );

  const isFilterActive = useMemo(
    () =>
      !activeFilters || !activeFilters.length
        ? false
        : activeFilters.some((filter) => filter.id === name),
    [name, activeFilters]
  );

  useEffect(() => {
    if (
      (hasFilterValue && isFilterActive) ||
      isGlobalFilterActive ||
      isFilteringArchivedTemplate
    ) {
      setHighlighted(true);
    } else {
      setHighlighted(false);
    }
  }, [
    hasFilterValue,
    isFilterActive,
    isGlobalFilterActive,
    isFilteringArchivedTemplate,
  ]);

  const displayName = t(`${translationKey}.${name}` as any) as string;
  const nameProps =
    name === "template_id" || name === "template_name" ? displayName : name;
  const filterProps = {
    name: nameProps,
    preFilteredRows,
    setFilter,
    filterValue,
  };

  const FilterComponent = FiltersTypes[name as keyof typeof FiltersTypes];

  if (!FilterComponent) {
    console.warn(`Filter type :${name} is not supported.`);
    return null;
  }

  return (
    <Popover key={name}>
      <PopoverTrigger>
        <Button
          border={
            highlighted ? "3px solid #68C0FF" : `1px solid ${colors.dark}`
          }
          variant="tertiary-ghost"
        >
          {displayName}
        </Button>
      </PopoverTrigger>

      <PopoverContent minWidth={80} width="100%">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{displayName}</PopoverHeader>
        <PopoverBody>
          <FilterComponent {...filterProps} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default SwitchFilter;
