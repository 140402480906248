import React from "react";
import { createIcon } from "@chakra-ui/react";

const CenterIcon = createIcon({
  displayName: "CenterIcon",
  viewBox: "0 0 18 21",
  path: (
    <>
      <path
        d="M4 13.8353V16.0022H14V13.8353H4ZM0 20.3359H18V18.169H0V20.3359ZM0 11.6685H18V9.50164H0V11.6685ZM4 5.16795V7.33479H14V5.16795H4ZM0 0.834259V3.0011H18V0.834259H0Z"
        fill="currentColor"
      />
      ,
    </>
  ),
});

export default CenterIcon;
