import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { userSchema } from "modules/users/schemas/usersModelSchemas";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";

export const getUser = (id: string) =>
  simpleRequest(generatePath(ENDPOINTS.USER, { id }), userSchema);

export default function useUser(fetch = true) {
  const { id } = useParams<RouteIdParam>();
  return useQuery(["user", Number(id)], () => getUser(id!), {
    enabled: fetch,
    suspense: true,
  });
}
