import React, { ReactNode } from "react";
import { Tooltip, chakra } from "@chakra-ui/react";

interface Props {
  children: ReactNode;
  label: string;
}

const TooltipArrow = ({ children, label }: Props) => {
  return (
    <Tooltip hasArrow label={label} placement="right">
      <chakra.span
        sx={{
          textDecoration: "underline",
          textUnderlinePosition: "under",
          cursor: "pointer",
          fontWeight: 700,
        }}
        fontSize="xs"
        color="abbey"
      >
        {children}
      </chakra.span>
    </Tooltip>
  );
};

export default TooltipArrow;
