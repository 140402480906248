type Dict = Record<string, any>;
function variantTertiaryGhost(props: Dict) {
  const { colorScheme: c } = props;

  if (c === "dark") {
    return {
      fontSize: "xs",
      borderRadius: "1px",
      layerStyle: "tertiaryDarkBg",
      _disabled: {
        layerStyle: "tertiaryDarkBg._disabled",
        opacity: 1,
        _hover: { layerStyle: "tertiaryDarkBg._disabled" },
      },
      _hover: {
        layerStyle: "tertiaryDarkBg._hover",
      },
      _focus: { layerStyle: "tertiaryDarkBg._hover" },
    };
  }
  return {
    fontSize: "xs",
    borderRadius: "1px",
    layerStyle: "tertiaryLightBg",
    _disabled: {
      layerStyle: "tertiaryLightBg._disabled",
      opacity: 1,
      _hover: { layerStyle: "tertiaryLightBg._disabled" },
    },
  };
}

function variantPrimarySolid() {
  return {
    minWidth: "auto",
    layerStyle: "solid",
    fontWeight: "500",
    fontSize: "sm",
    borderRadius: "sm",
    _hover: {
      layerStyle: "solid._hover",
      _disabled: {
        bg: "silver",
        color: "jumbo",
        opacity: 1,
        // override default variant button solid layerstyles dosen't work
      },
    },
    _focus: {
      layerStyle: "solid._focus",
    },
    _disabled: {
      layerStyle: "solid._disabled",
      bg: "silver",
      color: "jumbo",
      opacity: 1,
    },
  };
}

function variantSecondarySolid() {
  return {
    minWidth: "auto",
    layerStyle: "solid",
    fontSize: "xs",
    borderRadius: "sm",
    _hover: {
      layerStyle: "solid._hover",
      _disabled: {
        bg: "jumbo",
        opacity: 1,
        // override default variant button solid layerstyles dosen't work
      },
    },
    _focus: {
      layerStyle: "solid._focus",
    },
    _disabled: {
      layerStyle: "solid._disabled",
      bg: "jumbo",
      opacity: 1,
    },
  };
}

function variantLink(props: Dict) {
  const { colorScheme: c } = props;

  if (c === "dark") {
    return {
      fontWeight: "700",
      fontSize: "xs",
      layerStyle: "linkDarkBg",
      _hover: { layerStyle: "linkDarkBg._hover" },
      _focus: { layerStyle: "linkDarkBg._focus" },
      _disabled: { layerStyle: "linkDarkBg._disabled" },
    };
  } else if (c === "light") {
    return {
      fontWeight: "700",
      fontSize: "xs",
      layerStyle: "linkLightBgDark",
      _hover: { layerStyle: "linkLightBgDark._hover" },
      _focus: { layerStyle: "linkLightBgDark._focus" },
      _disabled: { layerStyle: "linkLightBgDark._disabled" },
    };
  } else {
    return {
      whiteSpace: "normal",
      fontSize: "xs",
      fontWeight: "700",
      layerStyle: "linkLightBg",
      _hover: { layerStyle: "linkLightBg._hover" },
      _focus: { layerStyle: "linkLightBg._focus" },
      _disabled: { layerStyle: "linkLightBg._disabled" },
    };
  }
}

function variantLinkWithIcon() {
  return {
    color: "dark",
    fontSize: "xs",
    fontWeight: "700",
    background: "none",
    border: "none",
    padding: "0",
    height: "auto",
    layerStyle: "linkLightBgDark",
    cursor: "pointer",
    _hover: { layerStyle: "linkLightBgDark._hover", textDecoration: "none" },
    _focus: { layerStyle: "linkLightBgDark._focus", textDecoration: "none" },
    _disabled: { layerStyle: "linkLightBgDark._disabled" },
  };
}

const Button = {
  variants: {
    "tertiary-ghost": variantTertiaryGhost,

    "primary-solid": variantPrimarySolid,

    "secondary-solid": variantSecondarySolid,

    "link-button": variantLink,

    "link-with-icon": variantLinkWithIcon,
  },
};
export default Button;
