import { useRouteMatch } from "react-router-dom";
import { PATHS } from "modules/shared/constants/paths";
import filterConfig from "modules/filters/config/filterConfig";

export default function useIsFilterConfig() {
  const isTemplateRoute = !!useRouteMatch({
    path: [PATHS.HOME, PATHS.TEMPLATES],
    exact: true,
    strict: true,
  });

  return isTemplateRoute ? filterConfig.templates : filterConfig.users;
}
