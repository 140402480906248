import React, { useMemo, useCallback } from "react";
import { Table, Tbody, Box, Text, Flex, Center } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  useTable,
  useSortBy,
  Column,
  Cell,
  useGlobalFilter,
  useFilters,
} from "react-table";

import { TemplateFromList } from "modules/templates/schemas/templateModelSchemas";
import {
  templateColumnNameSchema,
  TemplateColumnName,
} from "../schemas/templateViewSchema";
import TableHeader from "modules/shared/components/Table/TableHeader";
import TableRow from "modules/shared/components/Table/TableRow";
import TableCell from "modules/shared/components/Table/TableCell";
import ActionCell from "modules/shared/components/Table/ActionCell";
import DuplicateIconBlock from "modules/duplicate/components/DuplicateIconBlock";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { useIsContributor } from "modules/roles/hooks/useIsContributor";
import SwitchFilter from "modules/filters/components/SwitchFilter";
import filterRowsByName from "modules/filters/helpers/filterRowsByName";
import customFilter from "modules/filters/helpers/customFilter";
import Filters from "modules/filters/components/Filters";
import useFilterConfig from "modules/filters/hooks/useFilterConfig";
import CreateTemplateButton from "modules/templates/components/CreateTemplateButton";

interface Props {
  templates?: TemplateFromList[];
  isReviewing?: boolean;
  filterText?: string;
}

const MIN_WIDTH = 20;

const TemplatesTable = ({
  templates,
  filterText,
  isReviewing = false,
}: Props) => {
  const { t, i18n } = useTranslation();
  const isAdministrator = useIsAdministrator();
  const isContributor = useIsContributor();
  const { defaultGlobalFilters } = useFilterConfig();

  const getHeaderName = useCallback(
    (name: TemplateColumnName) => name,
    [i18n.language]
  );

  const columns = useMemo(
    () =>
      templateColumnNameSchema.options.map((name) => ({
        Header: getHeaderName(name),
        accessor: name,
        Cell: ({
          cell: { value, row },
        }: {
          cell: Cell<TemplateFromList, string | string[]>;
        }) =>
          name === templateColumnNameSchema.enum.actions && value ? (
            <ActionCell
              value={value}
              to={`/template/${row.values.template_id}`}
              isReviewing={isReviewing}
            >
              {(isAdministrator || isContributor) && (
                <DuplicateIconBlock
                  to={`/create?step=2&id=${row.values.template_id}`}
                />
              )}
            </ActionCell>
          ) : (
            <TableCell value={value} />
          ),
        Filter: SwitchFilter,
        filter: (...args) => filterRowsByName(...args, name),
        minWidth: name === "template_id" ? MIN_WIDTH : "null",
      })),
    [getHeaderName]
  ) as Column<TemplateFromList>[];

  const globalFilter = localStorage?.getItem("globalFilter")
    ? JSON.parse(localStorage.getItem("globalFilter") as string)
    : defaultGlobalFilters;
  const filters = localStorage?.getItem("filters")
    ? JSON.parse(localStorage.getItem("filters") as string)
    : [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    setAllFilters,
  } = useTable<TemplateFromList>(
    {
      columns,
      data: templates || [],
      initialState: {
        globalFilter: globalFilter,
        filters: filters,
      },
      globalFilter: customFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  React.useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(state.filters));
    localStorage.setItem("globalFilter", JSON.stringify(state.globalFilter));
  }, [state.filters, state.globalFilter]);
  if (templates?.length === 0) {
    return (
      <Flex mt="10px" d="column">
        {filterText && (
          <Filters<TemplateFromList>
            count={rows.length}
            headerGroups={headerGroups}
            filters={state.filters}
            globalFilter={state.globalFilter}
            setAllFilters={setAllFilters}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <Text m="20px" align="center">
          {t("templates.error.no_template")}
        </Text>
        <Center>
          <CreateTemplateButton />
        </Center>
      </Flex>
    );
  }

  return (
    <>
      {isReviewing ? (
        ""
      ) : (
        <Box position="relative" marginTop="40px">
          <Filters<TemplateFromList>
            count={rows.length}
            headerGroups={headerGroups}
            filters={state.filters}
            globalFilter={state.globalFilter}
            setAllFilters={setAllFilters}
            setGlobalFilter={setGlobalFilter}
          />
          <Box position="absolute" right={0} top={35}>
            <CreateTemplateButton />
          </Box>
        </Box>
      )}
      <Box overflow="auto" pt={5} pb={10} data-testid="templates-table">
        <Table {...getTableProps()} textStyle="bodySmall" w="100%">
          <TableHeader headerGroups={headerGroups} />
          <Tbody {...getTableBodyProps()} bgColor="white">
            {rows.map((row) => {
              prepareRow(row);
              return <TableRow key={row.id} row={row} />;
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default TemplatesTable;
