import React from "react";
import { Box, Container } from "@chakra-ui/react";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";
import Feedback from "modules/feedback/components/Feedback";
import GlobalMessagesTable from "modules/globalMessage/components/GlobalMessagesTable";
import { useGlobalMessages } from "modules/globalMessage/hooks/useGlobalMessages";

const Globals = () => {
  const { data } = useGlobalMessages();
  return (
    <>
      <Feedback />
      <Container data-testid="globals">
        <BreadNav />
        <Box>
          <GlobalMessagesTable globalMessages={data} />
        </Box>
      </Container>
    </>
  );
};

export default Globals;
