import Home from "./Home/Home";

import { PATHS } from "modules/shared/constants/paths";
import Administration from "./Administration/Administration";
import Assets from "./Assets/Assets";
import ColorScheme from "./ColorScheme/ColorScheme";
import Create from "./Create/Create";
import CreateColorScheme from "./CreateColorScheme/CreateColorScheme";
import CreateUser from "./CreateUser/CreateUser";
import Finalization from "./Finalization/Finalization";
import Target from "./Target/Target";
import Template from "./Template/Template";
import Templates from "./Templates/Templates";
import Test from "./Test/Test";
import User from "./User/User";
import UserProfile from "./User/Profile/UserProfile";
import Users from "./Users/Users";
import Monitoring from "./Monitoring/Monitoring";
import GlobalMessage from "./GlobalMessage/GlobalMessage";
import CreateGlobalMessage from "./CreateGlobalMessage/CreateGlobalMessage";
import Metrics from "./Monitoring/Metrics";
import DuplicateUser from "./DuplicateUser/DuplicateUser";

import Inspection from "./Monitoring/Inspection";
import Graphs from "./Monitoring/Graphs";
import EmergencyStop from "./EmergencyStop/EmergencyStop";
import Campaign from "./Monitoring/Campaign";

const privateRoutes = [
  {
    path: PATHS.HOME,
    Component: Home,
    exact: true,
  },
  { path: PATHS.CREATE, Component: Create, exact: false },
  { path: PATHS.TEMPLATE, Component: Template, exact: false },
  { path: PATHS.TEMPLATES, Component: Templates, exact: false },
  { path: PATHS.EMERGENCY_STOP, Component: EmergencyStop, exact: false },
  { path: PATHS.MONITORING, Component: Monitoring, exact: false },
  { path: PATHS.METRICS, Component: Metrics, exact: false },
  { path: PATHS.GRAPHS, Component: Graphs, exact: false },
  {
    path: PATHS.CREATE_GLOBALMESSAGE,
    Component: CreateGlobalMessage,
    exact: false,
  },
  { path: PATHS.GLOBAL_MESSAGES, Component: GlobalMessage, exact: true },
  { path: PATHS.GLOBAL_MESSAGE, Component: CreateGlobalMessage, exact: false },
  { path: PATHS.INSPECTION, Component: Inspection, exact: false },
  { path: PATHS.CAMPAIGN, Component: Campaign, exact: false },
  { path: PATHS.FINALIZATION, Component: Finalization, exact: false },
  { path: PATHS.TARGET, Component: Target, exact: false },
  { path: PATHS.TEST, Component: Test, exact: false },
  { path: PATHS.USERS, Component: Users, exact: false },
  { path: PATHS.CREATE_USER, Component: CreateUser, exact: false },
  { path: PATHS.DUPLICATE_USER, Component: DuplicateUser, exact: false },
  { path: PATHS.USER, Component: User, exact: false },
  {
    path: PATHS.CREATE_COLORSCHEME,
    Component: CreateColorScheme,
    exact: false,
  },
  { path: PATHS.COLORSCHEME, Component: ColorScheme, exact: false },
  { path: PATHS.ASSETS, Component: Assets, exact: false },
  { path: PATHS.ADMINISTRATION, Component: Administration, exact: false },

  { path: PATHS.PROFILE, Component: UserProfile, exact: false },
];

export default privateRoutes;
