import { useMutation, useQueryClient } from "react-query";
import { simpleRequest } from "core/services/api";
import { loginBodySchema } from "../schemas/loginSchemas";
import { CredentialsForm } from "../schemas/credentialsFormSchema";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useTokenStore from "./useTokenStore";

export const login = (credentials: CredentialsForm) =>
  simpleRequest(
    ENDPOINTS.LOGIN,
    loginBodySchema,
    methodSchema.enum.post,
    credentials,
    false
  );

export function useLogin() {
  const queryClient = useQueryClient();
  const setToken = useTokenStore(({ setToken }) => setToken);
  return useMutation(login, {
    onSuccess: (data) => {
      setToken(data.token);
      queryClient.invalidateQueries(["selfcare"]);
    },
  });
}
