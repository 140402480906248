import React, { ReactNode, Suspense } from "react";
import FullLoader from "core/components/Fullloader/FullLoader";
import { useQueryErrorResetBoundary } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { matchPath } from "react-router";
import { useHistory } from "react-router-dom";

import ErrorMessage from "./ErrorMessage";
import { PATHS } from "modules/shared/constants/paths";
import { API_ERRORS } from "modules/shared/constants/errors";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";

interface Props {
  children: ReactNode;
  path?: string;
}

const ErrorHandler = ({ children, path }: Props) => {
  const { t } = useTranslation();
  const { reset } = useQueryErrorResetBoundary();
  const isTemplatePath =
    path &&
    (matchPath(path, PATHS.TEMPLATE) ||
      matchPath(path, PATHS.FINALIZATION) ||
      matchPath(path, PATHS.TARGET) ||
      matchPath(path, PATHS.TEST));

  const { setFeedback } = useFeedbackStore();
  const history = useHistory();
  return (
    <Suspense fallback={<FullLoader />}>
      <ErrorBoundary
        onReset={reset}
        fallbackRender={({ error, resetErrorBoundary }) => {
          if (
            isTemplatePath &&
            error.stack?.includes(API_ERRORS.MISSING_TEMPLATE)
          ) {
            resetErrorBoundary();
            history.push("/create?step=1");
            setFeedback(feedbackSchema.enum.error, actionsSchema.enum.redirect);
          } else if (
            isTemplatePath &&
            error.stack?.includes(API_ERRORS.NOT_MATCHING_REGIONS)
          ) {
            resetErrorBoundary();
            history.push("/");
            setFeedback(feedbackSchema.enum.error, actionsSchema.enum.redirect);
          }

          return (
            <ErrorMessage
              messageText={t("errors.server")}
              reset={resetErrorBoundary}
              retryText={t("errors.retry")}
            />
          );
        }}
      >
        {children}
      </ErrorBoundary>
    </Suspense>
  );
};

export default ErrorHandler;
