import React from "react";
import { ContentFilterProvider } from "modules/filters/context/ContentFilterContext";

import Templates from "screens/Templates/Templates";

const Home = () => (
  <div data-testid="home">
    <ContentFilterProvider>
      <Templates />
    </ContentFilterProvider>
  </div>
);
export default Home;
