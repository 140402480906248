import React from "react";
import { useTranslation } from "react-i18next";

import ActionFeedback from "core/components/ActionFeedback/ActionFeedback";
import CustomMessage from "core/components/ActionFeedback/CustomMessage";
import DeleteUserForm from "modules/user/informations/components/DeleteUserForm";
import ErrorHandler from "modules/shared/components/ErrorHandler/ErrorHandler";
import InformationStatus from "modules/validate/components/InformationStatus";
import ResultAlert from "core/components/ActionFeedback/ResultAlert";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import WarningAlert from "core/components/ActionFeedback/WarningAlert";
import {
  feedbackSchema,
  actionsSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { useIsValidator } from "modules/roles/hooks/useIsValidator";

const Feedback = () => {
  const {
    type,
    action,
    reset,
    handleAction,
    setFeedback,
    customMessage,
    setCustomMessage,
  } = useFeedbackStore();
  const { t } = useTranslation();
  const isValidator = useIsValidator();
  const prefix = `actions.${action}.${type}`;

  const removeMessage = (index: number) => {
    const newErrorMessagesList = customMessage?.filter(
      (message, messageIndex) => index !== messageIndex
    );
    return setCustomMessage(newErrorMessagesList!);
  };

  return type ? (
    <>
      {customMessage ? (
        customMessage.map((message, index) => (
          <ActionFeedback
            key={`${index}-${type}`}
            type={type}
            onClick={() => removeMessage(index)}
          >
            <CustomMessage key={index} message={message} />
          </ActionFeedback>
        ))
      ) : (
        <ActionFeedback type={type} onClick={reset}>
          {type === feedbackSchema.enum.info ? (
            <InformationStatus />
          ) : type === feedbackSchema.enum.warning ? (
            <WarningAlert
              title={t(`${prefix}.title` as any)}
              message={isValidator ? undefined : t(`${prefix}.message` as any)}
              confirm={t(`${prefix}.confirm` as any)}
              cancel={t(`${prefix}.cancel` as any)}
              handleAction={handleAction!}
              reset={
                action === actionsSchema.enum.refuse ||
                action === actionsSchema.enum.legal ||
                action === actionsSchema.enum.commercial
                  ? () => setFeedback(feedbackSchema.enum.info)
                  : reset
              }
              type={isValidator ? "validator" : "contributor"}
            >
              {action === "deleteUser" && (
                <ErrorHandler>
                  <DeleteUserForm />
                </ErrorHandler>
              )}
            </WarningAlert>
          ) : (
            <ResultAlert
              message={
                action ? t(`${prefix}.message` as any) : t("errors.server")
              }
            />
          )}
        </ActionFeedback>
      )}
    </>
  ) : null;
};

export default Feedback;
