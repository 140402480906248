import { useQueryClient } from "react-query";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { ManageImageFormValues } from "../../schemas/customImageSchema";

interface ChangeImage {
  imageId: string;
  image: Omit<ManageImageFormValues, "original" | "id">;
}

export const changeImage = ({ image, imageId }: ChangeImage) => {
  return simpleRequest(
    generatePath(ENDPOINTS.IMAGE, { id: imageId }),
    emptySchema,
    methodSchema.enum.patch,
    image
  );
};

export function useChangeImage() {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(changeImage, {
    onSuccess: (_, { imageId }) => {
      queryClient.invalidateQueries(["images"]);
      queryClient.invalidateQueries(["image", imageId]);
      setFeedback(feedbackSchema.enum.success, actionsSchema.enum.changeImage);
    },
  });
}
