import React from "react";
import { Circle, Box, Flex } from "@chakra-ui/react";

import RefuseIcon from "core/components/InterfaceIcons/RefuseIcon";
import SuccessIcon from "core/components/FeedbackIcons/SuccessIcon";
import { StatusType } from "modules/feedback/components/InfoAlert";

interface Props {
  steps: [StatusType, StatusType];
}

const Stepper = ({ steps }: Props) => {
  const size = (step: StatusType) =>
    step === "inprogress" || step === "tocome" ? "15px" : "24px";
  const bg = (step: StatusType) =>
    step === "inprogress"
      ? "dark"
      : step === "tocome"
      ? "jumbo"
      : "transparent";
  const icon = (step: StatusType) =>
    step === "error" ? (
      <RefuseIcon w="24px" h="24px" />
    ) : step === "success" ? (
      <SuccessIcon w="24px" h="24px" />
    ) : null;
  return (
    <Flex direction="column" alignItems="center" mr="10px">
      <Circle size={size(steps[0])} bg={bg(steps[0])}>
        {icon(steps[0])}
      </Circle>
      <Box w="1px" bgColor="status" flex={1} />
      <Circle size={size(steps[1])} bg={bg(steps[1])}>
        {icon(steps[1])}
      </Circle>
    </Flex>
  );
};

export default Stepper;
