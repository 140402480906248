import React from "react";
import { createIcon } from "@chakra-ui/react";

const ValidateIcon = createIcon({
  displayName: "ValidateIcon",
  viewBox: "0 0 33 33",
  path: (
    <>
      <path
        d="M2 0h29c1.1 0 2 .9 2 2v29c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0z"
        fill="currentColor"
      />
      <path
        d="M24.7 11.1l-1.5-1.5-9.3 9.3-3.6-3.6-1.6 1.5 5.2 5.2 10.8-10.9z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
    </>
  ),
});

export default ValidateIcon;
