import React from "react";
import { createIcon } from "@chakra-ui/react";

const CancelIcon = createIcon({
  displayName: "CancelIcon",
  viewBox: "0 0 33 33",
  path: (
    <>
      <path
        d="M2 0h29c1.1 0 2 .9 2 2v29c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 14.969l-4.854-4.855-1.532 1.531 4.854 4.855-4.854 4.855 1.531 1.531 4.855-4.854 4.855 4.854 1.531-1.531-4.854-4.855 4.854-4.854-1.531-1.532-4.855 4.854z"
        fill="#fff"
      />
    </>
  ),
});

export default CancelIcon;
