import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

function NoRight() {
  const { t } = useTranslation();
  return (
    <Box flex={1} h="100%" alignItems="center" p={20}>
      <Heading as="h1" size="2xl">
        {t("errors.no_right.title")}
      </Heading>
      <Text mt={10} fontSize="xl">
        {t("errors.no_right.description")}
      </Text>
    </Box>
  );
}

export default NoRight;
