import * as z from "zod";
import { CARD_MAX_TARGET_VALUE } from "modules/user/cards/constants/defaultFormValues";
import { actionsSchema } from "modules/shared/schemas/actionsSchemas";

export const createCardFormSchema = z.object({
  description: z.string().nonempty(),
  target: z.number().max(CARD_MAX_TARGET_VALUE),
  region: z.string().nonempty(),
  actions: actionsSchema.optional(),
});

export type CreateCardFormValues = z.infer<typeof createCardFormSchema>;
