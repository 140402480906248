import React from "react";
import { Heading } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";

import {
  createUserFormSchema,
  CreateUserFormValues,
} from "modules/user/informations/schemas/formSchemas";

import UserActionBar from "modules/user/informations/components/UserActionBar";
import useUser from "modules/users/hooks/useUser";
import UserForms from "modules/user/shared/components/UserForms";
import { getFormValuesForDuplicateUser } from "modules/user/informations/helpers/getFormValuesFromUser";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";

const DuplicateUser = () => {
  const { t } = useTranslation();
  const { data } = useUser();
  const defaultValues = getFormValuesForDuplicateUser(data);
  const methods = useForm<CreateUserFormValues>({
    defaultValues: defaultValues,
    resolver: zodResolver(createUserFormSchema),
  });

  return (
    <FormProvider {...methods}>
      <UserActionBar />
      <ScreenContainer>
        <Heading as="h1" size="lg" mb={10} mt={10}>
          {t("duplicate_user.title")}
        </Heading>
        <UserForms />
      </ScreenContainer>
    </FormProvider>
  );
};

export default DuplicateUser;
