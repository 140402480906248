import { generatePath } from "react-router";
import { methodSchema } from "core/schemas/http";
import { simpleRequest } from "core/services/api";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { finalizeSchema, finalize } from "../schemas";
import {
  feedbackSchema,
  actionsSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

export const finalizeTemplate = (templateId: number) =>
  simpleRequest(
    generatePath(ENDPOINTS.FINALIZE, { id: templateId }),
    finalizeSchema,
    methodSchema.enum.post
  );

export function useFinalizeTemplate() {
  const { setFeedback, setCustomMessage } = useFeedbackStore();
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutationWithErrorHandling(finalizeTemplate, {
    onSuccess: (data: finalize, templateId) => {
      queryClient.invalidateQueries(["template", templateId]);
      queryClient.invalidateQueries(["templates"]);
      history.push(`/target/${templateId}`);

      if (data?.label) {
        const feedbackMessage = t("actions.finalize.updatedLabel", {
          prev: data.label.prev.toString(),
          new: data.label.new.toString(),
          value: data.label.value.toString(),
        });

        setFeedback(feedbackSchema.enum.success);
        setCustomMessage([feedbackMessage]);
      } else {
        setFeedback(feedbackSchema.enum.success, actionsSchema.enum.finalize);
      }
    },
  });
}
