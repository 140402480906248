import React from "react";
import { createIcon } from "@chakra-ui/react";

const EditIcon = createIcon({
  displayName: "EditIcon",
  viewBox: "0 0 33 33",
  path: (
    <>
      <path
        d="M2 0h29c1.1 0 2 .9 2 2v29c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0z"
        fill="currentColor"
      />
      <path
        fill="#fff"
        d="M21.3 13.2L18.1 10l-9.5 9.4v3.2h3.2zM24.4 10.1l-.7-.7-1.2-1.2-1.3-1.3-2.1 2 3.3 3.2zM8.6 24.1h15.8v2H8.6z"
      />
    </>
  ),
});

export default EditIcon;
