import textStyles from "../textStyles";
const Heading = {
  variants: {
    h1: textStyles.h1,
    h2: textStyles.h2,
    h3: textStyles.h3,
  },
};

export default Heading;
// https://github.com/chakra-ui/chakra-ui/issues/3884
