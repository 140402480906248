import { useQueryClient } from "react-query";
import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { generatePath } from "react-router";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";

interface ChangeImageUrl {
  image: FileList;
  imageId: string;
}

export const changeImageUrl = ({ image, imageId }: ChangeImageUrl) =>
  simpleRequest(
    generatePath(ENDPOINTS.IMAGE, { id: imageId.toString() }),
    emptySchema,
    methodSchema.enum.put,
    image[0]
  );

export const useChangeImageUrl = () => {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(changeImageUrl, {
    onSuccess: (_, { imageId }) => {
      queryClient.invalidateQueries(["images"]);
      queryClient.invalidateQueries(["imagesUrl", imageId]);
      queryClient.invalidateQueries(["image", imageId]);
      setFeedback(feedbackSchema.enum.success, actionsSchema.enum.saveImage);
    },
  });
};
