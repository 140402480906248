const Breadcrumb = {
  baseStyle: {
    link: {
      fontSize: "xs",
      fontWeight: "500",
      fontFamily: "Canal",
      letterSpacing: "0.02rem",
      color: "dark",
      textUnderlineOffset: "3px",
      "&[aria-current=page]": {
        color: "abbey",
        _hover: { textDecoration: "none" },
        cursor: "auto",
      },
    },
  },
};
export default Breadcrumb;
