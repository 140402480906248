import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Flex,
  FormHelperText,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
  CreateUserFormValues,
  UpdateUserFormValues,
} from "modules/user/informations/schemas/formSchemas";
import { useRouteMatch } from "react-router-dom";
import InputError from "core/components/InputError/InputError";

const LoginInfoForm = () => {
  const context = useFormContext<CreateUserFormValues | UpdateUserFormValues>();
  const { t } = useTranslation();
  const isNewUser = useRouteMatch({
    path: "/administration/create-user",
    exact: true,
  });
  const isDuplicateUser = useRouteMatch({
    path: "/administration/duplicate-user/:id",
    exact: true,
  });
  const allowButton = isNewUser || isDuplicateUser;
  return (
    <Box>
      <Heading as="h2" size="md" mt="10px" textAlign="left">
        {t("create_user.login_form.title")}
      </Heading>
      <Flex
        mt="10px"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <FormControl id="name" w="400px" isInvalid={!!context.errors.name}>
          <FormLabel>{t("create_user.login_form.name.label")}</FormLabel>
          <Input
            variant="outline"
            name="name"
            ref={context.register}
            errorBorderColor="error"
          />
          <InputError
            text={
              context.errors.name ? t("create_user.login_form.name.error") : ""
            }
          />
        </FormControl>
        <FormControl id="email" w="400px" isInvalid={!!context.errors.email}>
          <FormLabel>{t("create_user.login_form.email.label")}</FormLabel>

          <Input
            variant="outline"
            type="email"
            name="email"
            ref={context.register}
            errorBorderColor="error"
          />
          <InputError
            text={
              context.errors.email
                ? t("create_user.login_form.email.error")
                : ""
            }
          />
        </FormControl>
        {allowButton && (
          <FormControl id="password" mt="30px" w="400px">
            <FormLabel>{t("create_user.login_form.password.label")}</FormLabel>
            <Text fontSize="xs" mb="10px" textAlign="left">
              {t("preview.regions.label")}
            </Text>
            <Input
              variant="outline"
              type="password"
              name="password"
              ref={context.register}
              errorBorderColor="error"
            />
            <FormHelperText
              fontSize="xs"
              color={context.errors.password ? "error" : "abbey"}
              textAlign="left"
              sx={{ whiteSpace: "pre-line" }}
            >
              {t("create_user.login_form.password.hint")}
            </FormHelperText>
          </FormControl>
        )}
      </Flex>
    </Box>
  );
};

export default LoginInfoForm;
