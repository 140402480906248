import React from "react";
import { HStack, Box, Container } from "@chakra-ui/react";

import SaveColorSchemeAction from "./SaveColorSchemeAction";
import DeleteColorSchemeAction from "./DeleteColorSchemeAction";

const ColorSchemeActionBar = () => {
  return (
    <Box bgColor="black">
      <Container>
        <HStack spacing={5} p="10px" justifyContent="flex-end" pr="30px">
          <SaveColorSchemeAction />
          <DeleteColorSchemeAction />
        </HStack>
      </Container>
    </Box>
  );
};

export default ColorSchemeActionBar;
