import hasRightForEndpoint from "./hasRightForEndpoint";
import { REQUIRED_ENDPOINTS_BY_PATH } from "../constants";
import { Routes, Verb } from "modules/roles/schemas/rolesSchemas";
const hasRightForPath = (
  path: string | string[] | undefined,
  routes: Routes[],
  verbs?: Verb[]
) => {
  let hasRight = true; // has rights by default if no requirements defined
  if (path) {
    if (Array.isArray(path)) {
      path.forEach((p) => {
        const requiredEndpoints = REQUIRED_ENDPOINTS_BY_PATH[p] || [];
        requiredEndpoints.forEach((requiredEndpoint) => {
          hasRight = verbs
            ? hasRightForEndpoint(requiredEndpoint.route, verbs, routes)
            : hasRightForEndpoint(
                requiredEndpoint.route,
                requiredEndpoint.verbs,
                routes
              );
          if (hasRight === false) {
            return;
          }
        });
        if (hasRight === false) {
          return;
        }
      });
    } else {
      const requiredEndpoints = REQUIRED_ENDPOINTS_BY_PATH[path] || [];
      requiredEndpoints.forEach((requiredEndpoint) => {
        hasRight = hasRightForEndpoint(
          requiredEndpoint.route,
          requiredEndpoint.verbs,
          routes
        );
        if (hasRight === false) {
          return;
        }
      });
    }
  }
  return hasRight;
};

export default hasRightForPath;
