import { Row } from "react-table";

import { GlobalFilter } from "modules/filters/context/filterContext";

export default function customFilter<T extends object>(
  rows: Array<Row<T>>,
  columnsIds: any,
  globalFilter: GlobalFilter
) {
  let filteredRow = rows;
  if (globalFilter.archived) {
    filteredRow = rows?.filter((row) => row.values.status !== "ARCHIVED");
  }
  if (globalFilter.keyword) {
    const word = globalFilter.keyword as string;
    filteredRow = filteredRow.filter((row) => {
      return Object.values(row.values).some((value) => {
        if (Array.isArray(value)) {
          return value.some((c) =>
            String(c).toLowerCase().includes(word.toLowerCase())
          );
        }
        return String(value).toLowerCase().includes(word.toLowerCase());
      });
    });
  }
  return filteredRow;
}
