import { CheckIcon, InfoIcon, WarningIcon } from "@chakra-ui/icons";
import { Alert, CloseButton, Text } from "@chakra-ui/react";
import { FeedBackType } from "core/components/ActionFeedback/schemas/actionSchemas";

interface PropsToast {
  title: string;
  status?: FeedBackType;
  onClose: () => void;
}

const Toast = ({ title, status, onClose }: PropsToast) => {
  return (
    <Alert
      borderRadius="sm"
      status={status}
      variant="solid"
      bg={
        status === "success"
          ? "validation"
          : status === "error"
          ? "monza"
          : "abbey"
      }
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      maxWidth="300px"
    >
      {status === "success" ? (
        <CheckIcon color="white" />
      ) : status === "error" ? (
        <WarningIcon />
      ) : (
        <InfoIcon color="white" />
      )}
      <Text px={5}> {title} </Text>
      <CloseButton
        onClick={onClose}
        ml={2}
        size="sm"
        position="absolute"
        right="5px"
        top="5px"
      />
    </Alert>
  );
};

export default Toast;
