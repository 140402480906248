import React, { createContext, useContext, useState } from "react";

interface ContentFilterContextType {
  filterText: string;
  setFilterText: React.Dispatch<React.SetStateAction<string>>;
}

const ContentFilterContext = createContext<
  ContentFilterContextType | undefined
>(undefined);

export const ContentFilterProvider: React.FC<{ children?: React.ReactNode }> =
  ({ children = null }) => {
    const [filterText, setFilterText] = useState("");

    return (
      <ContentFilterContext.Provider value={{ filterText, setFilterText }}>
        {children}
      </ContentFilterContext.Provider>
    );
  };

export const useContentFilter = () => {
  const context = useContext(ContentFilterContext);
  if (context === undefined) {
    throw new Error(
      "useContentFilter must be used within a ContentFilterProvider"
    );
  }
  return context;
};
