import * as z from "zod";
import { actionsSchema } from "modules/shared/schemas/actionsSchemas";
import { testCardSchema } from "modules/test/schemas/testCardsApiSchemas";

export const userTestCardFromListSchema = testCardSchema.extend({
  actions: actionsSchema.optional(),
});

export const userTestCardsBodySchema = z.array(userTestCardFromListSchema);

export const userTestCardSchema = testCardSchema.extend({
  id: z.number().optional(),
});

export type UserTestCard = z.infer<typeof userTestCardSchema>;

export type UserTestCardFromList = z.infer<typeof userTestCardFromListSchema>;

export type UserTestCardsBody = z.infer<typeof userTestCardsBodySchema>;

export const createTestCardApiSchema = z
  .object({ test_card_id: z.number() })
  .nonstrict();
