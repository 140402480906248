const styles = {
  global: {
    "html, body": {
      fontWeight: "300",
      color: "abbey",
    },

    ".react-datepicker__day--selected": {
      background: "amaranth",
    },
    ".react-datepicker__day--in-selecting-range": {
      background: "wewak",
    },
    ".react-datepicker__day--in-range": {
      background: "amaranth",
    },
    ".react-datepicker__day--in-selecting-range:hover": {
      background: "amaranth",
    },
    ".react-datepicker__day--in-range:hover": {
      background: "amaranth",
    },
    ".react-datepicker__day--selected:hover": {
      background: "amaranth",
    },
    ".react-datepicker__day--keyboard-selected": {
      background: "wewak",
    },
    ".react-datepicker__header": {
      background: "dark",
    },
    ".react-datepicker__current-month": {
      color: "sunglo",
    },
    ".react-datepicker__day-name": {
      color: "white",
    },
  },
};

export default styles;
