import { statusSchema } from "modules/preview/schemas/templateSchemas";
import { useRole } from "modules/roles/hooks/useRole";
import { rolesSchema } from "modules/roles/schemas/rolesSchemas";
import { useTemplates } from "modules/templates/hooks/useTemplates";

export function usePendingTemplates() {
  const roles = useRole();
  const { data } = useTemplates(false);

  if (!roles) {
    return;
  }

  const islegalValidator = roles.includes(rolesSchema.enum.LEGAL_VALIDATOR);
  const isCommercialValidator = roles.includes(
    rolesSchema.enum.COMMERCIAL_VALIDATOR
  );

  if (islegalValidator && isCommercialValidator) {
    return data?.filter(
      (template) =>
        template.status === statusSchema.enum.PENDING_LEGAL ||
        template.status === statusSchema.enum.PENDING_COMMERCIAL
    );
  } else if (isCommercialValidator) {
    return data?.filter(
      (template) => template.status === statusSchema.enum.PENDING_COMMERCIAL
    );
  } else if (islegalValidator) {
    return data?.filter(
      (template) => template.status === statusSchema.enum.PENDING_LEGAL
    );
  }
  return;
}
