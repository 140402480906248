import { CustomImage } from "modules/images/schemas/customImageSchema";
import { omit } from "remeda";

export const getFormValuesFromImage = (
  image: CustomImage,
  regions: string[] | undefined
) => {
  const formImage = omit(image, ["url", "deletable", "mutable"]);
  //an empty array means all regions are selected
  formImage.regions =
    formImage.regions.length === 0 && regions ? regions : formImage.regions;
  return formImage;
};
