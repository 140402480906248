import React, { useMemo } from "react";
import { Heading } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  UpdateUserFormValues,
  updateUserFormSchema,
} from "modules/user/informations/schemas/formSchemas";

import UserActionBar from "modules/user/informations/components/UserActionBar";
import useUser from "modules/users/hooks/useUser";
import UserForms from "modules/user/shared/components/UserForms";
import { getFormValuesFromUser } from "modules/user/informations/helpers/getFormValuesFromUser";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";

const User = () => {
  const { data } = useUser();

  const defaultValues = getFormValuesFromUser(data);
  const methods = useForm<UpdateUserFormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues,
    resolver: zodResolver(updateUserFormSchema),
    shouldFocusError: true,
  });

  const title = useMemo(() => data?.name, [data]);

  return (
    <FormProvider {...methods}>
      <UserActionBar />
      <ScreenContainer>
        <Heading as="h1" size="lg" mb={10} mt={10}>
          {title}
        </Heading>
        <UserForms />
      </ScreenContainer>
    </FormProvider>
  );
};

export default User;
