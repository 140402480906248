import { Variables } from "modules/preview/schemas/templateSchemas";

const getVariablesDetailsFromForm = (
  variables?: { value: string }[]
): Variables | null => {
  if (!variables || variables.length === 0) {
    return null;
  }
  const keyPairs = variables.map((variable, index) => [
    `V${index}`,
    variable.value,
  ]);
  return Object.fromEntries(keyPairs);
};

export default getVariablesDetailsFromForm;
