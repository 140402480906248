import React, { useMemo } from "react";
import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { PATHS } from "modules/shared/constants/paths";
import getItemsFromPathname from "./helpers/getItemsFromPathname";

const BreadNav = () => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const items = useMemo(
    () => getItemsFromPathname(pathname),
    [pathname, i18n.language]
  );
  const { t } = useTranslation();
  return (
    <Breadcrumb>
      {items.length > 0 ? (
        items.map((item, index) =>
          item.path ? (
            <BreadcrumbItem key={`${item.name}-${index}`}>
              {item.path !== "/administration" &&
              item.path !== "/supervision" ? (
                <BreadcrumbLink as={Link} to={item.path}>
                  {item.name}
                </BreadcrumbLink>
              ) : (
                <Text textStyle="breadCrumb">{item.name}</Text>
              )}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={`${item.name}-${index}`} isCurrentPage>
              <BreadcrumbLink>{item.name}</BreadcrumbLink>
            </BreadcrumbItem>
          )
        )
      ) : (
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={PATHS.HOME}>
            {t("breadnav.home")}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};

export default BreadNav;
