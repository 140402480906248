import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import TemplatesTable from "./TemplatesTable";
import { usePendingTemplates } from "modules/templates/hooks/usePendingTemplates";
import { useIsValidator } from "modules/roles/hooks/useIsValidator";

const HotZone = () => {
  const { t } = useTranslation();
  const templates = usePendingTemplates();
  const isValidator = useIsValidator();
  if (!templates || !isValidator) {
    return null;
  }
  const count = templates.length;

  return (
    <Flex bg="abbey" direction="column" data-testid="hot-zone">
      <Text textStyle="label" align="left" color="white" px={10} pt={8} mb={5}>
        {t("templates.review.title", { count })}
      </Text>
      {count > 0 && <TemplatesTable templates={templates} isReviewing={true} />}
    </Flex>
  );
};

export default HotZone;
