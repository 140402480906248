import React, {
  LegacyRef,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Editable,
  EditablePreview,
  EditableInput,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { PreviewFormType } from "modules/create/schemas/formSchema";
import ActionControls from "../ui/ActionControls";
import TextAlignmentForm from "./TextAlignmentForm";
import EditableTextArea from "../ui/EditableTextArea";
import {
  textObjectClassSchema,
  TextObject,
} from "modules/preview/schemas/specSchemas";
import {
  Alignment,
  AlignmentParam,
  layoutFormTypeSchema,
  layoutHybridTypeSchema,
  TextParam,
  HybridType,
} from "../../schemas/templateSchemas";
import resetCSS from "modules/preview/constants/resetCSS";
import useLayout from "modules/preview/hooks/useLayout";
import useTextValue from "modules/preview/hooks/useTextValue";
import usePreviousLayout from "modules/preview/hooks/usePreviousLayout";

interface Props {
  style: StyleProps;
  name: TextObject;
  h: number;
  maxLength: number;
  text: TextParam;
  textAlign: AlignmentParam;
}

interface ControlProps {
  onChange: () => void;
  onBlur: () => void;
  value: string;
  name: string;
  ref?: LegacyRef<HTMLTextAreaElement>;
}

const CustomEditablePreview = styled(EditablePreview)`
  white-space: pre-wrap;
`;

const EditableTextForm = ({
  style,
  name,
  h,
  maxLength,
  text,
  textAlign,
}: Props) => {
  const layout = useLayout();
  const { t } = useTranslation();
  const context = useFormContext<PreviewFormType>();

  // we get the value directly from template for rendering frames outside context
  const textValueFromTemplate = useTextValue(text);
  // field name
  const textInputName = useMemo(() => `text[${text}]`, [text, layout]);

  // we use local state to keep input previous value for cancel button
  const [inputValue, setInputValue] = useState(
    context?.getValues(textInputName)
  );

  const isOT2 = useMemo(() => name === textObjectClassSchema.enum.OT2, [name]);
  const isOT1 = useMemo(() => name === textObjectClassSchema.enum.OT1, [name]);
  const isFull2 = useMemo(
    () => layout === layoutFormTypeSchema.enum.full2,
    [layout]
  );
  const isTextarea = isOT2 || (isOT1 && isFull2);
  const placeholder = isOT1
    ? t("preview.placeholder.title")
    : t("preview.placeholder.text");
  const height = `${h}px`;

  // styles
  const baseStyle = {
    ...style,
    width: "100%",
    height,
  };
  const previewStyle = isTextarea
    ? {
        ...baseStyle,
        lineHeight: "normal",
        minHeight: height,
        fontWeight: "300",
        overflowY: "auto",
        whiteSpace: "pre-wrap",
      }
    : { ...baseStyle };
  const inputStyle = { ...previewStyle, ...resetCSS };

  const previousLayout = usePreviousLayout(layout);
  const hybridLayouts = Object.values(layoutHybridTypeSchema.enum);

  useEffect(() => {
    if (
      hybridLayouts.includes(layout as HybridType) &&
      previousLayout &&
      !hybridLayouts.includes(previousLayout as HybridType)
    ) {
      context.setValue(
        "text[Frame3_Text]",
        context.getValues("text[Frame2_Text]")
      );
      if (textInputName === "text[Frame3_Text]") {
        context?.setValue("text[Frame2_Text]", "");
      }
    }
    if (layout === "banner" && previousLayout !== "banner") {
      context.setValue(
        "text[Frame2_Text]",
        (context.getValues("text[Frame2_Text]") as string).substring(0, 250)
      );
    }
    if (
      hybridLayouts.includes(previousLayout as HybridType) &&
      layout === "full3"
    ) {
      context?.setValue("text[Frame1_Text]", "");
    }
  }, [layout, previousLayout, context]);
  return (
    <>
      {context ? (
        <Controller
          name={textInputName}
          defaultValue=""
          control={context.control}
          render={({ ref, ...rest }: ControlProps) => {
            return (
              <Editable
                isPreviewFocusable={false}
                submitOnBlur={false}
                value={rest.value}
                h={height}
                flex={1}
                placeholder={placeholder}
                selectAllOnFocus={false}
              >
                {({ onSubmit, onEdit, onCancel, isEditing }) => {
                  const handleSubmit = () => {
                    onSubmit();
                  };

                  // keep previous value in state and set focus on input
                  const handleEdit = () => {
                    setInputValue(context.getValues(textInputName));
                    onEdit();
                  };

                  const handleReset = () => {
                    context?.setValue(textInputName, inputValue);
                    onCancel();
                  };
                  return (
                    <>
                      {isEditing && (
                        <TextAlignmentForm
                          name={textAlign}
                          defaultValue={style.textAlign as Alignment}
                        />
                      )}

                      <CustomEditablePreview sx={previewStyle} />

                      {isTextarea ? (
                        <EditableTextArea
                          {...rest}
                          style={inputStyle as StyleProps}
                          maxLength={maxLength}
                          ref={ref as RefObject<HTMLTextAreaElement>}
                          placeholder={placeholder}
                        />
                      ) : (
                        <EditableInput
                          {...rest}
                          sx={inputStyle}
                          maxLength={maxLength}
                          _focus={resetCSS}
                        />
                      )}
                      <ActionControls
                        isEditing={isEditing}
                        onEdit={handleEdit}
                        onReset={handleReset}
                        onSubmit={handleSubmit}
                        name={name}
                      />
                    </>
                  );
                }}
              </Editable>
            );
          }}
        />
      ) : (
        <Text sx={previewStyle}>{textValueFromTemplate}</Text>
      )}
    </>
  );
};

export default EditableTextForm;
