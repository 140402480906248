import * as z from "zod";
import { regionSchema } from "modules/regions/schemas/regionsSchemas";
import { rolesListSchema } from "modules/roles/schemas/rolesSchemas";

export const userStatusSchema = z.enum(["ON", "OFF"]);

const userFromListSchema = z
  .object({
    id: z.number().optional(),
    fallback_user: z.boolean().optional(),
    email: z.string(),
    name: z.string(),
    regions: z.array(regionSchema),
    roles: rolesListSchema,
    status: userStatusSchema,
  })
  .nonstrict();

export type UserFromList = z.infer<typeof userFromListSchema>;

export const userSchema = userFromListSchema
  .extend({
    password: z.string().optional(),
  })
  .nonstrict();

export type User = z.infer<typeof userSchema>;

export const usersListSchema = z.array(userFromListSchema);

export type UsersListBody = z.infer<typeof usersListSchema>;

export const resetUserPasswordSchema = z.object({
  reset_password: z.boolean(),
});

export type ResetUserPassword = z.infer<typeof resetUserPasswordSchema>;

export type RowUserFromList = {
  original: UserFromList;
};
