import { equals } from "remeda";
import { defaultAdvancedSettingsValues } from "modules/create/constants/defaultValues";
import { AdvancedSettings } from "modules/create/schemas/formSchema";

const defaultsValues = {
  ...defaultAdvancedSettingsValues,
  startHour: null,
  endHour: null,
};

const areAdvancedSettingsValuesEqualToDefaultValues = (
  variableValues?: AdvancedSettings
) => equals(defaultsValues, variableValues);

export default areAdvancedSettingsValuesEqualToDefaultValues;
