import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Flex,
  FormHelperText,
  Box,
  Heading,
  HStack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { ProfileFormValues } from "modules/user/profile/schemas/profileFormSchema";
import InputError from "core/components/InputError/InputError";
import SaveProfileAction from "./SaveProfileAction";
import { useRoles } from "modules/roles/hooks/useRoles";
import { useUserRegions } from "modules/regions/hooks/useUserRegions";

const ProfileInfoForm = () => {
  const context = useFormContext<ProfileFormValues>();
  const { roles } = useRoles();
  const regions = useUserRegions();
  const { t } = useTranslation();

  return (
    <Box>
      <Heading as="h2" size="md" mt="10px" textAlign="left">
        {t("create_user.status")}

        <Flex
          mt="10px"
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Flex w="400px" direction="column">
            <FormLabel>{t("create_user.role_list")}</FormLabel>
            <UnorderedList
              m="0px"
              fontSize="sm"
              fontWeight="light"
              listStyleType="none"
            >
              {roles?.map((role) => (
                <ListItem>{role}</ListItem>
              ))}
            </UnorderedList>
          </Flex>
          <Flex w="400px" direction="column">
            <FormLabel>{t("create_user.region_list")}</FormLabel>
            <UnorderedList
              m="0px"
              fontSize="sm"
              fontWeight="light"
              listStyleType="none"
            >
              {regions?.map((region) => (
                <ListItem>{region}</ListItem>
              ))}
            </UnorderedList>
          </Flex>
        </Flex>
      </Heading>
      <Heading as="h2" size="md" mt="20px" textAlign="left">
        {t("create_user.login_form.title")}
      </Heading>
      <Flex
        mt="10px"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <FormControl id="name" w="400px" isInvalid={!!context.errors.name}>
          <FormLabel>{t("create_user.login_form.name.label")}</FormLabel>
          <Input
            variant="outline"
            name="name"
            errorBorderColor="error"
            ref={context.register}
            isDisabled
          />
          <InputError
            text={
              context.errors.name ? t("create_user.login_form.name.error") : ""
            }
          />
        </FormControl>
        <FormControl id="email" w="400px" isInvalid={!!context.errors.email}>
          <FormLabel>{t("create_user.login_form.email.label")}</FormLabel>
          <Input
            variant="outline"
            type="email"
            name="email"
            ref={context.register}
            errorBorderColor="error"
            isDisabled
          />
          <InputError
            text={
              context.errors.email
                ? t("create_user.login_form.email.error")
                : ""
            }
          />
        </FormControl>
        <FormControl
          id="currentPassword"
          mt="30px"
          w="400px"
          isInvalid={!!context.errors.currentPassword}
        >
          <FormLabel>
            {t("create_user.login_form.current_password.label")}
          </FormLabel>
          <Input
            variant="outline"
            type="password"
            ref={context.register}
            name="currentPassword"
            errorBorderColor="error"
          />
        </FormControl>
        <FormControl
          id="password"
          mt="30px"
          w="400px"
          isInvalid={!!context.errors.password?.password}
        >
          <FormLabel>{t("create_user.login_form.password.label")}</FormLabel>
          <Input
            variant="outline"
            type="password"
            name="password.password"
            ref={context.register}
            errorBorderColor="error"
          />
          <FormHelperText
            fontSize="xs"
            color={context.errors.password?.password ? "error" : "abbey"}
            textAlign="left"
            sx={{ whiteSpace: "pre-line" }}
          >
            {t("create_user.login_form.password.hint")}
          </FormHelperText>
        </FormControl>
        <FormControl
          mt="30px"
          w="400px"
          id="password.confirm"
          isRequired
          textAlign="left"
          isInvalid={!!context.errors.password?.confirm}
        >
          <FormLabel>
            {t("create_user.login_form.confirm_password.label")}
          </FormLabel>
          <Input
            variant="outline"
            type="password"
            name="password.confirm"
            ref={context.register}
            errorBorderColor="error"
            isInvalid={!!context.errors.password?.confirm}
          />
          <InputError
            text={
              context.errors.password?.confirm
                ? t("create_user.login_form.confirm_password.error")
                : ""
            }
          />
        </FormControl>

        <HStack spacing={5} width="100%" justifyContent="flex-end" pt={12}>
          <SaveProfileAction />
        </HStack>
      </Flex>
    </Box>
  );
};

export default ProfileInfoForm;
