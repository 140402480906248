import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
} from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { defaultImageTypeQrcodeFormValues } from "../constants/defaultImageTypeQrcodeFormValues";
import { ManageQrcodeForm } from "./ManageQrcodeForm";
import {
  qrcodeFormSchema,
  ManageQrcodeFormValues,
  CustomImage,
} from "../schemas/customImageSchema";
import { useUpdateQrcode } from "../hooks/qrcode/useUpdateQrcode";
import { getUpdatedQrcodeFromFormValues } from "../helpers/getUpdatedQrcodeFromFormValues";
import { useCreateQrcode } from "../hooks/qrcode/useCreateQrcode";
import { useDeleteQrcode } from "../hooks/qrcode/useDeleteQrcode";
import { useUserRegions } from "modules/regions/hooks/useUserRegions";
import WarningAlert from "core/components/ActionFeedback/WarningAlert";
import { QrcodeBody, Mode } from "modules/qrcodes/schemas/customQrcodeSchema";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  qrcode?: QrcodeBody;
  image?: CustomImage;
  mode: Mode;
}

const DEFAULT_SIZE = 1;

export const QrcodeModalForm = ({
  isOpen,
  onClose,
  qrcode,
  mode,
  image,
}: Props) => {
  const { t } = useTranslation();

  const [showWarningAlert, setShowWarningAlert] = useState(false);

  const methods = useForm<ManageQrcodeFormValues>({
    mode: "onChange",
    resolver: zodResolver(qrcodeFormSchema),
    shouldFocusError: true,
    defaultValues: defaultImageTypeQrcodeFormValues,
  });
  const {
    formState: { dirtyFields },
    clearErrors,
    reset,
  } = methods;
  const regions = useUserRegions();
  const createImageTypeQrcode = useCreateQrcode();
  const deleteImageTypeQrcode = useDeleteQrcode();
  const changeQrcode = useUpdateQrcode();

  const disableSaveButton = qrcode?.image_id;

  const disableDeleteButton = useMemo(
    () => !image?.deletable,
    [qrcode?.image_id, image]
  );

  const handleSave = (dataQrcode: ManageQrcodeFormValues) => {
    if (mode === Mode.EDIT) {
      handleChangeImage(dataQrcode);
    } else {
      dataQrcode["size"] = DEFAULT_SIZE;
      createImageTypeQrcode.mutate(dataQrcode, {
        onSettled: handleCloseModal,
      });
    }
  };

  const handleChangeImage = useCallback(
    async (dataImage: ManageQrcodeFormValues) => {
      const hasInfosChanged =
        dirtyFields.target_url ||
        dirtyFields.description ||
        dirtyFields.name ||
        dirtyFields.regions ||
        dataImage.regions !== image?.regions;
      try {
        if (hasInfosChanged) {
          const image = getUpdatedQrcodeFromFormValues(dataImage, regions);
          await changeQrcode.mutateAsync({
            image,
            imageId: qrcode?.id!.toString(),
          });
        }
      } finally {
        handleCloseModal();
      }
    },
    [qrcode?.image_id, dirtyFields]
  );

  const handleDelete = useCallback(() => {
    deleteImageTypeQrcode.mutate(qrcode?.id!, {
      onSettled: handleCloseModal,
    });
  }, [qrcode?.id, deleteImageTypeQrcode]);

  const handleCloseModal = useCallback(() => {
    clearErrors();
    reset(defaultImageTypeQrcodeFormValues);
    if (showWarningAlert) {
      setShowWarningAlert(false);
    }
    onClose();
  }, [clearErrors, reset, onClose, showWarningAlert]);

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered={true}>
      <ModalOverlay />
      <ModalContent minWidth="700px" borderRadius="sm">
        <ModalHeader>
          <Box textStyle="h2">
            {t(
              `assets.qrcode.modal_title_${
                mode === Mode.EDIT ? "change" : "create"
              }` as const
            )}
          </Box>
          <Box textStyle="text">{t("assets.qrcode.hint")}</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methods}>
            <ManageQrcodeForm mode={mode} qrcode={qrcode} image={image} />
          </FormProvider>
        </ModalBody>
        <ModalFooter display="flex" h="160px">
          {showWarningAlert ? (
            <WarningAlert
              title={t("assets.qrcode.warning.title")}
              message={t("assets.qrcode.warning.message")}
              confirm={t("assets.qrcode.warning.confirm")}
              cancel={t("assets.qrcode.warning.cancel")}
              handleAction={handleDelete}
              reset={handleCloseModal}
            />
          ) : (
            <>
              <Button
                variant="tertiary-ghost"
                mr={3}
                onClick={handleCloseModal}
              >
                {t("assets.qrcode.cancel")}
              </Button>
              {qrcode?.image_id && (
                <Button
                  mr={3}
                  variant="secondary-solid"
                  onClick={methods.handleSubmit(handleChangeImage)}
                  disabled={!image?.mutable}
                  isLoading={changeQrcode.isLoading}
                >
                  {t("assets.qrcode.change")}
                </Button>
              )}
              <Button
                variant="secondary-solid"
                isLoading={createImageTypeQrcode.isLoading}
                onClick={
                  qrcode?.image_id
                    ? () => setShowWarningAlert(true)
                    : methods.handleSubmit(handleSave)
                }
                disabled={
                  !!(qrcode?.image_id ? disableDeleteButton : disableSaveButton)
                }
              >
                {t(
                  `assets.qrcode.${
                    qrcode?.image_id ? "delete" : "save"
                  }` as const
                )}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
