import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

interface Profile {
  name?: string;
  email?: string;
  password: string;
  current_password: string;
}

export const saveUserProfile = (profile: Profile) =>
  simpleRequest(
    ENDPOINTS.SELFCARE,
    emptySchema,
    methodSchema.enum.patch,
    profile
  );

export const useSaveUserProfile = () => {
  return useMutationWithErrorHandling(saveUserProfile);
};
