import React from "react";
import { Center, Text, Heading, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const EmptyTemplate = () => {
  const { t } = useTranslation();
  return (
    <Center h="100%">
      <Box>
        <Heading as="h2" variant="h2" color="jumbo" textAlign="center">
          {t("preview.empty.title")}
        </Heading>
        <Text textStyle="text" textAlign="center" mt="20px">
          {t("preview.empty.text")}
        </Text>
      </Box>
    </Center>
  );
};

export default EmptyTemplate;
