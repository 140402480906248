const Tooltip = {
  baseStyle: {
    px: "8px",
    py: "2px",
    bg: "dark",
    "--tooltip-bg": `colors.dark`,
    borderRadius: "sm",

    fontSize: "xs",
    boxShadow: "md",
    maxW: "160px",
  },
};

export default Tooltip;
