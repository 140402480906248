const Container = {
  baseStyle: {
    maxW: "container.xl",
    px: "10",
  },
  variants: {
    body: {
      pb: "24",
    },
  },
};
export default Container;
