import { ProbeType } from "modules/monitoring/ProbeList/schemas/formSchemas";

export function getDisplayValue(metric: ProbeType): string | number {
  if (
    metric.value &&
    typeof metric.value === "object" &&
    !Array.isArray(metric.value)
  ) {
    const keys = Object.keys(metric.value);
    if (keys.length === 1) {
      const values = Object.values(metric.value);
      return values[0] as string | number;
    } else {
      return "N/A";
    }
  }
  return "N/A";
}
