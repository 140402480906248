import React from "react";
import * as z from "zod";
import CancelIcon from "./CancelIcon";
import EditIcon from "./EditIcon";
import ValidateIcon from "./ValidateIcon";

export const controlIconsSchema = z.enum(["cancel", "validate", "edit"]);

export type ControlIcons = z.infer<typeof controlIconsSchema>;

export interface Props {
  icon: ControlIcons;
  onClick: React.MouseEventHandler<SVGElement>;
  isEditing?: boolean;
  isDisabled?: boolean;
}

const ControlIcon = ({
  icon,
  onClick,
  isDisabled = false,
  isEditing = false,
}: Props) => {
  const Icon =
    icon === "cancel" ? CancelIcon : icon === "edit" ? EditIcon : ValidateIcon;
  return (
    <Icon
      key={icon}
      role="button"
      onClick={isDisabled ? undefined : onClick}
      color={isDisabled ? "silver" : isEditing ? "monza" : "amaranth"}
      w="33px"
      h="33px"
      sx={{
        boxShadow: "0px 4px 4px 0px rgba(34, 38, 42, 0.5)",
        cursor: isDisabled ? "not-allowed" : "pointer",
      }}
      _hover={{ color: isDisabled ? "silver" : "monza" }}
    />
  );
};

export default ControlIcon;
