import { useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";

export const restartTemplate = (id: number) => {
  return simpleRequest(
    generatePath(ENDPOINTS.STOP, { id }),
    emptySchema,
    methodSchema.enum.delete
  );
};

export function useRestartTemplate() {
  const queryClient = useQueryClient();
  return useMutationWithErrorHandling(restartTemplate, {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries(["template", id]);
      queryClient.invalidateQueries(["templates"]);
    },
  });
}
