import { Verb, Routes } from "modules/roles/schemas/rolesSchemas";

import { matchPath } from "react-router";

// regex to match "/api/vx" pattern
const apiPathRegex = /\/\bapi\b\/\bv\d\b/;

const hasRightForEndpoint = (
  endpointRoute: string,
  endpointVerbs: Verb[],
  routes?: Routes[]
) => {
  const endpointRawPath = endpointRoute.replace(apiPathRegex, "");
  if (!routes) {
    return false;
  }
  const matchingRoute = routes.find((route) =>
    matchPath(route.route, { path: endpointRawPath, exact: true, strict: true })
  );
  return !!(
    matchingRoute &&
    (matchingRoute.verbs.includes("*") ||
      endpointVerbs.every((verb) => matchingRoute.verbs.includes(verb)))
  );
};

export default hasRightForEndpoint;
