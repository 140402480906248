import {
  layoutFormTypeSchema,
  LayoutFormType,
  layoutEmptySchema,
} from "modules/preview/schemas/templateSchemas";
import { bannerPositionSchema } from "modules/preview/schemas/specSchemas";
import full1 from "assets/full1.svg";
import full2 from "assets/full2.svg";
import full3 from "assets/full3.svg";
import full4 from "assets/full4.svg";
import top from "assets/top.svg";
import bottom from "assets/bottom.svg";
import center from "assets/center.svg";
import banner from "assets/banner.svg";
import empty from "assets/empty.svg";

const svg = { full1, full2, full3, full4, empty, banner, top, bottom, center };

const getSvg = (value: string | number) =>
  `url(${svg[value as LayoutFormType]})`;

const getLinearGradient = (colors: [string, string]) =>
  `to right, ${colors[0]} 50%, ${colors[1]} 50%`;

const getBackgroundProps = (
  isChecked: boolean,
  value: string | number,
  colors?: [string, string]
) =>
  layoutFormTypeSchema.check(value) ||
  bannerPositionSchema.check(value) ||
  layoutEmptySchema.check(value)
    ? `${getSvg(value)} ${isChecked ? "#F3F3F3" : "white"} center no-repeat`
    : `linear-gradient(${getLinearGradient(colors!)})`;

export default getBackgroundProps;
