import { FC } from "react";
import RoundedIcon from "core/components/InterfaceIcons/RoundedIcon";
import { Status } from "../types";
import theme from "theme";

const COLORS = {
  PASSED: theme.colors.validation,
  WARNING: theme.colors.warning,
  FAILED: theme.colors.amaranth,
  ERROR: theme.colors.dark,
  DONE: theme.colors.malibu,
  DEACTIVATED: theme.colors.silver,
};

type Props = {
  status?: Status | null;
};

export const ProbeStatus: FC<Props> = ({ status }: Props) => {
  const color = status
    ? COLORS[status as keyof typeof COLORS] || COLORS["DEACTIVATED"]
    : null;

  return color ? <RoundedIcon color={color} /> : null;
};
