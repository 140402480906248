import React from "react";
import MetricsCard from "./MetricsCard";
import { Flex, Box } from "@chakra-ui/react";
import { useMetrics } from "../useMetrics";
import FullLoader from "core/components/Fullloader/FullLoader";
import { useTranslation } from "react-i18next";
import { ProbeType } from "modules/monitoring/ProbeList/schemas/formSchemas";

const MetricsList = () => {
  const { data, isLoading } = useMetrics();
  const { t } = useTranslation();

  if (isLoading) {
    return <FullLoader />;
  }

  if (!data || !data[0]?.probes) {
    return <Box>{t("metrics.no_metrics")}</Box>;
  }

  return (
    <Flex flexWrap="wrap" justifyContent="center" my={4}>
      {data[0]?.probes &&
        Object.keys(data[0]?.probes)
          .filter(
            (key) =>
              data[0]?.probes[key] !== undefined &&
              data[0]?.probes[key] !== null
          )
          .map((key) => (
            <MetricsCard key={key} metric={data[0]?.probes[key] as ProbeType} />
          ))}
    </Flex>
  );
};

export default MetricsList;
