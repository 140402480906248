import { PreviewFormType } from "modules/create/schemas/formSchema";
import { defaultVariables } from "./defaultValues";

export const defaultEmptyFormValues: Partial<PreviewFormType> = {
  colors: undefined,
  name: "",
  layout: "empty",
  colorScheme: undefined,
  position: undefined,
  extraBanner: undefined,
  theme: null,
  genre: null,
  behavior: null,
  advancedSettings: undefined,
  variables: defaultVariables,
  builtInLogos: { canalplus: false, canalsat: false },
  customLogo: undefined,
};
