import i18n from "i18next";
import { PATHS } from "modules/shared/constants/paths";
import { routeSchema } from "modules/shared/schemas/routesSchemas";

interface Item {
  name: string;
  path?: string;
}

const getItemsFromPathname = (pathname: string) => {
  if (pathname === PATHS.HOME || pathname === PATHS.LOGIN) {
    return [];
  }
  const pathChunks = pathname
    .split("/")
    .filter((pathChunk, index) => index === 0 || pathChunk);
  return pathChunks
    .map((pathChunk, index) => {
      if (index === 0) {
        return { name: i18n.t("breadnav.home"), path: PATHS.HOME };
      }
      if (routeSchema.check(pathChunk)) {
        if (index === pathChunks.length - 1) {
          return { name: i18n.t(`breadnav.${pathChunk}`) };
        }
        if (isNaN(Number(pathChunks[index + 1]))) {
          return {
            name: i18n.t(`breadnav.${pathChunk}`),
            path: `/${pathChunk}`,
          };
        }
        return {
          name: `${i18n.t(`breadnav.${pathChunk}`)} ${pathChunks[index + 1]}`,
        };
      } else return undefined;
    })
    .filter((item) => item !== undefined) as Item[];
};

export default getItemsFromPathname;
