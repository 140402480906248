import React from "react";

import { Container } from "@chakra-ui/react";
import InspectCampaign from "modules/monitoring/InspectCampaign/InspectCampaign";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";

export default function Campaign() {
  return (
    <Container>
      <BreadNav />
      <InspectCampaign />
    </Container>
  );
}
