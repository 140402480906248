import React, { useState, useCallback } from "react";
import { Flex, Button, useDisclosure } from "@chakra-ui/react";

import PlusIcon from "core/components/InterfaceIcons/PlusIcon";
import { useTranslation } from "react-i18next";
import Images from "modules/images/components/Images";
import ImageModal from "modules/images/components/ImageModal";
import { ImageIdContext } from "modules/shared/context/ImageIdContext";

const ImagePanel = () => {
  const { t } = useTranslation();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [imageId, setImageId] = useState<number>();
  const handleChangeImage = (id: number) => {
    setImageId(id);
    onOpen();
  };

  const handleCloseModal = useCallback(() => {
    if (imageId) {
      setImageId(undefined);
    }
    onClose();
  }, [imageId, onClose]);

  return (
    <Flex direction="column">
      <Button
        width="auto"
        alignSelf="flex-end"
        variant="primary-solid"
        my="10px"
        onClick={onOpen}
        leftIcon={<PlusIcon />}
      >
        {t("assets.image.create")}
      </Button>
      <Images onChangeImage={handleChangeImage} />
      <ImageIdContext.Provider value={imageId}>
        <ImageModal isOpen={isOpen} onClose={handleCloseModal} />
      </ImageIdContext.Provider>
    </Flex>
  );
};

export default ImagePanel;
