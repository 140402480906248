import React from "react";
import { Text, Image, StyleProps, Flex } from "@chakra-ui/react";

const ExitTextBlock = ({ style }: { style: StyleProps }) => (
  <Flex w="100%" justifyContent="flex-end">
    <Image
      src={require("assets/moma_out.png").default}
      objectFit="contain"
      display="inline-block"
      mr={5}
    />
    <Text as="span" sx={style}>
      Sortir
    </Text>
  </Flex>
);

export default ExitTextBlock;
