import React from "react";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Link as RouterLink } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";

import { useIsContributor } from "modules/roles/hooks/useIsContributor";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";

const CreateTemplateButton = () => {
  const { t } = useTranslation();
  const isContributor = useIsContributor();
  const isAdministrator = useIsAdministrator();

  return isAdministrator || isContributor ? (
    <Button
      variant="primary-solid"
      as={RouterLink}
      leftIcon={<AddIcon />}
      data-testid="create-button"
      to="/create?step=1"
    >
      {t("templates.create")}
    </Button>
  ) : null;
};

export default CreateTemplateButton;
