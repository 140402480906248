import React, { useCallback, useMemo } from "react";
import { Box, Flex, Table, Tbody, Text } from "@chakra-ui/react";
import { useTable, useSortBy, Column, Cell } from "react-table";
import { useTranslation } from "react-i18next";

import { ActionButtons } from "./ActionButtons";
import {
  qrcodesTableSchema,
  QrcodesListBody,
  QrcodeColumnName,
  QrcodeBody,
  TableActionMode,
} from "modules/qrcodes/schemas/customQrcodeSchema";
import { useUserRegions } from "modules/regions/hooks/useUserRegions";
import filterRowsByName from "modules/filters/helpers/filterRowsByName";
import SwitchFilter from "modules/filters/components/SwitchFilter";
import TableCell from "modules/shared/components/Table/TableCell";
import TableHeader from "modules/shared/components/Table/TableHeader";
import TableRow from "modules/shared/components/Table/TableRow";

export const Qrcodes = ({
  qrcodes,
  onConfirmValue,
  handleChangeImage,
}: {
  qrcodes?: QrcodesListBody;
  onConfirmValue?: (id: number) => void;
  handleChangeImage?: (id: number, type: TableActionMode) => void;
}) => {
  const { t, i18n } = useTranslation();
  const regions = useUserRegions();
  const getHeaderName = useCallback(
    (name: QrcodeColumnName) => t(`qrcode.${name}` as const),
    [i18n.language]
  );

  const handleEdit = useCallback(
    (imageId: number) => {
      if (handleChangeImage) {
        handleChangeImage(imageId, TableActionMode.SHOW_FORM);
      }
    },
    [handleChangeImage]
  );

  const handleView = useCallback(
    (imageId: number) => {
      if (handleChangeImage) {
        handleChangeImage(imageId, TableActionMode.SHOW_QR_CODE);
      }
    },
    [handleChangeImage]
  );

  const columns = useMemo(
    () =>
      qrcodesTableSchema.options.map((name) => ({
        Header: getHeaderName(name),
        accessor: name,
        Cell: ({
          cell: { value, row },
        }: {
          cell: Cell<any, string | string[]>;
        }) => {
          if (name === qrcodesTableSchema.enum.actions && value) {
            return (
              <ActionButtons
                qrcodeId={row.original.image_id}
                imageId={row.values.id}
                handleInsert={
                  onConfirmValue
                    ? () => onConfirmValue(row.original.image_id)
                    : undefined
                }
                handleEdit={() => handleEdit(row.original.image_id)}
                handleView={() => handleView(row.original.image_id)}
              />
            );
          } else if (
            name === "regions" &&
            (row.values.regions.length === 0 ||
              row.values.regions.length === regions?.length)
          ) {
            return <TableCell value={t("qrcode.all_regions")} />;
          }
          return <TableCell value={value} />;
        },

        Filter: SwitchFilter,
        filter: (...args) => filterRowsByName(...args, name),
      })),
    [getHeaderName]
  ) as Column<QrcodeBody>[];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<QrcodeBody>(
      {
        columns,
        data: qrcodes || [],
      },
      useSortBy
    );

  if (qrcodes?.length === 0) {
    return (
      <Flex mt="10px" d="column">
        <Text m="20px" align="center">
          {t("qrcode.error.no_qrcode")}
        </Text>
      </Flex>
    );
  }

  return (
    <Box overflow="auto" pt={5} pb={10} data-testid="qrcodes-table">
      <Table {...getTableProps()} textStyle="bodySmall" w="100%">
        <TableHeader headerGroups={headerGroups} />
        <Tbody {...getTableBodyProps()} bgColor="white">
          {rows.map((row) => {
            prepareRow(row);
            return <TableRow key={row.id} row={row} />;
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
