import { useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { useParams } from "react-router-dom";

import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { DeleteUserFormValues } from "modules/user/informations/schemas/formSchemas";

interface FallbackUser {
  fallback_user_id: number;
}

export const deleteUser = (falbackUser: FallbackUser, userId: string) =>
  simpleRequest(
    generatePath(ENDPOINTS.USER, { id: userId! }),
    emptySchema,
    methodSchema.enum.delete,
    falbackUser
  );

export function useDeleteUser() {
  const { id } = useParams<RouteIdParam>();
  const queryClient = useQueryClient();
  return useMutationWithErrorHandling(
    (data: DeleteUserFormValues) =>
      deleteUser({ fallback_user_id: data.id }, id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", Number(id)]);
        queryClient.invalidateQueries(["users"]);
      },
    }
  );
}
