import {
  Button,
  Modal,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { NotAllowedIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import AlertIcon from "core/components/InterfaceIcons/AlertIcon";
import { useEmergencyStop } from "./hooks/useEmergencyStop";

interface Props {
  region: any;
}
const EmergencyStopModal = ({ region }: Props) => {
  const stopTemplate = useEmergencyStop();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  return (
    <>
      <HStack>
        {region.is_active ? <NotAllowedIcon /> : <CheckCircleIcon />}

        <Button variant="link-button" color="dark" onClick={onOpen}>
          {region.is_active ? t("emergency.stop") : t("emergency.restart")}
        </Button>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={"30%"}>
          <ModalHeader>{t("emergency.modal")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{t("emergency.message")}</Text>
          </ModalBody>

          <ModalFooter display="flex" justifyContent="space-between">
            <Button
              onClick={() =>
                stopTemplate.mutate({
                  region: region.name,
                  is_active: !region.is_active,
                })
              }
              leftIcon={<AlertIcon />}
              variant="secondary-solid"
            >
              {region.is_active ? t("emergency.stop") : t("emergency.restart")}
            </Button>
            <Button variant="secondary-solid" mr={3} onClick={onClose} mt={0}>
              {t("supervision.close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmergencyStopModal;
