const Link = {
  variants: {
    "nav-links": {
      color: "white",
      fontWeight: "bold",
      fontSize: "xs",

      py: "25px",
      px: "17px",
      borderBottom: "2px",
      borderColor: "transparent",

      _hover: {
        color: "wewak",
        borderBottom: "2px",
        py: "1.6rem",
        textDecoration: "none",
      },
    },

    "nav-links-active": {
      color: "sunglo",
      borderBottom: "2px",
      fontWeight: "bold",
      textDecoration: "none",
      fontSize: "xs",
      py: "25px",
      px: "17px",

      _hover: {
        color: "wewak",
        borderBottom: "2px",
        py: "1.6rem",
        textDecoration: "none",
      },
    },

    "nav-links-disabled": {
      color: "jumbo",

      py: "2.1rem",
      fontWeight: "bold",
      textDecoration: "none",
      fontSize: "sm",

      _hover: {
        textDecoration: "none",
        cursor: "not-allowed",
      },
    },

    "link-with-icon": {
      color: "dark",
      fontWeight: "bold",
      fontSize: "xs",
      textDecoration: "none",
      _hover: {
        textDecoration: "none",
        color: "amaranth",
      },
    },
  },
};

export default Link;
