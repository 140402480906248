import React, { useEffect } from "react";
import { Heading, Flex, Text, Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Frames from "modules/preview/components/Frames";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import useTemplate from "modules/preview/hooks/useTemplate";
import {
  feedbackSchema,
  actionsSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import FinalizationInfo from "modules/finalize/components/FinalizationInfo";

import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import useTestedTemplate from "modules/preview/hooks/useTestedTemplate";
import ArrowBackIcon from "core/components/InterfaceIcons/ArrowBackIcon";
import { Link as RouterLink } from "react-router-dom";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";
import useIsArchivedTemplate from "modules/preview/hooks/useIsArchivedTemplate";
import ErrorMessage from "modules/shared/components/ErrorHandler/ErrorMessage";

const Finalization = () => {
  useTemplate();
  const { t } = useTranslation();
  const isTested = useTestedTemplate();
  const { setFeedback, reset } = useFeedbackStore();
  const { id } = useParams<RouteIdParam>();
  const isArchived = useIsArchivedTemplate();

  useEffect(() => {
    reset();
    if (!isTested) {
      setFeedback(feedbackSchema.enum.error, actionsSchema.enum.finalize);
    }
  }, [isTested]);

  return isArchived ? (
    <ErrorMessage messageText={t("errors.archived")} />
  ) : (
    <ScreenContainer>
      <Heading as="h1" size="lg" mb={10} mt={10}>
        {t("actions.finalize.title")}
      </Heading>
      <Flex>
        <Box>
          <Flex justifyContent="space-between" mb="5px">
            <Text fontSize="xs"> {t("actions.finalize.subtitle")}</Text>
            <Button
              leftIcon={<ArrowBackIcon mr="5px" />}
              variant="link-button"
              as={RouterLink}
              to={`/template/${id}`}
            >
              {t("actions.finalize.back")}
            </Button>
          </Flex>
          <Frames />
        </Box>
        <Box>
          <FinalizationInfo />
        </Box>
      </Flex>
    </ScreenContainer>
  );
};

export default Finalization;
