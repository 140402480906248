import React from "react";
import { createIcon } from "@chakra-ui/react";

const LeftIcon = createIcon({
  displayName: "LeftIcon",
  viewBox: "0 0 18 21",
  path: (
    <>
      <path
        d="M12 13.8353H0V16.0022H12V13.8353ZM12 5.16795H0V7.33479H12V5.16795ZM0 11.6685H18V9.50164H0V11.6685ZM0 20.3359H18V18.169H0V20.3359ZM0 0.834259V3.0011H18V0.834259H0Z"
        fill="currentColor"
      />
      ,
    </>
  ),
});

export default LeftIcon;
