import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { ColorScheme } from "modules/preview/schemas/colorSchemesSchemas";
import { createColorSchemeBodySchema } from "modules/colorSchemes/schemas/createColorSchemeApiSchemas";
import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { PATHS } from "modules/shared/constants/paths";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";

export const createColorScheme = (colorScheme: ColorScheme) =>
  simpleRequest(
    ENDPOINTS.COLORSCHEMES,
    createColorSchemeBodySchema,
    methodSchema.enum.post,
    colorScheme
  );

export const useCreateColorScheme = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(createColorScheme, {
    onSuccess: async ({ id }) => {
      await queryClient.invalidateQueries("colorSchemes");
      history.push(generatePath(PATHS.COLORSCHEME, { id }));
      setFeedback(
        feedbackSchema.enum.success,
        actionsSchema.enum.saveColorScheme
      );
    },
  });
};
