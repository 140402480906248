import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Form as QrcodeForm } from "./forms/QrcodeForm/Form";
import { useIsDisableEmptyLayout } from "../hooks/useIsDisableEmptyLayout";
import BuiltInLogos from "./forms/BuiltInLogos";
import CustomLogoForm from "./forms/CustomLogoForm";

const Logos = () => {
  const { t } = useTranslation();
  const isDisableEmptyLayout = useIsDisableEmptyLayout();

  return (
    <Tabs p={0}>
      <TabList>
        <Tab>{t("preview.logos.customLogo.name")}</Tab>
        <Tab>{t("preview.logos.builtInLogos.name")}</Tab>
        <Tab>{t("preview.logos.qrcodes.name")}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel p={0}>
          <CustomLogoForm isDisabled={isDisableEmptyLayout} />
        </TabPanel>
        <TabPanel p={0}>
          <BuiltInLogos isDisabled={isDisableEmptyLayout} />
        </TabPanel>
        <TabPanel p={0}>
          <QrcodeForm isDisabled={isDisableEmptyLayout} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default Logos;
