import { useQuery } from "react-query";
import * as z from "zod";

import { dashboardSchema } from "../schemas/formSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { simpleRequest } from "core/services/api";

export const dashboard = () =>
  simpleRequest(ENDPOINTS.DASHBOARDS, dashboardSchema);

export function useDashboards(fetch = true) {
  const { data, ...rest } = useQuery(["dashboards"], dashboard, {
    enabled: fetch,
  });
  const meteoDashboard = data?.filter(
    (dashboard) => dashboard.category_name === "METEO"
  );

  try {
    dashboardSchema.parse(meteoDashboard);
  } catch (err) {
    if (err instanceof z.ZodError) {
      console.error("ZodError:", err, err.errors);
    }
  }

  return { data: meteoDashboard, ...rest };
}
