import * as z from "zod";

const sentinelDataSchemas = z.array(
  z.object({
    ts_id: z.number(),
    last_appearance: z.string(),
  })
);

export const validSentinelStatus = z.enum([
  "broadcast",
  "preparing",
  "checking_test",
  "unused_and_not_broadcast",
  "archived",
]);

export const invalidSentinelStatus = z.enum([
  "unsued_and_broadcast",
  "used_and_not_broadcast",
  "archived_and_broadcast",
  "unknown_and_broadcast",
]);

export const statusSentinelTemplate = z.enum([
  ...validSentinelStatus.options,
  ...invalidSentinelStatus.options,
]);

export const inspectedTemplateSchema = z.object({
  author: z.string().optional(),
  creation_date: z.string().optional(),
  genre: z.string().nullable().optional(),
  id: z.number(),
  label: z.string().nullable().optional(),
  last_modified: z.string().optional(),
  last_used: z.string().nullable().optional(),
  name: z.string().optional(),
  networks: z.array(z.string()).optional(),
  regions: z.array(z.string()).optional(),
  sentinel_status: z.string(),
  sentinel_data: sentinelDataSchemas.optional(),
  total_messages: z.number(),
  status: z.string().optional(),
  theme: z.string().nullable().optional(),
});

export const inspectedTemplatesSchema = z.array(inspectedTemplateSchema);
export type TemplateType = z.infer<typeof inspectedTemplatesSchema>;

export const inspectedKeyTemplate = z.object({
  id: z.number(),
  name: z.string().optional(),
  ts_id: z.number().optional().optional(),
  sentinel_status: z.string(),
  total_messages: z.number(),
  last_appearance: z.string().optional(),
});
export const inspectedKeysTemplate = z.array(inspectedKeyTemplate);
export type typeTplinspectedKeysTemplate = z.infer<typeof inspectedKeyTemplate>;

export const templatesColumnNameSchema = z.enum([
  "id",
  "name",
  "ts_id",
  "sentinel_status",
  "total_messages",
  "last_appearance",
]);

export type TemplatesColumnName = z.infer<typeof inspectedKeyTemplate>;
