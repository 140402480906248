import React, { ReactNode } from "react";
import { actionsNameSchema } from "modules/shared/schemas/actionsSchemas";
import { Button, VStack } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ActionIconBlock from "core/components/ActionBlock/ActionIconBlock";
import EditIcon from "core/components/InterfaceIcons/EditIcon";
import ReviewIcon from "core/components/InterfaceIcons/ReviewIcon";
import ViewIcon from "core/components/InterfaceIcons/ViewIcon";

interface Props {
  value: string | string[];
  isReviewing?: boolean;
  to: string;
  children?: ReactNode;
  onclick?: () => void;
}

const ActionCell = ({ value, isReviewing = false, to, children }: Props) => {
  const { t } = useTranslation();

  return isReviewing ? (
    <Button
      colorScheme="light"
      leftIcon={<ReviewIcon mr="5px" />}
      variant="link-button"
      as={RouterLink}
      to={to}
    >
      {t("templates.review.action")}
    </Button>
  ) : (
    <VStack spacing={2} alignItems="flex-start">
      <ActionIconBlock
        icon={
          value.includes(actionsNameSchema.enum.write) ? (
            <EditIcon />
          ) : value.includes(actionsNameSchema.enum.read) ? (
            <ViewIcon />
          ) : (
            <DeleteIcon />
          )
        }
        text={
          value.includes(actionsNameSchema.enum.write)
            ? t("actions.table.write")
            : value.includes(actionsNameSchema.enum.read)
            ? t("actions.table.read")
            : t("actions.table.delete")
        }
        to={to}
      />
      {children}
    </VStack>
  );
};

export default ActionCell;
