import { useQueryClient } from "react-query";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { TemplateFromList } from "modules/qrcodes/schemas/templateModelSchemas";

export const deleteQrcode = (qrcodeId: number) =>
  simpleRequest(
    generatePath(ENDPOINTS.QR_CODE, { id: qrcodeId.toString() }),
    emptySchema,
    methodSchema.enum.delete
  );

export function useDeleteQrcode() {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();

  return useMutationWithErrorHandling(deleteQrcode, {
    onMutate: async (qrcodeId) => {
      await queryClient.cancelQueries(["qrcodes"]);
      const previousQrcodes = queryClient.getQueryData(["qrcodes"]);

      queryClient.setQueryData(["qrcodes"], (oldQrcodes: any) => {
        return oldQrcodes.filter(
          (qrcode: TemplateFromList) => qrcode.id !== qrcodeId
        );
      });

      return { previousQrcodes };
    },
    onSuccess: (_, qrcodeId) => {
      queryClient.invalidateQueries(["images"]);
      queryClient.invalidateQueries(["qrcode", qrcodeId]);
      setFeedback(feedbackSchema.enum.success, actionsSchema.enum.deleteQrcode);
    },
  });
}
