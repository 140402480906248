import * as z from "zod";

export const usersColumnNameSchema = z.enum([
  "id",
  "email",
  "name",
  "roles",
  "regions",
  "status",
  "actions",
]);

export const usersFilterNameSchema = z.enum(["name", "roles", "regions"]);

export type UsersColumnName = z.infer<typeof usersColumnNameSchema>;
