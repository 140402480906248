import React, { useMemo, useEffect } from "react";
import { merge } from "remeda";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";

import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import getTemplateFromFormValues from "modules/preview/helpers/getTemplateFromFormValues";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import { useSaveTemplate } from "modules/actions/hooks/useSaveTemplate";
import { useCreateTemplate } from "modules/actions/hooks/useCreateTemplate";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import useTemplate from "modules/preview/hooks/useTemplate";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import DraftIcon from "core/components/InterfaceIcons/DraftIcon";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { useIsContributor } from "modules/roles/hooks/useIsContributor";
import useIsEmptyLayout from "modules/preview/hooks/useIsEmptyLayout";
import useLayout from "modules/preview/hooks/useLayout";
import {
  HybridType,
  layoutHybridTypeSchema,
} from "modules/preview/schemas/templateSchemas";

const SaveAction = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const history = useHistory();
  const { data } = useTemplate(false);
  const save = useSaveTemplate();
  const create = useCreateTemplate();
  const context = useFormContext<PreviewFormType>();
  const isContributor = useIsContributor();
  const { setFeedback } = useFeedbackStore();

  const status = useMemo(() => data?.functional_details?.status, [data]);

  const isNotDraft = status && status !== "DRAFT";
  const isEmptyLayout = useIsEmptyLayout();
  const isAdmin = useIsAdministrator();
  const disabled =
    isNotDraft || (isEmptyLayout && !isAdmin) || (!isAdmin && !isContributor);

  const handleError = () => {
    setFeedback(feedbackSchema.enum.error, actionsSchema.enum.save);
  };
  const layout = useLayout();
  const hybridLayouts = Object.values(layoutHybridTypeSchema.enum);

  //clean frame3_text if layour is not an hybrid
  useEffect(() => {
    if (!hybridLayouts.includes(layout as HybridType)) {
      context?.setValue("text[Frame3_Text]", "");
    }
  }, [hybridLayouts, layout, context]);

  const handleSave = (formValues: PreviewFormType) => {
    const template = merge(data, getTemplateFromFormValues(formValues));
    if (id) {
      save.mutate(template, {
        onSuccess: () => {
          setFeedback(feedbackSchema.enum.success, actionsSchema.enum.save);
        },
      });
    } else {
      create.mutate(template, {
        onSuccess: ({ templateId }) => {
          history.push(`/template/${templateId}`);
          setFeedback(feedbackSchema.enum.success, actionsSchema.enum.save);
        },
      });
    }
  };

  return (
    <Button
      onClick={context?.handleSubmit(handleSave, handleError)}
      leftIcon={<DraftIcon />}
      variant="primary-solid"
      isDisabled={disabled}
      isLoading={save.isLoading}
    >
      {t("actions.save.button")}
    </Button>
  );
};

export default SaveAction;
