import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import useTemplateStatus from "modules/preview/hooks/useTemplateStatus";

const Container = styled(Box)`
  padding: 8px 16px;
`;

const TemplateStatus = () => {
  const { t } = useTranslation();
  const status = useTemplateStatus();
  if (!status) {
    return null;
  }
  return (
    <Container>
      <Text textStyle="label" color="abbey">
        {t("preview.status")} {t(`templates.status_values.${status}` as const)}
      </Text>
    </Container>
  );
};

export default TemplateStatus;
