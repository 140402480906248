import { useContext } from "react";
import { generatePath } from "react-router";
import { useQueryClient } from "react-query";
import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  feedbackSchema,
  actionsSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { UserTestCard } from "modules/user/cards/schemas/userTestCardsApiSchemas";
import { CreateCardFormValues } from "../schemas/formSchemas";
import { UserIdContext } from "modules/shared/context/UserIdContext";
import { useSelfcare } from "modules/selfcare/hooks/useSelfcare";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";

export const editTestCard = (card: UserTestCard, userId?: string) =>
  userId
    ? simpleRequest(
        generatePath(ENDPOINTS.USER_TEST_CARD, {
          id: userId!,
          test_card_id: card.id!,
        }),
        emptySchema,
        methodSchema.enum.patch,
        card
      )
    : Promise.reject();

export const useEditTestCard = () => {
  const { data } = useSelfcare(false);
  const contextUserId = useContext(UserIdContext);
  const userId = contextUserId ?? data?.id.toString();
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(
    (data: CreateCardFormValues) => editTestCard(data, userId),
    {
      onSuccess: () => {
        contextUserId
          ? queryClient.invalidateQueries(["userTestCards", Number(userId)])
          : queryClient.invalidateQueries(["selfcare"]);
        setFeedback(feedbackSchema.enum.success, actionsSchema.enum.editCard);
      },
    }
  );
};
