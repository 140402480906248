import { generatePath } from "react-router";
import { useQueryClient } from "react-query";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { CustomizedColorScheme } from "modules/preview/schemas/colorSchemesSchemas";
import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";

export const saveColorScheme = (colorScheme: CustomizedColorScheme) =>
  simpleRequest(
    generatePath(ENDPOINTS.COLORSCHEME, { id: colorScheme.id! }),
    emptySchema,
    methodSchema.enum.patch,
    colorScheme
  );

export const useSaveColorScheme = () => {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(saveColorScheme, {
    onSuccess: () => {
      setFeedback(
        feedbackSchema.enum.success,
        actionsSchema.enum.saveColorScheme
      );
      queryClient.invalidateQueries(["colorSchemes"]);
    },
  });
};
