import { TestCardBody } from "../schemas/testCardsApiSchemas";
import i18n from "i18next";

const testCardsTransform = (cards: TestCardBody) =>
  cards.map((card) => ({
    value: card.id,
    label: card.description,
    subtitle: `${i18n.t("actions.test.form.card")} ${card.id}`,
  }));

export default testCardsTransform;
