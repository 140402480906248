import { GlobalMessageFormValues } from "../schemas/globalMessageFormSchema";

const now = new Date();
export const defaultFormValues: Partial<GlobalMessageFormValues> = {
  title: "",
  template_id: undefined,
  start_date: now,
  end_date: now,
  filters: { card_valid: undefined },
};
