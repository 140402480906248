import * as z from "zod";

export const inspectedCampaignSchemas = z.object({
  id: z.number(),
  alias: z.string().nullable(),
  network: z.string(),
  region: z.string(),
  author: z.string(),
  origin: z.string(),
  batch_id: z.number().nullable(),
  template_id: z.number().nullable(),
  start_date: z.string(),
  end_date: z.string(),
  test: z.boolean(),
  messages_count: z.number(),
});

export const inspectedCampaignsSchemas = z.array(inspectedCampaignSchemas);

export type inspectedCampaignType = z.infer<typeof inspectedCampaignSchemas>;

export const campaignColumnNameSchema = z.enum([
  "id",
  "region",
  "template_id",
  "start_date",
  "end_date",
  "messages_count",
]);

export type CampaignColumnName = z.infer<typeof campaignColumnNameSchema>;
