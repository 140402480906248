import { TemplatesListBody } from "modules/templates/schemas/templateModelSchemas";

const getSelectOptionFromTemplates = (templates: TemplatesListBody) => {
  const options = templates.map(({ template_id, template_name }) => ({
    value: template_id,
    label: `[${template_id}] ${template_name}`,
  }));

  return options;
};

export default getSelectOptionFromTemplates;
