import React from "react";

import { Container } from "@chakra-ui/react";
import InspectMessageForm from "modules/monitoring/Inspect/InspectForm";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";

export default function Inspection() {
  return (
    <Container>
      <BreadNav />
      <InspectMessageForm />
    </Container>
  );
}
