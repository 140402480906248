import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import useTokenStore from "modules/authentication/hooks/useTokenStore";
import axios from "axios";
import getApiUrl from "core/helpers/env/getApiUrl";
import { generatePath } from "react-router-dom";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { CustomImage } from "../schemas/customImageSchema";

export const fetchImageUrl = async (
  imageUrl?: string
): Promise<string | undefined> => {
  const token = useTokenStore.getState().token;
  const decodeUrl =
    imageUrl && generatePath(getApiUrl(ENDPOINTS.IMAGE_URL), { imageUrl });

  if (!decodeUrl || !token) {
    return undefined;
  }
  try {
    const result = await axios(decodeURIComponent(decodeUrl), {
      headers: { "x-auth-token": token },
      responseType: "blob",
    });
    return URL.createObjectURL(result.data);
  } catch (error) {
    return undefined;
  }
};

export function useImageUrl(
  image?: CustomImage,
  queryOptions?: UseQueryOptions<string | undefined, Error>
): UseQueryResult<string | undefined, Error> {
  return useQuery<string | undefined, Error>(
    ["imagesUrl", image?.id],
    () => fetchImageUrl(image?.url),
    {
      staleTime: Infinity,
      retry: false,
      ...queryOptions,
    }
  );
}
