import React from "react";
import { Tr, Td } from "@chakra-ui/react";
import { Row } from "react-table";

interface Props<T extends object> {
  row: Row<T>;
}

const MAX_WIDTH = 50;

const TableRow = <T extends object>({ row }: Props<T>) => (
  <Tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      const styleProps = {
        ...(cell.column.minWidth !== null && {
          minWidth: cell.column.minWidth,
        }),
        maxWidth: MAX_WIDTH,
      };
      return (
        <Td {...cell.getCellProps()} {...styleProps}>
          {cell.render("Cell")}
        </Td>
      );
    })}
  </Tr>
);

export default TableRow;
