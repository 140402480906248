import {
  TemplateType,
  typeTplinspectedKeysTemplate,
} from "../Schemas/InspectionTemplatesSchemas";

export const useSentinelTemplates = (
  data?: TemplateType
): typeTplinspectedKeysTemplate[] => {
  let result: typeTplinspectedKeysTemplate[] = [];
  data?.forEach((template) => {
    let tpl = {
      id: template.id,
      name: template.name,
      sentinel_status: template.sentinel_status,
      total_messages: template.total_messages,
    };
    template.sentinel_data?.forEach((item) => {
      tpl = {
        ...tpl,
        ...{ ts_id: item.ts_id, last_appearance: item.last_appearance },
      };
    });
    result.push(tpl);
  });

  return result;
};
