/**
 * check if a password has at least 1 uppercase, 1 lowercase, 1 number, 1 special character and at least 8 characters
 * @param password
 * @returns boolean
 * @signature
 *    isPasswordCompliant(string)
 * @category string
 */
const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const isPasswordCompliant = (password: string) => passwordRegex.test(password);

export default isPasswordCompliant;
