const Switch = {
  baseStyle: {
    track: {
      bg: "abbey",

      _disabled: {
        bg: "silver",
        cursor: "not-allowed",
      },
      _checked: {
        bg: "validation",
      },
    },
  },
};
export default Switch;
