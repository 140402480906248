import React, { useState, useEffect, useMemo } from "react";
import {
  Flex,
  Input,
  Button,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import "../styles/style-picker.css";
import { HexColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";
import { useDebouncyEffect } from "use-debouncy";
import getOpacityValueFromHexColor from "../helpers/getOpacityValueFromHexColor";
import getColorValueFromHexColor from "../helpers/getColorValueFromHexColor";

interface Props {
  name: string;
  value: string;
  onChange: (color: string) => void;
}
const DEBOUNCE_DELAY = 400;
const ColorPicker = ({ name, value, onChange }: Props) => {
  const { t } = useTranslation();

  const opacityValue = useMemo(
    () => getOpacityValueFromHexColor(value),
    [value]
  );
  const hexValue = useMemo(() => getColorValueFromHexColor(value), [value]);

  const [color, setColor] = useState(hexValue);
  const [opacity, setOpacity] = useState(opacityValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setColor(event.target.value);

  useEffect(() => {
    setColor(hexValue);
  }, [hexValue]);

  useEffect(() => {
    setOpacity(opacityValue);
  }, [opacityValue]);

  useDebouncyEffect(
    () => onChange(color.length > 6 && opacity ? color.concat(opacity) : color),
    DEBOUNCE_DELAY,
    [color, opacity]
  );

  return (
    <Flex
      className="color-picker"
      justifyContent="space-between"
      flexDirection="column"
    >
      <HexColorPicker color={color} onChange={setColor} />
      <HStack mt="10px">
        <Input
          width="180px"
          height="30px"
          borderRadius="sm"
          value={color || "#"}
          name={name}
          onChange={handleChange}
          fontSize="14px"
        />
        <Popover>
          <PopoverTrigger>
            <Button variant="tertiary-ghost" width="115px" height="30px">
              {t("assets.color_scheme.color_picker_opacity_button")}
            </Button>
          </PopoverTrigger>
          <PopoverContent width="auto">
            <PopoverHeader textStyle="subtitle">
              {t("assets.color_scheme.color_picker_opacity_title")}
            </PopoverHeader>
            <PopoverBody>
              <RadioGroup onChange={setOpacity} value={opacity || ""}>
                <Stack direction="column">
                  <Radio value="">
                    {" "}
                    {t("assets.color_scheme.color_picker_opacity_100")}
                  </Radio>
                  <Radio value="bf">
                    {t("assets.color_scheme.color_picker_opacity_75")}
                  </Radio>
                  <Radio value="80">
                    {t("assets.color_scheme.color_picker_opacity_50")}
                  </Radio>
                  <Radio value="40">
                    {t("assets.color_scheme.color_picker_opacity_25")}
                  </Radio>
                  <Radio value="00">
                    {t("assets.color_scheme.color_picker_opacity_0")}
                  </Radio>
                </Stack>
              </RadioGroup>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
    </Flex>
  );
};

export default ColorPicker;
