import { useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { useParams } from "react-router-dom";
import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { GlobalMessage } from "../schemas/globalMessageFormSchema";

export const saveGlobalMessage = (globalMessage: GlobalMessage) =>
  simpleRequest(
    generatePath(ENDPOINTS.GLOBAL, { id: globalMessage.id }),
    emptySchema,
    methodSchema.enum.patch,
    globalMessage
  );

export function useSaveGlobalMessage() {
  const queryClient = useQueryClient();
  const { id } = useParams<RouteIdParam>();

  return useMutationWithErrorHandling(saveGlobalMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["global", Number(id)]);
      queryClient.invalidateQueries(["globals"]);
    },
  });
}
