import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "@chakra-ui/react";

import HourForm from "modules/preview/components/forms/HourForm";
import { advancedSettingsHourSchema } from "modules/create/schemas/formSchema";

const TimeForm = () => {
  const { t } = useTranslation();

  return (
    <Flex mb="20px" mt="20px" direction="column">
      <Text color="dark" mb="10px" fontWeight={500}>
        {t("preview.settings.advanced.timeRange.label")}
      </Text>
      <Flex mb="10px" justifyContent="space-between" paddingRight="5px">
        <HourForm name={advancedSettingsHourSchema.enum.startHour} />
        <HourForm name={advancedSettingsHourSchema.enum.endHour} />
      </Flex>
      <Text fontSize="xs" color="abbey">
        {t("preview.settings.advanced.timeRange.hint")}
      </Text>
    </Flex>
  );
};

export default TimeForm;
