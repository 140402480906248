import hasRightForEndpoint from "modules/roles/helpers/hasRightForEndpoint";
import { verbSchema } from "modules/roles/schemas/rolesSchemas";
import { useSelfcare } from "modules/selfcare/hooks/useSelfcare";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";

export function useHasStopTemplatesRights() {
  const { data } = useSelfcare(false);
  return hasRightForEndpoint(
    ENDPOINTS.STOP,
    [verbSchema.Enum.POST],
    data?.routes
  );
}
