/**
 * returns the modulo value of 2 numbers, with a correct value if the first one is negative
 * @param number
 * @param number
 * @returns number
 * @signature
 *    modulo(number, number)
 * @category number
 */

const modulo = (value: number, integer: number) =>
  ((value % integer) + integer) % integer;

export default modulo;
