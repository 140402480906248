import { useQueryClient } from "react-query";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { emptySchema } from "modules/shared/webservices/schemas/responseSchemas";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";

export const updateQrcode = ({ image, imageId }: any) => {
  return simpleRequest(
    generatePath(ENDPOINTS.QR_CODE, { id: imageId }),
    emptySchema,
    methodSchema.enum.patch,
    image
  );
};

export function useUpdateQrcode() {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(updateQrcode, {
    onSuccess: (_, { imageId }) => {
      queryClient.invalidateQueries(["qrcodes"]);
      queryClient.invalidateQueries(["images"]);
      queryClient.invalidateQueries(["qrcode", imageId]);
      setFeedback(feedbackSchema.enum.success, actionsSchema.enum.changeQrcode);
    },
  });
}
