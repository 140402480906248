import { Row } from "react-table";
import parse from "date-fns/parse";
import { isBefore, isAfter, isSameDay } from "date-fns";
import { intersection } from "remeda";

export default function filterRowsByName<T extends object>(
  rows: Row<T>[],
  columnIds: string[],
  filterValues: any,
  name: string
) {
  const name_filter = columnIds.toString();

  if (name_filter === "creation_date") {
    return rows.filter((row) => {
      const creationDate = parse(
        row.values.creation_date,
        "yyyy-MM-dd",
        new Date()
      );
      if (filterValues[0] === undefined) {
        return rows;
      } else
        return filterValues[1]
          ? (isAfter(filterValues[1], creationDate) &&
              isBefore(filterValues[0], creationDate)) ||
              isSameDay(filterValues[0], creationDate) ||
              isSameDay(filterValues[1], creationDate)
          : isSameDay(filterValues[0], creationDate);
    });
  }

  if (Array.isArray(filterValues)) {
    return rows.filter((row) => {
      if (filterValues.length === 0) {
        return true;
      }
      const rowValue = row.values[name];
      if (Array.isArray(rowValue)) {
        return intersection(rowValue, filterValues).length > 0;
      }

      return filterValues.includes(row.values[name]);
    });
  }
  if (typeof filterValues === "string") {
    /* For users, add the possibility to filter with name and email*/
    if (name_filter === "name") {
      return rows.filter((row) => {
        return (
          (row.values[name_filter].toString() || "").includes(filterValues) ||
          row.values["email"].toString().includes(filterValues)
        );
      });
    }
    return rows.filter((row) => {
      return (row.values[name].toString() || "").includes(filterValues);
    });
  }
  return rows;
}
