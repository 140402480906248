import { TemplatesListBody } from "modules/templates/schemas/templateModelSchemas";

const filterTemplatesByRegions = (
  templates: TemplatesListBody,
  selectedRegions: string[]
) =>
  templates.filter(({ regions }) =>
    regions.some((region) => selectedRegions.includes(region))
  );

export default filterTemplatesByRegions;
