import React from "react";
import GraphCard from "./GraphCard";
import { Flex, Box } from "@chakra-ui/react";
import { useGraphs } from "../useGraphs";
import FullLoader from "core/components/Fullloader/FullLoader";
import { useTranslation } from "react-i18next";
import { ProbeType } from "modules/monitoring/ProbeList/schemas/formSchemas";

const GraphsList = () => {
  const { data, isLoading } = useGraphs();
  const { t } = useTranslation();
  if (isLoading) {
    return <FullLoader />;
  }

  if (!data || !data[0]?.probes) {
    return <Box>{t("graphs.no_graph")}</Box>;
  }

  return (
    <Flex flexWrap="wrap" gridGap={8} my={8}>
      {data[0]?.probes &&
        Object.keys(data[0]?.probes)
          .filter(
            (key) =>
              data[0]?.probes[key] !== undefined &&
              data[0]?.probes[key] !== null
          )
          .map((key) => (
            <GraphCard key={key} graph={data[0]?.probes[key] as ProbeType} />
          ))}
    </Flex>
  );
};
export default GraphsList;
