import { defaultVariables } from "modules/create/constants/defaultValues";
import { Variables } from "modules/preview/schemas/templateSchemas";

const getVariablesFromDetails = (details?: Variables | null) =>
  details
    ? Object.values(details)
        .filter((value) => value !== null)
        .map((value) => ({ value }))
    : defaultVariables;

export default getVariablesFromDetails;
