import React from "react";
import { Text, Box, useRadioGroup, Flex } from "@chakra-ui/react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Behavior,
  behaviorSchema,
} from "modules/preview/schemas/templateSchemas";
import { defaultBehavior } from "modules/create/constants/defaultValues";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import { useIsDisableEmptyLayout } from "modules/preview/hooks/useIsDisableEmptyLayout";
import AdvancedSettingsForm from "modules/preview/components/forms/AdvancedSettingsForm";
import SwitchButton from "modules/preview/components/ui/SwitchButton";
import TextWithIcon from "core/components/TextWithIcon/TextWithIcon";

const SettingsForm = () => {
  const { t } = useTranslation();
  const isDisableEmptyLayout = useIsDisableEmptyLayout();

  const { control } = useFormContext<PreviewFormType>();

  const {
    field: { ref, ...radioGroupProps },
  } = useController({
    name: "behavior",
    control: control,
  });
  const { getRootProps, getRadioProps } = useRadioGroup(radioGroupProps);

  const group = getRootProps();

  const selectedValue: Behavior = useWatch({
    control,
    name: "behavior",
    defaultValue: defaultBehavior,
  });

  return (
    <Box>
      <Text fontSize="sm" color="dark" mb="10px" fontWeight={500}>
        {t("preview.settings.subtitle")}
      </Text>
      <Flex {...group}>
        {behaviorSchema.options.map((behavior) => {
          const radio = getRadioProps({ value: behavior });
          return (
            <SwitchButton
              isDisabled={isDisableEmptyLayout}
              key={behavior}
              {...radio}
            />
          );
        })}
      </Flex>
      <Flex align="left" direction="column">
        <Box mt="20px" mb="10px" h="120px">
          <Text fontSize="xs" color="dark" mb="20px">
            {t(`preview.settings.${selectedValue!}.hint` as const)}
          </Text>
          <TextWithIcon icon="thumbUp">
            {t(`preview.settings.${selectedValue!}.advantages` as const)}
          </TextWithIcon>
          <TextWithIcon icon="thumbDown">
            {t(`preview.settings.${selectedValue!}.disadvantage` as const)}
          </TextWithIcon>
        </Box>
        <AdvancedSettingsForm isDisabled={isDisableEmptyLayout} />
      </Flex>
    </Box>
  );
};

export default SettingsForm;
