import React, { forwardRef } from "react";
import { Textarea, TextareaProps } from "@chakra-ui/react";

const TextareaInput = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => (
    <Textarea
      {...props}
      borderColor="silver"
      _placeholder={{ color: "abbey" }}
      errorBorderColor="error"
      borderRadius="4px"
      ref={ref}
    />
  )
);

export default TextareaInput;
