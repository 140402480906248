import React from "react";

import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { useResetCredentialsEmailStep } from "modules/authentication/hooks/useResetCredentialsEmailStep";
import { CredentialsEmailStep } from "modules/authentication/schemas/passwordSchemas";

export const ResetCredentialsEmailStepForm = () => {
  const { register, handleSubmit, unregister } =
    useForm<CredentialsEmailStep>();

  const { t } = useTranslation();

  const { isLoading, mutate } = useResetCredentialsEmailStep();

  const onSubmit = (data: CredentialsEmailStep) => {
    mutate(data, {
      onSuccess: () => {
        unregister("email");
      },
    });
  };
  return (
    <Box maxWidth="300px" mt="50px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="email" isRequired>
          <FormLabel>{t("login.email")}</FormLabel>
          <Input
            variant="outline"
            type="email"
            name="email"
            ref={register}
            errorBorderColor="error"
          />
        </FormControl>
        <VStack>
          <Button
            type="submit"
            variant="primary-solid"
            mt="30px"
            isLoading={isLoading}
            loadingText={t("login.loading")}
          >
            {t("login.reset.validate")}
          </Button>
        </VStack>
      </form>
    </Box>
  );
};
