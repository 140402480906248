import { pick } from "remeda";
import useColorScheme from "modules/preview/hooks/useColorScheme";
import customizableColors from "modules/create/constants/customizableColors";

export default function useCustomizableColors() {
  const colorScheme = useColorScheme();
  if (!colorScheme) {
    return null;
  }
  return pick(colorScheme.colors, customizableColors);
}
