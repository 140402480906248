import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import TargetIcon from "core/components/InterfaceIcons/TargetIcon";
import useGlobalMessage from "../hooks/useGlobalMessage";
import { useProductionGlobal } from "../hooks/useProductionGlobal";

export const ProductionAction = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const production = useProductionGlobal();
  const { data } = useGlobalMessage();
  const isValidatedGlobal = data && data.status === "VALIDATED";

  return (
    <Button
      onClick={() => production.mutate(Number(id))}
      leftIcon={<TargetIcon />}
      variant="primary-solid"
      isDisabled={!isValidatedGlobal}
      isLoading={production.isLoading}
    >
      {t("actions.finalize.button")}
    </Button>
  );
};
