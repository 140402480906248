import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import TargetIcon from "core/components/InterfaceIcons/TargetIcon";
import useGlobalMessage from "../hooks/useGlobalMessage";
import { useValidationGlobal } from "../hooks/useValidationGlobal";

export const ValidateAction = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const validation = useValidationGlobal();
  const { data } = useGlobalMessage();
  const isDraft = data && data.status === "DRAFT";

  return (
    <Button
      onClick={() => validation.mutate(Number(id))}
      leftIcon={<TargetIcon />}
      variant="primary-solid"
      isDisabled={!isDraft}
      isLoading={validation.isLoading}
    >
      {t("actions.validate.button")}
    </Button>
  );
};
