import React from "react";
import { useTranslation } from "react-i18next";
import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { PATHS } from "modules/shared/constants/paths";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { useIsConfigurator } from "modules/roles/hooks/useIsConfigurator";

const CreateUserButton = () => {
  const { t } = useTranslation();
  const isAdministrator = useIsAdministrator();
  const isConfigurator = useIsConfigurator();

  const disabled = !(isAdministrator || isConfigurator);

  return (
    <Button
      variant="primary-solid"
      isDisabled={disabled}
      as={disabled ? "button" : RouterLink}
      leftIcon={<AddIcon />}
      data-testid="create-user-button"
      {...(disabled ? {} : { to: PATHS.CREATE_USER })}
    >
      {t("users.create")}
    </Button>
  );
};

export default CreateUserButton;
