import * as z from "zod";

export const customizedColorSchemeFormSchema = z.object({
  name: z.string().nonempty(),
  description: z.string().nonempty().max(255),
  color_12: z.string(),
  color_13: z.string(),
  color_14: z.string(),
  color_15: z.string(),
});

export type CustomizedColorSchemeForm = z.infer<
  typeof customizedColorSchemeFormSchema
>;

export const formColorKeySchema = z.enum([
  "color_12",
  "color_13",
  "color_14",
  "color_15",
]);
export type FormColorKey = z.infer<typeof formColorKeySchema>;
export const defaultColorKeySchema = z.enum([
  "color_6",
  "color_7",
  "color_8",
  "color_9",
  "color_10",
  "color_11",
]);
export type DefaultColorKey = z.infer<typeof defaultColorKeySchema>;
