import {
  TemplateLayoutType,
  Alignments,
  AlignmentParam,
} from "modules/preview/schemas/templateSchemas";
import { layoutsSpec, alignmentMap } from "modules/preview/constants/specs";
import { NonEmptyLayoutSpec } from "modules/preview/schemas/specSchemas";

const getAlignmentFromFormAndLayout = (
  layout: TemplateLayoutType,
  alignment?: Alignments
) => {
  const alignments = alignmentMap[layout].map((value) => [
    `Frame${value}_Alignment`,
    (alignment && alignment[`Frame${value}_Alignment` as AlignmentParam]) ??
      (layoutsSpec as NonEmptyLayoutSpec)[layout].layout.textAlign,
  ]);
  return Object.fromEntries(alignments);
};

export default getAlignmentFromFormAndLayout;
