import React, { useEffect, useState } from "react";
import {
  CheckboxGroup,
  Checkbox,
  VStack,
  Text,
  Box,
  useCheckboxGroup,
  Switch,
  HStack,
  RadioGroup,
  Radio,
  Stack,
  FormControl,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, useController } from "react-hook-form";

import { CreateUserFormValues } from "modules/user/informations/schemas/formSchemas";
import WarningMessage from "core/components/WarningMessage/WarningMessage";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import useCountries from "../hooks/useCountries";
import useGlobalMessage from "../hooks/useGlobalMessage";

export interface Props {
  regions: string[] | undefined;
}

const CountriesForm = () => {
  const { t } = useTranslation();
  const { data } = useGlobalMessage();

  const globalMessageStatus = data?.status;
  const isDisabled = !!globalMessageStatus && globalMessageStatus !== "DRAFT";

  const { errors, control, getValues, setValue } = useFormContext<
    CreateUserFormValues | PreviewFormType
  >();

  const [isEnabled, setIsEnabled] = useState(
    data?.filters?.countries_on || data?.filters?.countries_off ? true : false
  );

  const [countriesOn, setCountriesOn] = useState(
    data?.filters?.countries_off ? "2" : "1"
  );
  const isError = errors.regions;
  const {
    field: { ref, ...checkboxGroupProps },
  } = useController({
    name:
      countriesOn === "1" ? "filters.countries_on" : "filters.countries_off",
    control,
    defaultValue:
      data?.filters?.countries_on ?? data?.filters?.countries_off ?? [],
  });

  const { getCheckboxProps, value: checkboxValue } =
    useCheckboxGroup(checkboxGroupProps);
  const regions = getValues("regions");
  const { data: countries } = useCountries(regions);

  const resetCountryFilters = (value: any) => {
    if (data?.filters?.countries_on || data?.filters?.countries_off) {
      if (value === "1") {
        setValue("filters.countries_on", checkboxValue);
        setCountriesOn("1");
      } else {
        setValue("filters.countries_off", checkboxValue);
        setCountriesOn("2");
      }
    } else {
      if (value === "1") {
        setValue("filters.countries_off", undefined);
        setCountriesOn("1");
      } else {
        setValue("filters.countries_on", undefined);
        setCountriesOn("2");
      }
    }
  };

  useEffect(() => {
    if (!isEnabled) {
      setValue("filters.countries_on", undefined);
      setValue("filters.countries_off", undefined);
    }
  }, [isEnabled]);

  return (
    <Box mt={8}>
      {regions ? (
        <HStack>
          <Switch
            onChange={() => setIsEnabled(!isEnabled)}
            isChecked={isEnabled}
            my="10px"
            isDisabled={isDisabled}
            data-testid="countries-on"
          />
          <Text mb="0" ml={5} fontSize="sm" color="dark">
            {t("global_message.filters.country_toggle")}
          </Text>
        </HStack>
      ) : null}
      <FormControl
        isDisabled={!isEnabled || isDisabled}
        display="flex"
        pt={4}
        pl={4}
        alignItems="center"
      >
        <RadioGroup onChange={resetCountryFilters} defaultValue={countriesOn}>
          <Stack direction="row">
            <Radio value="1">{t("global_message.filters.country_on")}</Radio>
            <Radio value="2">{t("global_message.filters.country_off")}</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      {isEnabled ? (
        <CheckboxGroup value={checkboxValue} isDisabled={isDisabled}>
          <VStack align="left" spacing={0} mt={4}>
            {countries?.map((country) => {
              const checkboxProps = getCheckboxProps();
              return (
                <Checkbox
                  {...checkboxProps}
                  name="countries"
                  key={country.code}
                  isReadOnly={
                    checkboxValue.length >= 8 &&
                    !checkboxValue.includes(country.code)
                  }
                  alignItems="flex-start"
                  value={country.code}
                  lineHeight="15px"
                  ref={ref}
                >
                  <Text fontSize="sm" color="dark" mb="20px">
                    {country.name}
                  </Text>
                </Checkbox>
              );
            })}
          </VStack>
          {isError && (
            <WarningMessage>{t("preview.regions.error")}</WarningMessage>
          )}
        </CheckboxGroup>
      ) : null}
    </Box>
  );
};

export default CountriesForm;
