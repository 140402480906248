import React, { useState } from "react";
import {
  Heading,
  Input,
  Button,
  Box,
  HStack,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useInspectedMessage } from "../hook/useInspectedMessage";
import MessagesTable from "./MessagesTable";

import FullLoader from "../../../../core/components/Fullloader/FullLoader";

const InspectFormMessage = () => {
  const [number, setNumber] = useState<number | undefined>(undefined);
  const [id, setId] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const { data, isLoading } = useInspectedMessage(id);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (number !== undefined) {
      setId(number);
    }
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Heading as="h2" size="md" mt="10px" textAlign="left">
          {t("supervision.msg_heading")}
        </Heading>
        <HStack mt={5}>
          <Input
            type="number"
            variant="outline"
            width={52}
            value={number}
            onChange={(e) => setNumber(Number(e.target.value))}
          />
          <Button type="submit" mt="10px" variant="secondary-solid">
            {t("supervision.submit")}
          </Button>
        </HStack>
      </form>
      {isLoading && <FullLoader />}
      {data ? (
        <MessagesTable messages={data} />
      ) : (
        <Flex mt={20} d="column">
          <Text m="20px" align="center">
            {t("supervision.msg_placeholder")}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default InspectFormMessage;
