import React from "react";
import { Flex } from "@chakra-ui/react";

import Frames from "modules/preview/components/Frames";
import PreviewForms from "modules/preview/components/PreviewForms";
import ErrorHandler from "modules/shared/components/ErrorHandler/ErrorHandler";

const Preview = () => (
  <ErrorHandler>
    <Flex data-testid="preview">
      <Flex direction="column">
        <Frames />
      </Flex>
      <PreviewForms />
    </Flex>
  </ErrorHandler>
);

export default Preview;
