import React, { useCallback } from "react";
import { HStack, VStack, Image, Switch, Text } from "@chakra-ui/react";
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PreviewFormType } from "modules/create/schemas/formSchema";

interface Props {
  isDisabled: boolean;
}
const BuiltInLogos = ({ isDisabled }: Props) => {
  const context = useFormContext<PreviewFormType>();
  const { t } = useTranslation();

  const renderSwitch = useCallback(
    ({
      onChange,
      onBlur,
      value,
      name,
      ref,
    }: ControllerRenderProps<PreviewFormType>) => (
      <Switch
        textStyle="bodySmall"
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        isChecked={value}
        onBlur={onBlur}
        name={name}
        ref={ref}
        isDisabled={isDisabled}
      />
    ),
    [isDisabled]
  );

  return (
    <VStack spacing={5} alignItems="start">
      <HStack mt={5} spacing={10}>
        <Image
          src={require("assets/canal.png").default}
          objectFit="contain"
          w={20}
        />
        <HStack>
          <Text textStyle="bodySmall">
            {t("preview.logos.builtInLogos.hide")}
          </Text>
          <Controller
            control={context.control}
            name={"builtInLogos.canalplus"}
            render={renderSwitch}
          />
          <Text textStyle="bodySmall">
            {t("preview.logos.builtInLogos.display")}
          </Text>
        </HStack>
      </HStack>
      <HStack spacing={10}>
        <Image
          src={require("assets/canalsat.png").default}
          objectFit="contain"
          w={20}
        />
        <HStack>
          <Text textStyle="bodySmall">
            {t("preview.logos.builtInLogos.hide")}
          </Text>
          <Controller
            control={context.control}
            name={"builtInLogos.canalsat"}
            render={renderSwitch}
          />
          <Text textStyle="bodySmall">
            {t("preview.logos.builtInLogos.display")}
          </Text>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default BuiltInLogos;
