import * as z from "zod";
import isMomaColor from "modules/templates/helpers/isMomaColor";

export const colorSchemeColorKeySchema = z.enum([
  "color_0",
  "color_1",
  "color_2",
  "color_3",
  "color_4",
  "color_5",
  "color_6",
  "color_7",
  "color_8",
  "color_9",
  "color_10",
  "color_11",
  "color_12",
  "color_13",
  "color_14",
  "color_15",
]);

export type ColorSchemeColorKey = z.infer<typeof colorSchemeColorKeySchema>;

const momaColorSchema = z.string().refine(isMomaColor);

export type MomaColor = z.infer<typeof momaColorSchema>;

const colorSchemeColorSchema = z.object({
  color_0: momaColorSchema,
  color_1: momaColorSchema,
  color_2: momaColorSchema,
  color_3: momaColorSchema,
  color_4: momaColorSchema,
  color_5: momaColorSchema,
  color_6: momaColorSchema,
  color_7: momaColorSchema,
  color_8: momaColorSchema,
  color_9: momaColorSchema,
  color_10: momaColorSchema,
  color_11: momaColorSchema,
  color_12: momaColorSchema,
  color_13: momaColorSchema,
  color_14: momaColorSchema,
  color_15: momaColorSchema,
});

export type ColorScheme = z.infer<typeof colorSchemesSchema>;
export type ColorSchemeColors = z.infer<typeof colorSchemeColorSchema>;

export const colorSchemesSchema = z.object({
  name: z.string(),
  description: z.string(),
  colors: colorSchemeColorSchema,
  id: z.number(),
});

export const colorSchemesBodySchema = z.array(colorSchemesSchema);

export type ColorSchemesBody = z.infer<typeof colorSchemesBodySchema>;

const customizedColorSchemeColorSchema = z.object({
  color_0: momaColorSchema.optional(),
  color_1: momaColorSchema.optional(),
  color_2: momaColorSchema.optional(),
  color_3: momaColorSchema.optional(),
  color_4: momaColorSchema.optional(),
  color_5: momaColorSchema.optional(),
  color_6: momaColorSchema.optional(),
  color_7: momaColorSchema.optional(),
  color_8: momaColorSchema.optional(),
  color_9: momaColorSchema.optional(),
  color_10: momaColorSchema.optional(),
  color_11: momaColorSchema.optional(),
  color_12: momaColorSchema,
  color_13: momaColorSchema,
  color_14: momaColorSchema,
  color_15: momaColorSchema,
});

const customizedColorSchemesSchema = z.object({
  name: z.string(),
  description: z.string(),
  colors: customizedColorSchemeColorSchema,
  id: z.number().optional(),
});

export const customizedColorSchemesBodySchema = z.array(
  customizedColorSchemesSchema
);

export type CustomizedColorScheme = z.infer<
  typeof customizedColorSchemesSchema
>;
