import * as z from "zod";

export const saveUserApiSchema = z.object({ user_id: z.number() }).nonstrict();

export const updateUserApiSchema = z.string();

export const resetUserPasswordApiSchema = z.string();

export const availableRolesApiSchema = z.array(z.string());

export const availableRegionsApiSchema = z.array(z.string());
