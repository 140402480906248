import * as z from "zod";

export const finalizeSchema = z.object({
  message: z.string(),
  label: z
    .object({
      value: z.string(),
      prev: z.number(),
      new: z.number(),
    })
    .optional(),
});

export type finalize = z.infer<typeof finalizeSchema>;
