import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import ControlIcon from "core/components/ControlIcons/ControlIcon";
import styled from "@emotion/styled";
import {
  textObjectClassSchema,
  TextObject,
} from "modules/preview/schemas/specSchemas";
import useDisableForm from "modules/preview/hooks/useDisableForm";

interface ControlProps {
  name: TextObject;
}

interface Props extends ControlProps {
  isEditing: boolean;
  onSubmit(): void;
  onReset(): void;
  onEdit(): void;
}

const Item = styled(Box)`
  position: absolute;
  right: -33px;
`;

const Controls = styled(Item)<ControlProps>`
  top: ${(props) =>
    props.name === textObjectClassSchema.enum.OT2 ? "20px" : "-18px"};
`;

const Control = styled(Item)<ControlProps>`
  top: ${(props) =>
    props.name === textObjectClassSchema.enum.OT2 ? "29px" : "-1px"};
`;

const ActionControls = ({
  isEditing,
  onSubmit,
  onReset,
  onEdit,
  name,
}: Props) => {
  const disabled = useDisableForm();
  return isEditing ? (
    <Controls name={name}>
      <Flex direction="column" height="72px" justify="space-between">
        <ControlIcon
          icon="validate"
          onClick={onSubmit}
          isEditing={true}
          key="validate"
        />
        <ControlIcon icon="cancel" onClick={onReset} isEditing={true} />
      </Flex>
    </Controls>
  ) : (
    <Control name={name}>
      <ControlIcon icon="edit" onClick={onEdit} isDisabled={disabled} />
    </Control>
  );
};

export default ActionControls;
