import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import LayoutForm from "modules/create/components/forms/LayoutForm";
import ColorSchemes from "modules/preview/components/ColorSchemes";
import Logos from "modules/preview/components/Logos";
import BannerForm from "modules/create/components/forms/BannerForm";
import InformationsForm from "modules/preview/components/forms/InformationsForm";
import VariablesForm from "modules/preview/components/forms/VariablesForm";
import SettingsForm from "modules/preview/components/forms/SettingsForm";
import AccordionTitle from "core/components/AccordionTitle/AccordionTitle";
import previewFormWidth from "modules/preview/constants/previewFormWidth";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import TemplateStatus from "modules/preview/components/ui/TemplateStatus";
import RegionsForm from "modules/regions/components/RegionsForm";
import StorablesForm from "modules/preview/components/forms/StorablesForm";
import { useSelfcare } from "modules/selfcare/hooks/useSelfcare";

const PreviewForms = () => {
  const { t } = useTranslation();
  const { errors } = useFormContext<PreviewFormType>();
  const isTitleError = errors.name;
  const isRegionError = errors.regions;
  const { data } = useSelfcare();

  return (
    <Box w={previewFormWidth} ml={10} data-testid="preview-form">
      <TemplateStatus />
      <Accordion allowMultiple allowToggle w="100%">
        <AccordionItem>
          <AccordionTitle error={!!isTitleError}>
            {t("preview.informations.title")}
          </AccordionTitle>
          <AccordionPanel pb={4}>
            <InformationsForm />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionTitle error={!!isRegionError}>
            {t("preview.regions.title")}
          </AccordionTitle>
          <AccordionPanel pb={4}>
            <RegionsForm regions={data?.regions} />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionTitle>{t("preview.layout")}</AccordionTitle>
          <AccordionPanel pb={4}>
            <LayoutForm />
            <BannerForm />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionTitle>{t("preview.logos.logos")}</AccordionTitle>
          <AccordionPanel pb={4}>
            <Logos />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionTitle>{t("preview.colorScheme.title")}</AccordionTitle>
          <AccordionPanel pb={4}>
            <ColorSchemes />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionTitle>{t("preview.variables.title")}</AccordionTitle>
          <AccordionPanel pb={4}>
            <VariablesForm />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionTitle>{t("preview.storables.title")}</AccordionTitle>
          <AccordionPanel pb={4}>
            <StorablesForm />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionTitle>{t("preview.settings.title")}</AccordionTitle>
          <AccordionPanel pb={4}>
            <SettingsForm />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default PreviewForms;
