import { useQuery } from "react-query";
import { simpleRequest } from "core/services/api";
import { availableRolesApiSchema } from "modules/user/informations/schemas/userApiSchema";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";

export const availableRoles = () =>
  simpleRequest(ENDPOINTS.ROLES, availableRolesApiSchema);

export function useAvailableRoles(fetch = true) {
  return useQuery(["roles"], availableRoles, {
    enabled: fetch,
  });
}
