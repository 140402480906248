import React from "react";
import { createIcon } from "@chakra-ui/react";

const LiveTvIcon = createIcon({
  displayName: "LiveTvIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <rect x="8" y="21" width="8" height="2" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7.11111H3V18.8889H21V7.11111ZM1 5V21H23V5H1Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00011 1L12.0001 5L10.9395 6.06066L6.93945 2.06066L8.00011 1Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 5L15.9999 0.999999L17.0605 2.06066L13.0605 6.06066L11.9999 5Z"
        fill="currentColor"
      />
    </>
  ),
});

export default LiveTvIcon;
