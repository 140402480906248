import { format, parseISO } from "date-fns";
import { Locale } from "../schemas";

const formatDate = (creation_date: string, language: Locale) => {
  const COMMENT_DATE_EN_FORMAT = "MM/dd/yyyy, hh:mm";
  const COMMENT_DATE_FR_FORMAT = "dd/MM/yyyy, hh:mm";

  return format(
    parseISO(creation_date),
    language === "en" ? COMMENT_DATE_EN_FORMAT : COMMENT_DATE_FR_FORMAT
  );
};

export default formatDate;
