/**
 * check if a string match the Moma color pattern
 * @param input
 * @returns boolean
 * @signature
 *    isMomaColor(string)
 * @category string
 */
export const momaColorRegex = /^#([0-4]{2}[0-9a-f]{6})$/i;

export const isMomaColor = (input: string) => momaColorRegex.test(input);

export default isMomaColor;
