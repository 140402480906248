import React from "react";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { HeaderGroup } from "react-table";
import FilterContext, { FilterContextValue } from "../context/filterContext";
import FiltersBlock from "./FiltersBlock";
import useFilterConfig from "modules/filters/hooks/useFilterConfig";

interface FiltersProps<T extends object> extends FilterContextValue<T> {
  headerGroups: HeaderGroup<T>[];
  count: number;
}

export default function Filters<T extends object>({
  globalFilter,
  filters,
  setGlobalFilter,
  setAllFilters,
  headerGroups,
  count,
}: FiltersProps<T>) {
  const { t } = useTranslation();
  const { translationKey } = useFilterConfig();

  return (
    <FilterContext.Provider
      value={{
        globalFilter,
        setGlobalFilter,
        filters,
        setAllFilters,
        headerGroups,
      }}
    >
      <FiltersBlock />

      <Text textStyle="labelSmall" mt="20px">
        {count
          ? t("filters.quantity.title", {
              count,
              key: t(`filters.${translationKey}` as any),
            })
          : t("filters.quantity.empty", {
              key: t(`filters.${translationKey}` as any),
            })}
      </Text>
    </FilterContext.Provider>
  );
}
