import React, { ReactNode, useEffect } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import styled from "@emotion/styled";
import LocaleSelect from "modules/i18n/components/LocaleSelect";

const Title = styled(Heading)`
  text-transform: uppercase;
`;

export const AuthentificationBox = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.removeQueries();
  }, []);

  return (
    <Box bg="dark" display="flex" flexDirection="column" minHeight="100vh">
      <Box display="flex" alignSelf="flex-end">
        <LocaleSelect />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderRadius="sm"
        my="5%"
        bg="white"
        p={10}
        w="40%"
        h="fit-content"
        alignSelf="center"
      >
        <Title as="h1" size="2xl">
          {t("login.moma")}
        </Title>
        {children}
      </Box>
    </Box>
  );
};
