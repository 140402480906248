import React from "react";

import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Box,
  Button,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import {
  CredentialsPasswordStep,
  credentialsPasswordStepSchema,
} from "modules/authentication/schemas/passwordSchemas";
import { useResetCredentialsPassword } from "modules/authentication/hooks/useResetCredentialsPasswordStep";
import InputError from "core/components/InputError/InputError";

export const ResetCredentialsPasswordStepForm = () => {
  const { register, handleSubmit, unregister, errors } =
    useForm<CredentialsPasswordStep>({
      resolver: zodResolver(credentialsPasswordStepSchema),
    });

  const { t } = useTranslation();

  const { isLoading, mutate } = useResetCredentialsPassword();

  const onSubmit = (data: CredentialsPasswordStep) => {
    mutate(data, {
      onSuccess: () => {
        unregister(["new_password", "confirm_password"]);
      },
    });
  };

  return (
    <Box maxWidth="300px" mt="50px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="new_password" mt="30px" isRequired>
          <FormLabel>{t("login.reset.new_password")}</FormLabel>
          <Input
            variant="outline"
            type="password"
            name="new_password"
            ref={register}
            errorBorderColor="error"
            isInvalid={!!errors.new_password}
          />
          <FormHelperText
            fontSize="xs"
            color={errors.new_password ? "error" : "abbey"}
            textAlign="left"
            sx={{ whiteSpace: "pre-line" }}
          >
            {t("login.reset.new_password_hint")}
          </FormHelperText>
        </FormControl>
        <FormControl id="confirm_password" mt="30px" isRequired>
          <FormLabel>{t("login.reset.confirm_password")}</FormLabel>
          <Input
            variant="outline"
            type="password"
            name="confirm_password"
            ref={register}
            errorBorderColor="error"
            isInvalid={!!errors.confirm_password}
          />
          <InputError
            text={
              errors.confirm_password
                ? t("login.reset.confirm_password_hint")
                : ""
            }
          />
        </FormControl>
        <VStack>
          <Button
            type="submit"
            variant="primary-solid"
            mt="30px"
            isLoading={isLoading}
            loadingText={t("login.loading")}
          >
            {t("login.reset.validate")}
          </Button>
        </VStack>
      </form>
    </Box>
  );
};
