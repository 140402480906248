import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { UserFromList } from "modules/users/schemas/usersModelSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { saveUserApiSchema } from "../schemas/userApiSchema";

export const createUser = (user: UserFromList) =>
  simpleRequest(
    ENDPOINTS.USERS,
    saveUserApiSchema,
    methodSchema.enum.post,
    user
  );

export const useCreateUser = () => {
  return useMutationWithErrorHandling(createUser);
};
