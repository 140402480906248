import { Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useLocationQueryParams from "core/hooks/useLocationQueryParams";
import { useOktaLogin } from "modules/authentication/hooks/useOktaLogin";
import { OktaCredentialsForm } from "modules/authentication/schemas/oktaCredentialsFormSchema";
import { PATHS } from "modules/shared/constants/paths";
import { SSOAuthenticationBox } from "modules/authentication/components/SSOAuthenticationBox";
import { Link as RouterLink } from "react-router-dom";
import { RepeatIcon } from "@chakra-ui/icons";
import FullLoader from "core/components/Fullloader/FullLoader";

const LoginOktaCallback = () => {
  const query = useLocationQueryParams();
  const authorizationCode = query.get("code")!;
  const oktaData = JSON.parse(localStorage.getItem("oktaData")!);

  const payload: OktaCredentialsForm = {
    authorization_code: authorizationCode,
    state: oktaData.state,
    verifier_code: oktaData.codeVerifier,
  };
  const { t } = useTranslation();
  const { isError, mutate } = useOktaLogin();
  useEffect(() => {
    mutate(payload);
  }, []);

  if (isError) {
    return (
      <SSOAuthenticationBox>
        <Button
          variant="primary-solid"
          as={RouterLink}
          leftIcon={<RepeatIcon />}
          data-testid="repeat-okta"
          to={PATHS.OKTA_REDIRECT}
          mt={3}
        >
          {t("okta.error")}
        </Button>
      </SSOAuthenticationBox>
    );
  }

  return (
    <SSOAuthenticationBox>
      <FullLoader />
    </SSOAuthenticationBox>
  );
};

export default LoginOktaCallback;
