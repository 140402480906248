import {
  Button,
  Modal,
  HStack,
  ModalBody,
  UnorderedList,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
  ListItem,
} from "@chakra-ui/react";

import { ViewIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { ProbeType } from "modules/monitoring/ProbeList/schemas/formSchemas";

const MetricModalDetails = ({
  metric,
  onCloseModal,
}: {
  metric: ProbeType;
  onCloseModal: () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <HStack spacing={0.25}>
        <Button variant="tertiary-ghost" onClick={onOpen}>
          <ViewIcon mr={2} /> {t("supervision.preview")}
        </Button>
      </HStack>
      <Modal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          onCloseModal();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"30%"}>
          <ModalHeader>{metric?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {metric ? (
              <UnorderedList>
                {Object.entries(metric)
                  .filter(
                    ([key]) =>
                      key !== "parent" &&
                      key !== "categories" &&
                      key !== "roles"
                  )
                  .map(([key, value]) => (
                    <ListItem key={key}>
                      <strong>{key}: </strong>
                      {JSON.stringify(value)}
                    </ListItem>
                  ))}
              </UnorderedList>
            ) : null}
          </ModalBody>

          <ModalFooter>
            <Button
              variant="secondary-solid"
              mr={3}
              onClick={() => {
                onClose();
                onCloseModal();
              }}
            >
              {t("supervision.close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MetricModalDetails;
