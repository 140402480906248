import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, AlertDescription, Button, HStack } from "@chakra-ui/react";

import InfoAlert from "modules/feedback/components/InfoAlert";
import useTemplateStatus from "modules/preview/hooks/useTemplateStatus";
import { useRole } from "modules/roles/hooks/useRole";
import getTemplateTitleFromStatusAndRole from "modules/validate/helpers/getTemplateTitleFromStatusAndRole";
import getTemplateStatusInfo from "modules/validate/helpers/getTemplateStatusInfo";
import { useParams } from "react-router-dom";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { useRestartTemplate } from "modules/stopTemplate/hooks/useRestartTemplate";

const InformationStatus = () => {
  const { t } = useTranslation();
  const roles = useRole();
  const { id } = useParams<RouteIdParam>();
  const templateStatus = useTemplateStatus();
  const { mutate } = useRestartTemplate();
  const title = getTemplateTitleFromStatusAndRole(templateStatus, roles);
  if (!title) {
    return null;
  }
  const stoppedTemplate = templateStatus === "STOPPED";
  const status = getTemplateStatusInfo(templateStatus, roles);

  return (
    <>
      <Flex
        zIndex="0"
        d="column"
        w={stoppedTemplate ? "100%" : "auto"}
        textAlign="center"
      >
        {stoppedTemplate && (
          <HStack justifyContent="flex-end">
            <Button variant="link-button" onClick={() => mutate(Number(id))}>
              {t("actions.restart")}
            </Button>
          </HStack>
        )}
        <Flex
          d="column"
          maxW={stoppedTemplate ? "100%" : "550px"}
          align="flex-start"
        >
          <AlertDescription
            display="block"
            fontWeight={700}
            textAlign={stoppedTemplate ? "center" : "left"}
            mb="10px"
            ml={stoppedTemplate ? "0px" : "40px"}
          >
            {t(`info.title.${title}` as const)}
          </AlertDescription>
          {!stoppedTemplate && status && <InfoAlert statutes={status} />}
        </Flex>
      </Flex>
    </>
  );
};

export default InformationStatus;
