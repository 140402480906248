import React, { useMemo } from "react";
import { useRadioGroup, Heading, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useController, useFormContext } from "react-hook-form";
import RadioCard from "../ui/RadioCard";
import { bannerPositionSchema } from "modules/preview/schemas/specSchemas";
import {
  PreviewFormType,
  radioCardSizeSchema,
} from "modules/create/schemas/formSchema";
import useIsSide from "modules/create/hooks/useIsSide";
import DISABLED_OPACITY from "modules/preview/constants/disabledOpacity";
import {
  layoutFormTypeSchema,
  layoutHybridTypeSchema,
} from "modules/preview/schemas/templateSchemas";
import useDisableForm from "modules/preview/hooks/useDisableForm";

const Title = styled(Heading)<{ disabled: boolean }>`
  text-align: left;
  margin-top: 30px;
  opacity: ${(props) => (props.disabled ? DISABLED_OPACITY : 1)};
`;

const Position = styled(Text)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? DISABLED_OPACITY : 1)};
  width: 100%;
`;

const BannerPositionForm = () => {
  const { t } = useTranslation();
  const side = useIsSide();
  const { control, getValues } = useFormContext<PreviewFormType>();
  const selectedLayout = getValues("layout");
  const extraBanner = getValues("extraBanner");

  const isFull4Layout = useMemo(
    () => selectedLayout === layoutFormTypeSchema.enum.full4,
    [selectedLayout]
  );
  const isBannerLayout = useMemo(
    () => selectedLayout === layoutFormTypeSchema.enum.banner,
    [selectedLayout]
  );

  const isFormActivated = useMemo(
    () =>
      isBannerLayout ||
      (isFull4Layout && extraBanner) ||
      layoutHybridTypeSchema.check(selectedLayout),
    [isFull4Layout, isBannerLayout, extraBanner]
  );

  const {
    field: { ref, ...radioGroupProps },
  } = useController({
    name: "position",
    control,
  });

  const { getRootProps, getRadioProps } = useRadioGroup(radioGroupProps);
  const isFormDisabled = useDisableForm();
  const group = getRootProps();

  return (
    <>
      {!side && (
        <Title as="h4" fontSize="sm" disabled={!isFormActivated}>
          {t("create.banner.position")}
        </Title>
      )}
      <Flex
        {...group}
        direction={side ? "row" : "column"}
        data-testid="banner-position-form"
      >
        {bannerPositionSchema.options.map((position) => {
          const radio = getRadioProps({ value: position });
          return (
            <RadioCard
              key={position}
              {...radio}
              size={
                side
                  ? radioCardSizeSchema.enum.small
                  : radioCardSizeSchema.enum.medium
              }
              isDisabled={!isFormActivated || isFormDisabled}
            >
              <Position
                disabled={!isFormActivated}
                fontSize="sm"
                textAlign="left"
              >
                {t(`create.banner.${position}` as const)}
              </Position>
            </RadioCard>
          );
        })}
      </Flex>
    </>
  );
};

export default BannerPositionForm;
