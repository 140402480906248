import React from "react";
import {
  FormControl,
  FormLabel,
  Box,
  Input,
  Textarea,
  Text,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Filters, Row } from "react-table";

import InputError from "core/components/InputError/InputError";
import capitalizeFirstLetter from "core/helpers/utils/capitalizeFirstLetter";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { SendBulkMessageFormValues } from "../schemas/bulkMessageSchema";
import { FiltersTypes } from "../constants/FiltersTypes";
import { useSendBulkMessage } from "../hooks/useSendBulkMessage";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";

interface Props<T extends object> {
  closeModal: () => void;
  users: Row<T>[];
  activeFilters: Filters<T>;
}

const SendBulkMessageForm = <T extends object>({
  closeModal,
  users,
  activeFilters,
}: Props<T>) => {
  const { handleSubmit, errors, register } =
    useFormContext<SendBulkMessageFormValues>();
  const { t } = useTranslation();
  const { isLoading, mutate } = useSendBulkMessage();

  const usersIds = React.useMemo(
    () => users.map((user) => user.values.id),
    [users]
  );

  const { setFeedback } = useFeedbackStore();

  const onSubmit = (data: SendBulkMessageFormValues) => {
    mutate(
      { ...data, users: usersIds },
      {
        onSuccess: () => {
          setFeedback(
            feedbackSchema.enum.success,
            actionsSchema.enum.sendBulkMessage
          );
          closeModal();
        },
        onError: () => {
          setFeedback(
            feedbackSchema.enum.error,
            actionsSchema.enum.sendBulkMessage
          );
          closeModal();
        },
      }
    );
  };

  const getselectedFilters = React.useCallback(
    (type: string) =>
      activeFilters?.find((filters) => filters.id === type)?.value,
    [activeFilters]
  );

  const filtersTypes = Object.values(FiltersTypes);

  const renderSelectedFilter = (
    translation: string,
    selectedFilters: string[]
  ): React.ReactElement => {
    return (
      <Box key={translation}>
        <Text textStyle="text" display="inline">
          {translation}
        </Text>{" "}
        <Text textStyle="labelSmall" display="inline">
          {selectedFilters
            .map((selectedFilter) => capitalizeFirstLetter(selectedFilter))
            .join(", ")}
        </Text>
      </Box>
    );
  };
  const renderSelectedFilters: React.ReactElement[] = React.useMemo(
    () =>
      filtersTypes.map((filterType) => {
        const activeFiltersTypes = activeFilters.map(
          (activeFilter) => activeFilter.id
        );
        const selectedFilters = getselectedFilters(filterType);

        if (
          activeFiltersTypes.includes(filterType) &&
          selectedFilters.length > 0
        ) {
          const translation =
            filterType === FiltersTypes.ROLES
              ? "send_message.filter_recap.roles"
              : "send_message.filter_recap.regions";
          return renderSelectedFilter(t(translation), selectedFilters);
        }
        const translation =
          filterType === FiltersTypes.ROLES
            ? "send_message.filter_recap.every_roles"
            : "send_message.filter_recap.every_regions";
        return (
          <Text key={translation} textStyle="text">
            {t(translation)}
          </Text>
        );
      }),
    [activeFilters]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        id="subject"
        isInvalid={!!errors?.subject}
        isDisabled={false}
      >
        <FormLabel>{t("send_message.subject")}</FormLabel>
        <Input
          variant="outline"
          name="subject"
          ref={register}
          errorBorderColor="error"
        />
        <InputError text={errors?.subject ? t("send_message.error") : ""} />
      </FormControl>
      <FormControl
        id="description"
        w="100%"
        isInvalid={!!errors?.body}
        isDisabled={false}
      >
        <FormLabel>{t("send_message.body")}</FormLabel>
        <Textarea
          variant="outline"
          name="body"
          ref={register}
          errorBorderColor="error"
        />
        <InputError text={errors?.body ? t("send_message.error") : ""} />
      </FormControl>

      <Box mb="20px">
        <Text textStyle="text">
          {t("send_message.filter_recap.label", { usersCount: users.length })}
        </Text>
        {renderSelectedFilters}
      </Box>

      <FormControl>
        <Box position="relative">
          <Button type="submit" variant="primary-solid" disabled={isLoading}>
            {t("send_message.submit")}
          </Button>
        </Box>
      </FormControl>
    </form>
  );
};

export default SendBulkMessageForm;
