import React, { useMemo, useCallback } from "react";
import { Table, Tbody, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column, Cell } from "react-table";

import {
  CardsColumnName,
  adminCardsColumnNameSchema,
  profileCardsColumnNameSchema,
} from "modules/user/cards/schemas/userTestCardsViewSchemas";
import TableHeader from "modules/shared/components/Table/TableHeader";
import TableRow from "modules/shared/components/Table/TableRow";
import TableCell from "modules/shared/components/Table/TableCell";
import {
  UserTestCardsBody,
  UserTestCardFromList,
} from "modules/user/cards/schemas/userTestCardsApiSchemas";
import { useDeleteTestCard } from "modules/user/cards/hooks/useDeleteTestCard";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import CardsActionButtons from "./CardsActionButtons";
import { useIsParamRoute } from "modules/shared/hooks/useIsParamRoute";

interface Props {
  cards?: UserTestCardsBody;
  handleCardEdit: (card: UserTestCardFromList) => void;
}

const CardsTable = ({ cards, handleCardEdit }: Props) => {
  const isParamRoute = useIsParamRoute();
  const { t, i18n } = useTranslation();
  const deleteCard = useDeleteTestCard();
  const { setFeedback } = useFeedbackStore();

  const getHeaderName = useCallback(
    (name: CardsColumnName) => t(`user-cards.${name}` as const),
    [i18n.language]
  );

  const handleDelete = useCallback(
    (id: number) => {
      setFeedback(
        feedbackSchema.enum.warning,
        actionsSchema.enum.deleteCard,
        () => deleteCard.mutate(id.toString())
      );
    },
    [deleteCard.mutate]
  );

  const columnNames = useMemo(
    () =>
      isParamRoute
        ? adminCardsColumnNameSchema.options
        : profileCardsColumnNameSchema.options,
    [isParamRoute]
  );

  const columns: Column<UserTestCardFromList>[] = useMemo(
    () =>
      (columnNames as CardsColumnName[]).map((name) => ({
        Header: getHeaderName(name),
        accessor: name,
        Cell: ({
          cell: { value, row },
        }: {
          cell: Cell<UserTestCardFromList, string | string[]>;
        }) =>
          name === "actions" ? (
            <CardsActionButtons
              card={row.values as UserTestCardFromList}
              handleEdit={handleCardEdit}
              handleDelete={handleDelete}
            />
          ) : (
            <TableCell value={value} />
          ),
      })),
    [getHeaderName, cards]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: cards || [],
      },
      useSortBy
    );

  if (cards?.length === 0) {
    return (
      <Box textAlign="center" mt="10px">
        {t("user-cards.no_cards")}
      </Box>
    );
  }

  return (
    <Box overflow="auto" py={10}>
      <Table
        {...getTableProps()}
        data-testid="users-cards-table"
        textStyle="bodySmall"
        w="100%"
      >
        <TableHeader headerGroups={headerGroups} />
        <Tbody {...getTableBodyProps()} bgColor="white">
          {rows.map((row) => {
            prepareRow(row);
            return <TableRow key={row.id} row={row} />;
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CardsTable;
