import isMomaDate from "core/helpers/utils/isMomaDate";
import * as z from "zod";

/**
 * Technical details  : params schemas
 */
export const layoutFormTypeSchema = z.enum([
  "full1",
  "full2",
  "full3",
  "full4",
  "banner",
]);

export type LayoutFormType = z.infer<typeof layoutFormTypeSchema>;

export const layoutBannerTypeSchema = z.enum(["bannermid", "bannertop"]);

export type BannerType = z.infer<typeof layoutBannerTypeSchema>;

export const layoutHybridTypeSchema = z.enum([
  "hybrid",
  "hybridmid",
  "hybridtop",
]);

export const layoutTypeSchema = z.union([
  layoutFormTypeSchema,
  layoutHybridTypeSchema,
]);

export type HybridType = z.infer<typeof layoutHybridTypeSchema>;

export const layoutEmptySchema = z.enum(["empty"]);

export type LayoutEmpty = z.infer<typeof layoutEmptySchema>;

export const templateLayoutTypeSchema = z.union([
  layoutFormTypeSchema,
  layoutBannerTypeSchema,
  layoutHybridTypeSchema,
  layoutEmptySchema,
]);

export type TemplateLayoutType = z.infer<typeof templateLayoutTypeSchema>;

export const templateLayoutWithEmptyTypeSchema = z.union([
  templateLayoutTypeSchema,
  layoutEmptySchema,
]);

export type TemplateLayoutWithEmptyType = z.infer<
  typeof templateLayoutWithEmptyTypeSchema
>;

export const backgroundColorParamSchema = z.enum([
  "Window_BackColor",
  "Frame0_BackColor",
  "Frame1_BackColor",
  "Frame2_BackColor",
  "Frame3_BackColor",
]);

export type BackgroundColorParam = z.infer<typeof backgroundColorParamSchema>;

export const borderColorParamSchema = z.enum([
  "Window_BorderColor",
  "Frame0_BorderColor",
  "Frame1_BorderColor",
  "Frame2_BorderColor",
  "Frame3_BorderColor",
]);

export const textColorParamSchema = z.enum([
  "Window_TextColor",
  "Frame1_TextColor",
  "Frame2_TextColor",
  "Frame3_TextColor",
]);

export const colorParamSchema = z.union([
  borderColorParamSchema,
  textColorParamSchema,
  backgroundColorParamSchema,
]);

export type ColorParam = z.infer<typeof colorParamSchema>;

export const imageParamSchema = z.enum(["Window_Logo", "Frame0_Logo"]);

export const textParamSchema = z.enum([
  "Frame1_Text",
  "Frame2_Text",
  "Frame3_Text",
]);

export type TextParam = z.infer<typeof textParamSchema>;

export const alignmentParamSchema = z.enum([
  "Frame0_Alignment",
  "Frame1_Alignment",
  "Frame2_Alignment",
  "Frame3_Alignment",
]);

export type AlignmentParam = z.infer<typeof alignmentParamSchema>;

export const styleParamSchema = z.enum([
  "Frame0_Style",
  "Frame1_Style",
  "Frame2_Style",
  "Frame3_Style",
]);

export const behaviourParamSchema = z.enum([
  "Behavior",
  "Popup_Timeout",
  "Message_Timeout",
]);

const paramSchema = z.union([
  backgroundColorParamSchema,
  borderColorParamSchema,
  textColorParamSchema,
  alignmentParamSchema,
  styleParamSchema,
  imageParamSchema,
  textParamSchema,
  behaviourParamSchema,
]);

export type ParamType = z.infer<typeof paramSchema>;

/**
 * Technical details : template schemas
 */

export type TechnicalTemplate = Partial<Record<ParamType, string>> & {
  Window_Layout: TemplateLayoutType;
};

export type Full1Template = Required<
  Omit<
    TechnicalTemplate,
    | "Frame3_Style"
    | "Frame3_BackColor"
    | "Frame3_TextColor"
    | "Frame3_BorderColor"
    | "Frame3_Text"
    | "Frame3_Alignment"
  >
>;

export type Full3Template = Required<
  Omit<
    Full1Template,
    | "Frame1_style"
    | "Frame1_BackColor"
    | "Frame1_TextColor"
    | "Frame1_BorderColor"
    | "Frame1_Text"
    | "Frame1_Alignment"
  >
>;

export type Full4Template = Required<
  Omit<
    Full1Template,
    "Frame1_style" | "Frame1_BackColor" | "Frame1_BorderColor"
  >
>;

export type FullTemplate = Full1Template | Full3Template | Full4Template;

export const textsSchema = z.object({
  Frame1_Text: z.union([z.string(), z.null()]).optional(), // no Frame1_Text in full3 layout
  Frame2_Text: z.string().nullable().optional(),
  Frame3_Text: z.union([z.string(), z.null()]).optional(),
});

export const alignmentSchema = z.enum(["left", "center", "right"]);

export type Alignment = z.infer<typeof alignmentSchema>;

export const alignmentsSchema = z.object({
  Frame0_Alignment: z.union([alignmentSchema, z.null()]).optional(),
  Frame1_Alignment: z.union([alignmentSchema, z.null()]).optional(),
  Frame2_Alignment: alignmentSchema.nullable().optional(),
  Frame3_Alignment: z.union([alignmentSchema, z.null()]).optional(),
});

export type Alignments = z.infer<typeof alignmentsSchema>;

export const colorsSchema = z.object({
  Window_BackColor: z.number().max(15).nullable().optional(),
  Window_TextColor: z
    .union([z.number().max(15), z.null()])
    .optional()
    .nullable(),
  Window_BorderColor: z.number().max(15).nullable().optional(),
  Frame0_BackColor: z.number().max(15).nullable().optional(),
  Frame0_BorderColor: z.number().max(15).nullable().optional(),
  Frame0_TextColor: z.number().max(15).nullable().optional(), //to be remove when ready
  Frame1_BackColor: z.number().max(15).nullable().optional(),
  Frame1_TextColor: z.number().max(15).nullable().optional(),
  Frame1_BorderColor: z.number().max(15).nullable().optional(),
  Frame2_BackColor: z.number().max(15).nullable().optional(),
  Frame2_TextColor: z.number().max(15).nullable().optional(),
  Frame2_BorderColor: z.number().max(15).nullable().optional(),
  Frame3_BackColor: z
    .union([z.number().max(15), z.null()])
    .optional()
    .nullable(),
  Frame3_TextColor: z
    .union([z.number().max(15), z.null()])
    .optional()
    .nullable(),
  Frame3_BorderColor: z
    .union([z.number().max(15), z.null()])
    .optional()
    .nullable(),
});

export type Colors = z.infer<typeof colorsSchema>;

const styleSchema = z.union([
  z.string(), // value is ignored but allowed for API compatibility
  z.null(),
]);

export const stylesSchema = z.object({
  Frame0_Style: styleSchema.optional(),
  Frame1_Style: styleSchema.optional(),
  Frame2_Style: styleSchema.optional(),
  Frame3_Style: styleSchema.optional(),
});

const windowLogoSchema = z.enum(["none", "both", "canal+", "canalsat"]);

export type WindowLogo = z.infer<typeof windowLogoSchema>;

export const logosSchema = z.object({
  Window_Logo: windowLogoSchema.optional(),
  Frame0_Logo: z.union([z.number(), z.null()]).optional(),
});

export const layoutSchema = z.object({
  Window_Layout: templateLayoutTypeSchema,
});

export const technicalSchema = layoutSchema
  .merge(colorsSchema)
  .merge(textsSchema)
  .merge(alignmentsSchema)
  .merge(logosSchema)
  .merge(stylesSchema);

export type TechnicalDetails = z.infer<typeof technicalSchema>;

/**
 * Configuration details
 */
export const themeSchema = z.enum(["loyalty", "program", "technical"]);
export const genreSchema = z.enum(["operator", "partner", "commercial"]);
export const behaviorSchema = z.enum(["pop-valid", "pop-auto", "direct"]);
export type Theme = z.infer<typeof themeSchema>;
export type Genre = z.infer<typeof genreSchema>;
export type Behavior = z.infer<typeof behaviorSchema>;

const baseConfigurationSchema = z
  .object({
    theme: themeSchema.nullable(),
    genre: genreSchema.nullable(),
    palette: z.number().nullable(),
    Behavior: behaviorSchema.nullable(),
    Popup_Timeout: z.number().nullable(),
    Message_Timeout: z.number().nullable(),
    start_hour: z.union([z.string(), z.null()]),
    end_hour: z.union([z.string(), z.null()]),
    msg_ack_count: z.number().nullable(),
    msg_ack_delay: z.number().nullable(),
    tested: z.boolean().optional(),
  })
  .nonstrict();

const configurationSchema = baseConfigurationSchema.merge(
  z.object({ regions: z.array(z.string()) })
);

export type Configuration = z.infer<typeof configurationSchema>;

/**
 * Variables
 */
export const variablesSchema = z
  .object({
    V0: z.union([z.string(), z.null()]).optional(),
    V1: z.union([z.string(), z.null()]).optional(),
    V2: z.union([z.string(), z.null()]).optional(),
    V3: z.union([z.string(), z.null()]).optional(),
    V4: z.union([z.string(), z.null()]).optional(),
    V5: z.union([z.string(), z.null()]).optional(),
    V6: z.union([z.string(), z.null()]).optional(),
    V7: z.union([z.string(), z.null()]).optional(),
  })
  .nonstrict()
  .nullable();

export interface Variables {
  V0?: string | null;
  V1?: string | null;
  V2?: string | null;
  V3?: string | null;
  V4?: string | null;
  V5?: string | null;
  V6?: string | null;
  V7?: string | null;
}
const variableSchema = z.enum(["V0", "V1", "V2", "V3", "V4", "V5", "V6", "V7"]);
export type Variable = z.infer<typeof variableSchema>;

/**
 * Storables
 */
export const storablesSchema = z
  .object({
    S0: z.union([z.string(), z.null()]).optional(),
    S1: z.union([z.string(), z.null()]).optional(),
    S2: z.union([z.string(), z.null()]).optional(),
    S3: z.union([z.string(), z.null()]).optional(),
    S4: z.union([z.string(), z.null()]).optional(),
    S5: z.union([z.string(), z.null()]).optional(),
    S6: z.union([z.string(), z.null()]).optional(),
    S7: z.union([z.string(), z.null()]).optional(),
  })
  .nonstrict()
  .nullable();

export interface Storables {
  S0?: string | null;
  S1?: string | null;
  S2?: string | null;
  S3?: string | null;
  S4?: string | null;
  S5?: string | null;
  S6?: string | null;
  S7?: string | null;
}
const storableSchema = z.enum(["S0", "S1", "S2", "S3", "S4", "S5", "S6", "S7"]);
export type Storable = z.infer<typeof storableSchema>;
/**
 * Fonctionnal details
 */
export const statusSchema = z.enum([
  "DRAFT",
  "PENDING_LEGAL",
  "PENDING_COMMERCIAL",
  "VALIDATED",
  "REFUSED",
  "PRODUCTION",
  "ARCHIVED",
  "STOPPED",
]);

export type Status = z.infer<typeof statusSchema>;
export const momaDateSchema = z.string().refine(isMomaDate);

export type MomaDate = z.infer<typeof momaDateSchema>;
export const functionalSchema = z.object({
  creation_date: momaDateSchema,
  creator: z.string().optional(),
  status: statusSchema,
  author: z.string(),
});

/**
 * template
 */
export const baseTemplateSchema = z
  .object({
    template_name: z.union([z.string(), z.null()]),
    label: z.string().optional(),
    technical_details: technicalSchema,
    variable_details: variablesSchema,
    storable_details: storablesSchema,
    configuration_details: configurationSchema,
  })
  .nonstrict();

export const draftTemplateSchema = baseTemplateSchema
  .merge(
    z.object({
      functional_details: functionalSchema.optional(),
    })
  )
  .nonstrict();

export type DraftTemplate = z.infer<typeof draftTemplateSchema>;

// used for the rest of the app
export const templateSchema = baseTemplateSchema
  .merge(
    z.object({
      functional_details: functionalSchema,
      template_id: z.number(),
    })
  )
  .nonstrict();

export type Template = z.infer<typeof templateSchema>;
