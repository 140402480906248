import { FontSpec, LayoutSpec } from "../schemas/specSchemas";

export const fontSizesSpec: FontSpec = {
  Large: "21.34px",
  Medium: "17.4px",
};

// describes mandatory alignment params for each layout
export const alignmentMap = {
  full1: [0, 1, 2],
  full2: [0, 1, 2],
  full3: [0, 2],
  full4: [0, 1, 2],
  banner: [1, 2],
  bannertop: [1, 2],
  bannermid: [1, 2],
  hybrid: [0, 1, 2, 3],
  hybridmid: [0, 1, 2, 3],
  hybridtop: [0, 1, 2, 3],
  empty: [0],
};

export const fixedColors = [
  { name: "black", color: "#000000" },
  { name: "white", color: "#FFFFFF" },
  { name: "grey01", color: "#AAAAAA" },
  { name: "grey02", color: "#5A5A5A" },
  { name: "alphablack", color: "#000000" },
  { name: "red", color: "#FF0000" },
];

export const layoutsSpec: LayoutSpec = {
  empty: {},
  full1: {
    layout: {
      singleTitleLine: true,
      separatedTitle: true,
      fullScreen: true,
      textAlign: "left",
    },
    frames: {
      full: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 0,
          w: 720,
          h: 576,
        },
        // Rectangles
        {
          name: "OF0",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 60,
          w: 560,
          h: 70,
        },
        {
          name: "OF1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_BorderColor",
          x: 80,
          y: 140,
          w: 560,
          h: 40,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 190,
          w: 560,
          h: 290,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 490,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_TextColor",
          maxLength: 150,
          size: "Large",
          text: "Frame1_Text",
          textAlign: "Frame1_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 145,
          w: 540,
          h: 30,
        },
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          maxLength: 2000,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 200,
          w: 540,
          h: 270,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 495,
          w: 540,
          h: 30,
        },
        // Images
        {
          name: "OIMA1",
          content: "Frame0_Logo",
          x: 85,
          y: 65,
          w: 180,
          h: 48,
        },
        {
          name: "OIMA2",
          content: "CANAL+ Logo", // is it a real value ?
          x: 365,
          y: 65,
          w: 110,
          h: 48,
        },
        {
          name: "OIMA3",
          content: "CanalSat Logo", // is it a real value ?
          x: 480,
          y: 65,
          w: 155,
          h: 48,
        },
      ],
    },
  },
  full2: {
    layout: {
      singleTitleLine: false,
      separatedTitle: true,
      fullScreen: true,
      textAlign: "center",
    },
    frames: {
      full: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 0,
          w: 720,
          h: 576,
        },
        // Rectangles
        {
          name: "OF0",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 60,
          w: 560,
          h: 70,
        },
        {
          name: "OF1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_BorderColor",
          x: 80,
          y: 140,
          w: 560,
          h: 70,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 220,
          w: 560,
          h: 260,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 490,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_TextColor",
          maxLength: 150,
          size: "Large",
          text: "Frame1_Text",
          textAlign: "Frame1_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 145,
          w: 540,
          h: 60,
        },
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          maxLength: 2000,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 230,
          w: 540,
          h: 240,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 495,
          w: 540,
          h: 30,
        },
        // Images
        {
          name: "OIMA1",
          content: "Frame0_Logo",
          x: 85,
          y: 65,
          w: 180,
          h: 48,
        },
        {
          name: "OIMA2",
          content: "CANAL+ Logo", // is it a real value ?
          x: 365,
          y: 65,
          w: 110,
          h: 48,
        },
        {
          name: "OIMA3",
          content: "CanalSat Logo", // is it a real value ?
          x: 480,
          y: 65,
          w: 155,
          h: 48,
        },
      ],
    },
  },
  full3: {
    layout: {
      fullScreen: true,
      textAlign: "left",
    },
    frames: {
      full: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 0,
          w: 720,
          h: 576,
        },
        // Rectangles
        {
          name: "OF0",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 60,
          w: 560,
          h: 70,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 140,
          w: 560,
          h: 340,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 490,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          maxLength: 2000,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 150,
          w: 540,
          h: 320,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 495,
          w: 540,
          h: 30,
        },
        // Images
        {
          name: "OIMA1",
          content: "Frame0_Logo",
          x: 85,
          y: 65,
          w: 180,
          h: 48,
        },
        {
          name: "OIMA2",
          content: "CANAL+ Logo", // is it a real value ?
          x: 365,
          y: 65,
          w: 110,
          h: 48,
        },
        {
          name: "OIMA3",
          content: "CanalSat Logo", // is it a real value ?
          x: 480,
          y: 65,
          w: 155,
          h: 48,
        },
      ],
    },
  },
  full4: {
    layout: {
      singleTitleLine: true,
      separatedTitle: false,
      fullScreen: true,
      textAlign: "left",
    },
    frames: {
      full: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 0,
          w: 720,
          h: 576,
        },
        // Rectangles
        {
          name: "OF0",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 60,
          w: 560,
          h: 70,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 140,
          w: 560,
          h: 340,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 490,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_TextColor",
          maxLength: 150,
          size: "Large",
          text: "Frame1_Text",
          textAlign: "Frame1_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 145,
          w: 540,
          h: 30,
        },
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          maxLength: 2000,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 185,
          w: 540,
          h: 285,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 495,
          w: 540,
          h: 30,
        },
        // Images
        {
          name: "OIMA1",
          content: "Frame0_Logo",
          x: 85,
          y: 65,
          w: 180,
          h: 48,
        },
        {
          name: "OIMA2",
          content: "CANAL+ Logo", // is it a real value ?
          x: 365,
          y: 65,
          w: 110,
          h: 48,
        },
        {
          name: "OIMA3",
          content: "CanalSat Logo", // is it a real value ?
          x: 480,
          y: 65,
          w: 155,
          h: 48,
        },
      ],
    },
  },
  bannertop: {
    layout: {
      singleTitleLine: true,
      separatedTitle: false,
      fullScreen: false,
      position: "top",
      textAlign: "left",
    },
    frames: {
      banner: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 45,
          w: 720,
          h: 200,
        },
        // Rectangles
        {
          name: "OF1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_BorderColor",
          x: 80,
          y: 50,
          w: 560,
          h: 40,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 95,
          w: 560,
          h: 100,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 200,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_TextColor",
          maxLength: 150,
          size: "Large",
          text: "Frame1_Text",
          textAlign: "Frame1_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 55,
          w: 540,
          h: 30,
        },
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          maxLength: 250,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 100,
          w: 540,
          h: 90,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 205,
          w: 540,
          h: 30,
        },
      ],
    },
  },
  bannermid: {
    layout: {
      singleTitleLine: true,
      separatedTitle: true,
      fullScreen: false,
      position: "center",
      textAlign: "left",
    },
    frames: {
      banner: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 170,
          w: 720,
          h: 200,
        },
        // Rectangles
        {
          name: "OF1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_BorderColor",
          x: 80,
          y: 175,
          w: 560,
          h: 40,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 220,
          w: 560,
          h: 100,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 325,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_TextColor",
          maxLength: 150,
          size: "Large",
          text: "Frame1_Text",
          textAlign: "Frame1_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 180,
          w: 540,
          h: 30,
        },
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          maxLength: 250,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 225,
          w: 540,
          h: 90,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 330,
          w: 540,
          h: 30,
        },
      ],
    },
  },
  banner: {
    layout: {
      singleTitleLine: true,
      separatedTitle: true,
      fullScreen: false,
      position: "bottom",
      textAlign: "left",
    },
    frames: {
      banner: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 365,
          w: 720,
          h: 200,
        },
        // Rectangles
        {
          name: "OF1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_BorderColor",
          x: 80,
          y: 370,
          w: 560,
          h: 40,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 415,
          w: 560,
          h: 100,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 520,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_TextColor",
          maxLength: 150,
          size: "Large",
          text: "Frame1_Text",
          textAlign: "Frame1_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 375,
          w: 540,
          h: 30,
        },
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          maxLength: 250,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 420,
          w: 540,
          h: 90,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 525,
          w: 540,
          h: 30,
        },
      ],
    },
  },
  hybrid: {
    layout: {
      singleTitleLine: true,
      separatedTitle: false,
      fullScreen: true,
      textAlign: "left",
    },
    frames: {
      banner: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 365,
          w: 720,
          h: 200,
        },
        // Rectangles
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 380,
          w: 560,
          h: 130,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 520,
          w: 560,
          h: 40,
        },
        // Texts
        {
          name: "OT3",
          backgroundColor: "Frame3_BackColor",
          foregroundColor: "Frame3_TextColor",
          maxLength: 24,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 390,
          w: 540,
          h: 110,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 250,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 525,
          w: 540,
          h: 30,
        },
      ],
      full: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 0,
          w: 720,
          h: 576,
        },
        // Rectangles
        {
          name: "OF0",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 60,
          w: 560,
          h: 70,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 140,
          w: 560,
          h: 340,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 490,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_TextColor",
          maxLength: 150,
          size: "Large",
          text: "Frame1_Text",
          textAlign: "Frame1_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 145,
          w: 540,
          h: 30,
        },
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          size: "Medium",
          text: "Frame3_Text",
          maxLength: 2000,
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 185,
          w: 540,
          h: 285,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 495,
          w: 540,
          h: 30,
        },
        // Images
        {
          name: "OIMA1",
          content: "Frame0_Logo",
          x: 85,
          y: 65,
          w: 180,
          h: 48,
        },
        {
          name: "OIMA2",
          content: "CANAL+ Logo", // is it a real value ?
          x: 365,
          y: 65,
          w: 110,
          h: 48,
        },
        {
          name: "OIMA3",
          content: "CanalSat Logo", // is it a real value ?
          x: 480,
          y: 65,
          w: 155,
          h: 48,
        },
      ],
    },
  },
  hybridmid: {
    layout: {
      singleTitleLine: true,
      separatedTitle: false,
      fullScreen: true,
      textAlign: "left",
    },
    frames: {
      banner: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 170,
          w: 720,
          h: 200,
        },
        // Rectangles
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 185,
          w: 560,
          h: 130,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 325,
          w: 560,
          h: 40,
        },
        // Texts
        {
          name: "OT3",
          backgroundColor: "Frame3_BackColor",
          foregroundColor: "Frame3_TextColor",
          maxLength: 250,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 195,
          w: 540,
          h: 110,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 330,
          w: 540,
          h: 30,
        },
      ],
      full: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 0,
          w: 720,
          h: 576,
        },
        // Rectangles
        {
          name: "OF0",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 60,
          w: 560,
          h: 70,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 140,
          w: 560,
          h: 340,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 490,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_TextColor",
          maxLength: 150,
          size: "Large",
          text: "Frame1_Text",
          textAlign: "Frame1_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 145,
          w: 540,
          h: 30,
        },
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          maxLength: 2000,
          size: "Medium",
          text: "Frame3_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 185,
          w: 540,
          h: 285,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 495,
          w: 540,
          h: 30,
        },
        // Images
        {
          name: "OIMA1",
          content: "Frame0_Logo",
          x: 85,
          y: 65,
          w: 180,
          h: 48,
        },
        {
          name: "OIMA2",
          content: "CANAL+ Logo", // is it a real value ?
          x: 365,
          y: 65,
          w: 110,
          h: 48,
        },
        {
          name: "OIMA3",
          content: "CanalSat Logo", // is it a real value ?
          x: 480,
          y: 65,
          w: 155,
          h: 48,
        },
      ],
    },
  },
  hybridtop: {
    layout: {
      singleTitleLine: true,
      separatedTitle: false,
      fullScreen: true,
      textAlign: "left",
    },
    frames: {
      banner: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 45,
          w: 720,
          h: 200,
        },
        // Rectangles
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 60,
          w: 560,
          h: 130,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 200,
          w: 560,
          h: 40,
        },
        // Texts
        {
          name: "OT3",
          backgroundColor: "Frame3_BackColor",
          foregroundColor: "Frame3_TextColor",
          maxLength: 250,
          size: "Medium",
          text: "Frame2_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 70,
          w: 540,
          h: 110,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 205,
          w: 540,
          h: 30,
        },
      ],
      full: [
        // Background
        {
          name: "OB",
          backgroundColor: "Window_BackColor",
          foregroundColor: "Window_BorderColor",
          x: 0,
          y: 0,
          w: 720,
          h: 576,
        },
        // Rectangles
        {
          name: "OF0",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 80,
          y: 60,
          w: 560,
          h: 70,
        },
        {
          name: "OF2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_BorderColor",
          x: 80,
          y: 140,
          w: 560,
          h: 340,
        },
        {
          name: "OF5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "Frame0_BorderColor",
          x: 0,
          y: 490,
          w: 720,
          h: 40,
        },
        // Texts
        {
          name: "OT1",
          backgroundColor: "Frame1_BackColor",
          foregroundColor: "Frame1_TextColor",
          maxLength: 150,
          size: "Large",
          text: "Frame1_Text",
          textAlign: "Frame1_Alignment",
          style: {
            padding: "5px 10px",
          },
          x: 90,
          y: 145,
          w: 540,
          h: 30,
        },
        {
          name: "OT2",
          backgroundColor: "Frame2_BackColor",
          foregroundColor: "Frame2_TextColor",
          maxLength: 2000,
          size: "Medium",
          text: "Frame3_Text",
          textAlign: "Frame2_Alignment",
          style: {
            padding: "10px",
          },
          x: 90,
          y: 185,
          w: 540,
          h: 285,
        },
        {
          name: "OT5",
          backgroundColor: "Frame0_BackColor",
          foregroundColor: "#01FFFFFF",
          maxLength: 24,
          size: "Large",
          textAlign: "Frame0_Alignment",
          style: {
            textTransform: "uppercase",
            padding: "5px 10px",
          },
          x: 90,
          y: 495,
          w: 540,
          h: 30,
        },
        // Images
        {
          name: "OIMA1",
          content: "Frame0_Logo",
          x: 85,
          y: 65,
          w: 180,
          h: 48,
        },
        {
          name: "OIMA2",
          content: "CANAL+ Logo", // is it a real value ?
          x: 365,
          y: 65,
          w: 110,
          h: 48,
        },
        {
          name: "OIMA3",
          content: "CanalSat Logo", // is it a real value ?
          x: 480,
          y: 65,
          w: 155,
          h: 48,
        },
      ],
    },
  },
};
