import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Button } from "@chakra-ui/react";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { ProfileFormValues } from "../schemas/profileFormSchema";
import { useSaveUserProfile } from "modules/user/profile/hooks/useSaveUserProfile";
import { useSelfcare } from "modules/selfcare/hooks/useSelfcare";
import DraftIcon from "core/components/InterfaceIcons/DraftIcon";
import getUserProfileFromFormValues from "modules/user/profile/helpers/getUserProfileFromFormValues";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";

const SaveUserAction = () => {
  const { t } = useTranslation();
  const { data } = useSelfcare(false);
  const save = useSaveUserProfile();
  const context = useFormContext<ProfileFormValues>();

  const { setFeedback } = useFeedbackStore();

  const handleSave = (formValues: ProfileFormValues) => {
    if (data) {
      const profile = getUserProfileFromFormValues(formValues);
      save.mutate(profile, {
        onSuccess: () => {
          setFeedback(
            feedbackSchema.enum.success,
            actionsSchema.enum.saveProfile
          );
        },
      });
    }
  };

  return (
    <Button
      onClick={context?.handleSubmit(handleSave)}
      leftIcon={<DraftIcon />}
      variant="primary-solid"
      isLoading={save.isLoading}
    >
      {t("create_user.save")}
    </Button>
  );
};

export default SaveUserAction;
