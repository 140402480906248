import React, { Suspense } from "react";
import { Tab, Tabs, TabList, TabPanels, TabPanel } from "@chakra-ui/react";
import InspectFormMessage from "./messages/InspectFormMessage";
import InspectFormTemplate from "./templates/InspectFormTemplate";
import { useTranslation } from "react-i18next";

const InspectForm = () => {
  const { t } = useTranslation();

  return (
    <Tabs mt={20}>
      <TabList>
        <Tab>{t("supervision.msg")}</Tab>
        <Tab>{t("supervision.tpl")}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <InspectFormMessage />
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<div>Loading...</div>}>
            <InspectFormTemplate />
          </Suspense>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default InspectForm;
