import React from "react";
import { HStack, Text } from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import CalendarInput from "core/components/CalendarInput/CalendarInput";
import { GlobalMessageFormValues } from "../schemas/globalMessageFormSchema";
import useGlobalMessage from "../hooks/useGlobalMessage";

const DateForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { control } = useFormContext<GlobalMessageFormValues>();
  const { data } = useGlobalMessage();

  const globalMessageStatus = data?.status;
  const isDisabled = !!globalMessageStatus && globalMessageStatus !== "DRAFT";

  return (
    <>
      <Text fontSize="xs" mb="20px" color="dark" textAlign="left">
        {t("global_message.date.label")}
      </Text>
      <HStack>
        <Controller
          data-testid="form-start-date"
          control={control}
          name="start_date"
          render={({ value, onChange }) => (
            <CalendarInput
              locale={language}
              onChange={onChange}
              selected={new Date(value)}
              id="start_date"
              disabled={isDisabled}
            />
          )}
        />
        <Controller
          control={control}
          data-testid="form-end-date"
          name="end_date"
          render={({ value, onChange }) => (
            <CalendarInput
              locale={language}
              onChange={onChange}
              selected={new Date(value)}
              id="end_date"
              disabled={isDisabled}
            />
          )}
        />
      </HStack>
    </>
  );
};

export default DateForm;
