import { TargetFormValues } from "modules/target/schemas/formSchemas";
import format from "date-fns/format";
import { TargetRequest } from "modules/target/schemas/targetSchemas";

const getTargetRequestFromFormValues = ({
  id,
  message,
  startDate,
  endDate,
}: TargetFormValues): TargetRequest => ({
  templateId: id,
  request: {
    target_request: message,
    start_date: format(startDate, "yyyy-MM-dd"),
    end_date: format(endDate, "yyyy-MM-dd"),
  },
});

export default getTargetRequestFromFormValues;
