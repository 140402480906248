import React from "react";
import { Text, Flex, Container } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Flex data-testid="footer" bg="dark" h="72px" mt="auto">
      <Container>
        <Flex alignItems="center" h="100%">
          <Text fontSize="xs" color="white" fontWeight={700}>
            {t("footer")}
          </Text>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Footer;
