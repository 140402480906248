import { useQuery } from "react-query";
import { dashboardSchema } from "modules/monitoring/ProbeList/schemas/formSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { simpleRequest } from "core/services/api";

export function getMetrics() {
  return async () => {
    const rawdata = await simpleRequest(ENDPOINTS.DASHBOARDS, dashboardSchema);
    const metricsdata = rawdata.filter(
      (dashboard) => dashboard.category_name === "MEASURE"
    );
    const data = dashboardSchema.parse(metricsdata);
    return data;
  };
}

export function useMetrics(fetch = true) {
  return useQuery(["metrics"], getMetrics(), {
    enabled: fetch,
  });
}
