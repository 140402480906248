import { PreviewFormType } from "modules/create/schemas/formSchema";

import getSpecLayoutFromSelectedLayout from "./getSpecLayoutFromFormLayout";
import getAlignmentFromFormAndLayout from "./getAlignmentFromFormAndLayout";
import getVariablesDetailsFromForm from "./getVariablesDetailsFromForm";
import getStorablesDetailsFromForm from "./getStorablesDetailsFromForm";
import sanitizeStringToNumberValue from "core/helpers/utils/sanitizeStringToNumberValue";
import sanitizeStringValue from "core/helpers/utils/sanitizeStringValue";
import sanitizeColors from "./sanitizeColors";

type configurationDetailsType = {
  palette: number | null;
  genre: string | null;
  label?: string | null;
  theme: string | null;
  regions: [string, ...string[]];
  Behavior: string | null;
  Popup_Timeout: number | null;
  Message_Timeout: number | null;
  start_hour: string | null;
  end_hour: string | null;
  msg_ack_count: number | null;
  msg_ack_delay: number | null;
};

const getTemplateFromFormValues = ({
  layout,
  text,
  colorScheme,
  position,
  extraBanner,
  alignment,
  genre,
  theme,
  name,
  label,
  variables,
  storables,
  regions,
  behavior,
  advancedSettings,
  colors,
  builtInLogos,
  customLogo,
}: PreviewFormType) => {
  const Window_Layout = getSpecLayoutFromSelectedLayout(
    layout,
    position,
    extraBanner
  );
  const variable_details = getVariablesDetailsFromForm(variables);
  const storable_details = getStorablesDetailsFromForm(storables);
  if (Window_Layout === "empty") {
    return {
      template_name: sanitizeStringValue(name),
      technical_details: {
        Window_Layout,
        Window_Logo: "none",
      },
      genre: sanitizeStringValue(genre),
      configuration_details: {
        regions,
      },
      variable_details,
      storable_details,
    };
  }

  const alignmentDetails = getAlignmentFromFormAndLayout(
    Window_Layout,
    alignment
  );

  const {
    popupTimeOut,
    messageTimeOut,
    startHour,
    endHour,
    messageCount,
    messageDelay,
  } = advancedSettings ?? {};

  const usedColors = colors ? sanitizeColors(colors) : undefined;

  const frameStyles = {
    Frame0_Style: null,
    Frame1_Style: null,
    Frame2_Style: null,
    Frame3_Style: null,
  };

  const builtInLogo =
    builtInLogos?.canalplus === true && builtInLogos?.canalsat === true
      ? "both"
      : builtInLogos?.canalplus === false && builtInLogos?.canalsat === true
      ? "canalsat"
      : builtInLogos?.canalplus === true && builtInLogos?.canalsat === false
      ? "canal+"
      : "none";

  const configuration_details: configurationDetailsType = {
    palette: sanitizeStringToNumberValue(colorScheme),
    genre: sanitizeStringValue(genre),
    theme: sanitizeStringValue(theme),
    regions,
    Behavior: sanitizeStringValue(behavior),
    Popup_Timeout: sanitizeStringToNumberValue(popupTimeOut),
    Message_Timeout: sanitizeStringToNumberValue(messageTimeOut),
    start_hour: sanitizeStringValue(startHour),
    end_hour: sanitizeStringValue(endHour),
    msg_ack_count: sanitizeStringToNumberValue(messageCount),
    msg_ack_delay: sanitizeStringToNumberValue(messageDelay),
  };

  if (sanitizeStringValue(label)) {
    configuration_details.label = label;
  }

  return {
    template_name: sanitizeStringValue(name),
    technical_details: {
      ...usedColors,
      ...text,
      ...alignmentDetails,
      ...frameStyles,
      Window_Layout,
      Window_Logo: builtInLogo,
      Frame0_Logo: customLogo,
    },
    configuration_details,
    variable_details,
    storable_details,
  };
};

export default getTemplateFromFormValues;
