import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { generatePath } from "react-router";

import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { globalMessageSchema } from "../schemas/globalMessageFormSchema";

export const getGlobalMessage = (id: string) =>
  simpleRequest(generatePath(ENDPOINTS.GLOBAL, { id }), globalMessageSchema);

export default function useGlobalMessage(fetch = true) {
  const { id } = useParams<RouteIdParam>();
  const history = useHistory();
  // redirect to create template if id is a string
  if (id && isNaN(Number(id))) {
    history.push("/globals/create");
  }

  return useQuery(["global", Number(id)], () => getGlobalMessage(id!), {
    enabled: fetch && !isNaN(Number(id)),
    suspense: true,
  });
}
