import { useFormContext } from "react-hook-form";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import { AlignmentParam } from "modules/preview/schemas/templateSchemas";
import useTemplate from "modules/preview/hooks/useTemplate";

const useAlignment = (alignmentParam: AlignmentParam) => {
  const { data } = useTemplate(false);
  const context = useFormContext<PreviewFormType>();
  return context
    ? context.watch("alignment")?.[alignmentParam] ?? "left"
    : data?.technical_details[alignmentParam];
};

export default useAlignment;
