import { createContext } from "react";
import { Filters, HeaderGroup } from "react-table";

export interface GlobalFilter {
  [key: string]: string | boolean | undefined;
}

export interface FilterContextValue<T extends object> {
  globalFilter?: GlobalFilter;
  setGlobalFilter?: (filter: GlobalFilter) => void;
  filters: Filters<T>;
  setAllFilters: (filters: Filters<T>) => void;
  headerGroups: HeaderGroup<T>[];
}

const FilterContext = createContext<FilterContextValue<any> | null>(null);

export default FilterContext;
