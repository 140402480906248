import React from "react";
import { FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";

import { PreviewFormType } from "modules/create/schemas/formSchema";
import Dropdown from "core/components/Dropdown/Dropdown";
import getSelectOptionsFromSchema from "modules/preview/helpers/getSelectOptionsFromSchema";
import { Genre, Theme } from "modules/preview/schemas/templateSchemas";
import useIsEmptyLayout from "modules/preview/hooks/useIsEmptyLayout";

interface Props {
  formName: "theme" | "genre";
  options: string[];
  defaultValue?: Theme | Genre | null;
  disabled?: boolean;
  noOptions?: string;
}

const InformationsDropdown = ({
  formName,
  options,
  defaultValue,
  noOptions,
  disabled = false,
}: Props) => {
  const context = useFormContext<PreviewFormType>();
  const { t } = useTranslation();
  const isEmptyLayout = useIsEmptyLayout();
  const dropdownOptions = getSelectOptionsFromSchema(
    options,
    `preview.informations.${formName}.`,
    isEmptyLayout
  );

  return (
    <FormControl id={formName} mt="10px" isDisabled={disabled}>
      <FormLabel>
        {t(`preview.informations.${formName}.title` as const)}
      </FormLabel>
      <Controller
        control={context.control}
        defaultValue={defaultValue}
        name={formName}
        render={({ onChange, value, name, ref }) => {
          return (
            <Dropdown
              disabled={disabled}
              options={dropdownOptions}
              name={name}
              inputRef={ref}
              value={dropdownOptions.find((option) => option.value === value)!}
              onChange={(option) => onChange(option.value)}
              noOptions={noOptions}
            />
          );
        }}
      />
      <FormHelperText fontSize="xs" color="abbey">
        {t(`preview.informations.${formName}.hint` as const)}
      </FormHelperText>
    </FormControl>
  );
};

export default InformationsDropdown;
