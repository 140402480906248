import { useFormContext } from "react-hook-form";
import getSpecLayoutFromSelectedLayout from "modules/preview/helpers//getSpecLayoutFromFormLayout";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import useTemplate from "modules/preview/hooks/useTemplate";

const useLayout = () => {
  const context = useFormContext<PreviewFormType>();
  const { data } = useTemplate(false);

  return context
    ? getSpecLayoutFromSelectedLayout(
        context.watch("layout"),
        context.watch("position"),
        context.watch("extraBanner")
      )
    : data?.technical_details?.Window_Layout;
};

export default useLayout;
