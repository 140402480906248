import React, { useCallback } from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useDeleteColorScheme } from "../hooks/useDeleteColorScheme";
import { useParams } from "react-router-dom";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { useTranslation } from "react-i18next";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";

const DeleteColorSchemeAction = () => {
  const { id } = useParams<RouteIdParam>();
  const deleteColorScheme = useDeleteColorScheme();
  const { setFeedback } = useFeedbackStore();
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    setFeedback(
      feedbackSchema.enum.warning,
      actionsSchema.enum.deleteColorScheme,
      () => (id ? deleteColorScheme.mutate(id) : {})
    );
  }, [id, deleteColorScheme]);

  return (
    <Button
      onClick={handleDelete}
      leftIcon={<DeleteIcon />}
      variant="primary-solid"
    >
      {t("assets.color_scheme.delete")}
    </Button>
  );
};

export default DeleteColorSchemeAction;
