const layerStyles = {
  tertiaryLightBg: {
    color: "dark",
    border: "solid 1px",
    _focus: { bg: "zircon" },

    _disabled: {
      _hover: {
        bg: "silver",
      },

      bg: "silver",
      color: "jumbo",
      opacity: 1,
    },
  },

  tertiaryDarkBg: {
    border: "solid 1px",
    borderColor: "zircon",
    color: "zircon",
    bg: "dark",
    _disabled: { color: "jumbo", opacity: 1, borderColor: "jumbo" },
    _hover: {
      bg: "zircon",
      borderColor: "dark",
      color: "dark",
    },
    _focus: { bg: "zircon", borderColor: "dark", color: "dark" },
  },

  solid: {
    _hover: { bg: "monza" },
    color: "white",
    bg: "amaranth",
    opacity: 1,

    _focus: { bg: "monza" },
  },

  linkLightBg: {
    color: "monza",

    _hover: {
      color: "amaranth",
      textDecorationLine: "underline",
      textUnderlinePosition: "under",
      _active: {
        color: "monza",
      },
    },
    _focus: {
      color: "amaranth",
      textDecorationLine: "underline",
    },

    _disabled: {
      color: "silver",
      _hover: { color: "silver", textDecorationLine: "none" },
    },
  },

  linkLightBgDark: {
    color: "dark",

    _hover: {
      color: "amaranth",
      textDecorationLine: "underline",
      textUnderlinePosition: "under",
    },
    _focus: {
      color: "amaranth",
      textDecorationLine: "underline",
    },
    _active: {
      color: "monza",
    },

    _disabled: {
      color: "silver",
      _hover: { color: "silver", textDecorationLine: "none" },
    },
  },

  linkDarkBg: {
    color: "white",

    _hover: {
      color: "silver",
      textDecorationLine: "underline",
      textUnderlinePosition: "under",
    },
    _focus: {
      color: "silver",
      textDecorationLine: "underline",
    },

    _disabled: {
      color: "silver",
      _hover: { color: "silver", textDecorationLine: "none" },
    },
  },
};

export default layerStyles;
