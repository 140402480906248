/**
 * returns a string with an uppercased first letter
 * @param word
 * @returns string
 * @signature
 *    capitalizeFirstLetter(string)
 * @category string
 */

const capitalizeFirstLetter = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export default capitalizeFirstLetter;
