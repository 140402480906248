import * as z from "zod";
import {
  themeSchema,
  statusSchema,
  momaDateSchema,
  genreSchema,
} from "modules/preview/schemas/templateSchemas";
import { regionsListBodySchema } from "modules/regions/schemas/regionsSchemas";
import { actionsSchema } from "modules/shared/schemas/actionsSchemas";

export const templateFromListSchema = z.object({
  template_id: z.number().nullable(),
  creation_date: momaDateSchema,
  template_name: z.string(),
  theme: themeSchema.nullable(),
  genre: genreSchema.nullable(),
  author: z.string(),
  label: z.string().nullable(),
  status: statusSchema,
  regions: regionsListBodySchema,
  actions: actionsSchema.optional(),
});

export type TemplateFromList = z.infer<typeof templateFromListSchema>;

export const templatesListSchema = z.array(templateFromListSchema);

export type TemplatesListBody = z.infer<typeof templatesListSchema>;
