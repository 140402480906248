import React from "react";
import { useParams } from "react-router-dom";
import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import previewFormWidth from "modules/preview/constants/previewFormWidth";
import { useFinalizeTemplate } from "modules/finalize/hooks/useFinalizeTemplate";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import useTestedTemplate from "modules/preview/hooks/useTestedTemplate";
import TargetIcon from "core/components/InterfaceIcons/TargetIcon";

const FinalizationInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const finalize = useFinalizeTemplate();
  const isTested = useTestedTemplate();
  return (
    <Box w={previewFormWidth} ml={10}>
      <Heading as="h2" size="lg" mb="20px">
        {t("actions.finalize.info.title")}
      </Heading>
      <Text> {t("actions.finalize.info.text")}</Text>
      <Box mt="40px">
        <Button
          onClick={() => finalize.mutate(Number(id))}
          leftIcon={<TargetIcon />}
          variant="primary-solid"
          disabled={!isTested}
          isLoading={finalize.isLoading}
        >
          {t("actions.finalize.button")}
        </Button>
      </Box>
    </Box>
  );
};

export default FinalizationInfo;
