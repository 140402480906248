import React, { useMemo, useCallback } from "react";
import { Table, Tbody, Box, Center, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { UserFromList } from "modules/users/schemas/usersModelSchemas";
import { useTable, useSortBy, Column, Cell, useFilters } from "react-table";
import { generatePath } from "react-router";

import {
  usersColumnNameSchema,
  UsersColumnName,
} from "modules/users/schemas/usersViewSchemas";
import TableHeader from "modules/shared/components/Table/TableHeader";
import TableRow from "modules/shared/components/Table/TableRow";
import TableCell from "modules/shared/components/Table/TableCell";
import ActionCell from "modules/shared/components/Table/ActionCell";
import { PATHS } from "modules/shared/constants/paths";
import Filters from "modules/filters/components/Filters";
import SwitchFilter from "modules/filters/components/SwitchFilter";
import filterRowsByName from "modules/filters/helpers/filterRowsByName";
import CreateUserButton from "./CreateUserButton";
import SendBulkMessageButton from "./SendBulkMessageButton";
import DuplicateIconBlock from "modules/duplicate/components/DuplicateIconBlock";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { useIsConfigurator } from "modules/roles/hooks/useIsConfigurator";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";

interface Props {
  users?: UserFromList[];
}

const UsersTable = ({ users }: Props) => {
  const { t, i18n } = useTranslation();

  const getHeaderName = useCallback(
    (name: UsersColumnName) => t(`users.${name}` as const),
    [i18n.language]
  );
  const isAdministrator = useIsAdministrator();
  const isConfigurator = useIsConfigurator();
  const hasUserAccess = useHasAccessRights(PATHS.USER, ["POST"]);
  const CanDuplicateAndEditUser =
    isAdministrator || isConfigurator || hasUserAccess;
  const columns = useMemo(
    () =>
      usersColumnNameSchema.options.map((name) => ({
        Header: getHeaderName(name),
        accessor: name,
        disableSortBy:
          name === usersColumnNameSchema.enum.roles ||
          name === usersColumnNameSchema.enum.regions,
        Cell: ({
          cell: { value, row },
        }: {
          cell: Cell<UserFromList, string | string[]>;
        }) =>
          name === usersColumnNameSchema.enum.actions ? (
            CanDuplicateAndEditUser ? (
              <Box>
                <ActionCell
                  value={value}
                  to={generatePath(PATHS.USER, { id: row.values.id })}
                ></ActionCell>
                <DuplicateIconBlock
                  to={generatePath(PATHS.DUPLICATE_USER, { id: row.values.id })}
                />
              </Box>
            ) : null
          ) : (
            <TableCell value={value} />
          ),
        Filter: SwitchFilter,
        filter: (...args) => filterRowsByName(...args, name),
      })),
    [getHeaderName]
  ) as Column<UserFromList>[];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setAllFilters,
  } = useTable(
    {
      columns,
      data: users || [],
    },
    useFilters,
    useSortBy
  );

  if (users?.length === 0) {
    return (
      <Flex mt="10px" d="column">
        <Text m="20px" align="center">
          {t("users.no_users")}
        </Text>
        <Center>
          <CreateUserButton />
        </Center>
      </Flex>
    );
  }

  return (
    <>
      <Box position="relative" marginTop="40px">
        <Filters<UserFromList>
          count={rows.length}
          headerGroups={headerGroups}
          filters={state.filters}
          setAllFilters={setAllFilters}
        />
        <Box position="absolute" right={0} top={35}>
          {users && (
            <SendBulkMessageButton users={rows} activeFilters={state.filters} />
          )}
          <CreateUserButton />
        </Box>
      </Box>
      <Box overflow="auto" pt={5} pb={10}>
        <Table
          {...getTableProps()}
          data-testid="users-table"
          textStyle="bodySmall"
          w="100%"
        >
          <TableHeader headerGroups={headerGroups} />
          <Tbody {...getTableBodyProps()} bgColor="white">
            {rows.map((row) => {
              prepareRow(row);
              return <TableRow key={row.id} row={row} />;
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default UsersTable;
