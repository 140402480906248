import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { baseToast } from "modules/shared/helpers/baseToast";
import { simpleRequest } from "core/services/api";
import { logoutBodySchema } from "../schemas/loginSchemas";
import useTokenStore from "./useTokenStore";
import { methodSchema } from "core/schemas/http";

const logout = () =>
  simpleRequest(ENDPOINTS.LOGOUT, logoutBodySchema, methodSchema.enum.post);

export function useLogout() {
  const { t } = useTranslation();
  const resetToken = useTokenStore(({ resetToken }) => resetToken);
  return useMutation(logout, {
    onSuccess: () => {
      resetToken();
      baseToast({
        id: "LOGOUT_SUCCESS",
        title: t("logout.success"),
        status: "success",
      });
    },
    onError: () => {
      baseToast({
        id: "LOGOUT_ERROR",
        title: t("logout.error"),
        status: "error",
      });
    },
  });
}
