import React from "react";
import { Box, Grid, useRadioGroup } from "@chakra-ui/react";

import RadioImageCard from "core/components/RadioImageCard/RadioImageCard";
import { useImageUrl } from "modules/images/hooks/useImageUrl";
import { CustomImage } from "modules/images/schemas/customImageSchema";

const RadioLogoCard = ({
  image,
  radioProps,
}: {
  image: CustomImage;
  radioProps: ReturnType<ReturnType<typeof useRadioGroup>["getRadioProps"]>;
}) => {
  const { data: url } = useImageUrl(image);

  if (url) {
    return (
      <Grid gap={2}>
        <RadioImageCard key={image.id} {...radioProps} logoUrl={url} />
        <Box>{image.title}</Box>
      </Grid>
    );
  }
  return null;
};

export default RadioLogoCard;
