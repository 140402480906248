import React from "react";
import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  Box,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TooltipArrow from "../../../../../../core/components/TooltipArrow/TooltipArrow";
import { Qrcodes } from "../../../../../qrcodes/components/Qrcodes";
import { QrcodesListBody } from "modules/qrcodes/schemas/customQrcodeSchema";

export const ListContent = ({
  data,
  methods,
  isOpen,
}: {
  data?: QrcodesListBody;
  isOpen: boolean;
  methods: {
    onConfirmValue: (id: number) => void;
    onClose: () => void;
  };
}) => {
  const { t } = useTranslation();
  const { onConfirmValue, onClose } = methods;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minWidth="70em"
        position="fixed"
        top="30%"
        h="45%"
        borderRadius="sm"
      >
        <ModalHeader>
          <Box textStyle="h3">{t("preview.logos.qrcodes.choose")}</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="auto">
          <Qrcodes qrcodes={data} onConfirmValue={onConfirmValue} />
        </ModalBody>
        <ModalFooter display="flex" justifyContent="space-between">
          <Box textStyle="link" textDecoration="underline">
            <TooltipArrow label={t("preview.logos.qrcodes.request_message")}>
              <Button
                variant="link-button"
                as={RouterLink}
                to={{ pathname: "/profile", state: { tab: 2 } }}
              >
                {t("preview.logos.qrcodes.missing")}
              </Button>
            </TooltipArrow>
          </Box>
          <HStack>
            <Button variant="tertiary-ghost" mr={3} onClick={onClose}>
              {t("create.cancel")}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
