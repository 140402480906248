import React, { useEffect, useContext, useState } from "react";
import {
  FormControl,
  FormLabel,
  Box,
  Input,
  Checkbox,
  Text,
  Button,
  useCheckboxGroup,
  CheckboxGroup,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, useController } from "react-hook-form";

import styled from "@emotion/styled";
import UploadIcon from "core/components/UploadIcon/UploadIcon";
import { getFormValuesFromImage } from "modules/images/helpers/getFormValuesFromImage";
import { useUserRegions } from "modules/regions/hooks/useUserRegions";
import { ImageIdContext } from "modules/shared/context/ImageIdContext";
import { ManageImageFormValues } from "../schemas/customImageSchema";
import InputError from "core/components/InputError/InputError";
import { useImage } from "../hooks/useImage";

const StyledInput = styled.input`
  height: 40px;
  width: 180px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`;

const ManageImageForm = () => {
  const userRegions = useUserRegions();
  const id = useContext(ImageIdContext);
  const { data: image } = useImage(id, false);
  const [fileError, setFileError] = useState(false);
  // if id exists, it means we are currently deleting or changing the image
  const disableForm = !!(id && !image?.mutable);
  const { setValue, watch, control, errors, register, formState } =
    useFormContext<ManageImageFormValues>();
  const { t } = useTranslation();
  const {
    field: { ref, ...checkboxGroupProps },
  } = useController({
    name: "regions",
    control,
    defaultValue: [],
  });

  const { getCheckboxProps, value } = useCheckboxGroup(checkboxGroupProps);
  const checkboxProps = getCheckboxProps();
  const [isAllItemsChecked, setIsAllItemsChecked] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const isBulkSelectChecked = React.useMemo(() => {
    return value.length === userRegions?.length;
  }, [value, userRegions, id]);

  useEffect(() => {
    if (id && image) {
      const { title, description, regions } = getFormValuesFromImage(
        image,
        userRegions
      );
      setValue("regions", regions);
      setValue("title", title);
      setValue("description", description);
    } else if (!id) {
      setValue("regions", userRegions);
    }
  }, [id, image, userRegions]);

  useEffect(() => {
    // We use this effect as Zod validation is inefficient here, we can't type the instance of FileList to have a minimum length
    if (formState.dirtyFields.original) {
      setFileError(!watch("original"));
    }
  }, [formState.dirtyFields, watch("original")]);

  useEffect(() => {
    if (isAllItemsChecked) {
      setValue("regions", userRegions);
    } else if (!isAllItemsChecked) {
      if (hasFocus) {
        setValue("regions", []);
      }
    }
  }, [isAllItemsChecked, userRegions, hasFocus]);

  useEffect(() => {
    if (watch("regions").length === userRegions?.length) {
      setIsAllItemsChecked(true);
    } else {
      setIsAllItemsChecked(false);
    }
  }, [watch("regions"), userRegions]);

  return (
    <form id="image-form" name="image-form">
      <FormControl
        id="title"
        w="400px"
        isInvalid={!!errors?.title}
        isDisabled={disableForm}
      >
        <FormLabel>{t("assets.image.name")}</FormLabel>
        <Input
          variant="outline"
          name="title"
          ref={register}
          errorBorderColor="error"
        />
        <InputError text={errors?.title ? t("assets.image.error") : ""} />
      </FormControl>
      <FormControl
        id="description"
        w="100%"
        isInvalid={!!errors?.description}
        isDisabled={disableForm}
      >
        <FormLabel>{t("assets.image.description")}</FormLabel>
        <Input
          variant="outline"
          name="description"
          ref={register}
          errorBorderColor="error"
        />
        <InputError text={errors?.description ? t("assets.image.error") : ""} />
      </FormControl>
      {(!id || image?.mutable) && (
        <FormControl>
          <Box position="relative">
            <Button
              variant="primary-solid"
              as="div"
              leftIcon={<UploadIcon />}
              isDisabled={disableForm}
            >
              {t("assets.image.upload")}
            </Button>
            <StyledInput
              type="file"
              name="original"
              ref={register}
              id="original"
            />
          </Box>
          <Text h="25px">
            {watch("original") ? watch("original")[0]?.name : ""}
          </Text>
          <InputError text={fileError ? t("assets.image.error") : ""} />
        </FormControl>
      )}

      <CheckboxGroup value={value}>
        <FormControl isDisabled={disableForm}>
          <FormLabel>{t("assets.image.regions")}</FormLabel>
          <Checkbox
            isChecked={isBulkSelectChecked}
            isIndeterminate={value.length > 0 && !isBulkSelectChecked}
            onChange={() => setIsAllItemsChecked(!isAllItemsChecked)}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            isDisabled={disableForm}
            name="regions"
          >
            <Text fontSize="sm" color="dark" mr="20px">
              {t("assets.image.all_regions")}
            </Text>
          </Checkbox>
          <Flex
            flexDirection="column"
            height="150px"
            flexWrap="wrap"
            mt="5px"
            ml="5px"
          >
            {userRegions?.map((region) => (
              <Checkbox
                {...checkboxProps}
                name="regions"
                ref={ref}
                key={region}
                alignItems="flex-start"
                value={region}
                lineHeight="15px"
                isDisabled={disableForm}
              >
                <Text fontSize="sm" color="dark" mb="10px" mr="20px">
                  {region}
                </Text>
              </Checkbox>
            ))}
          </Flex>
          <InputError text={errors?.regions ? t("assets.image.error") : ""} />
        </FormControl>
      </CheckboxGroup>
    </form>
  );
};

export default ManageImageForm;
