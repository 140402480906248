import React, { useCallback, useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDebouncyEffect } from "use-debouncy";

import useFilterContext from "modules/filters/hooks/useFilterContext";
import { TemplateFromList } from "modules/templates/schemas/templateModelSchemas";

const DEBOUNCE_DELAY = 300;

const KeywordFilter = () => {
  const globalFilterValue = useFilterContext<TemplateFromList>();
  const globalFilterKeyword = globalFilterValue?.globalFilter?.keyword || "";
  const [keyword, setKeyword] = useState(globalFilterKeyword);
  const { t } = useTranslation();

  useEffect(() => {
    if (globalFilterValue?.globalFilter?.keyword !== keyword) {
      setKeyword(globalFilterKeyword);
    }
  }, [globalFilterKeyword]);

  useDebouncyEffect(
    () => {
      if (globalFilterValue?.setGlobalFilter) {
        globalFilterValue?.setGlobalFilter({
          ...globalFilterValue.globalFilter,
          keyword,
        });
      }
    },
    DEBOUNCE_DELAY,
    [keyword]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  }, []);

  if (!globalFilterValue) {
    return null;
  }

  return (
    <Input
      placeholder={t("templates.keyword")}
      value={keyword as string}
      onChange={handleChange}
    />
  );
};

export default KeywordFilter;
