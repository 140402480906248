import { methodSchema } from "core/schemas/http";
import { simpleRequest } from "core/services/api";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { baseToast } from "modules/shared/helpers/baseToast";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import {
  CredentialsEmailStep,
  emailValidation,
} from "../schemas/passwordSchemas";

const resetCredentialsEmailStep = (credentials: CredentialsEmailStep) =>
  simpleRequest(
    ENDPOINTS.RESET_PASSWORD,
    emailValidation,
    methodSchema.enum.post,
    credentials,
    false
  );

export function useResetCredentialsEmailStep() {
  const { t } = useTranslation();

  return useMutation(resetCredentialsEmailStep, {
    onSuccess: () => {
      baseToast({
        id: "RESET_PASSWORD_SUCCESS",
        title: t("login.reset.email_sent"),
        status: "success",
      });
    },
    onError: () => {
      baseToast({
        id: "RESET_PASSWORD_SUCCESS",
        title: t("login.reset.email_sent"),
        status: "success",
      });
    },
  });
}
