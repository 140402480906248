import React from "react";
import { Heading } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLocation } from "react-router-dom";

import {
  ProfileFormValues,
  profileFormValuesSchema,
} from "modules/user/profile/schemas/profileFormSchema";

import { useSelfcare } from "modules/selfcare/hooks/useSelfcare";
import ProfileForms from "modules/user/profile/components/ProfileForms";
import getFormValuesFromUserProfile from "modules/user/profile/helpers/getFormValuesFromUserProfile";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";

const UserProfile = () => {
  const { data } = useSelfcare();
  const location = useLocation<{ tab?: number }>();

  const { tab } = location.state ?? {};

  const methods = useForm<ProfileFormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
    defaultValues: data && getFormValuesFromUserProfile(data),
    resolver: zodResolver(profileFormValuesSchema),
    shouldFocusError: true,
  });

  return (
    <FormProvider {...methods}>
      <ScreenContainer>
        <Heading as="h1" size="lg" mb={10} mt={10}>
          {data?.name}
        </Heading>
        <ProfileForms tab={tab} />
      </ScreenContainer>
    </FormProvider>
  );
};

export default UserProfile;
