import React, { useMemo, useCallback } from "react";
import { Table, Tbody, Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column, Cell, useFilters } from "react-table";

import TableHeader from "modules/shared/components/Table/TableHeader";
import TableRow from "modules/shared/components/Table/TableRow";
import TableCell from "modules/shared/components/Table/TableCell";

import filterRowsByName from "modules/filters/helpers/filterRowsByName";
import {
  inspectedCampaignType,
  campaignColumnNameSchema,
  CampaignColumnName,
} from "./schemas/InspectionCampaignSchemas";

interface Props {
  campaigns?: inspectedCampaignType[];
}
const emptyArray: never[] = [];
const CampaignTable = ({ campaigns }: Props) => {
  const { t, i18n } = useTranslation();
  const getHeaderName = useCallback(
    (name: CampaignColumnName) => {
      t(`supervision.campaign.${name}` as any);
    },
    [i18n.language]
  );

  const columns = useMemo(
    () =>
      campaignColumnNameSchema.options.map((name) => ({
        Header: <Box minW={24}>{name}</Box>,
        accessor: name,
        Cell: ({
          cell: { value, row },
        }: {
          cell: Cell<inspectedCampaignType, string | []>;
        }) => <TableCell value={value ?? "NA"} />,
        filter: (...args) => filterRowsByName(...args, name),
      })),
    [getHeaderName, campaigns]
  ) as Column<inspectedCampaignType>[];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: campaigns || emptyArray,
      },
      useFilters,
      useSortBy
    );

  if (campaigns?.length === 0) {
    return (
      <Flex mt={20} d="column">
        <Text m="20px" align="center">
          {t("supervision.campaign.no_campaign")}
        </Text>
      </Flex>
    );
  }

  return (
    <Box overflow="auto">
      <Text textStyle="labelSmall" mt="20px" mb="20px">
        {campaigns?.length} {t("supervision.campaign.quantity_campaigns")}
      </Text>
      <Table
        {...getTableProps()}
        data-testid="supervision-campaign"
        textStyle="bodySmall"
        w="100%"
        min-width="150px"
      >
        <TableHeader headerGroups={headerGroups} />
        <Tbody {...getTableBodyProps()} bgColor="white">
          {rows.map((row) => {
            prepareRow(row);
            return <TableRow key={row.id} row={row} />;
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CampaignTable;
