import React from "react";
import { Box, useRadio, Image, useRadioGroup } from "@chakra-ui/react";

type Props = ReturnType<ReturnType<typeof useRadioGroup>["getRadioProps"]> & {
  logoUrl: string;
};

const RadioImageCard = (props: Props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        boxShadow="md"
        width="fit-content"
        _checked={{
          boxShadow: "outline",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        h="60px"
        w="155px"
        display="flex"
      >
        <Image
          {...checkbox}
          src={props.logoUrl as string}
          objectFit="contain"
        />
      </Box>
    </Box>
  );
};

export default RadioImageCard;
