import React, { ReactNode } from "react";
import { Container } from "@chakra-ui/react";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";
import Feedback from "modules/feedback/components/Feedback";

interface Props {
  children: ReactNode;
}

const ScreenContainer = ({ children }: Props) => (
  <Container pb="40px">
    <BreadNav />
    <Feedback />
    {children}
  </Container>
);

export default ScreenContainer;
