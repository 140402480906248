import { useQuery } from "react-query";
import * as z from "zod";

import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { country } from "../schemas/globalMessageFormSchema";

export const getCountries = (regions: string[]) =>
  simpleRequest(
    `${ENDPOINTS.COUNTRIES}?regions=${regions.join("&regions=")}`,
    z.array(country)
  );

export default function useCountries(regions: string[], fetch = true) {
  return useQuery(["countries", regions], () => getCountries(regions), {
    enabled: fetch,
  });
}
