import React, { useEffect, useMemo } from "react";
import { FormControl, Switch, FormLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import {
  layoutFormTypeSchema,
  layoutHybridTypeSchema,
} from "modules/preview/schemas/templateSchemas";
import useDisableForm from "modules/preview/hooks/useDisableForm";
import useLayout from "modules/preview/hooks/useLayout";
import { defaultColorsValue } from "modules/create/constants/defaultValues";
import getColorsFromLayout from "modules/preview/helpers/getColorsFromLayout";

interface ControllerRenderProps {
  value: boolean;
  onChange(e: boolean): void;
}

const BannerSwitch = () => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();
  const isFormDisabled = useDisableForm();
  const selectedLayout = getValues("layout");
  const isLayoutWithBanner = useMemo(
    () =>
      selectedLayout === layoutFormTypeSchema.enum.full4 ||
      layoutHybridTypeSchema.check(selectedLayout),
    [selectedLayout]
  );
  const formattedLayout = useLayout();
  const colors = getValues("colors") ?? defaultColorsValue;

  useEffect(
    function resetBannerColor() {
      if (
        selectedLayout === layoutFormTypeSchema.enum.full4 &&
        getValues("extraBanner")
      ) {
        if (
          colors["Frame3_BackColor"] === null ||
          colors["Frame3_TextColor"] === null ||
          colors["Frame3_BorderColor"] === null
        ) {
          setValue(
            "colors.Frame3_BackColor",
            getColorsFromLayout("full4", defaultColorsValue, true)
              .Frame3_BackColor
          );
          setValue(
            "colors.Frame3_TextColor",
            getColorsFromLayout("full4", defaultColorsValue, true)
              .Frame3_TextColor
          );
          setValue(
            "colors.Frame3_BorderColor",
            getColorsFromLayout("full4", defaultColorsValue, true)
              .Frame3_BorderColor
          );
        }
      } else {
        setValue("colors.Frame3_BackColor", null);
        setValue("colors.Frame3_TextColor", null);
        setValue("colors.Frame3_BorderColor", null);
      }
    },
    [selectedLayout, getValues, setValue, formattedLayout, colors]
  );

  return (
    <Controller
      name="extraBanner"
      defaultValue={getValues("extraBanner") || false}
      control={control}
      render={({ onChange, value }: ControllerRenderProps) => {
        return (
          <FormControl
            display="flex"
            alignItems="center"
            isDisabled={!isLayoutWithBanner}
          >
            <Switch
              id="banner"
              isDisabled={!isLayoutWithBanner || isFormDisabled}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              isChecked={value}
              data-testid="banner-switch"
            />
            <FormLabel htmlFor="banner" mb="0" ml={5} fontSize="x-small">
              {t("create.banner.add")}
            </FormLabel>
          </FormControl>
        );
      }}
    />
  );
};

export default BannerSwitch;
