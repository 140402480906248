import { useMutation, useQueryClient } from "react-query";
import { simpleRequest } from "core/services/api";
import { loginBodySchema } from "../schemas/loginSchemas";
import { OktaCredentialsForm } from "../schemas/oktaCredentialsFormSchema";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useTokenStore from "./useTokenStore";

export const oktaLogin = (oktaCredentials: OktaCredentialsForm) =>
  simpleRequest(
    ENDPOINTS.LOGIN_OKTA_POST,
    loginBodySchema,
    methodSchema.enum.post,
    oktaCredentials,
    false
  );

export const useOktaLogin = () => {
  const queryClient = useQueryClient();
  const setToken = useTokenStore(({ setToken }) => setToken);
  return useMutation(oktaLogin, {
    onError: (data) => {},
    onSuccess: (data) => {
      setToken(data.token);
      queryClient.invalidateQueries(["selfcare"]);
    },
  });
};
