import React, { ReactNode } from "react";
import { Flex, AlertDescription } from "@chakra-ui/react";

import ValidationButtons from "core/components/ActionFeedback/ValidationButtons";

interface Props {
  title: string;
  message?: string;
  confirm: string;
  cancel: string;
  handleAction?: () => void;
  reset?: () => void;
  type?: "contributor" | "validator";
  children?: ReactNode;
}

const WarningAlert = ({
  title,
  message,
  confirm,
  cancel,
  handleAction,
  reset,
  type = "contributor",
  children,
}: Props) => {
  return (
    <Flex d="column" maxW="550px" align="flex-start">
      <AlertDescription display="block" fontWeight={700} textAlign="left">
        {title}
      </AlertDescription>
      {message && (
        <AlertDescription
          display="block"
          fontSize="xs"
          textAlign="left"
          lineHeight="17px"
          mt="10px"
          mb="10px"
        >
          {message}
        </AlertDescription>
      )}
      {handleAction && reset && (
        <ValidationButtons
          confirm={confirm}
          cancel={cancel}
          handleAction={handleAction}
          reset={reset}
          type={type}
        />
      )}
      {children}
    </Flex>
  );
};

export default WarningAlert;
