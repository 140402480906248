import React from "react";
import { createIcon } from "@chakra-ui/react";

const RefuseIcon = createIcon({
  displayName: "RefuseIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="12"
        cy="12"
        r="12"
        fill="#BB0002"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.5611L7.43894 6L6 7.43894L10.5611 12L6 16.5611L7.43894 18L12 13.4389L16.5611 18L18 16.5611L13.4389 12L18 7.43894L16.5611 6L12 10.5611Z"
        fill="white"
      />
    </>
  ),
});

export default RefuseIcon;
