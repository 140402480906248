import { create } from "zustand";
import { persist } from "zustand/middleware";
import useInitializationStore from "modules/shared/hooks/useInitializationStore";
interface TokenState {
  token: string | null;
  setToken: (token: string) => void;
  resetToken: () => void;
}

const options = {
  name: "token",
  onRehydrateStorage: () => () => {
    useInitializationStore.getState().setInitialized();
  },
};

const useTokenStore = create<TokenState>()(
  persist(
    (set) => ({
      token: null,
      setToken: (token: string) => set({ token }),
      resetToken: () => set({ token: null }),
    }),
    options
  )
);
export default useTokenStore;
