import React from "react";
import { Flex, Text, HStack, Heading } from "@chakra-ui/react";

import { fixedColors } from "modules/preview/constants/specs";

import RadioCard from "modules/create/components/ui/RadioCard";
import { radioCardSizeSchema } from "modules/create/schemas/formSchema";
import { useTranslation } from "react-i18next";

const FixedColors = () => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="space-between" flexDirection="column" mt="20px">
      <Heading as="h3" variant="h3">
        {t("assets.color_scheme.fixed_colors")}
      </Heading>
      <Text mt="5px">{t("assets.color_scheme.fixed_description")}</Text>
      <HStack>
        {fixedColors.map((colorScheme) => (
          <HStack key={colorScheme.name}>
            <RadioCard
              size={radioCardSizeSchema.enum.smallRounded}
              roundedColors={[colorScheme.color, colorScheme.color]}
              value="1"
              isChecked={false}
            >
              <Text fontSize="sm" textAlign="center">
                {colorScheme.name}
              </Text>
              <Text fontSize="sm" textAlign="center">
                {colorScheme.color}
              </Text>
            </RadioCard>
          </HStack>
        ))}
      </HStack>
    </Flex>
  );
};

export default FixedColors;
