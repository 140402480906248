import React from "react";
import { HStack, Box, Container } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { PATHS } from "modules/shared/constants/paths";
import { ProductionAction } from "./ProductionAction";
import { ValidateAction } from "./ValidateAction";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";
import DuplicateGlobalMessage from "./DuplicateGlobalMessage";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import DeleteGlobalMessageAction from "./DeleteAction";

const ActionBarGlobalMessage = () => {
  const hasDuplicationAcces = useHasAccessRights(PATHS.GLOBAL_MESSAGES, [
    "POST",
  ]);
  const hasDeleteAccess = useHasAccessRights(PATHS.GLOBAL_MESSAGE, ["DELETE"]);
  const { id } = useParams<RouteIdParam>();

  return (
    <Box bgColor="black">
      <Container>
        <HStack spacing={5} p="10px" justifyContent="space-around" pr="30px">
          <ProductionAction />
          <ValidateAction />
          {hasDeleteAccess && <DeleteGlobalMessageAction />}
          {hasDuplicationAcces && id && (
            <DuplicateGlobalMessage id={id} color={"white"} />
          )}
        </HStack>
      </Container>
    </Box>
  );
};

export default ActionBarGlobalMessage;
