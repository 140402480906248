import { generatePath } from "react-router";
import { useQuery } from "react-query";
import { simpleRequest } from "core/services/api";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { customImageSchema } from "modules/images/schemas/customImageSchema";

export const getImage = (id?: number) =>
  id
    ? simpleRequest(generatePath(ENDPOINTS.IMAGE, { id }), customImageSchema)
    : undefined;

export function useImage(id?: number, fetch = true) {
  return useQuery(["images", id], () => getImage(id), {
    enabled: fetch,
  });
}
