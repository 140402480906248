const ALPHA_100 = "01";
const ALPHA_75 = "02";
const ALPHA_50 = "03";
const ALPHA_25 = "04";
const ALPHA_0 = "00";

const getAlphaMomaValueByAlpha = (alpha?: string) =>
  alpha === "00"
    ? ALPHA_0
    : alpha === "40"
    ? ALPHA_25
    : alpha === "80"
    ? ALPHA_50
    : alpha === "bf"
    ? ALPHA_75
    : ALPHA_100;

const getMomaColorFromHexColor = (hexColor: string) => {
  const colorValue = hexColor.slice(1, 7);
  const alphaIndex = hexColor.slice(7);
  const alphaMoma = getAlphaMomaValueByAlpha(alphaIndex);
  return `#${alphaMoma}${colorValue}`;
};

export default getMomaColorFromHexColor;
