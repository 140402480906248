import { useQuery } from "react-query";
import { inspectedCampaignsSchemas } from "..//schemas/InspectionCampaignSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { simpleRequest } from "core/services/api";
import { generatePath } from "react-router-dom";

export const getCampaignMonitor = (region?: string) => {
  if (region && region !== "ALL") {
    return simpleRequest(
      generatePath(ENDPOINTS.CAMPAIGN_INSPECTION, { region }),
      inspectedCampaignsSchemas
    );
  }
  return simpleRequest(
    generatePath(ENDPOINTS.CAMPAIGN_INSPECTION_ALL_REGIONS),
    inspectedCampaignsSchemas
  );
};

export function useInspectedCampaign(region?: string, fetch = true) {
  return useQuery(["campaigns", region], () => getCampaignMonitor(region), {
    enabled: !!region,
  });
}
