import React, { useCallback, ChangeEvent } from "react";
import { useRadioGroup, Flex, VStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import RadioCard from "../ui/RadioCard";
import {
  layoutFormTypeSchema,
  layoutEmptySchema,
} from "modules/preview/schemas/templateSchemas";
import {
  PreviewFormType,
  radioCardSizeSchema,
} from "modules/create/schemas/formSchema";
import useIsSide from "modules/create/hooks/useIsSide";
import useDisableForm from "modules/preview/hooks/useDisableForm";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import { useIsAdministrator } from "modules/roles/hooks/useIsAdministrator";
import { defaultEmptyFormValues } from "modules/create/constants/defaultEmptyValues";
import { defaultFormValues } from "modules/create/constants/defaultValues";

const LayoutForm = () => {
  const { control, getValues, setValue, reset } =
    useFormContext<PreviewFormType>();
  const { t } = useTranslation();
  const isAdmin = useIsAdministrator();

  const {
    field: { ref, ...radioGroupProps },
  } = useController({
    name: "layout",
    control,
  });

  const { getRootProps, getRadioProps } = useRadioGroup(radioGroupProps);

  const side = useIsSide();
  const group = getRootProps();
  const disabled = useDisableForm();
  const options = isAdmin
    ? [...layoutFormTypeSchema.options, ...layoutEmptySchema.options]
    : layoutFormTypeSchema.options;

  const { setFeedback } = useFeedbackStore();

  const handleChangeCallback = useCallback(
    (
      changeEvent: ChangeEvent<HTMLInputElement>,
      onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    ) => {
      if (onChange) {
        if (
          !(changeEvent.target.value === "full4") &&
          getValues("extraBanner")
        ) {
          setValue("extraBanner", false);
          if (side) {
            setFeedback(
              feedbackSchema.enum.error,
              actionsSchema.enum.changeLayout
            );
          }
        }
        // if we select an empty layout, the whole form must be reset to empty values
        if (changeEvent.target.value === "empty") {
          reset(defaultEmptyFormValues);
          // if the previous value was the empty layout, we must reset the form to default values
        } else if (getValues("layout") === "empty") {
          reset(defaultFormValues);
        }
        onChange(changeEvent);
      }
    },
    [getValues, reset, setValue]
  );

  return (
    <Flex
      {...group}
      flexDirection="row"
      flexWrap="wrap"
      data-testid="layout-form"
    >
      {options.map((layout) => {
        const radio = getRadioProps({ value: layout });
        return (
          <RadioCard
            role="radio"
            isDisabled={disabled}
            key={layout}
            {...radio}
            onChange={(e) => handleChangeCallback(e, radio.onChange)}
            size={
              side
                ? radioCardSizeSchema.enum.medium
                : radioCardSizeSchema.enum.large
            }
          >
            <VStack maxWidth={side ? "150px" : "250px"}>
              <Text fontSize="sm">{t(`specs.name.${layout}` as const)}</Text>
              <Text fontSize="x-small">
                {t(`specs.description.${layout}` as const)}
              </Text>
            </VStack>
          </RadioCard>
        );
      })}
    </Flex>
  );
};

export default LayoutForm;
