import React from "react";
import { BackgroundFrame } from "modules/preview/schemas/specSchemas";
import FixedBox from "core/components/FixedBox/FixedBox";
import { FrameProps } from "modules/preview/components/Frames";
import { getBoxProps } from "../../helpers/getBoxProps";
import useColor from "modules/preview/hooks/useColor";

const BackgroundBlock = (props: FrameProps<BackgroundFrame>) => {
  const foregroundColor = useColor(props.frame.foregroundColor);
  const backgroundColor = useColor(props.frame.backgroundColor);

  return (
    <FixedBox
      {...getBoxProps(props)}
      bgColor={backgroundColor}
      borderColor={foregroundColor}
    />
  );
};

export default BackgroundBlock;
