const colors = {
  amaranth: "#DE324C",
  monza: "#B5001B",
  sunglo: "#E66579",
  dark: "#22262A",
  abbey: "#555557",
  jumbo: "#838383",
  silver: "#C1BDBD",
  silver_gray: "#00000019",
  zircon: "#F3F3F3",
  malibu: "#68C0FF",
  white: "#FFFFFF",
  error: "#BB0002",
  warning: "#FFA500",
  validation: "#018901",
  cosmos: "#FFE8E8",
  snowyMint: "#E7FFE7",
  alice: "#E9F6FF",
  status: "#C4C4C4",
  wewak: "#EF98A5",
};

export default colors;
