import React, { useMemo } from "react";
import { merge } from "remeda";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Button } from "@chakra-ui/react";

import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import getTemplateFromFormValues from "modules/preview/helpers/getTemplateFromFormValues";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import { useSaveTemplate } from "modules/actions/hooks/useSaveTemplate";
import { useRequestTemplateValidation } from "modules/actions/hooks/useRequestTemplateValidation";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import useTemplate from "modules/preview/hooks/useTemplate";
import CheckIcon from "core/components/InterfaceIcons/CheckIcon";

const ValidateAction = () => {
  const { t } = useTranslation();
  const { data } = useTemplate(false);
  const save = useSaveTemplate();
  const validate = useRequestTemplateValidation();
  const context = useFormContext<PreviewFormType>();
  const { setFeedback } = useFeedbackStore();
  const status = useMemo(() => data?.functional_details?.status, [data]);
  const disabled = !status || status !== "DRAFT";

  const handleError = () => {
    setFeedback(feedbackSchema.enum.error, actionsSchema.enum.save);
  };

  const handleValidate = (formValues: PreviewFormType) => {
    const template = merge(data, getTemplateFromFormValues(formValues));
    save.mutate(template, {
      onSuccess: (_, { template_id }) => {
        template_id && validate.mutate(template_id);
      },
    });
  };

  return (
    <Button
      onClick={() =>
        setFeedback(
          feedbackSchema.enum.warning,
          actionsSchema.enum.validate,
          context.handleSubmit(handleValidate, handleError)
        )
      }
      leftIcon={<CheckIcon />}
      variant="primary-solid"
      isLoading={save.isLoading || validate.isLoading}
      disabled={disabled}
    >
      {t("actions.validate.button")}
    </Button>
  );
};

export default ValidateAction;
