import React from "react";
import { FormLabel, HStack } from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from "react-i18next";
import { Controller, Control } from "react-hook-form";
import { TargetFormValues } from "modules/target/schemas/formSchemas";
import CalendarInput from "core/components/CalendarInput/CalendarInput";

interface Props {
  control: Control<TargetFormValues>;
}

const DiffusionForm = ({ control }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <>
      <FormLabel color="abbey" mt="30px">
        {t("actions.target.form.diffusion.label")}
      </FormLabel>
      <HStack>
        <Controller
          control={control}
          name="startDate"
          render={({ value, onChange }) => (
            <CalendarInput
              locale={language}
              onChange={onChange}
              selected={value}
              id="startDate"
            />
          )}
        />
        <Controller
          control={control}
          name="endDate"
          render={({ value, onChange }) => (
            <CalendarInput
              locale={language}
              onChange={onChange}
              selected={value}
              id="endDate"
            />
          )}
        />
      </HStack>
    </>
  );
};

export default DiffusionForm;
