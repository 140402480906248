import { User } from "modules/users/schemas/usersModelSchemas";
import {
  CreateUserFormValues,
  UpdateUserFormValues,
} from "modules/user/informations/schemas/formSchemas";

const getUserFromFormValues = (
  values: CreateUserFormValues | UpdateUserFormValues
): User => ({
  name: values.name,
  email: values.email,
  status: values.status,
  regions: values.regions,
  roles: values.roles,
  password: values.password || undefined,
});

export default getUserFromFormValues;
