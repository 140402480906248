import * as z from "zod";

export const profileCardsColumnNameSchema = z.enum([
  "id",
  "target",
  "description",
  "region",
]);
export const adminCardsColumnNameSchema = z.enum([
  "id",
  "target",
  "description",
  "region",
  "actions",
]);

export type AdminCardsColumnName = z.infer<typeof adminCardsColumnNameSchema>;
export type ProfileCardsColumnName = z.infer<
  typeof profileCardsColumnNameSchema
>;
export type CardsColumnName = AdminCardsColumnName | ProfileCardsColumnName;
