import React from "react";
import { createIcon } from "@chakra-ui/react";

const DuplicateIcon = createIcon({
  displayName: "DuplicateIcon",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M16 1H4C2.9 1 2 1 2 1V17H4V3H16V1ZM19 5H8C6.9 5 6 5 6 5V23C6 23 6.9 23 8 23H19C20.1 23 21 23 21 23V5C21 5 20.1 5 19 5ZM19 21H8V7H19V21Z"
        fill="currentColor"
      />
    </>
  ),
});

export default DuplicateIcon;
