import { useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { simpleRequest } from "core/services/api";
import { methodSchema } from "core/schemas/http";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { useMutationWithErrorHandling } from "modules/shared/hooks/useMutationWithErrorHandling";
import { feedbackSchema } from "core/components/ActionFeedback/schemas/actionSchemas";
import { genericSchema } from "../schemas/schemas";

interface EmergencyStopProps {
  region: string;
  is_active: boolean;
}

export const getEmergencyStopStatus = ({
  region,
  is_active,
}: EmergencyStopProps) => {
  return simpleRequest(
    generatePath(ENDPOINTS.EMERGENCY_STOP, { region }),
    genericSchema,
    methodSchema.enum.post,
    { activate: is_active }
  );
};
export function useEmergencyStop() {
  const queryClient = useQueryClient();
  const { setFeedback } = useFeedbackStore();
  return useMutationWithErrorHandling(getEmergencyStopStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(["regionListStatus"]);
      setFeedback(feedbackSchema.enum.info);
    },
  });
}
