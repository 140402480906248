import React, { useMemo, useCallback } from "react";
import { Table, Tbody, Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, Column, Cell, useFilters } from "react-table";

import TableHeader from "modules/shared/components/Table/TableHeader";
import TableRow from "modules/shared/components/Table/TableRow";
import TableCell from "modules/shared/components/Table/TableCell";
import filterRowsByName from "modules/filters/helpers/filterRowsByName";
import { useRegionStatus } from "modules/emergencyStop/hooks/useRegionStatus";
import {
  RegionColumnName,
  regionColumnName,
  RegionStatus,
} from "./schemas/schemas";
import EmergencyStopModal from "./EmergencyStopModal";

const emptyArray: never[] = [];
const RegionsStatusList = () => {
  const { data } = useRegionStatus();
  const { t, i18n } = useTranslation();

  const getHeaderName = useCallback(
    (name: RegionColumnName) => <Box minW={24}>{name}</Box>,
    [i18n.language]
  );

  const columns = useMemo(
    () =>
      regionColumnName.options.map((name) => ({
        Header: <Box minW={24}>{name}</Box>,
        accessor: name,
        Cell: ({
          cell: { value, row },
        }: {
          cell: Cell<RegionStatus, string | string[]>;
        }) =>
          name === regionColumnName.enum.delete ? (
            <EmergencyStopModal region={row.values as RegionStatus} />
          ) : (
            <TableCell value={value ?? "N/A"} />
          ),
        filter: (...args) => filterRowsByName(...args, name),
      })),
    [getHeaderName, data]
  ) as Column<any>[];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data || emptyArray,
      },
      useFilters,
      useSortBy
    );

  if (data?.length === 0) {
    return (
      <Flex mt={20} d="column">
        <Text m="20px" align="center">
          {t("supervision.no_message")}
        </Text>
      </Flex>
    );
  }

  return (
    <>
      <Box position="relative" marginTop="40px"></Box>
      <Box overflow="auto" pt={5} pb={10}>
        <Table
          {...getTableProps()}
          data-testid="regions-table"
          textStyle="bodySmall"
          w="100%"
          min-width="150px"
        >
          <TableHeader headerGroups={headerGroups} />
          <Tbody {...getTableBodyProps()} bgColor="white">
            {rows.map((row) => {
              prepareRow(row);
              return <TableRow key={row.id} row={row} />;
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default RegionsStatusList;
