import React from "react";
import { Thead, Tr, Th, chakra } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { HeaderGroup } from "react-table";
import ArrowUpIcon from "core/components/InterfaceIcons/ArrowUpIcon";
import ArrowDownIcon from "core/components/InterfaceIcons/ArrowDownIcon";

interface Props<T extends object> {
  headerGroups: HeaderGroup<T>[];
}

const Header = styled(Th)<{ disabled: boolean }>`
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
`;

const TableHeader = <T extends object>({ headerGroups }: Props<T>) => (
  <Thead bgColor="black">
    {headerGroups.map((headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <Header
            disabled={!!column.disableSortBy}
            color="white"
            {...column.getHeaderProps(
              (column as HeaderGroup<T>).getSortByToggleProps()
            )}
          >
            {column.render("Header")}

            <chakra.span width="5px" pl="1" display="inline-block">
              {(column as HeaderGroup<T>).isSorted ? (
                (column as HeaderGroup<T>).isSortedDesc ? (
                  <ArrowDownIcon aria-label="sorted descending" />
                ) : (
                  <ArrowUpIcon aria-label="sorted ascending" />
                )
              ) : (
                ""
              )}
            </chakra.span>
          </Header>
        ))}
      </Tr>
    ))}
  </Thead>
);

export default TableHeader;
