import { useQuery } from "react-query";
import { simpleRequest } from "core/services/api";
import { customImageBodySchema } from "modules/images/schemas/customImageSchema";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";

export const fetchImages = async () => {
  const images = await simpleRequest(ENDPOINTS.IMAGES, customImageBodySchema);

  if (Array.isArray(images)) {
    return images.filter((image) => image.type === "logo");
  }
  return [];
};

export function useImages(fetch = true) {
  return useQuery(["images"], fetchImages, {
    enabled: fetch,
    suspense: true,
  });
}
