import { EmptyPreviewFormType } from "modules/create/schemas/formSchema";
import { DraftTemplate, Template, Theme } from "../schemas/templateSchemas";

import getFormValuesFromTemplateName from "./getFormValuesFromTemplateName";
import getAdvancedSettingsFromConfiguration from "./getAdvancedSettingsFromConfiguration";
import getFormValuesFromTechnicalDetails from "./getFormValuesFromTechnicalDetails";
import getVariablesFromDetails from "./getVariablesFromDetails";
import getStorablesFromDetails from "./getStorablesFromDetails";
import getBuiltInLogosFromWindowLogo from "./getBuiltInLogosFromWindowLogo";

const getFormValuesFromTemplate = (
  {
    template_name,
    technical_details,
    configuration_details,
    variable_details,
    storable_details,
  }: Template | DraftTemplate,
  isCreationMode = false
) => {
  const { layout, position, extraBanner } = getFormValuesFromTemplateName(
    technical_details?.Window_Layout
  );
  const { palette, regions, theme, genre, Behavior, label } =
    configuration_details;
  const { colors, text, alignment } =
    getFormValuesFromTechnicalDetails(technical_details);
  const advancedSettings = getAdvancedSettingsFromConfiguration(
    configuration_details
  );
  const builtInLogos = getBuiltInLogosFromWindowLogo(
    technical_details.Window_Logo
  );

  const customLogo = technical_details.Frame0_Logo;

  const variables = getVariablesFromDetails(variable_details);
  const storables = getStorablesFromDetails(storable_details);

  return {
    layout,
    name: template_name,
    label,
    colorScheme: palette?.toString(),
    position,
    extraBanner,
    colors,
    text,
    alignment,
    builtInLogos,
    customLogo,
    regions: isCreationMode ? [] : regions,
    variables,
    storables,
    theme: theme as Theme,
    genre,
    behavior: Behavior,
    advancedSettings,
  } as EmptyPreviewFormType;
};

export default getFormValuesFromTemplate;
