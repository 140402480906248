import { useQuery } from "react-query";
import { dashboardSchema } from "modules/monitoring/ProbeList/schemas/formSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { simpleRequest } from "core/services/api";

export function getGraphs() {
  return async () => {
    const rawdata = await simpleRequest(ENDPOINTS.DASHBOARDS, dashboardSchema);
    const graphsdata = rawdata.filter(
      (dashboard) => dashboard.category_name === "GRAPH"
    );
    const data = dashboardSchema.parse(graphsdata);
    return data;
  };
}

export function useGraphs(fetch = true) {
  return useQuery(["graphs"], getGraphs(), {
    enabled: fetch,
  });
}
