import React, { useMemo, useEffect } from "react";
import { Container, Box, Heading, Flex } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import Preview from "modules/preview/components/Preview";
import {
  PreviewFormType,
  previewFormSchema,
} from "modules/create/schemas/formSchema";
import ActionBar from "modules/actions/components/ActionBar";
import useTemplate from "modules/preview/hooks/useTemplate";
import getFormValuesFromTemplate from "modules/preview/helpers/getFormValuesFromTemplate";
import { defaultFormValues } from "modules/create/constants/defaultValues";
import BottomActions from "modules/comments/components/BottomActions";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";
import Feedback from "modules/feedback/components/Feedback";
import BackToDraft from "modules/backtodraft/components/BackToDraft";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { feedbackSchema } from "core/components/ActionFeedback/schemas/actionSchemas";
import ErrorHandler from "modules/shared/components/ErrorHandler/ErrorHandler";
import { frameWidth } from "modules/preview/constants/frameDimensions";

const Template: React.FC<{ templateId?: string }> = ({ templateId }) => {
  const { data } = useTemplate(true, templateId ?? undefined); //

  const { setFeedback, action, type } = useFeedbackStore();
  const methods = useForm<PreviewFormType>({
    resolver: zodResolver(previewFormSchema),
    shouldUnregister: false,
    defaultValues: useMemo(
      () => (data ? getFormValuesFromTemplate(data) : defaultFormValues), // because of suspense, we should never fallback to defaultFormValues
      [data]
    ),
  });
  const status = useMemo(() => data?.functional_details.status, [data]);
  const title = useMemo(() => data?.template_name, [data]);

  useEffect(() => {
    if (
      status &&
      status !== "DRAFT" &&
      status !== "PRODUCTION" &&
      status !== "ARCHIVED"
    ) {
      setFeedback(feedbackSchema.enum.info);
    }
  }, [status]);

  const marginTop = useMemo(() => {
    if (
      status &&
      ["PENDING_LEGAL", "PENDING_COMMERCIAL", "REFUSED"].includes(status)
    ) {
      return "1328px";
    }
    if (status && ["STOPPED", "VALIDATED"].includes(status)) {
      return "1140px";
    }

    return action || type ? "1040px" : "880px";
  }, [status, action]);

  return (
    <Flex>
      <Box w={0}>
        <Flex justifyContent="center" w="100vw">
          <Container>
            <Box w={`${frameWidth}px`} mt={marginTop}>
              <ErrorHandler>
                <BottomActions />
              </ErrorHandler>
            </Box>
          </Container>
        </Flex>
      </Box>

      <Box w="100%">
        <FormProvider {...methods}>
          <ActionBar />
          <Container>
            <BreadNav />
            <BackToDraft />
            <Feedback />
            <Heading as="h1" size="lg" mb={10} mt={10}>
              {title}
            </Heading>
            <form>
              <Preview />
            </form>
          </Container>
        </FormProvider>
      </Box>
    </Flex>
  );
};

export default Template;
