import React from "react";
import { Box, Container } from "@chakra-ui/react";

import { useTemplates } from "modules/templates/hooks/useTemplates";
import BreadNav from "modules/shared/components/BreadNav/BreadNav";
import Feedback from "modules/feedback/components/Feedback";
import HotZone from "modules/templates/components/HotZone";
import TemplatesTable from "modules/templates/components/TemplatesTable";
import { useContentFilter } from "modules/filters/context/ContentFilterContext";

const Templates = () => {
  const { filterText } = useContentFilter();
  const { data } = useTemplates(true, filterText);
  return (
    <>
      <Feedback />
      <Container data-testid="templates">
        <BreadNav />
        <Box>
          <HotZone />
          <TemplatesTable templates={data} filterText={filterText} />
        </Box>
      </Container>
    </>
  );
};

export default Templates;
