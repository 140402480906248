import { useEffect, useRef } from "react";
import { LayoutFormType, HybridType } from "../schemas/templateSchemas";

export default function usePreviousLayout(
  layout: any
): LayoutFormType | HybridType | undefined {
  const ref = useRef();

  useEffect(() => {
    ref.current = layout;
  }, [layout]);
  return ref.current;
}
