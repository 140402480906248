import { useQuery } from "react-query";
import { simpleRequest } from "core/services/api";
import { templatesListSchema } from "modules/templates/schemas/templateModelSchemas";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";
import { useRole } from "modules/roles/hooks/useRole";
import templatesListTransform from "modules/templates/helpers/templatesListTransform";

export const getTemplates = (filterText?: string) => {
  const url =
    filterText && filterText.length !== 0 && filterText !== " "
      ? `${ENDPOINTS.TEMPLATES}?text=${encodeURIComponent(filterText)}`
      : ENDPOINTS.TEMPLATES;
  return simpleRequest(url, templatesListSchema);
};
export function useTemplates(fetch = true, filterText?: string) {
  const roles = useRole();
  return useQuery(["templates", filterText], () => getTemplates(filterText), {
    select: (data) => templatesListTransform(data, roles),
    enabled: fetch,
    suspense: true,
  });
}
