import {
  layoutFormTypeSchema,
  genreSchema,
  themeSchema,
  behaviorSchema,
} from "../../preview/schemas/templateSchemas";
import { bannerPositionSchema } from "../../preview/schemas/specSchemas";
import { PreviewFormType } from "modules/create/schemas/formSchema";
import getColorsFromLayout from "modules/preview/helpers/getColorsFromLayout";

export const defaultLogo = "canal+";
export const defaultColor = "1";
export const defaultLayout = layoutFormTypeSchema.enum.full1;
export const defaultBannerPosition = bannerPositionSchema.enum.top;

export const defaultTheme = themeSchema.enum.loyalty;
export const defaultGenre = genreSchema.enum.operator;
export const defaultVariables = [];
export const defaultStorables = [];
export const defaultBehavior = behaviorSchema.enum["pop-valid"];

export const defaultAdvancedSettingsValues = {
  messageCount: "1",
  messageDelay: "4",
  popupTimeOut: "120",
  messageTimeOut: "120",
};

export const defaultAdvancedSettingsMaxValues = {
  messageCount: 255,
  messageDelay: 65535,
  popupTimeOut: 255,
  messageTimeOut: 255,
};

export const defaultAdvancedSettingsHour = null;

export const defaultColorsValue = {
  Window_BackColor: "4",
  Window_TextColor: "1",
  Window_BorderColor: "4",
  Frame0_BackColor: "15",
  Frame0_BorderColor: "15",
  Frame1_BackColor: "12",
  Frame1_TextColor: "1",
  Frame1_BorderColor: "12",
  Frame2_BackColor: "12",
  Frame2_TextColor: "1",
  Frame2_BorderColor: "12",
  Frame3_BackColor: "12",
  Frame3_TextColor: "1",
  Frame3_BorderColor: "12",
};

export const defaultFormValues: Partial<PreviewFormType> = {
  colors: getColorsFromLayout(defaultLayout, defaultColorsValue, false),
  name: "",
  label: "",
  layout: defaultLayout,
  colorScheme: defaultColor,
  position: defaultBannerPosition,
  extraBanner: false,
  theme: defaultTheme,
  genre: defaultGenre,
  behavior: defaultBehavior,
  advancedSettings: {
    ...defaultAdvancedSettingsValues,
    startHour: defaultAdvancedSettingsHour,
    endHour: defaultAdvancedSettingsHour,
  },
  variables: defaultVariables,
  storables: defaultStorables,
  builtInLogos: { canalplus: true, canalsat: true },
  customLogo: undefined,
};

export const defaultColorScheme = {
  name: undefined,
  description: undefined,
  color_12: "#000000",
  color_13: "#000000",
  color_14: "#000000",
  color_15: "#000000",
};
