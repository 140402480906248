import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { PATHS } from "modules/shared/constants/paths";

import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Link,
  Button,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { useLogin } from "modules/authentication/hooks/useLogin";
import { CredentialsForm } from "modules/authentication/schemas/credentialsFormSchema";

const LoginForm = () => {
  const { register, handleSubmit, unregister } = useForm<CredentialsForm>();
  const { t } = useTranslation();
  const { isLoading, isError, mutate } = useLogin();
  const onSubmit = (data: CredentialsForm) => {
    mutate(data, {
      onSuccess: () => {
        unregister("login");
        unregister("password");
      },
    });
  };

  return (
    <Box maxWidth="300px" mt="50px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="email" isRequired>
          <FormLabel>{t("login.email")}</FormLabel>
          <Input
            variant="outline"
            type="email"
            name="login"
            ref={register}
            errorBorderColor="error"
          />
        </FormControl>
        <FormControl id="password" mt="30px" isRequired>
          <FormLabel>{t("login.password")}</FormLabel>
          <Input
            variant="outline"
            type="password"
            name="password"
            ref={register}
            errorBorderColor="error"
          />
        </FormControl>
        <VStack>
          <Button
            type="submit"
            variant="primary-solid"
            mt="30px"
            isLoading={isLoading}
            loadingText={t("login.loading")}
          >
            {t("login.login")}
          </Button>
          <Link as={RouterLink} to={PATHS.RESET_CREDENTIAL_EMAIL_STEP}>
            <Text
              fontSize="sm"
              textDecoration="underline"
              mt={5}
              bg="none"
              textStyle="subtitle"
            >
              {t("login.reset.label")}
            </Text>
          </Link>
          {isError && (
            <Box mt="10px" data-testid="login-error">
              {t("login.error")}
            </Box>
          )}
        </VStack>
      </form>
    </Box>
  );
};

export default LoginForm;
