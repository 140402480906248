import React, { Suspense } from "react";
import {
  Heading,
  VStack,
  HStack,
  Accordion,
  AccordionItem,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";

import { useForm, FormProvider } from "react-hook-form";
import AccordionTitle from "core/components/AccordionTitle/AccordionTitle";

import { useUserRegions } from "modules/regions/hooks/useUserRegions";
import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";
import { zodResolver } from "@hookform/resolvers/zod";
import RegionForm from "modules/globalMessage/components/RegionForm";
import {
  GlobalMessageFormValues,
  globalMessageFormSchema,
} from "modules/globalMessage/schemas/globalMessageFormSchema";
import { useTranslation } from "react-i18next";
import TemplateForm from "modules/globalMessage/components/TemplateForm";
import TemplatePreview from "modules/globalMessage/components/TemplatePreview";
import DateForm from "modules/globalMessage/components/DateForm";
import ValidCardSwitch from "modules/globalMessage/components/ValidCardSwitch";
import CountriesForm from "modules/globalMessage/components/CountriesForm";
import SaveGlobalMessage from "../../modules/globalMessage/components/SaveGlobalMessage";
import TitleInput from "modules/globalMessage/components/TitleInput";
import { defaultFormValues } from "modules/globalMessage/constants/defaultValues";
import ActionBarGlobalMessage from "modules/globalMessage/components/ActionBarGlobalMessage";
import { useHasAccessRights } from "modules/actions/hooks/useHasAccessRights";
import { PATHS } from "modules/shared/constants/paths";
import useDuplicateGlobal from "modules/globalMessage/hooks/useDuplicateGlobalMessage";
import useGlobalMessage from "modules/globalMessage/hooks/useGlobalMessage";
import useLocationQueryParams from "core/hooks/useLocationQueryParams";

const CreateGlobalMessage = () => {
  const { t } = useTranslation();

  const queryParams = useLocationQueryParams();
  const duplicateGlobalMessage = useDuplicateGlobal();
  const globalMessage = useGlobalMessage();
  const { data } = queryParams?.get("id")
    ? duplicateGlobalMessage
    : globalMessage;

  const hasRightForGlobalMessages = useHasAccessRights(
    PATHS.CREATE_GLOBALMESSAGE,
    ["POST"]
  );

  const methods = useForm<GlobalMessageFormValues>({
    resolver: zodResolver(globalMessageFormSchema),
    defaultValues: data ?? defaultFormValues,
  });

  const userRegions = useUserRegions();

  return (
    <FormProvider {...methods}>
      {hasRightForGlobalMessages && <ActionBarGlobalMessage />}

      <ScreenContainer>
        <HStack justifyContent="space-between">
          <Heading as="h2" variant="h2" my="80px">
            {t("global_message.create")}
          </Heading>
          <SaveGlobalMessage />
        </HStack>
        {data ? (
          <Text>
            {t("preview.status")} {data?.status}
          </Text>
        ) : null}
        <HStack
          spacing={20}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <VStack pt={9} width="100%">
            <Accordion allowMultiple allowToggle w="100%">
              <AccordionItem>
                <AccordionTitle>{t("global_message.title")}</AccordionTitle>
                <AccordionPanel pb={4}>
                  <TitleInput />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionTitle>{t("global_message.region")}</AccordionTitle>
                <AccordionPanel pb={4}>
                  <RegionForm regions={userRegions} />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionTitle>
                  {t("global_message.template.title")}
                </AccordionTitle>
                <AccordionPanel pb={4}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <TemplateForm />
                  </Suspense>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionTitle>
                  {t("actions.target.form.diffusion.label")}
                </AccordionTitle>
                <AccordionPanel pb={4}>
                  <DateForm />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionTitle>
                  {t("global_message.filters.title")}
                </AccordionTitle>
                <AccordionPanel pb={4}>
                  <ValidCardSwitch />
                  <CountriesForm />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </VStack>
          <TemplatePreview />
        </HStack>
      </ScreenContainer>
    </FormProvider>
  );
};

export default CreateGlobalMessage;
