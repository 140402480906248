import {
  layoutFormTypeSchema,
  layoutBannerTypeSchema,
  layoutHybridTypeSchema,
  TemplateLayoutType,
  LayoutEmpty,
  layoutEmptySchema,
} from "../schemas/templateSchemas";

import {
  bannerPositionSchema,
  BannerPosition,
} from "modules/preview/schemas/specSchemas";

function isBanner(layout: TemplateLayoutType) {
  return layout === layoutFormTypeSchema.enum.banner;
}

function hasOptionnalBanner(layout: TemplateLayoutType) {
  return (
    layout === layoutFormTypeSchema.enum.full4 ||
    layout === layoutHybridTypeSchema.enum.hybridtop ||
    layout === layoutHybridTypeSchema.enum.hybrid ||
    layout === layoutHybridTypeSchema.enum.hybridmid
  );
}

const bannerTypeByPosition = {
  [bannerPositionSchema.enum.top]: layoutBannerTypeSchema.enum.bannertop,
  [bannerPositionSchema.enum.bottom]: layoutFormTypeSchema.enum.banner,
  [bannerPositionSchema.enum.center]: layoutBannerTypeSchema.enum.bannermid,
};

const hybridTypeByPosition = {
  [bannerPositionSchema.enum.top]: layoutHybridTypeSchema.enum.hybridtop,
  [bannerPositionSchema.enum.bottom]: layoutHybridTypeSchema.enum.hybrid,
  [bannerPositionSchema.enum.center]: layoutHybridTypeSchema.enum.hybridmid,
};

const getSpecLayoutFromFormLayout = (
  layout: TemplateLayoutType | LayoutEmpty,
  position?: BannerPosition | null,
  extraBanner?: boolean | null
) => {
  if (layoutEmptySchema.check(layout)) {
    return layout;
  }
  if (extraBanner && hasOptionnalBanner(layout)) {
    return hybridTypeByPosition[position!] as TemplateLayoutType;
  } else if (isBanner(layout)) {
    return bannerTypeByPosition[position!] as TemplateLayoutType;
  } else return layout;
};

export default getSpecLayoutFromFormLayout;
