import React, { useEffect, useState } from "react";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Input,
  useTheme,
} from "@chakra-ui/react";
import { useContentFilter } from "modules/filters/context/ContentFilterContext";
import { useTranslation } from "react-i18next";

const ContentFilter = () => {
  const { t } = useTranslation();

  const { filterText, setFilterText } = useContentFilter();
  const { colors } = useTheme();

  const [inputValue, setInputValue] = useState(filterText);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setInputValue(filterText);
  }, [filterText]);

  const handleSubmit = () => {
    setFilterText(inputValue);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          data-testid="content-filter"
          border={
            filterText.length > 0
              ? "3px solid #68C0FF"
              : `1px solid ${colors.dark}`
          }
          variant="tertiary-ghost"
        >
          {t("filters.template_content.title")}
        </Button>
      </PopoverTrigger>
      <PopoverContent minWidth={80} width="100%">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader> {t("filters.template_content.title")}</PopoverHeader>
        <PopoverBody>
          <Input
            data-testid="filter-input"
            defaultValue={filterText}
            value={inputValue}
            onChange={handleChange}
            placeholder={t("filters.template_content.title")}
          />
          <Button onClick={handleSubmit} mt={4} variant="tertiary-ghost">
            {" "}
            {t("filters.template_content.submit")}
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ContentFilter;
